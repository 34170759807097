<template>
  <!-- RURBAN UPDATED -->
  <div>
    <!----------------------------------- Response Page top Card Container ---------------------->
    <div class="responses__container--header" v-if="!loading">
      <div>
        <div class="res__cont--heading">{{ responses.length }} Responses</div>
        <!-- Maximum Responses Limit Exceeded -->
        <!-- <div v-if="responses.length > maxResLimit" class="fs-rs_res-limit-exceed">
          <div class="res-exceed-msg">
            <i class="light-icon-alert-triangle"></i>
            <p>Max Response Limit Exceeded. Please Upgrade your plan to see all responses!!!</p>
          </div>
        </div> -->
        <!--  -->
        <!-- Accepting response Toggle -->
        <div class="accepting_res" v-if="formData" :class="{ accepting_res__block: formData.accepting_responses }">
          <!-- <div class="accepting_res--toggle" v-if="formData" :class="{ 'not_accepting_res--toggle': !formData.accepting_responses }">
            <d-toggle-switch v-if="formData" @click="updateResAccept" labelPosition="left" :label="toggle_label" :value="formData.accepting_responses"></d-toggle-switch>
          </div> -->
          <!-- Msg BLock When not accepting Responses -->
          <div v-if="!formData.accepting_responses" class="respondant__msg__block">
            <div class="msg__block__row">
              <div><lv-input class="respondant__msg__block--input" label="Message for respondents" placeholder="This form is no longer accepting responses" v-model="not_accepting_res_msg"></lv-input></div>
              <div @click="saveNotResAcceptMsg" class="save__respondant__msg__btn"><lv-button class="lv--success" size="md" label="Save Message"></lv-button></div>
            </div>
          </div>
        </div>
        <!--  -->
        <!-- ---------------------Tabs ---------------->
        <div class="res_tabs">
          <div class="tab-buttons">
            <div class="tab-button" :class="{ active: activeTab === 'table' }" @click="activeTab = 'table'">Table</div>
            <div class="tab-button" :class="{ active: activeTab === 'individual' }" @click="activeTab = 'individual'">Individual</div>

            <div class="tab-button" :class="{ active: activeTab === 'stats' }" @click="activeTab = 'stats'">Stats</div>
          </div>
        </div>

        <!-- Tabs End -->
      </div>
    </div>
    <!-----------------------------------------------Response page CARD-2 ---------------------->
    <div class="responses__container--bottom" v-if="loading">
      <lv-list-loader :width="300" :height="100" style="margin: 128px auto; display: block;" />
    </div>
    <div class="responses__container--bottom" v-else-if="responses.length">
      <!----------------------- TABLE TAB Content -------------------->
      <div v-if="activeTab === 'table'">
        <div class="res-table__div">
          <responses-table :responses="responses" :schema="formData.schema" />
        </div>
      </div>
      <!-- ***** -->
      <!-----------------    INDIVIDUAL TAB Content ---------------------->
      <div v-if="activeTab === 'individual'">
        <!----Individual Response Navigation & Customization  -->
        <div class="individual_nav-row">
          <!--Response Navigation -->
          <div class="response-navigation">
            <Lv-Button icon="light-icon-arrow-left" outlined class="lv--primary" @click="decrementResponseIndex"></Lv-Button>
            <Lv-button class="--link-button lv--primary" outlined disabled="disabled">{{ responseIndex + 1 }}</Lv-button>
            <Lv-Button icon="light-icon-arrow-right" outlined class="lv--primary" @click="incrementResponseIndex"></Lv-Button>
          </div>
          <!-- ----- -->

          <!--  -->
          <!-- <div class="res_delete">
            <i class="light-icon-trash" @click="deleteIndividualResponse(responseIndex)" style="cursor:pointer"></i>
            <i class="light-icon-printer"></i>
          </div> -->
        </div>
        <ResponsePrefill v-if="responses.length > 0" :response="responses[responseIndex]" :schema="formData.schema"></ResponsePrefill>
      </div>
      <!-- ends -->
      <!-----------------    STATS TAB Content ---------------------->
      <div v-if="activeTab === 'stats'">
        <!-- <ResponseStats :schema="formData.schema" :responses="responses"></ResponseStats> -->
        <ResponseDateStats :responses="responses" :formData="formData" v-if="responses.length"></ResponseDateStats>
        <ResponseChartWrapper :responses="responses" :formData="formData" v-if="responses.length"></ResponseChartWrapper>
      </div>
    </div>
    <div class="responses__container--bottom" v-else>No Response as of now.</div>

    <!-- Card 2 Container Ends -->
  </div>
</template>

<script>
import LvInput from 'lightvue/input';

import Responses from '../models/responses';
import Forms from '../models/form_schema';
import ResponsePrefill from 'builder/ResponsePrefill.vue';
import LvButton from 'lightvue/button';
// import ToggleButton from '../../bit/light-builder/builder/elements/ToggleButton';
import DToggleSwitch from 'lightvue/input-toggle';
// import Table from "../component,s/responses/Table.vue";
import ResponsesTable from '@main/components/responses/ResponsesTable';
// import Chart from 'lightvue/chart/Chart.vue';
import ResponseDateStats from '../components/responses/ResponseDateStats';
import ResponseChartWrapper from '../components/responses/ResponseChartWrapper';
import ResponseStats from '../components/responses/ResponseStats';

import LvListLoader from 'lightvue/skeleton/preset/ListLoader.vue';
import config from '../config/index';

export default {
  components: {
    ResponsePrefill,
    LvButton,
    LvInput,
    ResponsesTable,
    DToggleSwitch,
    ResponseDateStats,
    ResponseChartWrapper,
    ResponseStats,
    LvListLoader,
    // Chart,
    // PieChart,
    // Table
  },

  data() {
    return {
      loading: false,
      responseIndex: 0,
      responseCount: 0,
      formData: {},
      resFormID: '',
      responses: [],
      mongoResponses: [],
      responseHidden: false,
      activeTab: 'table',
      toggle_label: 'Accepting Responses',
      maxResLimit: '',
      not_accepting_res_msg: '',
    };
  },

  created() {
    console.log('Route Params', this.$route.params.form_slug);
    this.showResponses();
    console.log(this.responses);
    let queryActiveTab = this.$route.query.activetab;
    if (queryActiveTab == 'table' || queryActiveTab == 'individual' || queryActiveTab == 'stats') {
      this.activeTab = queryActiveTab;
    }
  },

  methods: {
    async showResponses() {
      this.loading = true;
      let resForm = await Forms.getBySlug(this.$route.params.form_slug);
      this.formData = resForm.data;
      // this.maxResLimit = this.formData.max_responses;
      this.not_accepting_res_msg = this.formData.not_accepting_res_msg;
      this.resFormID = resForm.data._id;
      this.toggle_label = this.formData.accepting_responses ? 'Accepting Responses' : 'Not Accepting Responses';
      // console.log("FORM ID", this.resFormID);
      // (this.responses = await Responses.getResponses(this.resFormID));

      try {
        let apiResp = await fetch(`${config.api_base_url}/stats/get_by_slug`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            form_slug: this.$route.params.form_slug + '',
          }),
        });
        apiResp = await apiResp.json();
        console.log(apiResp);
        if (apiResp.status !== 'error') {
          this.responses = apiResp;
          this.responses = this.responses.map(res => {
            return { ...res, response: res.response_data };
          });
          this.responseCount = this.responses.length;
          // console.log("Reponses",this.responses);
          // console.log('RESPONSES on response Page', this.responses[0].date_submitted.seconds);
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    // Toggle Accepting Response
    async updateResAccept() {
      if (this.formData && this.resFormID) {
        this.formData.accepting_responses = !this.formData.accepting_responses;
        var updateForm = new Forms({
          docId: this.resFormID,
        });
        updateForm.push({
          accepting_responses: this.formData.accepting_responses,
        });
        await updateForm.save();

        if (!this.formData.accepting_responses) {
          this.toggle_label = 'Not ' + this.toggle_label;
        } else {
          this.toggle_label = 'Accepting Responses';
        }
        console.log('Accepting responses', updateForm.data.accepting_responses);
      }
    },

    // Update Not Accepting respondants Message
    async saveNotResAcceptMsg() {
      if (this.formData && this.resFormID) {
        var updateForm = new Forms({
          docId: this.resFormID,
        });
        updateForm.push({
          not_accepting_res_msg: this.not_accepting_res_msg,
        });
        await updateForm.save();
        this.$toast.add({
          content: 'Your message saved!!!',
          type: 'info',
          duration: 2000,
        });

        console.log('Not Accepting responses', updateForm.data.not_accepting_res_msg);
        debugger;
      }
    },

    incrementResponseIndex() {
      // if (this.responseIndex + 1 == this.maxResLimit) {
      //   this.$toast.add({
      //     content: 'Upgrade your Plan !!',
      //     type: 'warn',
      //     duration: 3000,
      //   });
      // }
      //  else if (this.responseIndex + 1 < this.responseCount - 1) {
      //   this.responseIndex++;
      // }
      if (this.responseIndex + 1 < this.responseCount - 1) {
        this.responseIndex++;
      }
    },

    decrementResponseIndex() {
      if (this.responseIndex > 0) {
        this.responseIndex--;
      }
    },

    async deleteIndividualResponse() {
      console.log('Response index Before', this.responseIndex);
      // ie Last index
      if (this.responseIndex + 1 == this.responseCount) {
        await Responses.deleteResponse(this.resFormID, this.responses[this.responseIndex].response_id);

        this.responses.splice(this.responseIndex, 1);
        this.responseIndex--;
        this.responseCount--;
        await Forms.updateTotalResponse(this.resFormID, -1);
      } else {
        await Responses.deleteResponse(this.resFormID, this.responses[this.responseIndex].response_id);

        this.responses.splice(this.responseIndex, 1);
        console.log('Removed', this.responseIndex + 1, 'Individual response');

        this.responseCount--;
        await Forms.updateTotalResponse(this.resFormID, -1);
      }
    },

    showSummary() {},
  },
};
</script>

<style lang="scss">
.responses__container--header,
.responses__container--bottom {
  margin: 24px;
  background: #ffffff;
  /* background: #f8f8f8; */
  /* height: 270px; */
  border-radius: 12px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
}

.res__cont--heading {
  background-color: #037f7f;
  color: #ffffff;
  text-align: center;
  padding: 30px;
  font-size: 32px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.res-exceed-msg {
  margin: 1.5rem 0;
  padding: 1rem 1rem;
  font-size: 16px;
  display: flex;
  i {
    font-size: 28px;
    font-weight: 600;
  }
  p {
    margin-top: 6px;
    margin-left: 10px;
  }
}
.res-exceed-msg {
  color: #89691c;
  background-color: #fdf7e7;
  border-top: 5px solid #f0ab00;
}

.accepting_res {
  margin: 12px;
  border: 1px solid rgb(179, 177, 177);
  border-radius: 8px;
  &.accepting_res__block {
    border: none;
  }
}

.accepting_res--toggle {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}
.not_accepting_res--toggle {
  background-color: #ee938f;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.respondant__msg__block {
  padding: 12px;
  /* display: flex; */
  /* justify-content: center; */

  /* justify-content: flex-start; */
}

.msg__block__row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.respondant__msg__block--input {
  width: 600px;
}

.save__respondant__msg__btn {
  margin-left: 30px;
  margin-top: 25px;
}

/* **** */

.response__header--tabs {
  display: flex;
  justify-content: right;
  align-items: right;
  margin: 16px 0px 16px 0px;
}

/* TABS */
.res_tabs {
  padding: 12px;
}
.res_tabs .tab-buttons {
  display: flex;
}
.res_tabs .tab-button {
  flex-grow: 1;
  display: block;
  background-color: #ffffff;

  padding: 1rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color ease 0.2s;
}

.tab-button {
  text-align: center;
}
.res_tabs .tab-button.active {
  background-color: #b2bec3;
}
.res_tabs .tab-content {
  width: 100%;
  padding: 1rem;
  background: #fff;
  text-align: left;
}

/* Individual response Customization */
.individual_nav-row {
  display: flex;
  padding: 12px;
  justify-content: space-around;
}

.response-navigation {
  /* margin-top: 16px; */
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.res_delete {
  display: flex;
  justify-content: flex-end;
  font: bold;
}

.res_delete i:hover {
  border-radius: 50%;
  background-color: #b2bec3;
}
.res_delete {
  .light-icon-trash,
  .light-icon-printer {
    font-size: 24px;
    padding: 6px;
  }
}

/*  */
.res-table__div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* CARD-2 */
.responses__container--bottom {
  padding: 16px;
}

/* Coming soon */
.coming-soon-block {
  padding-top: 44px;
  display: flex;
  justify-content: center;

  /* justify-content: center; */
}

.coming-soon-letter-card {
  /* height: 88px; */
  width: 68px;
  padding: 26px;
  border-radius: 5px;
  // border: 2px solid hsl(198deg 5% 92%);
  margin-right: 8px;
  color: #203d4a;
  background: #fff;

  background-color: #f5f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); */
}

.coming-soon-subhead {
  padding: 12px;
  color: #203d4a;
}
/*  */
</style>
