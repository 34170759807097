import firebase from 'firebase/app';
import 'firebase/firestore';
// import { FORM_ID_PREFIX as DOC_ID_PREFIX } from "../config/constants";
const DOC_ID_PREFIX = 'form';

interface Response {
  [key: string]: {
    [key: string]: any;
  };
}

interface ResponseObject {
  response_id?: string;
  date_submitted?: firebase.firestore.Timestamp;
  deleted?: boolean;
  response?: {
    [key: string]: any;
  };
}

export interface FormData {
  _id?: string;
  _date_added?: firebase.firestore.Timestamp;
  _date_updated?: firebase.firestore.Timestamp;
  form_slug?: string;
  deleted?: boolean;
  [key: string]: any;
  responses?: Response;
}

interface classArguments {
  snapshot?: firebase.firestore.DocumentSnapshot;
  id?: string; // Unique-ID (Alphanumeric only) to create new document [without prefix]
  docId?: string; // Document-ID with prefix, to create a new object of this class. [with prefix]
  data?: FormData; // Data to initialize the new object's data
}

export default class Responses {
  // Reference to the collection
  public static collectionRef(): firebase.firestore.CollectionReference {
    return firebase.firestore().collection('submission');
  }
  // Private variables
  private _id: string;
  private _data: FormData;
  private _unsaved_data: FormData = {};
  private _ref: firebase.firestore.DocumentReference;
  // private _snapshot: firebase.firestore.DocumentSnapshot;

  constructor(args: classArguments) {
    if (args.snapshot) {
      // Initializing new object, with query (Snapshot)
      this._id = args.snapshot.id;
      this._data = { ...args.snapshot.data() };
      this._ref = args.snapshot.ref;
      // this._snapshot = snapshot;
    } else if (args.docId) {
      // Initializing new object, without new query
      this._ref = Responses.collectionRef().doc(args.docId);
      this._id = this._ref.id;
      this._data = args.data || {};
    } else {
      // Creating a new document
      if (!args.id) {
        args.id = Responses.collectionRef().doc().id;
      }
      this._ref = Responses.collectionRef().doc(`${DOC_ID_PREFIX}_${args.id}`);
      this._id = this._ref.id;
      this._data = args.data || {};
      // Initialize new document
      this.push({
        _date_added: firebase.firestore.Timestamp.fromMillis(+new Date()),
        _id: this._id,
        deleted: false,
        status: 'completed',
        security: 'public',
        // end_date:this._data._date_added.setMonth(this._data._date_added.getMonth() + 1);
      });
    }
  }

  // Public methods Methods
  get data(): FormData {
    // Return complete data object
    return { ...this._data, ...this._unsaved_data };
    //._data already saved data on firestore which is private(it is for internal use in our models)
    // whereas data is public which can be used from outside
    // data is combination of both ._data & ._unsaved_data
  }

  public push(newData: FormData): FormData {
    this._unsaved_data = { ...this._unsaved_data, ...newData };
    return this.data; //calling above data method which Returns complete data object
  }
  public async create(): Promise<Responses> {
    await this._ref.set(this.data);
    return this;
  }
  public async save(): Promise<Responses> {
    this.push({
      _date_updated: firebase.firestore.Timestamp.fromMillis(+new Date()),
      //pushed date_updated in unsaved data
    });
    await this._ref.update(this._unsaved_data);
    return this;
  }

  // public async endDate(): Promise<Responses> {

  //     this.push({
  //         end_date: firebase.firestore.Timestamp.fromMillis(+new Date + 1)
  //     })
  //     await this._ref.update(this._unsaved_data);
  //     return this;
  // }

  // public static getByData(docData: FormData): Form {
  //     // return new object, without any network request
  //     return new Form({
  //         docId: docData._id,
  //         data: docData
  //     })
  // }

  public static getById(docId: string): Promise<Responses> {
    return new Promise((resolve, reject) => {
      Responses.collectionRef()
        .doc(docId)
        .get()
        .then((snapshot: firebase.firestore.DocumentSnapshot) => {
          if (snapshot.exists) return resolve(new Responses({ snapshot }));
          else {
            throw new Error(`No document found with id: ${docId}`);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public static getByAuthor(authorId: string): Promise<Responses[]> {
    return new Promise((resolve, reject) => {
      Responses.collectionRef()
        .where('author_id', '==', authorId)
        .get() //fetching all forms of particular user based on userId
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            throw new Error('No form found');
          }
          //if user has created some forms then then we are looping through them all & retuning each form as new Form object
          else {
            return resolve(querySnapshot.docs.map(doc => new Responses({ snapshot: doc })));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public static getBySlug(slug: string): Promise<Responses> {
    return new Promise((resolve, reject) => {
      Responses.collectionRef()
        .where('form_slug', '==', slug || '')
        .get()
        .then((snapshot: firebase.firestore.QuerySnapshot) => {
          if (snapshot.docs.length === 0) {
            throw new Error('No Form found with this slug');
          }
          if (snapshot.docs.length > 1) {
            throw new Error('multiple Forms found with this slug');
          } else {
            resolve(new Responses({ snapshot: snapshot.docs[0] }));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public static async getResponses(formId: string): Promise<ResponseObject[]> {
    let responses = await this.getById(formId);
    const responsesArrayTemp = Object.entries(responses.data);
    let responsesArray: ResponseObject[] = [];
    responsesArrayTemp.forEach(([key, responseObject]) => {
      // console.log(key); // 'one'
      if (responseObject.deleted !== true) {
        responsesArray.push({
          response_id: key,
          ...responseObject,
        });
      }
      // console.log("Reponses Array in Models",responsesArray); // 1
    });

    // console.log(responsesArray);
    return responsesArray;
  }

  public static async addNewResponse({ form_id, response_id, new_response }) {
    try {
      await Responses.collectionRef()
        .doc(form_id)
        .set(
          {
            [response_id]: {
              date_submitted: firebase.firestore.Timestamp.fromMillis(+new Date()),
              deleted: false,
              response: new_response,
            },
          },
          {
            merge: true,
          }
        );
    } catch (err) {
      console.log(err);
    }
  }

  public static async deleteResponse(form_id: string, response_id: string) {
    try {
      await Responses.collectionRef()
        .doc(form_id)
        .set(
          {
            [response_id]: {
              // date_submitted: firebase.firestore.Timestamp.fromMillis(+new Date()),
              deleted: true,
              
            },
          },
          {
            merge: true,
          }
        );
    } catch (err) {
      console.log(err);
    }
  }

  // public static async deleteResponse(form_id: string, response_id: string) {
  //   try {
      
  //     var currentForm_ref = Responses.collectionRef()
  //       .where('form_id', '==', form_id)
  //       // .where('response_id','==', response_id);
  //     currentForm_ref.get().then(function(querySnapshot) {
  //       querySnapshot.forEach(function(doc) {
  //         console.log(doc);
  //         debugger
  //         // doc.ref.update({
  //         //   [response_id]:{
  //         //   deleted: true,
  //         //   }
          
  //         // }),
        
  //           // { merge: true };
  //         console.log('Response DELETED SUCCESSFULLY');
  //       });
  //     });
  //   } catch (error) {
  //     console.error('Error removing document: ', error);
  //   }
  // }
}
