import formSchemaMixin from './formSchemaMixin.js';

export default {
  mixins: [formSchemaMixin],
  computed: {
    // TODO: Rename to SchemaSettings Mixin
    settings: {
      get: function() {
        if (this.$store.state.builder.schema) return this.$store.state.builder.schema.settings;
        else return {};
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSettings', newValue);
      },
    },
  },
};
