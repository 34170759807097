import { ISection } from './section';
import { uid } from '../utils/uid';

export interface ISchema {
  sections?: ISection[];
  settings?: {
    is_quiz: Boolean;
    form_heading: string;
    form_description: string;
    head_color: string;
    border_color: string;
    border_radius: string;
    border_width: boolean;
    form_header: boolean;
    section_heading_visibility: boolean;
    meta_info: {
      page_title: string;
      page_description: string;
      thumbnail_url: string;
    };
  };
  quiz_settings?: Object;
}

interface ISchemaConfigs {
  schema_type?: 'quiz' | '';
}

export function createNewSchema(defaults?: ISchema, configs?: ISchemaConfigs): ISchema {
  let newSchemaDefaults: ISchema = {
    sections: [],
    settings: {
      is_quiz: false,
      form_heading: 'Form Title',
      form_description: 'Form Description',
      form_header: true,
      form_header_align: 'left',
      section_header_align: 'left',
      section_heading_visibility: true,
      multiple_responses: false,
      redirect_url: '',
      meta_info: {
        page_title: '',
        page_description: '',
        thumbnail_url: '',
      },
      styles: {
        playground_background: '#f5f8fa',
        playground_padding_top: 64,
        // playground_padding_bottom: '64',
        playground_padding_left: 64,
        // playground_padding_right: '64',
        playground_margin: 0,
        form_theme_color: 'Teal',
        form_layout_color: 'Teal',
        form_background: '',
        form_shadow: false,
        header_font_color: '',
        header_font_size: 24,
        header_description_font_size: 16,
        border_radius: '12',
        border_width: false,
        // border_color: 'Teal',
        progressbar_hide: false,
        progressbar_color: '',
        progressbar_wizard_mode: false,
        header_separator_visibility: false,
        section_header_bg_color: '#edf2f6',
        section_header_font_color: '#607b89',
        rounded_input: true,
        bottom_bar: true,
        floating_label: false,
        submit_btn_align: 'right',
        button_color: '',
        button_width: '',
      },
    },
  };
  if (configs?.schema_type === 'quiz') {
    newSchemaDefaults['quiz_settings'] = {
      timer_enabled: true,
      global_timer_enabled: true,
      section_timer_enabled: false,
      global_timer_duration: 30,
      animated_timer: false,
      individual_score_enabled: false,
      negative_marking: false,
    };
    newSchemaDefaults.settings['is_quiz'] = true;
    // for the start button
    newSchemaDefaults.settings['form_heading'] = 'Quiz Title';
    newSchemaDefaults.settings['form_description'] = 'Quiz Description';
  }
  return {
    ...newSchemaDefaults,
    ...defaults,
  };
}
