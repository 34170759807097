import { IRow } from './row';
import { uid } from '../utils/uid';

export interface ISection {
  id?: string;
  name?: string;
  heading?: string;
  visiblity?: boolean;
  hidden?: boolean;
  config?: {};
  type?: string;
  heading_visibility?: boolean;
  rows?: IRow[]; // array of rows
}

interface ISectionConfigs {}

export function createNewSection(defaults?: ISection, configs?: ISectionConfigs): ISection {
  let newSectionDefaults: ISection = {
    name: '',
    type: '',
    heading_visibility: true,
    heading: 'Section Heading',
    visiblity: true,
    hidden: false,
    config: {
      default_btn: true,
      section_timer_duration: 30,
    },
    rows: [],
  };
  return {
    ...newSectionDefaults,
    ...defaults,
    id: 'sec' + uid(),
  };
}
