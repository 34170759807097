<template>
  <div class="fs__quiz-temp--container">
    <div class="quiz__template--header">
      <div>
        <h2 class="heading">Templates</h2>
      </div>
    </div>
    <div class="categories-grid--wrapper">
      <div class="category-column">
        <!-- <div class="heading">Categories</div>
        <div class="filter-container">
          <ul>
          </ul>
        </div> -->
        <!-- todo -->
      </div>
      <div class="templates-grid--column">
        <div class="templates-grid--wrapper">
          <div class="template-card">
            <div class="browser-bar"></div>
            <div class="thumbnail-image--wrap">
              <div class="thumbnail-img"><span style="opacity:0.7;">Blank Quiz</span> </div>
            </div>
            <div class="caption-block">
              <div class="caption">
                <h4> Blank Quiz</h4>
              </div>
              <div class="btn-container">
                <lv-button @click="createNewQuiz">Choose</lv-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    createNewQuiz() {
      this.$router.push({ name: 'Create', query: { quiz: 'quiz' } });
    },
  },
};
</script>

<style lang="scss">
.fs__quiz-temp--container {
  max-width: 970px;
  margin: 24px 48px;
  // border: 1px solid grey;
}
.quiz__template--header {
  padding: 28px 48px;
  .heading {
    border-bottom: 4px solid teal;
    max-width: 112px;
    margin-bottom: 28px;
    //    border-bottom: 2px solid linear-gradient(90deg,teal,rgb(39, 104, 134));
  }
}
.categories-grid--wrapper {
  display: flex;
  justify-content: center;
}
// left column
.category-column {
  display: flex;
  flex-direction: column;
  margin-right: 44px;

  .heading {
    margin-top: 5px;
    margin-bottom: 31px;
    font-size: 18px;
    font-weight: 600;
  }
  .filter-container {
    box-shadow: 0 0 7px -1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    background: #fff;
    width: 164px;
    height: 644px;
  }
}

.categories-grid--wrapper {
  display: flex;
}

.templates-grid--column {
  display: flex;
  flex-direction: column;
}

.template-card {
  padding: 0;
  border: 0;
  overflow: hidden;
  width: 278px;
  max-width: 100%;
  min-height: 216px;
  box-shadow: 0 0 7px -1px;
  text-align: left;
  margin-bottom: 20px;
  border-radius: 4px;
  .browser-bar {
    width: 100%;
    height: 10px;
    background-image: url(https://d3fs9o4a89jmeb.cloudfront.net/bfe23eb8-a549-447f-ac2d-53d815d2e45c/template-assets/browser-bar.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .thumbnail-image--wrap {
    min-height: 165.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .thumbnail-img {
      font-size: 24px;
      font-weight: 600;
    }
  }
  .caption-block {
    height: 55px;
    .caption {
      width: 100%;
      height: 75px;
      position: relative;
      padding: 5px 15px 0;
      border-top: 1px solid #e9e9e9;
      background-color: #fff;
      z-index: 5;
    }

    .btn-container {
      width: 100%;
      height: 55px;
      padding-top: 10px;
      position: relative;
      transform: translateY(-75px);
      text-align: center;
      background-color: #fff;
      transition: all 0.2s linear;
      z-index: 0;
    }
  }
}
.template-card:hover .btn-container {
 
transform: translateY(-127px);
 
}
.thumbnail-image--wrap{
    background-image: url('/img-default.png');
  }

</style>