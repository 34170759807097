import { CTA_ACTION_TYPES } from '../../src/config/constants.ts';
import quizMixin from './quizMixin';
import isQuizModeEnabled from './computedMixins/isQuizModeEnabled';

function normalizeLink(rawLink) {
  if (rawLink.includes('https://')) {
    return rawLink;
  }
  return 'https://' + rawLink;
}

export default {
  mixins: [quizMixin, isQuizModeEnabled],
  emits: ['ctaClick'],
  methods: {
    handleMixinCTA(cta) {
      this.$emit('ctaClick', cta);
      const { cta_action_type, cta_action_value } = cta;
      console.log(cta_action_type);
      if (this.mode === 'live') {
        this.emitDimensionsToIframe();
      }
      // TODO: Emit CTA to iFrame
      // Track CTA

      // !
      this.isQuizModeEnabled && this.calculateScore();

      switch (cta_action_type) {
        case CTA_ACTION_TYPES.NEXT_SECTION: {
          if (this.isLastSection) {
            this.handleSubmit();
          } else {
            if (this.isQuizModeEnabled) {
              this.calculateScore();
              this.schema?.quiz_settings?.timer_enabled && this.handleGlobalTimerOnSectionChange();
            }
            this.handleNext();
          }
          break;
        }
        case CTA_ACTION_TYPES.PREV_SECTION: {
          if (this.selectedSection === 0) {
            // Throw Error
            console.log('Already in First Section !!');
          } else {
            this.changeSelectedSection(this.selectedSection - 1);
          }
          break;
        }
        case CTA_ACTION_TYPES.SUBMIT: {
          if (this.isQuizModeEnabled && this.is_running) {
            this.is_running = false;
            console.log('%c⌛ Timer stopped', 'padding: 4px; background-color:#009387; color: white');
          }
          this.handleSubmit();
          break;
        }
        case CTA_ACTION_TYPES.SUBMIT_ANOTHER: {
          if (this.mode === 'live' || this.mode === 'preview') {
            this.resetForm();
          }
          // start new session
          this.changeSelectedSection(0);
          break;
        }
        case CTA_ACTION_TYPES.REDIRECT: {
          const redirectUrl = normalizeLink(cta_action_value);
          window.location.replace(redirectUrl);
          break;
        }
        case CTA_ACTION_TYPES.OPEN_LINK: {
          const linkUrl = normalizeLink(cta_action_value);
          window.open(linkUrl, '_blank');
          break;
        }
        default: {
          console.warn('Invalid CTA !!');
          break;
        }
      }
    },

    async handleNext() {
      if (this.isQuizModeEnabled && this.schema?.quiz_settings?.timer_enabled) {
        this.handleSectionTimerOnSectionChange();
      }
      if (this.mode === 'live' || this.mode === 'preview') {
        if (!this.validateSection()) {
          return;
        }
      }
      if (this.mode === 'live') {
        let submitObject = {
          formData: this.formData,
          submissionStatus: 'partial',
        };
        this.$emit('submit', submitObject);
        // await this.processSubmission(submitObject);
        // TODO: Handle Loading states.
      }
      // TODO: Handle Conditional Jumps Here.

      this.changeSelectedSection(this.selectedSection + 1);
    },
    async handleSubmit() {
      if (this.mode === 'live' || this.mode === 'preview') {
        if (!this.validateSection()) {
          return;
        }

        if (this.isQuizModeEnabled) {
          this.calculateFinalScore();
        }
      } // TODO: Handle From Validations + Error Handling
      if (this.mode === 'live') {
        let submitObject = {
          formData: this.formData,
          submissionStatus: 'complete',
        };
        this.$emit('submit', submitObject);
        await this.processSubmission(submitObject);
        // TODO: Handle Loading states.
        setTimeout(() => {
          // Temporary Hack.
          this.submitted = true;
        }, 400);
      }
      // TODO: Handle Conditional Jumps Here.
      this.changeSelectedSection(this.schema.sections.length - 1); // TODO: Take care of proper ThankYou Section.
      // this.selectedSection++;
      // might break anytime
    },
    async processSubmission(submitObject) {
      // Actual API hit to submit the response.
      //submitObject = {
      //   formData: this.formData,
      //   submissionStatus: 'complete',
      // };
      //formObject is the complete snapshot same as firebase doc
      //submitObject.formData contains response filled to each field
      let formSubmitPayload = {
        form_id: this.formObject._id,
        form_data: this.formObject,
        response_id: this.responseId,
        response_data: submitObject.formData,
        submission_status: submitObject.submissionStatus,
        _csrf: this.csrfToken,
      };

      let quizSubmitPayload = {
        ...formSubmitPayload,
      };
      try {
        const { data } = await this.$axios.$post(`${this.apiBaseURL}/submit`, {
          ...formSubmitPayload,
        });
        this.emitSubmitToIframe(submitObject.formData);
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
