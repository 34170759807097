<template>
  <div>
    <!-----------------------------Text Input----------------------------- -->

    <div v-if="activeElement.field_type == 'TextInput' || activeElement.field_type == 'FormInput'">
      <lv-input v-model="activeElement.label" name="label" label="Label" :bottomBar="true" rounded />
      <br />
      <!-- Placeholder toggle switch -->
      <d-toggle-switch label="Placeholder" name="feature" v-model="activeElement.placeholder_required" />
      <div v-if="activeElement.placeholder_required">
        <lv-input v-model="activeElement.placeholder" name="placeholder" placeholder="Lorem Ipsum" />
      </div>
      <!---------------- Required toggle switch ---------->
      <div class="el-prop-field" v-if="formSchema.settings.is_quiz">
        <d-toggle-switch label="Required" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="reqText" v-model="activeElement.required" />
      </div>
      <!----- HELP Block toggle switch ------>
      <div class="el-prop-field">
        <d-toggle-switch label="Helpblock" name="reqHelp" v-model="activeElement.help_block" />
      </div>
      <div v-if="activeElement.help_block">
        <lv-input v-model="activeElement.help_block_text" placeholder="Please Input Your Helpblock here..." name="helpblock" />
      </div>
    </div>
    <!-----------------------------Contact Number Input----------------------------- -->

    <div v-if="activeElement.field_type == 'FormContactNumber'">
      <lv-input v-model="activeElement.label" name="label" label="Label" :bottomBar="true" rounded />
      <br />
      <lv-input v-model="activeElement.country_code" name="label" label="Country Code" :bottomBar="true" rounded placeholder="+91" />
      <br />
      <!-- Placeholder toggle switch -->
      <d-toggle-switch label="Placeholder" name="feature" v-model="activeElement.placeholder_required" />
      <div v-if="activeElement.placeholder_required">
        <lv-input v-model="activeElement.placeholder" name="placeholder" placeholder="Lorem Ipsum" />
      </div>
      <!---------------- Required toggle switch ---------->
      <div class="el-prop-field">
        <d-toggle-switch label="Required" name="reqText" v-model="activeElement.required" />
      </div>
      <!----- HELP Block toggle switch ------>
      <div class="el-prop-field">
        <d-toggle-switch label="Helpblock" name="reqHelp" v-model="activeElement.help_block" />
      </div>
      <div v-if="activeElement.help_block">
        <lv-input v-model="activeElement.help_block_text" placeholder="Please Input Your Helpblock here..." name="helpblock" />
      </div>
    </div>

    <!---------------------------------------------- Number Input-------------------------------------- -->
    <div v-if="activeElement.field_type == 'FormNumber'">
      <lv-input v-model="activeElement.label" name="label" :bottomBar="true" label="Label" />
      <!-- Placeholder toggle switch -->
      <d-toggle-switch v-if="formSchema?.quiz_settings" label="Placeholder" name="feature" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" v-model="activeElement.placeholder_required" />
      <div v-if="activeElement.placeholder_required">
        <lv-input v-model="activeElement.placeholder" :bottomBar="true" name="Ninput" />
      </div>
      <div class="el-prop-field">
        <!-- Required Toggle Switch -->
        <d-toggle-switch v-if="formSchema?.quiz_settings" label="Required" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="reqNum" v-model="activeElement.required" />
      </div>
      <!-- HELP Block toggle switch -->
      <d-toggle-switch label="Helpblock" name="reqHelp" v-model="activeElement.help_block" />
      <div v-if="activeElement.help_block">
        <lv-input v-model="activeElement.help_block_text" placeholder="Please Input Your Helpblock here..." name="helpblock" :bottomBar="true" />
      </div>
    </div>

    <!---------------------------------------------- Toggle Button-------------------------------------- -->
    <div v-if="activeElement.field_type == 'FormToggle'">
      <lv-input v-model="activeElement.label" name="label" label="Label" :bottomBar="true" placeholder="Enable Label" />

      <!-- Required Toggle Switch -->
      <div v-if="formSchema?.quiz_settings" class="el-prop-field">
        <d-toggle-switch v-if="formSchema?.quiz_settings" label="Required" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="reqToggle" v-model="activeElement.required" />
      </div>
      <!-- HELP Block toggle switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Helpblock" name="reqHelp" v-model="activeElement.help_block" />
        <div v-if="activeElement.help_block">
          <lv-input v-model="activeElement.help_block_text" :bottomBar="true" placeholder="Please Input Your Helpblock here..." name="helpblock" />
        </div>
      </div>
      <!-- ----------- -->
    </div>
    <!-- --------------------------------------------------------------------------- -->

    <!-------------------------------------- Normal Button---------------------------- -->
    <div v-if="activeElement.field_type == 'FormButton'">
      <lv-input v-model="activeElement.label" name="label" label="Label" :bottomBar="true" placeholder="Enter Label" />
      <br />
      <lv-dropdown :options="isThankyouSectionActive ? ctaOptionsThank : ctaOptions" label="Action on click" :value="activeElement.cta_action_type" @input="changeCta" optionLabel="text" optionValue="type" />
      <br />
      <template v-if="activeElement && activeElement.cta_action_type">
        <lv-input v-if="activeElement.cta_action_type === CTA_ACTION_TYPES.OPEN_LINK" label="External Link URL" v-model="activeElement.cta_action_value" placeholder="Enter your url here..." :bottomBar="true">
          <!-- <template #prepend>
            https://
          </template> -->
        </lv-input>
        <lv-input v-else-if="activeElement.cta_action_type === CTA_ACTION_TYPES.REDIRECT" label="Redirect URL" v-model="activeElement.cta_action_value" placeholder="Enter your url here..." :bottomBar="true" />
      </template>

      <lv-input type="number" label="Width(px)" :bottomBar="true" v-model="activeElement.btn_width" />

      <!-- Required Toggle Switch -->
      <div v-if="formSchema?.quiz_settings?.global_timer_enabled" class="el-prop-field">
        <d-toggle-switch label="Required" name="reqToggle" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" v-model="activeElement.required" />
      </div>
      <!-- Push Type Btn Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Push Type" name="reqToggle" v-model="activeElement.push" />
      </div>
      <!-- Outlined Type Btn Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Outlined Type" name="reqToggle" v-model="activeElement.outlined" />
      </div>
      <!-- Raised style Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Raised" name="reqToggle" v-model="activeElement.raised" />
      </div>
      <!-- DeepShadow style Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Deep-Shadow" name="reqToggle" v-model="activeElement.deepShadow" />
      </div>
      <!-- Rounded Style Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Rounded" name="reqToggle" v-model="activeElement.rounded" />
      </div>
      <!-- Deep Shadow Hover Style Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Deep-Shadow Hover" name="reqToggle" v-model="activeElement.deepShadowHover" />
      </div>
      <br />
      <ColorPicker v-model="activeElement.background_color" label="Button Color" />
      <br />
      <ColorPicker v-model="activeElement.font_color" label="Font Color" />
      <br />

      <div class="ep-align__btn">
        <span>Align Button </span>
        <LvButton outlined icon="light-icon-align-left" @click="activeElement.align = 'left'" />
        <LvButton outlined icon="light-icon-align-center" @click="activeElement.align = 'center'" />
        <LvButton outlined icon="light-icon-align-right" @click="activeElement.align = 'right'" />
      </div>
      <br />
      <lv-input type="number" label="Margin" :bottomBar="true" v-model="activeElement.btn_margin" />
    </div>
    <!-- --------------------------------------------------------------------------- -->

    <!-------------------------------- Radio Button------------------------------------------- -->
    <div v-if="activeElement.field_type == 'RadioButton'">
      <h4 class="cate gory_title">Label</h4>

      <lv-input v-model="activeElement.label" name="label" label="Label" :bottomBar="true" />
      <button @click="showOptions = !showOptions">Edit Options</button>
      <!-- Required Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Required" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="reqToggle" v-model="activeElement.required" />
      </div>
      <div v-if="showOptions == true">
        <div class="radio-option-row" v-for="(option, key) in activeElement.options" :key="key">
          <lv-input :value="option" name="label" :bottomBar="true" />
        </div>
      </div>
    </div>

    <!-- ------------------------------------------------------- -->

    <!-- ---------------------------------------------------------------------------- -->
    <!---------------------------- Text Area ------------------------------------------->

    <div v-if="activeElement.field_type === 'FormTextarea'">
      <lv-input v-model="activeElement.label" name="Label" label="Label" :bottomBar="true" />
      <br />
      <d-toggle-switch v-if="formSchema?.quiz_settings" label="Placeholder" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="feature" v-model="activeElement.placeholder_required" />
      <br />
      <div v-if="activeElement.placeholder_required">
        <lv-input v-model="activeElement.placeholder" name="placeholder" :bottomBar="true" placeholder="Lorem Ipsum" />
      </div>
      <d-toggle-switch label="Max Length" name="Max Length" v-model="activeElement.max_length_required" />
      <div>
        <lv-input type="number" v-if="activeElement.max_length_required" :bottomBar="true" v-model="activeElement.max_length" name="Max Length" placeholder="0" />
      </div>
      <d-toggle-switch v-if="formSchema?.quiz_settings" label="Required" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="Required" v-model="activeElement.required" />
      <br />
      <d-toggle-switch label="Auto Resize" name="Auto Resize" v-model="activeElement.auto_resize" />
      <br />
      <d-toggle-switch label="Help Text" name="Help Text" v-model="activeElement.help_block"></d-toggle-switch>
      <br />
      <div>
        <lv-input v-if="activeElement.help_block" v-model="activeElement.help_block_text" :bottomBar="true" placeholder="Help Text" name="Help Text"></lv-input>
      </div>
      <br />
      <!-- <d-toggle-switch label="Rows" name="Rows" v-model="activeElement.rows_required"></d-toggle-switch> -->
      <!-- <br /> -->
      <div>
        <lv-input type="number" label="Rows" v-model="activeElement.rows" placeholder="Rows" :bottomBar="true" name="Rows"></lv-input>
      </div>
      <br />
      <!-- <d-toggle-switch label="Column" name="column" v-model="activeElement.column_required"></d-toggle-switch> <br />
        <div>
          <lv-input type="number" v-if="activeElement.column_required"
           v-model="activeElement.column"
           placeholder="Column"
           name="column"
          >
          </lv-input>
      </div> <br />-->
    </div>
    <!-- --------------------------------- SLIDER --------------------------- -->

    <div v-if="activeElement.field_type === 'FormRangeSlider'">
      <lv-input label="Label" v-model="activeElement.label" :bottomBar="true" />
      <br />
      <!-- Required Toggle Switch -->
      <div v-if="formSchema?.quiz_settings" class="el-prop-field">
        <d-toggle-switch label="Required" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="reqToggle" v-model="activeElement.required" />
      </div>
    </div>
    <!-- ----------------------------------------------------------------------------- -->

    <!-- --------------------------------- TEXT BOX --------------------------- -->

    <div v-if="activeElement.field_type === 'FormTextBox'">
      <lv-input label="Text Message" v-model="activeElement.msg" :bottomBar="true" />
      <br />
      <lv-input label="Sub Message" v-model="activeElement.sub_msg" :bottomBar="true" />
      <!-- Required Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Required" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="reqToggle" v-model="activeElement.required" />
      </div>
    </div>
    <!-- ----------------------------------------------------------------------------- -->
    <!-- --------------------------------- RATINGS --------------------------- -->
    <div v-if="activeElement.field_type === 'FormRating'">
      <lv-input label="Increment" v-model="activeElement.increment" name="increment" :bottomBar="true" />
      <br />
      <d-toggle-switch label="Show Rating" name="show-rating" v-model="activeElement.showRating" Rating></d-toggle-switch>
      <br />
      <!-- Required Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch v-if="formSchema?.quiz_settings" label="Required" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="reqToggle" v-model="activeElement.required" />
      </div>
      <br />
      <lv-input type="number" label="Max Rating" v-model="activeElement.maxRating" name="Max_Rating" :bottomBar="true" />
      <br />
    </div>
    <!-- ----------------------------------------------------------------------------- -->
    <!-- ----------------------------------------------------------------------------- -->
    <!-- ********************************** DROPDOWN ****************************** -->

    <!-- <container @drop="dndDrop" @drag-start="dndDragStart" @dragEnd="dndDragEnd" :get-child-payload="getChildPayload" class="options_wrap" v-if="activeElement.field_type === 'FormDropdown'"> -->
    <div v-if="activeElement.field_type === 'FormDropdown'">
      <h4 class="category_title">Label</h4>
      <!-- Required Toggle Switch -->
      <div class="el-prop-field">
        <lv-input label="Enter label" :bottomBar="true" v-model="activeElement.label"></lv-input>
      </div>

      <h4 class="category_title">Question</h4>
      <div class="el-prop-field">
        <d-toggle-switch v-if="formSchema?.quiz_settings" label="Required" :disabled="formSchema.quiz_settings?.global_timer_enabled && selectedSection !== 0" name="reqToggle" v-model="activeElement.required" />
      </div>

      <div class="el-prop-field">
        <lv-input label="Enter placeholder" :bottomBar="true" v-model="activeElement.placeholder"></lv-input>
      </div>

      <input type="file" hidden accept="image/*" @change="onFileChange($event, 'img_src')" ref="imageInputButtonSelect" />
      <!-- <div class="el-prop-field">
        <d-toggle-switch label="Show Image" name="image" v-model="activeElement.img_show" />
      </div> -->

      <div class="el-prop-field" v-if="activeElement.img_show">
        <lv-input label="Image URL" placeholder="Enter image url" v-model="activeElement.img_src">
          <template #append>
            <lv-button icon="light-icon-upload" @click="$refs.imageInputButtonSelect.click()"></lv-button>
          </template>
        </lv-input>
      </div>

      <div class="el-prop-field">
        <d-toggle-switch label="Rounded" v-model="activeElement.rounded" />
      </div>
      <div class="el-prop-field">
        <d-toggle-switch label="Floating-Label" v-model="activeElement.floating_label" />
      </div>
      <div class="el-prop-field">
        <d-toggle-switch label="Bottom-Bar" v-model="activeElement.bottom_bar" />
      </div>

      <div v-if="formSchema.settings.is_quiz && (formSchema?.quiz_settings?.individual_score_enabled || formSchema?.quiz_settings?.negative_marking)">
        <h4 class="category_title">Score</h4>

        <lv-input type="number" min="0" label="Individual Score:" v-model="activeElement.individual_score"></lv-input>
        <div class="el-prop-field">
          <lv-input type="number" min="0" :bottomBar="true" label="Negative Marks:" v-model="activeElement.negative_score">
            <template #prepend>
              <span style="{font-weight:800;padding:8px;font-size:20px;}">-</span>
            </template>
          </lv-input>
        </div>
      </div>
      <h4 class="category_title">Options</h4>
      <div v-for="(option, index) in activeElement.options" :key="index" class="options__item" :class="{ '--active': selectedOptionId === option, '--no-active': dragging && selectedOptionId == option }">
        <div class="option-mover" title="Move">
          <i class="light-icon-grip-vertical" />
        </div>
        <div class="option-actions">
          <div class="option-action-btn" @click.stop="duplicateOption(index)" title="Duplicate">
            <i class="light-icon-copy" />
          </div>
          <div class="option-action-btn" @click.stop="deleteOption(index)" title="Delete">
            <i class="light-icon-trash" />
          </div>
        </div>
        <!-- <lv-input class="options__background" label="Answer Option Text"></lv-input> -->
        <!-- <lv-input v-model="activeElement.options[index]"></lv-input> -->

        <lv-input v-model="activeElement.options[index].src">
          <template #append v-if="selectedSection != 0">
            <lv-checkbox name="option" v-model="activeElement.options[index].correct" color="success" outline rounded style="margin-top:9px;" title="Correct option" @input="toggleValue($event, index)"> </lv-checkbox>
          </template>
        </lv-input>
      </div>
      <!-- <div style="padding: 12px"> -->
      <lv-button @click="addNewOption" style="margin-top:16px">Add New Option</lv-button>
      <!-- ---------------------------------------------------------------------------- -->
    </div>

    <!---------------------------------------- FORM CHECKBOX GROUP--------------------------- -->
    <div v-if="activeElement.field_type === 'FormCheckboxGroup'">
      <h4 class="category_title">Label</h4>
      <lv-input label="Enter label" :bottomBar="true" v-model="activeElement.label"></lv-input>

      <!-- Required Toggle Switch -->
      <h4 class="category_title">Question</h4>
      <div class="el-prop-field">
        <d-toggle-switch label="Required" :disabled="formSchema.quiz_settings?.global_timer_enabled && selectedSection !== 0" name="reqToggle" v-model="activeElement.required" />
      </div>
      <div class="el-prop-field">
        <input type="file" hidden accept="image/*" @change="onFileChange($event, 'img_src')" ref="imageInputButtonSelect" />
      </div>
      <!-- <div class="el-prop-field">
        <d-toggle-switch label="Show Image" name="image" v-model="activeElement.img_show" />
      </div> -->
      <div class="el-prop-field" v-if="activeElement.img_show">
        <lv-input label="Image URL" placeholder="Enter image url" v-model="activeElement.img_src">
          <template #append>
            <lv-button icon="light-icon-upload" @click="$refs.imageInputButtonSelect.click()"></lv-button>
          </template>
        </lv-input>
      </div>

      <!-- <lv-input label="Enter placeholder" :bottomBar="true" v-model="activeElement.placeholder"></lv-input> -->

      <div v-if="formSchema.settings.is_quiz && (formSchema?.quiz_settings?.individual_score_enabled || formSchema?.quiz_settings?.negative_marking)">
        <h4 class="category_title">Score</h4>
        <!-- Individual score for quiz -->
        <lv-input v-if="formSchema?.quiz_settings?.individual_score_enabled" type="number" min="0" label="Individual Score:" v-model="activeElement.individual_score"></lv-input>
        <!-- Negative score-->
        <br />
        <lv-input v-if="formSchema?.quiz_settings?.negative_marking" type="number" min="0" :bottomBar="true" label="Negative Marks:" v-model="activeElement.negative_score">
          <template #prepend>
            <span style="{font-weight:800;padding:8px;font-size:20px;}">-</span>
          </template>
        </lv-input>
      </div>

      <h4 class="category_title">Options</h4>
      <div v-for="(option, index) in activeElement.options" :key="index" class="options__item" :class="{ '--active': selectedOptionId === option, '--no-active': dragging && selectedOptionId == option }">
        <div class="option-mover" title="Move">
          <i class="light-icon-grip-vertical" />
        </div>
        <div class="option-actions">
          <div class="option-action-btn" @click.stop="duplicateOption(index)" title="Duplicate">
            <i class="light-icon-copy" />
          </div>
          <div class="option-action-btn" @click.stop="deleteOption(index)" title="Delete">
            <i class="light-icon-trash" />
          </div>
        </div>
        <!-- <lv-input class="options__background" label="Answer Option Text"></lv-input> -->
        <!-- <lv-input v-model="activeElement.options[index]"></lv-input> -->
        <lv-input v-model="activeElement.options[index].src">
          <template #append v-if="selectedSection != 0">
            <lv-checkbox v-model="activeElement.options[index].correct" color="success" outline rounded style="margin-top:9px;" title="Correct option"> </lv-checkbox>
          </template>
        </lv-input>
      </div>
      <!-- <div style="padding: 12px"> -->
      <lv-button @click="addNewOption" style="margin-top:16px">Add New Option</lv-button>
      <br />
      <br />

      <!-- </div> -->

      <!-- </container> -->
    </div>

    <!-- ------------------------------------------------------------------- -->
    <!---------------------------------------- FORM RADIO GROUP--------------------------- -->
    <div v-if="activeElement.field_type === 'FormRadioGroup'">
      <h4 class="category_title">Label</h4>
      <lv-input label="Enter label" :bottomBar="true" v-model="activeElement.label"></lv-input>

      <h4 class="category_title">Question</h4>
      <!-- Required Toggle Switch -->
      <div class="el-prop-field">
        <d-toggle-switch label="Required" :disabled="formSchema.quiz_settings?.global_timer_enabled && selectedSection !== 0" name="reqToggle" v-model="activeElement.required" />
      </div>
      <input type="file" hidden accept="image/*" @change="onFileChange($event, 'img_src')" ref="imageInputButtonSelect" />
      <!-- <div class="el-prop-field">
        <d-toggle-switch label="Show Image" name="image" v-model="activeElement.img_show" />
      </div> -->

      <div class="el-prop-field" v-if="activeElement.img_show">
        <lv-input label="Image URL" placeholder="Enter image url" v-model="activeElement.img_src">
          <template #append>
            <lv-button icon="light-icon-upload" @click="$refs.imageInputButtonSelect.click()"></lv-button>
          </template>
        </lv-input>
      </div>

      <br />

      <!-- Indiviudal score for quiz -->
      <div v-if="formSchema.settings.is_quiz && (formSchema?.quiz_settings?.individual_score_enabled || formSchema?.quiz_settings?.negative_marking)">
        <h4 class="category_title">Score</h4>
        <lv-input v-if="formSchema?.quiz_settings?.individual_score_enabled" type="number" min="0" label="Individual Score:" v-model="activeElement.individual_score"></lv-input>

        <!-- Negative score-->
        <lv-input v-if="formSchema?.quiz_settings?.negative_marking" type="number" min="0" :bottomBar="true" label="Negative Marks:" v-model="activeElement.negative_score">
          <template #prepend>
            <span style="{font-weight:800;padding:8px;font-size:20px;}">-</span>
          </template>
        </lv-input>
      </div>
      <br />
      <h4 class="category_title">Options</h4>
      <div v-for="(option, index) in activeElement.options" :key="index" class="options__item" :class="{ '--active': selectedOptionId === option, '--no-active': dragging && selectedOptionId == option }">
        <div class="option-mover" title="Move">
          <i class="light-icon-grip-vertical" />
        </div>
        <div class="option-actions">
          <div class="option-action-btn" @click.stop="duplicateOption(index)" title="Duplicate">
            <i class="light-icon-copy" />
          </div>
          <div class="option-action-btn" @click.stop="deleteOption(index)" title="Delete">
            <i class="light-icon-trash" />
          </div>
        </div>
        <!-- <lv-input class="options__background" label="Answer Option Text"></lv-input> -->
        <!-- <lv-input v-model="activeElement.options[index]"></lv-input> -->
        <lv-input v-model="activeElement.options[index].src">
          <template #append v-if="selectedSection != 0">
            <lv-checkbox name="option" v-model="activeElement.options[index].correct" color="success" outline rounded style="margin-top:9px;" title="Correct option" @input="toggleValue($event, index)"> </lv-checkbox>
          </template>
        </lv-input>
      </div>
      <!-- <div style="padding: 12px"> -->
      <lv-button @click="addNewOption" style="margin-top:16px">Add New Option</lv-button>
      <br />
      <br />

      <!-- </div> -->

      <!-- </container> -->
    </div>

    <!-- ------------------------------------------------------------------- -->
    <!---------------------------------------- FORM BUTTON SELECT--------------------------- -->
    <div v-if="activeElement.field_type === 'FormButtonSelect'">
      <h4 class="category_title">Label</h4>
      <lv-input label="Enter label" :bottomBar="true" v-model="activeElement.label"></lv-input>
      <br />

      <span>Align label </span>
      <br />
      <!-- ? move to styles -->
      <div class="el__align__btn">
        <LvButton :outlined="activeElement.label_align != 'left'" icon="light-icon-align-left" @click="activeElement.label_align = 'left'" />
        <LvButton :outlined="activeElement.label_align != 'center'" icon="light-icon-align-center" @click="activeElement.label_align = 'center'" />
        <LvButton :outlined="activeElement.label_align != 'right'" icon="light-icon-align-right" @click="activeElement.label_align = 'right'" />
      </div>
      <!-- Required Toggle Switch -->
      <h4 class="category_title">Question</h4>
      <div class="el-prop-field">
        <d-toggle-switch v-if="formSchema?.quiz_settings" label="Required" :disabled="formSchema.quiz_settings.global_timer_enabled && selectedSection !== 0" name="reqToggle" v-model="activeElement.required" />
      </div>

      <!-- Show Image -->
      <input type="file" hidden accept="image/*" @change="onFileChange($event, 'img_src')" ref="imageInputButtonSelect" />
      <!-- <div class="el-prop-field">
        <d-toggle-switch label="Show Image" name="image" v-model="activeElement.img_show" />
      </div> -->

      <div class="el-prop-field" v-if="activeElement.img_show">
        <lv-input label="Image URL" placeholder="Enter image url" v-model="activeElement.img_src">
          <template #append>
            <lv-button icon="light-icon-upload" @click="$refs.imageInputButtonSelect.click()"></lv-button>
          </template>
        </lv-input>
      </div>

      <!-- Indiviudal score for quiz -->
      <div v-if="formSchema.settings.is_quiz && (formSchema?.quiz_settings?.individual_score_enabled || formSchema?.quiz_settings?.negative_marking)">
        <h4 class="category_title">Score</h4>

        <lv-input v-if="formSchema?.quiz_settings?.individual_score_enabled" type="number" min="0" label="Individual Score:" v-model="activeElement.individual_score"></lv-input>
        <!-- Negative score-->
        <br />
        <lv-input v-if="formSchema?.quiz_settings?.negative_marking" type="number" min="0" :bottomBar="true" label="Negative Marks:" v-model="activeElement.negative_score">
          <template #prepend>
            <span style="{font-weight:800;padding:8px;font-size:20px;}">-</span>
          </template>
        </lv-input>
      </div>

      <h4 class="category_title">Options</h4>
      <div class="el-prop-field">
        <d-toggle-switch label="Enable MultiSelect" name="multiSelect" v-model="activeElement.enable_multiselect" />
      </div>
      <br />
      <container @drop="dndDrop" @dragStart="dndDragStart" @dragEnd="dndDragEnd">
        <draggable v-for="(option, index) in activeElement.options" :key="'row_' + activeElement.id">
          <div class="options__item" :class="{ '--active': selectedOptionId === index, '--no-active': selectedOptionId !== index }" @click.stop="selectRow(index)" @mouseover.stop="selectHoverRow(index)" @mouseleave.stop="selectedOptionId = ''">
            <div class="option-mover" title="Move" :class="{ '--active': selectedOptionId === index }">
              <i class="light-icon-grip-vertical" />
            </div>
            <div class="option-actions" v-if="selectedOptionId === index">
              <div class="option-action-btn" @click.stop="duplicateOption(index)" title="Duplicate">
                <i class="light-icon-copy" />
              </div>
              <div class="option-action-btn" @click.stop="deleteOption(index)" title="Delete">
                <i class="light-icon-trash" />
              </div>
            </div>
            <lv-input v-model="activeElement.options[index].src">
              <template #append v-if="selectedSection != 0">
                <!-- <LvButton icon="light-icon-square-check" class="--link-button lv--primary" /> -->
                <!-- <lv-checkbox v-if="activeElement.enable_multiselect" type="checkbox" v-model="activeElement.options[index].correct" title="Correct options" icon toggle plain>
                  <i class="icon light-icon-circle-check" slot="extra"></i>
                  <i class="icon light-icon-circle" slot="off-extra"></i>
                </lv-checkbox> -->

                <lv-checkbox v-if="activeElement.enable_multiselect" v-model="activeElement.options[index].correct" color="success" outline rounded style="margin-top:9px;" title="Correct option"> </lv-checkbox>

                <lv-checkbox v-else name="option" v-model="activeElement.options[index].correct" color="success" outline rounded style="margin-top:9px;" title="Correct option" @input="toggleValue($event, index)"> </lv-checkbox>
              </template>
            </lv-input>
          </div>
        </draggable>
      </container>
      <!-- <div style="padding: 12px"> -->
      <lv-button @click="addNewOption" style="margin-top:16px">Add New Option</lv-button>

      <br />
      <br />

      <!-- </div> -->

      <!-- </container> -->
    </div>

    <!-- ------------------------------------------------------------------- -->
    <!-- --------------------------FORM-PHOTO SELECT--------------------------------------------- -->
    <div v-if="activeElement.field_type === 'FormPhotoSelect'">
      <h4 class="category_title">Label</h4>
      <lv-input label="Enter label" :bottomBar="true" v-model="activeElement.label"></lv-input>
      <br />
      <span>Align label </span>
      <div class="el__align__btn">
        <LvButton :outlined="activeElement.label_align != 'left'" icon="light-icon-align-left" @click="activeElement.label_align = 'left'" />
        <LvButton :outlined="activeElement.label_align != 'center'" icon="light-icon-align-center" @click="activeElement.label_align = 'center'" />
        <LvButton :outlined="activeElement.label_align != 'right'" icon="light-icon-align-right" @click="activeElement.label_align = 'right'" />
      </div>

      <h4 class="category_title">Question</h4>

      <div class="el-prop-field">
        <d-toggle-switch label="Required" name="reqToggle" :disabled="formSchema.quiz_settings?.global_timer_enabled && selectedSection !== 0" v-model="activeElement.required" />
      </div>

      <input type="file" hidden accept="image/*" @change="onFileChange($event, 'img_src')" ref="imageInputButtonSelect" />
      <!-- <div class="el-prop-field">
        <d-toggle-switch label="Show Image" name="image" v-model="activeElement.img_show" />
      </div> -->

      <div class="el-prop-field" v-if="activeElement.img_show">
        <lv-input label="Image URL" placeholder="Enter image url" v-model="activeElement.img_src">
          <template #append>
            <lv-button icon="light-icon-upload" @click="$refs.imageInputButtonSelect.click()"></lv-button>
          </template>
        </lv-input>
      </div>
      <br />

      <!-- Indiviudal score for quiz -->
      <h4 v-if="formSchema.settings.is_quiz" class="category_title">Score</h4>
      <div v-if="formSchema.settings.is_quiz && (formSchema?.quiz_settings?.individual_score_enabled || formSchema?.quiz_settings?.negative_marking)">
        <lv-input v-if="formSchema?.quiz_settings?.individual_score_enabled" type="number" min="0" label="Individual Score:" v-model="activeElement.individual_score"></lv-input>
        <!-- Negative score-->
        <br />
        <lv-input v-if="formSchema?.quiz_settings?.negative_marking" type="number" min="0" :bottomBar="true" label="Negative Marks:" v-model="activeElement.negative_score">
          <template #prepend>
            <span style="{font-weight:800;padding:8px;font-size:20px;}">-</span>
          </template>
        </lv-input>
      </div>
      <br />

      <h4 class="category_title">Options</h4>

      <div class="el-prop-field">
        <d-toggle-switch label="Enable MultiSelect" name="multiSelect" v-model="activeElement.enable_multiselect" />
      </div>
      <br />
      <container @drop="dndDrop" @dragStart="dndDragStart" @dragEnd="dndDragEnd">
        <draggable v-for="(option, index) in activeElement.options" :key="'row_' + activeElement.id">
          <div class="options__item" :class="{ '--active': selectedOptionId === index, '--no-active': selectedOptionId !== index }" @click.stop="selectRow(index)" @mouseover.stop="selectHoverRow(index)" @mouseleave.stop="selectedOptionId = ''">
            <div class="option-mover" title="Move" :class="{ '--active': selectedOptionId === index }">
              <i class="light-icon-grip-vertical" />
            </div>
            <div class="option-actions" v-if="selectedOptionId === index">
              <div class="option-action-btn" @click.stop="duplicateOption(index)" title="Duplicate">
                <i class="light-icon-copy" />
              </div>
              <div class="option-action-btn" @click.stop="deleteOption(index)" title="Delete">
                <i class="light-icon-trash" />
              </div>
            </div>
            <lv-input v-model="activeElement.options[index].src">
              <template #append v-if="selectedSection != 0">
                <lv-checkbox v-if="activeElement.enable_multiselect" v-model="activeElement.options[index].correct" color="success" outline rounded style="margin-top:9px;" title="Correct option"> </lv-checkbox>

                <lv-checkbox v-else name="option" v-model="activeElement.options[index].correct" color="success" outline rounded style="margin-top:9px;" title="Correct option" @input="toggleValue($event, index)"> </lv-checkbox>
              </template>
            </lv-input>
          </div>
        </draggable>
      </container>
      <lv-button @click="addNewOption" style="margin-top:16px">Add New Image</lv-button>
      <!-- width -->
    </div>
    <!-- ---------------------------------------------------------------------------- -->
    <!-- --------------------------FORM-LOGO--------------------------------------------- -->
    <div v-if="activeElement.field_type === 'FormImage'">
      <input type="file" hidden accept="image/*" @change="onFileChange($event, 'logo_url')" ref="imageInput" />

      <div class="el-prop-field">
        <lv-input label="Image URL" placeholder="Enter image url..." v-model="activeElement.logo_url">
          <template #append>
            <lv-button icon="light-icon-upload" @click="$refs.imageInput.click()"></lv-button>
          </template>
        </lv-input>
      </div>

      <!-- alt -->
      <div class="el-prop-field">
        <lv-input type="text" label="Image Alt" helpText="Alt text describing the photo" :bottomBar="true" v-model="activeElement.logo_alt" />
      </div>

      <!-- width -->
      <div class="el-prop-field">
        <lv-input type="number" label="Image Width (px)" :bottomBar="true" v-model="activeElement.logo_width" />
      </div>
      <!-- height -->
      <div class="el-prop-field">
        <lv-input type="number" label="Image Height (px)" :bottomBar="true" v-model="activeElement.logo_height" />
      </div>
    </div>
    <!-- -----------------------------------FORM HEADING----------------------------------------- -->

    <div v-if="activeElement.field_type === 'FormHeading'">
      <div class="el-prop-field">
        <lv-input label="Heading" placeholder="Enter heading" v-model="activeElement.heading"> </lv-input>
      </div>

      <div class="el-prop-field">
        <lv-input label="Sub heading" placeholder="Enter subheading" v-model="activeElement.sub_heading"> </lv-input>
      </div>

      <span>Align </span>
      <div class="el__align__btn">
        <LvButton :outlined="activeElement.heading_align != 'left'" icon="light-icon-align-left" @click="activeElement.heading_align = 'left'" />
        <LvButton :outlined="activeElement.heading_align != 'center'" icon="light-icon-align-center" @click="activeElement.heading_align = 'center'" />
        <LvButton :outlined="activeElement.heading_align != 'right'" icon="light-icon-align-right" @click="activeElement.heading_align = 'right'" />
      </div>
    </div>
    <!-------------------- FORM HTML EDITOR -------------------------->
    <div v-if="activeElement.field_type === 'FormCustomHTML'">
      <div class="el-prop-field">
        <lv-text-area label="Markup" :autoResize="true" :bottomBar="true" placeholder="Enter markup" v-model="activeElement.markup" />
      </div>
    </div>
  </div>
</template>

<script>
// import LvInput from 'lightvue/input';
import LvInput from 'lightvue/input';
import DToggleSwitch from 'lightvue/input-toggle';
import { Container, Draggable } from './smooth-dnd/main';
import { v4 as uuid } from 'uuid';
import ColorPicker from 'lightvue/colorpicker/ColorPicker';
import LvDropdown from 'lightvue/dropdown/Dropdown';
import LvTextArea from 'lightvue/textarea/Textarea';
import { uid } from './utils/uid';
import { CTA_ACTION_TYPES } from '@/config/constants.ts';
import LvCheckbox from 'lightvue/checkbox';
export default {
  name: 'ElementProperties',
  props: ['placeholder', 'name', 'value', 'options', 'color'],
  data() {
    return {
      label: 'Enter Your label',
      showOptions: false,
      selectedOptionId: null,
    };
  },
  components: {
    // LvInput,
    LvInput,
    ColorPicker,
    DToggleSwitch,
    Draggable,
    Container,
    LvDropdown,
    LvCheckbox,
    LvTextArea,
  },
  data: () => {
    return {
      activeTab: 'properties',
      CTA_ACTION_TYPES,
      dragging: false,
      ctaOptions: [
        {
          text: 'Move to Next Section',
          type: CTA_ACTION_TYPES.NEXT_SECTION,
        },
        {
          text: 'Move to Previous Section',
          type: CTA_ACTION_TYPES.PREV_SECTION,
        },
        {
          text: 'Open Link (in new tab)',
          type: CTA_ACTION_TYPES.OPEN_LINK,
        },
        {
          text: 'Submit',
          type: CTA_ACTION_TYPES.SUBMIT,
        },
      ],
      ctaOptionsThank: [
        {
          text: 'Redirect (in same tab)',
          type: CTA_ACTION_TYPES.REDIRECT,
        },
        {
          text: 'Open Link (in new tab)',
          type: CTA_ACTION_TYPES.OPEN_LINK,
        },
        {
          text: 'Submit Another Response',
          type: CTA_ACTION_TYPES.SUBMIT_ANOTHER,
        },
      ],

      // cta_index: null,
    };
  },
  // -------------------------------computed---------------------------------------------------------
  computed: {
    formSchema() {
      return this.$store.state.builder.schema;
    },
    activeRowId() {
      return this.$store.state.builder.activeRowId;
    },
    selectedSection: {
      get: function() {
        return this.$store.state.builder.selectedSection; //selectedSection starts from 0
      },
    },
    activeElement() {
      let rowIndex = this.formSchema.sections[this.selectedSection].rows.findIndex(row => row.id === this.activeRowId);
      if (rowIndex === -1) {
        return null;
      } else {
        return this.formSchema.sections[this.selectedSection].rows[rowIndex].fields[0];
      }
    },
    isThankyouSectionActive() {
      return this.formSchema.sections[this.selectedSection].type === 'ThankYou';
    },
  },
  methods: {
    selectRow(rowIndex) {
      if (!this.dragging) this.selectedOptionId = rowIndex;
    },
    selectHoverRow(rowIndex) {
      if (!this.dragging) this.selectedOptionId = rowIndex;
    },
    toggleValue(val, index) {
      if (!this.activeElement.enable_multiselect) {
        this.activeElement.options.forEach((option, i) => (option.correct = i === index && val));
      }
    },
    changeCta(ctaActionType) {
      console.log(ctaActionType);
      this.activeElement.cta_action_type = ctaActionType;
    },

    addNewOption() {
      let activeQuestion = this.activeElement;
      let optionsLength = activeQuestion.options.length;
      // let newOption = {
      //   text: `Option ${optionsLength + 1}`,
      //   value: optionsLength + 1,
      //   id: uuid(),
      // };
      let newOption =
        this.activeElement.field_type != 'FormPhotoSelect'
          ? {
              src: `Option ${optionsLength + 1}`,
              correct: false,
            }
          : {
              src: 'https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg',
              correct: false,
            };
      //only for 1d array
      activeQuestion.options.splice(optionsLength, 0, newOption);
    },
    deleteOption(i) {
      let activeQuestion = this.activeElement;
      return activeQuestion.options.splice(i, 1);
    },
    duplicateOption(fromIndex) {
      console.log(`Duplicating option from ${fromIndex}`);
      let activeQuestion = this.activeElement;
      // let newOption = {
      //   ...activeQuestion.options[fromIndex],
      //   id: uuid(),
      // };
      let newOption = activeQuestion.options[fromIndex];

      // console.log(newOption);

      activeQuestion.options.splice(fromIndex + 1, 0, newOption);
    },
    moveOption(fromIndex, toIndex) {
      console.log(`Moving row from ${fromIndex} to ${toIndex}`);
      let activeQuestion = this.activeElement;
      let removeOption = activeQuestion.options[fromIndex];
      // Deleting fromIndex
      activeQuestion.options.splice(fromIndex, 1);
      // Adding toIndex
      activeQuestion.options.splice(toIndex, 0, removeOption);
    },
    dndDragStart(e) {
      // console.log(e);
      this.dragging = true;
      // this.selectedOptionId = e.payload.id;
    },
    dndDragEnd(e) {
      this.dragging = false;
    },
    dndDrop(e) {
      this.dragging = false;
      this.moveOption(e.removedIndex, e.addedIndex);
    },
    onFileChange({ target }, prop) {
      const file = target && target.files && target.files[0];
      if (file) {
        this.activeElement[prop] = URL.createObjectURL(file);
      }
    },
  },
};
</script>

<style lang="scss">
.category_title {
  padding: 10px 8px;
  border-radius: 4px;
  color: #3a4a52;
  background-color: #38b2ac4a;
  margin: 12px 0;
  text-transform: capitalize;
}

.el-prop-field {
  margin-top: 8px;
}

.ep-align__btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.options_wrap {
  margin-left: 4px;
  /* padding: 16px; */
}
.options__item {
  padding: 16px;
  border-bottom: 1px solid #bcc8cd;
  background-color: #dee6e9;

  position: relative;

  .option-mover {
    opacity: 0;
    position: absolute;
    top: 0px;
    right: 100%;
    height: 100%;
    width: 16px;
    background-color: #607c8a;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
    cursor: move;
    border-radius: 4px 0px 0px 4px;
    /* box-shadow: 3px 3px 12px 3px #ebebeb; */
  }

  .option-actions {
    position: absolute;
    top: -32px;
    right: 0px;
    background-color: #607c8a;
    display: none;
    // display: flex;
    padding: 0px 12px;
    border-radius: 8px 8px 0px 0px;
    /* box-shadow: 3px 3px 12px 3px #ebebeb; */
  }
  .option-action-btn {
    /* background-color: rgb(210, 65, 65); */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .option-action-btn:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
  &:hover:not(.--no-active),
  &.--active {
    background-color: #b2bec3;
    .option-mover {
      opacity: 1;
    }
    .option-actions {
      display: flex;
    }
  }
}

input {
  width: inherit;
}

.el__align__btn {
  display: flex;
  justify-content: flex-start;
  margin: 8px 0px 12px 0px;
  button {
    margin-left: 8px;
  }
}
</style>
