<template>
  <!-- <div class="fs-fe__logo" :style="{ 'background-image': logo_url }"></div> -->
  <div :class="['fs-fe__logo__container', `--${logo_align}`]">
    <img :src="logo_url" :alt="logo_alt" class="fs-fe__logo__img" :style="{ width: logo_width + 'px', height: logo_height + 'px', margin: logo_margin + 'px' }" />
  </div>
</template>

<script>
export default {
  props: ['logo_url', 'logo_width', 'logo_height', 'logo_alt', 'logo_margin', 'logo_align'],
};
</script>

<style lang="scss">
.fs-fe__logo__container {
  display: flex;
  &.--center {
    justify-content: center;
  }
  &.--left {
    justify-content: flex-start;
  }
  &.--right {
    justify-content: flex-end;
  }
}

.fs-fe__logo {
  width: 300px;
  //   height: 300;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   background-image: url('https://homepages.cae.wisc.edu/~ece533/images/mountain.png');
}
</style>
