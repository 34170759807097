<template>
  <div>
    <div class="thankCard__body">
      <br /><br />
      <div class="thankyou-msg-div">
        <i class="light-icon-check"></i>
        <span class="thank-msg">Thanks!</span>
      </div>
      <br />
      <span class="thank-sub-msg">Your Response was Submitted</span>
      <br />
      <lv-button rounded push class="lv--secondary" style="width: 300px; margin: auto">Submit Another Response</lv-button>
      <!-- **************************************** -->
    </div>
    <!-- ************ -->
    <div class="watermark-wrap">
      <watermark />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.thankCard__body {
  //   background: #ffffff;
  height: 270px;
  padding: 24px;
  border-radius: 12px;

  text-align: center;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thankyou-msg-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.thank-msg {
  font-size: 28px;
  color: #203d4a;
}
.thank-sub-msg {
  color: #203d4a;
}
.light-icon-check {
  font-size: 32px;
  /* height: 50px; */
  font-style: bold;
  background-color: teal;
  color: #ffffff;
  border-radius: 50%;
  padding: 4px;
  margin-right: 12px;
}

.submit-another div {
  margin-top: 16px;
  color: teal;
}

.submit-another-link {
  text-decoration: none;
  color: teal;
  cursor: pointer;
}

.thankCard__footer {
  padding: 24px;
  background-color: #f4f4f4;
  background-color: transparent;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  /* margin-bottom: 20px; */
  font-size: 16px;
}
</style>
