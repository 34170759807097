<template>
  <section class="Settings__Page">
    <div class="Side__bar">
      <div class="menu-item">
        <div class="item__list active__item" @click="$router.push({ hash: '#general' })">GENERAL</div>
        <div class="item__list" @click="$router.push({ hash: '#collaborators', params: $route.params })">Collaborators</div>
        <div class="item__list" @click="$router.push({ hash: '#social', params: $route.params })">Social Media</div>
        <div class="item__list" @click="$router.push({ hash: '#email', params: $route.params })">Email</div>
        <div class="item__list" @click="$router.push({ hash: '#tracking', params: $route.params })">Tracking</div>
      </div>
    </div>
    <div class="Setting__properties">
      <div class="Selected__setting__property">
        <div id="general">
          <form-general-settings />
        </div>

        <!-- Collaborators Settings -->
        <div id="collaborators" class="fs-sp__setting-card__wrap">
          <collaborators class="setting__element" />
        </div>

        <!-- Social Settings -->
        <div id="social" class="fs-sp__setting-card__wrap">
          <social-media-sharing class="setting__element" />
        </div>

        <!-- Email Settings -->
        <div id="email" class="fs-sp__setting-card__wrap">
          <email-settings class="setting__element" />
        </div>

        <!-- Tracking Settings -->
        <div id="tracking" class="fs-sp__setting-card__wrap">
          <tracking class="setting__element" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EmailSettings from '@main/components/settings/EmailSettings.vue';
import FormGeneralSettings from '@main/components/settings/FormGeneralSettings.vue';
import Collaborators from '@main/components/settings/Collaborators.vue';
import SocialMediaSharing from '@main/components/settings/SocialMediaSharing.vue';
import Tracking from '@main/components/settings/Tracking.vue';
import HiddenSetingsOverlay from './HiddenSetingsOverlay.vue';
// import TemplateModal from "@main/components/settings/TemplateModal"
export default {
  components: {
    EmailSettings,
    FormGeneralSettings,
    Collaborators,
    SocialMediaSharing,
    Tracking,
    HiddenSetingsOverlay,
    // ComingSoon,
    // TemplateModal
  },
};
</script>

<style lang="scss">
.Settings__Page {
  display: flex;
  padding: 30px;
}
.Side__bar {
  width: 320px;
}
.Setting__properties {
  width: calc(100% - 400px);
}
.Side__bar .menu-item {
  position: fixed;
  background-color: #ffffff;
  width: 262px;
  border: 1px solid #eaebec;
  margin-top: -1px;
  border-top-right-radius: 5px;
  z-index: 1;
}

.fs-sp__overlay__card {
  z-index: 20;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .sp-coming-soon_card {
    position: absolute;
    // position: fixed;
    top: 265px;
    border-radius: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 #0e1e251f;
    background-color: #fff;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 #0e1e251f;
    color: #646e73;
    // margin: 24px 0px;
    padding: 28px;
  }
}

// .fs-sp__overlay_bg {
//   z-index: 0;
//   .settings__card {
//     opacity: 0.2;
//   }
//   .setting-title {
//     opacity: 0.2;
//   }
//   .setting-description {
//     opacity: 0.2;
//   }
// }

.item__list {
  color: #0e1e25;
  font-weight: 700;
  border: 1px solid #f7f7f8;
  color: inherit;
  display: block;
  font-weight: 500;
  padding: 8px 16px;
  background: #f9fafa;
  cursor: pointer;
}

.active__item {
  border-left: 2px solid #00ad9f;
  background: #ffffff;
  font-weight: 700;
}

.setting__element {
  margin-top: 64px;
}

.setting-header {
  padding: 8px 0px;
}

.setting-title {
  font-size: 24px;
  font-weight: 600;
  color: #2e3c42;
  padding: 8px 0;
}
.setting-description {
  font-size: 16px;
  opacity: 0.5;
  margin: 8px 0 0;
}

.settings__card {
  position: relative;
  border-radius: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 #0e1e251f;
  background-color: #fff;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #0e1e251f;
  color: #646e73;
  margin: 24px 0px;
  padding: 24px;
  z-index: 0;
}

.fs-sp__setting-card__wrap {
  position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
</style>
