<template>
  <div class="Contact-pg-cont">
    <div class="cp-leftPanel">
      <div class="cp-mainhead-wrap">
        <div class="cp-main-head">Contact Us</div>
        <!-- Form Section  -->
        <div class="cp-form-card">
          <div class="cp-right-head">Get in Touch</div>
          <div class="cp-input-div">
            <lv-input placeholder="Enter name..."
              ><template #prepend>
                <div class="cp-input-icon-div">
                  <i class="light-icon-user"></i>
                </div> </template
            ></lv-input>
          </div>
          <div class="cp-input-div">
            <lv-input placeholder="Enter Email..."
              ><template #prepend>
                <div class="cp-input-icon-div">
                  <i class="light-icon-mail"></i>
                </div> </template
            ></lv-input>
          </div>
          <div class="cp-input-div">
            <lv-input placeholder="Enter Phone number..." icon-left="light-icon-phone" label="Phone number" />
          </div>
          <div class="cp-input-div">
            <textarea type="text" rows="6" cols="36" placeholder="Enter your message..." class="cp-textarea-input" />
          </div>
          <div class="cp-form-btn">Send <i class="light-icon-send"></i></div>
        </div>
        <!-- Form Section Ends -->
      </div>
    </div>
    <div class="cp-rightPanel"></div>
  </div>
</template>

<script>
import LvInput from 'lightvue/input';
export default {
  components: {
    LvInput,
  },
};
</script>

<style scoped>
.Contact-pg-cont {
  display: flex;
}

.cp-leftPanel {
  width: 50%;
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
  background-color: #fff;

  /* z-index: -1; */
}
.cp-rightPanel {
  width: 50%;
  height: 100vh;
  background-image: url('../assets/bg_leaf.png');
  background-repeat: no-repeat;
  /* z-index: -1; */
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
}

.cp-mainhead-wrap {
  margin: 58px 58px 0px 58px;
  max-width: 250px;
}
.cp-main-head {
  border-bottom: 4px solid #5b5b5b;
  color: #203d4a;

  /* border-radius: 20px; */
  font-size: 32px;
  font-weight: 800;
  padding: 12px;
  text-align: center;
}

.cp-form-card {
  margin: 36px;
  padding: 56px;
  max-width: 800px;
}

.cp-right-head {
  max-width: 280px;

  text-align: center;
  border-bottom: 2px solid #5b5b5b;
  font-size: 24px;
  font-weight: 600;
  color: #203d4a;

  margin-bottom: 16px;
}

.cp-input-div {
  margin: 16px;
}

.cp-input-icon-div {
  background-color: #337ab7;
  text-align: center;
}
.light-icon-user,
.light-icon-mail,
.light-icon-phone {
  color: #fff;
  font-size: 26px;
  padding: 16px;
  text-align: center;
}

.cp-textarea-input {
  background-color: #edf2f7;
  width: 100%;
}

.cp-form-btn {
  color: #fff;
  border: none;
  border-radius: 16px;
  font-size: 20px;
  background-color: #337ab7;
  text-align: center;
  padding: 8px;
  cursor: pointer;
}
</style>
