import { IField } from './field';
import { uid } from '../utils/uid';

export interface IRow {
  config?: string;
  fields?: IField[];
  id?: string;
  visiblity?: boolean;
  type?: '';
}

interface IRowConfigs {}

export function createNewRow(defaults?: IRow, configs?: IRowConfigs): IRow {
  let newRowDefaults: IRow = {
    config: '',
    type: '',
    visiblity: true,
  };
  return {
    ...newRowDefaults,
    ...defaults,
    id: 'row_' + uid(),
  };
}
