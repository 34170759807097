export default {
  computed: {
    currentForm: {
      get: function() {
        return this.$store.state.application.currentForm;
      },
      set: function(newValue) {
        this.$store.dispatch('application/setCurrentForm', newValue);
      },
    },
  },
};
