<template>
  <!-- ------------------------------------EMAIL SETTINGS CARD ------------------------------------>
  <div>
    <div class="setting-header">
      <div class="top-heading">
        <div class="setting-icon">
          <!-- <i class="light-icon-mail"></i> -->
        </div>
        <div class="setting-title">Email Notifications</div>
      </div>
      <div class="setting-description">
        Automatically send self notifications & custom followup emails to your participants.
      </div>
    </div>
    <!-- -----------Setting Header------- -->
    <!-- --------------Card Start------------------ -->
    <div class="settings__card">
      <hidden-setings-overlay />
      <!-- Receive email for submission block -->
      <div class="email-field-block">
        <input type="checkbox" id="rx-email" name="receive email for submission" value="email" class="rx-email" />
        <label class="email-field-label" for="rx-email">Receive an email for every submission</label>
      </div>
      <!-- Project URL Block -->
      <div class="email-field-block">
        <input type="checkbox" id="send-email" name="send email" value="s-email" class="send-email" />
        <label class="email-field-label" for="send-email">Send custom email to participants</label>
      </div>

      <!----------------------------- Update Settings Button ---------------------------->
      <div class="update-setting">
        <lv-button class="lv--success" push size="md">Update Settings</lv-button>
      </div>
      <!-- ---------------- -->
    </div>
  </div>
  <!-- ------CARD ENDS-------------------- -->
</template>

<script>
import LvButton from 'lightvue/button';
import HiddenSetingsOverlay from './HiddenSetingsOverlay';

export default {
  components: {
    LvButton,
    HiddenSetingsOverlay,
  },
};
</script>

<style scoped>
.top-heading {
  display: flex;
}

.light-icon-mail {
  font-size: 36px;
}

.email-card-body {
  background-color: #ffffff;
  padding: 30px;

  border-radius: 16px;
  /* border-end-start-radius: 16px;
  border-end-end-radius: 16px; */
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); */
}

.email-field-block {
  margin-bottom: 20px;
}

.rx-email,
.send-email {
  margin-right: 4px;
  cursor: pointer;
  /* color-adjust: exact; */
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  height: 1.6rem;
  width: 1.6rem;
  color: teal;
  background-color: #fff;
  border-color: #a0aec0;
  border-width: 1px;
  border-radius: 0.25rem;
}

.update-btn {
  padding: 8px 12px;
  background: teal;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 12px;
  margin-top: 16px;
}
.publish-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
