import { Users } from '../models';
import config from '@main/config/index.ts';
import { useState } from 'vue-gtag-next';

export async function registerUser(res, fname, lname, phone) {
  var user_details = {
    email_id: res.user.email || '',
    name_first: fname || '',
    name_last: lname || '',
    name: res.user.displayName || fname + ' ' + lname,
    phone: res.user.phoneNumber || '',
    photo_url: res.user.photoURL || '',
    forms_count: '',
    password: '',
    // password:res.user.password || "",
    verified: false,
  };

  var user = new Users({
    id: res.user.uid,
    data: { ...user_details, api_key: window.btoa('usr_' + res.user.uid) },
  });
  //   user.push({
  //     extra: "For Testing"
  //   });

  await user.create();
  // this.$toast.add({
  //   content:"Welcome to FORM STUDIO!!",
  //   type:'Success',
  //   duration: 2000
  // })
  console.log('AT AUTH.js New User is created !!');
  // console.log("user id",user.data._id);
  // console.log("API KEY",user.data.api_key)
  // ************ZAP SIGN UP*********
  // geting user IP info:
  let geo_info = {};
  try {
    await fetch('https://ipinfo.io/json?token=5d6d3acb07ec46')
      .then(_ => {
        return _.json();
      })
      .then(resp => {
        geo_info = resp;
      });
  } catch (err) {
    //
  }

  let navigator = {
    user_agent: window.navigator.userAgent,
    vendor: window.navigator.vendor,
    platform: window.navigator.platform,
  };

  const url = config.api_base_url;

  fetch(url + '/signup', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...user.data, geo_info, navigator }),
  }).catch(function(e) {
    console.error(e);
  });
  return user;
}

export function setGaUser(userId, userEmail) {
  const { property } = useState();
  property.value = {
    id: config.ga_id,
    params: {
      user_id: userId,
      user_email: userEmail,
    },
  };
}
