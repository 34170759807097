<template>
  <div>
    <div class="fs-rd__single-response__wrap">
      <div class="__single-response__label-wrap">
        <lv-dropdown v-if="edit" v-model="highlightedFields.first" :options="fields" optionLabel="field_label" optionValue="field_id" placeholder="Select a field" @input="e => updateSelectedFields(e, 'first')" bottom-bar />
        <div class="__single-response__label" v-else>{{ selectedFirstField.field_label }}</div>
      </div>
      <response-single-chart v-if="selectedFirstField" :fieldId="selectedFirstField.field_id" :fieldLabel="selectedFirstField.field_label" :chartType="selectedFirstField.chart_type" :responses="responses" />
    </div>
    <div class="fs-rd__single-response__wrap">
      <div class="fs-rd_divider" v-if="fields.length > 1"></div>
      <div class="__single-response__label-wrap">
        <lv-dropdown v-if="edit" v-model="highlightedFields.second" :options="fields" optionLabel="field_label" optionValue="field_id" placeholder="Add a new chart" @input="e => updateSelectedFields(e, 'second')" bottom-bar clearable />
        <div class="__single-response__label" v-else-if="selectedSecondField">{{ selectedSecondField.field_label }}</div>
      </div>
      <response-single-chart v-if="selectedSecondField" :fieldId="selectedSecondField.field_id" :fieldLabel="selectedSecondField.field_label" :chartType="selectedSecondField.chart_type" :responses="responses" />
    </div>
  </div>
</template>

<script>
import ResponseSingleChart from './ResponseSingleChart';
import LvDropdown from 'lightvue/dropdown';
import Forms from '../../models/form_schema';

export default {
  components: {
    ResponseSingleChart,
    LvDropdown,
  },

  props: ['formData', 'responses', 'edit'],

  data() {
    return {
      fields: [],
      highlightedFields: {
        first: null,
        second: null,
      },
      typeToCharts: {
        FormDropdown: 'PercentChart',
        ToggleButton: 'Doughnut',
        FormToggle: 'Doughnut',
        FormRangeSlider: 'Horizontalbar',
        FormRadioGroup: 'Pie',
      },
    };
  },
  async created() {
    this.selectFields();
    this.createChartsArray();
  },
  computed: {
    selectedFirstField() {
      if (this.highlightedFields.first) {
        let fieldIndex = this.fields.findIndex(field => field.field_id === this.highlightedFields.first);
        if (fieldIndex === -1) {
          return null;
        } else {
          return { ...this.fields[fieldIndex] };
        }
      } else return null;
    },
    selectedSecondField() {
      if (this.highlightedFields.second) {
        let fieldIndex = this.fields.findIndex(field => field.field_id === this.highlightedFields.second);
        if (fieldIndex === -1) {
          return null;
        } else {
          return { ...this.fields[fieldIndex] };
        }
      } else return null;
    },
  },
  methods: {
    selectFields() {
      if (this.formData.highlighted_field_id) {
        this.highlightedFields = this.formData.highlighted_field_id;
      }
    },
    createChartsArray() {
      this.formData.schema.sections.forEach(section => {
        section.rows.forEach(row => {
          row.fields.forEach(field => {
            if (this.typeToCharts[field.field_type]) {
              this.fields.push({
                field_id: field.id,
                field_label: field.label,
                field_type: field.field_type,
                chart_type: this.typeToCharts[field.field_type],
              });
              // initializing highlightedFields
              if (this.highlightedFields.first === null) {
                this.highlightedFields.first = field.id;
              } else if (this.highlightedFields.second === null) {
                this.highlightedFields.second = field.id;
              }
            }
          });
        });
      });
    },
    async updateSelectedFields(newValue, index) {
      // console.log(newValue, index);
      setTimeout(async () => {
        await Forms.directlyUpdate(this.formData._id, 'highlighted_field_id', {
          ...this.highlightedFields,
        });
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
.fs-rd_divider {
  margin: 16px auto;
  width: 80%;
  height: 2px;
  border-radius: 2px;

  background-color: rgba(0, 0, 0, 0.05);
}
.fs-rd__single-response__wrap {
  // padding: 16px 0px;
  .__single-response__label-wrap {
    min-height: 40px;
    margin-bottom: 8px;
  }
  .__single-response__label {
    padding: 10px 12px;
    color: #566d79;
  }
}
</style>
