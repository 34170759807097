<template>
  <div>
    <header class="navbar">
      <!-- ----LOGO & TITLE-------- -->
      <div class="logo-title">
        <div @click="navigatetoDashboard">
          <img src="../../assets/form-studio-only-logo.svg" alt="" class="brand-logo" />
        </div>
        <div v-if="$route.name !== 'Create' && $route.name !== 'Editor'" class="brand-title" @click="navigatetoDashboard">Form <span style="font-size: 16px">Studio</span></div>
        <div v-if="$route.name === 'Editor' || $route.name === 'Create'" class="project-title" @click="editProjectName">
          <input type="text" class="project-name_input transparent-input" placeholder="Project name" :size="currentForm.project_name ? currentForm.project_name.length + 1 : 12" :value="currentForm.project_name" @input="e => (currentForm.project_name = e.target.value)" />
          <i class="light-icon-pencil"></i>
        </div>
      </div>
      <!-- -------------- -->
      <a href="#" class="toggle-button">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </a>
      <div class="navbar-links">
        <ul>
          <li
            class="nav-item"
            v-if="$route.name !== 'Dashboard'"
            @click="
              $router.push({
                name: 'Dashboard',
              })
            "
          >
            Dashboard
          </li>
          <li class="nav-item" v-if="$route.name === 'Editor'" @click="checkUnsaved_changes">
            Settings
          </li>

          <navbar-save />
          <li
            class="nav-item"
            v-if="$route.name === 'Preview' || $route.name === 'form-settings'"
            @click="
              $router.push({
                name: 'Editor',
                query: { id: $route.query.id },
              })
            "
          >
            Back to Editor
          </li>

          <!--  -->
          <li
            class="nav-item"
            v-if="$route.name === 'Publish'"
            @click="
              $router.push({
                name: 'form-settings',
                query: { id: $route.query.id },
              })
            "
          >
            Back
          </li>
          <!--  -->
          <li class="nav-item" v-if="$route.name === 'Dashboard' && user">
            <img src="@main/assets/account.svg" v-if="!user.photo_url" class="account-icon" />
            <img :src="user.photo_url" v-if="user.photo_url" class="account-icon" />

            <Dropdown :userName="user.name" />
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
import Dropdown from './Dropdown.vue';
import NavbarSave from './NavbarSave';
import { userMixin, currentFormMixin } from '@main/mixins';

export default {
  name: 'Navbar',
  components: { NavbarSave, Dropdown },
  mixins: [userMixin, currentFormMixin],
  data() {
    return {
      unsavedChanges: true,
    };
  },

  methods: {
    navigatetoDashboard() {
      this.$router.push({ name: 'Dashboard' });
    },

    checkUnsaved_changes() {
      // if (this.unsavedChanges) {
      //   alert("You have unsaved changes");
      this.$router.push({
        name: 'form-settings',
        query: { id: this.$route.query.id },
      });
      // }
    },

    editProjectName() {
      //
    },
  },
};
</script>

<style lang="scss">
/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  background-color: #203d4a;
  color: #ffffff;
  padding: 0px 24px;

  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
}

/* ----------- */
.logo-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-title {
  font-size: 24px;
  padding: 16px;
  cursor: pointer;
  line-height: 24px;
  text-transform: uppercase;
}

.project-title {
  font-size: 24px;
  padding: 16px;
  line-height: 24px;
  i {
    cursor: pointer;
  }
}

.project-name_input {
  font-size: 20px;
  padding-right: 16px;
  max-width: 250px;
  color: white;
}
.transparent-input {
  border: none;
  outline: none;
  background: transparent;
  &::placeholder {
    color: #ffffff;
    opacity: 0.4;
  }
}
.brand-logo {
  width: 34px;
  cursor: pointer;
}

.navbar-links ul {
  display: flex;
  height: 100%;
}

.navbar-links li {
  list-style-type: none;
}

.navbar-links .nav-item {
  cursor: pointer;
  padding: 0px 16px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-links .nav-item:hover {
  background-color: #38b2ac;
}

.account-icon {
  height: 28px;
  width: 28px;
  margin-right: 12px;
  border-radius: 50%;
}

/* TODO: Refactor for mobile responsive============== */
@media (max-width: 600px) {
  .toggle-button {
    display: flex;
  }
  .navbar-links {
    display: none;
    width: 100%;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-links ul {
    width: 100%;
    flex-direction: column;
  }

  .navbar-links li {
    text-align: center;
  }

  .navbar-links li a {
    padding: 0.5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
  }
}

/*----------------  */
/* ----sidebar btn---- */
label #sidebar_btn {
  z-index: 1;
  color: #fff;
  /* position: fixed; */
  cursor: pointer;
  /* left: 300px; */
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#check:checked ~ .sidebar {
  left: -190px;
}
.toggle-button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}
</style>
