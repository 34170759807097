<template>
  <div>
    <!----------------------------------------------PLAYGROUND------------------------------------------------------------>

    <section class="fs-pg" v-if="schema && schema.settings" :style="{ background: schema.settings.styles.playground_background }">
      <div
        class="fs-pg__form-wrap"
        :style="{
          'border-color': schema.settings.styles.form_layout_color || schema.settings.styles.form_theme_color,
          'border-radius': schema.settings.styles.border_radius,
          background: schema.settings.styles.form_background,
        }"
        :class="{ '--no-border': !schema.settings.border_width, '--form-wrap-shadow': !schema.settings.styles.form_shadow }"
      >
        <div class="fs-pg__form-header" :style="{ 'background-color': schema.settings.styles.form_layout_color || schema.settings.styles.form_theme_color, color: schema.settings.styles.header_font_color }" :class="{ '--d-none': !schema.settings.form_header, '--ai-center': schema.settings.form_header_align == 'center', '--ai-end': schema.settings.form_header_align == 'right' }">
          <div class="fs-pg__form-heading" :style="{ 'font-size': schema.settings.styles.header_font_size + 'px' }">
            {{ schema.settings.form_heading }}
          </div>
          <div class="fs-pg__form-desc" :style="{ 'font-size': schema.settings.styles.header_descp_font_size + 'px' }">
            {{ schema.settings.form_description }}
          </div>
        </div>
        <ProgressBar v-if="schema && schema.sections" :color="schema.settings.styles.progressbar_color || schema.settings.styles.form_theme_color" :section-count="schema.sections.length" :selected-section="selectedSection" class="fs-pg__progressbar" :class="{ '--d-none': schema.settings.styles.progressbar_hide }" />
        <form-separator class="fs-pg__separator" :class="{ '--d-none': !schema.settings.styles.header_separator_visibility }" />

        <div class="fs-pg__form-body" v-if="schema && schema.sections">
          <quiz-timer v-if="schema.quiz_settings && showTimer" :animated="schema.quiz_settings.animated_timer" :totalTime="schema.quiz_settings.section_timer_enabled ? schema.sections[this.selectedSection].config.section_timer_duration : schema.quiz_settings.global_timer_duration" :duration="timerDuration" />
          <div v-for="(section, sectionIndex) in schema.sections" :key="'sec_' + section.id">
            <div v-show="sectionIndex === selectedSection">
              <!----- Section heading -->
              <div class="fs-pg__form-section-heading" v-if="schema.settings && schema.settings.section_heading_visibility && schema.sections[selectedSection] && schema.sections[selectedSection].heading_visibility" :style="{ 'background-color': schema.settings.styles.section_header_bg_color, 'border-left-color': schema.settings.styles.section_header_font_color }">
                {{ schema.sections[sectionIndex].heading }}
              </div>
              <!-- -------- -->
              <div v-if="schema && schema.sections[sectionIndex] && schema.sections[sectionIndex].rows">
                <div v-for="(row, rowIndex) in schema.sections[sectionIndex].rows" :key="'row_' + row.id">
                  <div class="fs-pg__form-row" v-for="(field, fieldIndex) in row.fields" :key="field.id">
                    <PlaygroundQuestion :field="field" :settings="schema.settings" v-model="formData[field.id]" @ctaClick="handleMixinCTA" :errors="formErrors[field.id]" />
                  </div>
                </div>
              </div>

              <!--- Next & Back Section Button-- -->
              <div class="fs-pg__next-back-row" v-if="schema && schema.sections[sectionIndex] && schema.sections[selectedSection].config.default_btn !== false">
                <lv-button push class="lv--success" v-if="sectionIndex > 0 && selectedSection + 1 !== schema.sections.length" @click="handleMixinCTA({ cta_action_type: CTA_ACTION_TYPES.PREV_SECTION })" icon="light-icon-arrow-left" label="Back" :rounded="schema.settings.styles.rounded_input" :style="{ 'background-color': schema.settings.styles.form_theme_color }" :disabled="backButtonDisabled" />
                <div v-if="sectionIndex === 0"></div>
                <lv-button push class="lv--success" :rounded="schema.settings.styles.rounded_input" v-if="sectionIndex + 1 <= schema.sections.length - 2" @click="handleMixinCTA({ cta_action_type: CTA_ACTION_TYPES.NEXT_SECTION })" icon-right="light-icon-arrow-right" :style="{ 'background-color': schema.settings.styles.form_theme_color }">Next</lv-button>
                <lv-button push class="lv--success" :rounded="schema.settings.styles.rounded_input" icon-right="light-icon-plane-departure" @click="handleMixinCTA({ cta_action_type: CTA_ACTION_TYPES.SUBMIT })" v-if="sectionIndex + 1 === schema.sections.length - 1" :style="{ 'background-color': schema.settings.styles.form_theme_color }">Submit</lv-button>
              </div>
            </div>
          </div>
          <!-- Show only if not availabe in schema -->
        </div>
        <!-- <ThankYouCard v-else/> -->
        <!-- Thank you Section -->
        <!--  -->
        <!-- **************************************** -->
      </div>
      <!-- ************ -->
      <div class="watermark-wrap">
        <watermark />
      </div>
    </section>
    <AdCard class="fs-pg" v-if="submitted === true" />

    <!-- ********************************************************************************************************* -->
  </div>
</template>

<script>
import PlaygroundQuestion from './PlaygroundQuestion.vue';
import Watermark from './Watermark.vue';
import LvButton from 'lightvue/button';
import ProgressBar from './ProgressBar';
import ThankYouCard from './ThankYouCard';
import AdCard from './AdCard';
import FormSeparator from './elements/FormSeparator';
import { uid } from '../src/utils/index.js';
import handleCTAmixins from './mixins/handleCTAmixins';
import iframeMixin from './mixins/iframeMixins';
import validationMixin from './mixins/validationMixin';
import { CTA_ACTION_TYPES } from '../src/config/constants.ts';
import QuizTimer from './QuizTimer.vue';

export default {
  name: 'FormPreview',
  props: ['schema', 'formObject', 'csrfToken', 'apiBaseURL', 'mode'], // receving these props from server side
  mixins: [handleCTAmixins, iframeMixin, validationMixin],
  components: {
    Watermark,
    LvButton,
    PlaygroundQuestion,
    ProgressBar,
    ThankYouCard,
    AdCard,
    FormSeparator,
    QuizTimer,
  },

  data() {
    return {
      selectedSection: 0,
      formData: {},
      formErrors: {},
      submitted: false,
      submissionStatus: '',
      CTA_ACTION_TYPES,
      responseId: 'res_' + uid(),
    };
  },
  computed: {
    backButtonDisabled() {
      return this.selectedSection === 1 || (this.schema?.quiz_settings?.timer_enabled && this.schema?.quiz_settings?.section_timer_enabled);
    },
    isThankyouSectionActive() {
      return this.schema.sections[this.selectedSection].type === 'ThankYou';
    },
    isLastSection() {
      return this.schema.sections[this.selectedSection + 1] && this.schema.sections[this.selectedSection + 1].type === 'ThankYou';
    },
    showTimer() {
      return this.schema?.quiz_settings?.timer_enabled && ((this.schema.quiz_settings.global_timer_enabled && this.selectedSection >= 0 && this.selectedSection < this.schema.sections.length - 1) || (this.schema?.quiz_settings?.section_timer_enabled && this.selectedSection > 0 && this.selectedSection < this.schema.sections.length - 1));
    },
  },

  methods: {
    changeSelectedSection(newSectionIndex) {
      // -----------------------------------
      this.selectedSection = newSectionIndex;
      this.$router.push({ query: { ...this.$route.query, step: parseInt(newSectionIndex) + 1 } });
    },
    // ARE WE USING IT ????
    // handleOptionSelect(correctOption, id) {
    //   let answered = this.formData[id];

    //   // checking if answer is correct and save to score corresponding to each question
    //   this.score[id] = typeof answered === 'object' ? +(answered.sort((a, b) => a - b).join('') === correctOption.sort((a, b) => a - b).join('')) : +(answered === correctOption);

    //   // calculating the total score
    //   this.totalCorrectAns = Object.values(this.score).reduce((s1, s2) => s1 + s2, 0);

    //   // console.log('%ctotal correct ans %s', 'color: yellow', this.totalCorrectAns);
    // },
    resetForm() {
      this.submitted = false;
      this.formData = {};
      this.totalCorrectAns = 0;
      this.score = [];
      this.responseId = 'res_' + uid();
      // ? so that timer does not show previous time on reset
      this.timerDuration = 0;
    },
  },
};
</script>

<style lang="scss">
/* Thank you only */
/* Intro To responser card */
@import './Playground.scss';

.watermark-wrap {
  max-width: 700px;
  margin: 20px auto;
  display: flex;
  justify-content: flex-end;
}

/* ------ */
</style>
