<template>
  <div class="btn-row" :class="{ 'btn-align-left': align === 'left', 'btn-align-center': align === 'center', 'btn-align-right': align === 'right' }" :style="{ 'margin': btn_margin + 'px' }">
    <Button :label="label" :raised="raised" :deepShadow="deepShadow" :rounded="rounded" :deepShadowHover="deepShadowHover" :push="push" :outlined="outlined" class="lv--success" :style="{ 'background-color': background_color, color: font_color, width: btn_width + 'px' }" @click="handleClick" :value="modelValue" @input="updateValue" />
  </div>
</template>

<script>
import Button from 'lightvue/button/Button.vue';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  components: {
    Button,
  },
  props: ['cta_action_type', 'cta_action_value', 'label', 'raised', 'deepShadow', 'rounded', 'deepShadowHover', 'push', 'outlined', 'align', 'background_color', 'font_color', 'btn_width', 'btn_margin'],
  methods: {
    handleClick() {
      this.$emit('ctaClick', {
        cta_action_type: this.cta_action_type,
        cta_action_value: this.cta_action_value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-row {
  display: flex;
  &.btn-align-center {
    justify-content: center;
    align-items: center;
  }
  &.btn-align-left {
    justify-content: left;
    align-items: flex-start;
  }
  &.btn-align-right {
    justify-content: flex-end;
  }
  &.--d-none {
    display: none;
  }
}

.multiple_res_btn {
  &.--low-opacity {
    opacity: 0.5;
  }
  &.--d-none {
    display: none;
  }
}
</style>
