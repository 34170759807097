<template>
  <div class="form-properties__category-wrap">
    <div class="form-properties__category-wrap__button-container">
      <lv-button iconRight="light-icon-wand" label="Project Design &amp; Colors" @click="$emit('showProjectDesign')" />
    </div>
    <!--------------------------------- Quiz Speciic Settings ----------------------------->
    <accordian-custom-styles v-if="isQuizModeEnabled" :label="`Quiz Properties`" :activeCategory="activeCategory" @click.stop="setActiveCategory('quiz_properties')" />
    <QuizProperties v-if="isQuizModeEnabled && activeCategory === 'quiz_properties'" />

    <!--------------------------------- FORM BACKGROUND ------------------>
    <accordian-custom-styles :label="`Form Background`" :activeCategory="activeCategory" @click.stop="setActiveCategory('Form Background')" />
    <div v-if="activeCategory === 'Form Background'" class="category-items">
      <!-- FORM THEME COLOR -->
      <ColorPicker v-model="settings.styles.playground_background" label="Background Color" />
      <br />

      <lv-input :bottomBar="true" v-model="settings.styles.playground_padding_top" label="Padding Top-Bottom">
        <template #append>
          <span>px</span>
        </template>
      </lv-input>
      <br />
      <!-- <lv-input v-model="settings.styles.playground_padding_bottom" label="Padding Bottom" /> -->
      <!-- <br /> -->
      <lv-input :bottomBar="true" v-model="settings.styles.playground_padding_left" label="Padding Left-Right" />
      <br />
      <!-- <lv-input v-model="settings.styles.playground_padding_right" label="Padding Right" /> -->
      <!-- <br /> -->
      <!-- Form Shadow -->
      <d-toggle-switch label="Shadow" v-model="settings.styles.form_shadow" />
      <br />
    </div>

    <!--------------------------------- FORM Header Properties & STYLES ----------------------------->
    <accordian-custom-styles :label="`Form Body`" :activeCategory="activeCategory" @click.stop="setActiveCategory('Form Body')" />
    <div v-if="activeCategory === 'Form Body'" class="category-items">
      <!-- <accordian-custom-styles :label="`Form Header`" :activeCategory="activeCategory" @click.stop="setActiveCategory('Form Header')" /> -->
      <h4 class="category_title">Form Heading</h4>

      <!-- Form Heading -->
      <div class="form-prop-fields">
        <lv-input v-model="settings.form_heading" :bottomBar="true" label="Form Title" />
      </div>
      <div class="form-prop-fields">
        <lv-input type="number" :bottomBar="true" v-model="settings.styles.header_font_size" :placeholder="settings.styles.header_font_size" label="Heading Size" />
      </div>
      <!-- Form Description -->
      <div class="form-prop-fields">
        <lv-input v-model="settings.form_description" :bottomBar="true" label="Form Description" />
      </div>

      <div class="form-prop-fields">
        <lv-input type="number" :bottomBar="true" v-model="settings.styles.header_descp_font_size" label="Description Size" :placeholder="settings.styles.header_descp_font_size" />
      </div>
      <!-- <lv-dropdown v-model="selectedStyle" :options="headerStyles" placeholder="Select style" clearable /> -->

      <ColorPicker v-model="settings.styles.form_layout_color" label="Header Color" />
      <br />

      <ColorPicker v-model="settings.styles.header_font_color" label="Header Font Color" />
      <br />

      <div class="form-prop-fields">
        <!-- Form without Header -->
        <d-toggle-switch label="Form Header" name="reqHeader" v-model="settings.form_header" />
        <!-- FORM Header Configs -->
        <div v-if="settings.form_header" class="header__align__btn">
          <br />
          <LvButton :outlined="settings.form_header_align != 'left'" icon="light-icon-align-left" @click="settings.form_header_align = 'left'" />
          <LvButton :outlined="settings.form_header_align != 'center'" icon="light-icon-align-center" @click="settings.form_header_align = 'center'" />
          <LvButton :outlined="settings.form_header_align != 'right'" icon="light-icon-align-right" @click="settings.form_header_align = 'right'" />
        </div>
      </div>

      <h4 class="category_title">Form Progress Bar</h4>

      <d-toggle-switch label="Disable" v-model="settings.styles.progressbar_hide" />
      <br />
      <d-toggle-switch label="Header Separator" v-model="settings.styles.header_separator_visibility" />

      <br />
      <ColorPicker v-if="settings.styles.progressbar_hide" v-model="settings.styles.progressbar_color" label="Color" />
      <br />

      <!-- Progress Bar Color -->
      <d-toggle-switch label="Wizard Mode" v-model="settings.styles.progressbar_wizard_mode" />

      <h4 class="category_title">Section</h4>

      <div class="section-head">
        <d-toggle-switch label="Section Heading" name="secHeader" v-model="settings.section_heading_visibility" />
        <br />
        <!-- Section heading Alignment -->
        <!-- <div  class="header__align__btn">
  <br>
<LvButton outlined icon="light-icon-align-left" @click="settings.section_header_align ='left' "/>
<LvButton outlined icon="light-icon-align-center" @click="settings.section_header_align ='center' "/>
<LvButton outlined icon="light-icon-align-right"  @click="settings.section_header_align ='right' "     />
</div> -->
      </div>
      <d-toggle-switch label="Default Buttons" v-model="schema.sections[selectedSection].config.default_btn" />
      <ColorPicker v-if="settings.section_heading_visibility" v-model="settings.styles.section_header_bg_color" label="Header Color" />
      <br />
      <ColorPicker v-if="settings.section_heading_visibility" v-model="settings.styles.section_header_font_color" label="Font Color" />

      <!-- Section Heading Visibility -->
    </div>
  </div>
</template>
<script>
import DToggleSwitch from 'lightvue/input-toggle';
import LvInput from 'lightvue/input';
import ColorPicker from 'lightvue/colorpicker/ColorPicker';
import LvButton from 'lightvue/button/Button.vue';
import AccordianCustomStyles from './AccordianCustomStyles.vue';
import RangeSlider from 'lightvue/rangeslider/RangeSlider.vue';
import LvDropdown from 'lightvue/dropdown/Dropdown.vue';
import QuizProperties from './form-properties/QuizProperties.vue';
import formSchemaSettingsMixin from './mixins/computedMixins/formSchemaSettingsMixin.js';
import isQuizModeEnabled from './mixins/computedMixins/isQuizModeEnabled.js';

export default {
  name: 'FormProperties',
  props: ['color'],
  mixins: [formSchemaSettingsMixin, isQuizModeEnabled],
  emits: ['showProjectDesign'],
  data() {
    return {
      label: 'Enter Your label',
      activeCategory: 'Form Body',
      headerStyles: ['Header Color', 'Font Color', 'Heading Font Size', 'Description Font Size', 'Header Font Color'],
      selectedStyle: null,
    };
  },
  components: {
    DToggleSwitch,
    LvInput,
    ColorPicker,
    LvButton,
    AccordianCustomStyles,
    RangeSlider,
    LvDropdown,
    QuizProperties,
  },

  computed: {
    selectedSection: {
      get: function() {
        return this.$store.state.builder.selectedSection;
      },
    },

    // styles: {
    //   get: function() {
    //     return this.$store.state.builder.schema.styles;
    //   },
    // },
  },
  methods: {
    setActiveCategory(newCategory) {
      this.activeCategory = this.activeCategory === newCategory ? '' : newCategory;
    },
  },
};
</script>

<style scoped lang="scss">
.form-properties__category-wrap {
  border-radius: 0px;
  // background: #3f5062;
  // border-left: 5px solid rgba(0, 0, 0, 0);

  // border-bottom: 1px solid #314254;
  // border-top: 1px solid rgba(255, 255, 255, 0.1);
  // box-shadow: inset 7px 7px 100px #364453, inset -7px -7px 100px #485c71;
  &.--active {
    box-shadow: inset 0px 0px 1px #212a33, inset 0px 0px 2px #212a33;
    background-color: rgba(255, 255, 255, 0.04);
    border-left: 5px solid #607b89;
  }
  &__button-container {
    padding: 1rem 0;

    // width: 100%;
    display: grid;
    place-items: center;
  }
}

.category-items {
  padding: 16px;
  transition: all 0.4s;
}

.customizations-inline-label {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.toggle-switches {
  margin-top: 8px;
}

.form-prop-fields {
  margin-bottom: 12px;
}

.section-head {
  margin: 8px 0px 8px 0px;
}

.header__align__btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0px 12px 0px;
}
.header__align__btn button {
  margin-left: 8px;
}
</style>
