<template>
  <div @click="activeRowId = ''">
    <!----------------------------------------------PLAYGROUND------------------------------------------------------------>
    <section class="fs-pg" @click.stop="activeRowId = ''" v-if="schema && schema.settings" :style="{ 'background-color': schema.settings.styles.playground_background, 'padding-top': schema.settings.styles.playground_padding_top + 'px', 'padding-bottom': schema.settings.styles.playground_padding_top + 'px', 'padding-right': schema.settings.styles.playground_padding_left + 'px', 'padding-left': schema.settings.styles.playground_padding_left + 'px' }">
      <div
        class="fs-pg__form-wrap"
        :style="{
          'border-color': schema.settings.styles.form_layout_color || schema.settings.styles.form_theme_color,
          'border-radius': schema.settings.styles.border_radius,
          background: settings.styles.form_background,
        }"
        :class="{ '--no-border': !schema.settings.border_width, '--form-wrap-shadow': !schema.settings.styles.form_shadow }"
      >
        <div class="fs-pg__form-header" :style="{ 'background-color': schema.settings.styles.form_layout_color || schema.settings.styles.form_theme_color, color: schema.settings.styles.header_font_color }" :class="{ '--d-none': !schema.settings.form_header, '--ai-center': schema.settings.form_header_align == 'center', '--ai-end': schema.settings.form_header_align == 'right' }">
          <div class="fs-pg__form-heading" :style="{ 'font-size': schema.settings.styles.header_font_size + 'px' }">
            {{ schema.settings.form_heading }}
            <!-- <input type="text" placeholder="Form Heading" v-model="schema.settings.form_heading" class="fs-pg__form-heading-input" /> -->
            <!-- <i class="light-icon-pencil"></i> -->
          </div>
          <div class="fs-pg__form-desc" :style="{ 'font-size': schema.settings.styles.header_descp_font_size + 'px' }">
            {{ schema.settings.form_description }}
            <!-- <input type="text" placeholder="Form description..." v-model="schema.settings.form_description" class="fs-pg__form-desc-input" /> -->
            <!-- <i class="light-icon-pencil"></i> -->
          </div>
        </div>
        <ProgressBar v-if="schema && schema.sections" :color="schema.settings.styles.progressbar_color || schema.settings.styles.form_theme_color" :section-count="schema.sections.length" :selected-section="selectedSection" class="fs-pg__progressbar" :class="{ '--d-none': schema.settings.styles.progressbar_hide }" />
        <!-- Header Separator -->
        <form-separator class="fs-pg__separator" :class="{ '--d-none': !schema.settings.styles.header_separator_visibility }" />

        <wizard-progress-bar v-if="schema && schema.sections && settings.styles.progressbar_wizard_mode" :color="schema.settings.styles.progressbar_color || schema.settings.styles.form_theme_color" :section-count="schema.sections.length" :selected-section="selectedSection + 1" @wiz-section="changeSelectedSection" />

        <div class="fs-pg__form-body" v-if="schema && schema.sections">
          <quiz-timer v-if="showTimer" :animated="schema?.quiz_settings?.animated_timer" :totalTime="schema.quiz_settings.section_timer_enabled ? schema.sections[this.selectedSection].config.section_timer_duration : schema.quiz_settings.global_timer_duration" />
          <!----- Section heading -->
          <div class="fs-pg__form-section-heading" v-if="schema.settings && schema.settings.section_heading_visibility && schema.sections[selectedSection] && schema.sections[selectedSection].heading_visibility" :style="{ 'background-color': schema.settings.styles.section_header_bg_color, 'border-left-color': schema.settings.styles.section_header_font_color }">
            <input class="fs-pg__transparent-input" placeholder="Section Name" :size="schema.sections[selectedSection].heading ? schema.sections[selectedSection].heading.length + 1 : 16" v-model="schema.sections[selectedSection].heading" :style="{ color: schema.settings.styles.section_header_font_color }" />
            <i class="light-icon-pencil"></i>
            <!-- Section {{ selectedSection + 1 }} -->
          </div>
          <!-- -------- -->
          <container @drop="dndDrop" @dragStart="dndDragStart" @dragEnd="dndDragEnd" group-name="builder" v-if="schema && schema.sections && schema.sections[selectedSection] && schema.sections[selectedSection]">
            <draggable v-for="(row, rowIndex) in schema.sections[selectedSection].rows" :key="'row_' + row.id">
              <div
                class="fs-pg__form-row"
                :class="{
                  '--selected': activeRowId === row.id,
                  '--hovered': hoverRowIndex === rowIndex,
                }"
                v-for="(field, fieldIndex) in row.fields"
                :key="field.id"
                @click.stop="selectRow(rowIndex)"
                @mouseover.stop="selectHoverRow(rowIndex)"
                @mouseleave.stop="hoverRowIndex = ''"
              >
                <!-- <component
                  :is="field.field_type"
                  v-model="formData[field.name]"
                  v-bind="field"
                  :rounded="schema.settings.rounded_input"
                  :floatingLabel="schema.settings.floating_label"
                ></component>-->

                <PlaygroundQuestion :field="field" :settings="schema.settings" v-model="formData[field.id]" @ctaClick="handleMixinCTA" />

                <div class="fs-pg__row-mover" :class="{ '--active': activeRowId === row.id || hoverRowIndex === rowIndex }" title="Move">
                  <i class="light-icon-grip-vertical" />
                </div>

                <div class="fs-pg__row-actions" v-if="activeRowId === row.id">
                  <!-- <div class="fs-pg__row-action-btn" @click.stop title="Move">
                  <i class="light-icon-grip-vertical" />
                </div>
                <div class="fs-pg__row-action-btn" @click.stop title="Settings">
                  <i class="light-icon-settings" />
                </div>

                <div class="fs-pg__row-action-btn" @click.stop title="Hide Temporary">
                  <i class="light-icon-scissors" />
                  </div>-->
                  <div class="fs-pg__row-action-btn" @click.stop title="Duplicate">
                    <i class="light-icon-copy" />
                  </div>
                  <div class="fs-pg__row-action-btn" @click.stop="deleteRow(rowIndex)" title="Delete">
                    <i class="light-icon-trash" />
                  </div>
                </div>
              </div>
            </draggable>
            <!-- </div> -->
          </container>

          <!-- <div v-if="schema.sections.length - 1 == selectedSection">
            <div class="fs-pg__thankCard__body">
              <br /><br />
              <div class="thankyou-msg-div">
                <i class="light-icon-check"></i>
                <span class="thank-msg">Thanks!</span>
              </div>
              <br />
              <span class="thank-sub-msg">Your Response was Submitted</span>
              <br />
              <lv-button v-if="schema.settings.multiple_responses" rounded push class="lv--secondary" @click="resetForm" style="width: 300px; margin: auto">Submit Another Response</lv-button>
              <lv-button v-else rounded push class="lv--secondary" @click="redirectForm" style="width: 300px; margin: auto">Click to know more</lv-button>
            </div>
          </div> -->
          <!-- **************************************** -->

          <!-- </div> -->

          <!-- :class="{'--ai-center': schema.settings.styles.submit_btn_align == 'center', '--ai-start': schema.settings.styles.submit_btn_align == 'left' }" -->
          <!--- Next & Back Section Button-- -->
          <div class="fs-pg__next-back-row" v-if="schema && schema.sections[selectedSection] && schema.sections[selectedSection].rows.length && schema.sections[selectedSection].config.default_btn !== false">
            <lv-button push class="lv--success" v-if="selectedSection > 0 && selectedSection + 1 !== schema.sections.length" @click="handleMixinCTA({ cta_action_type: CTA_ACTION_TYPES.PREV_SECTION })" icon="light-icon-arrow-left" label="Back" :rounded="schema.settings.styles.rounded_input" :style="{ 'background-color': schema.settings.styles.form_theme_color }" :disabled="backButtonDisabled" />
            <div v-if="selectedSection === 0"></div>
            <lv-button push class="lv--success" :rounded="schema.settings.styles.rounded_input" icon-right="light-icon-arrow-right" @click="handleMixinCTA({ cta_action_type: CTA_ACTION_TYPES.NEXT_SECTION })" v-if="selectedSection + 1 <= schema.sections.length - 2" :style="{ 'background-color': schema.settings.styles.form_theme_color }">Next</lv-button>
            <lv-button push class="lv--success fs-pg__submit-btn" :rounded="schema.settings.styles.rounded_input" icon-right="light-icon-plane-departure" @click="handleMixinCTA({ cta_action_type: CTA_ACTION_TYPES.SUBMIT })" v-if="selectedSection + 1 === schema.sections.length - 1" :style="{ 'background-color': settings.styles.button_color || schema.settings.styles.form_theme_color, width: settings.styles.button_width }">Submit</lv-button>
          </div>
        </div>
      </div>

      <!-- THANK YOU CARD -->
      <!-- <ThankYouCard v-if="showThankYou"/> -->

      <!--  -->
    </section>
    <!-- ********************************************************************************************************* -->
  </div>
</template>

<script>
import './Playground.scss';
import { Container, Draggable } from './smooth-dnd/main';
import PlaygroundQuestion from './PlaygroundQuestion.vue';
import LvButton from 'lightvue/button';
import ProgressBar from './ProgressBar';
import { uid } from './utils/uid';
import WizardProgressBar from './WizardProgressBar.vue';
import FormSeparator from './elements/FormSeparator.vue';
import { CTA_ACTION_TYPES } from '@/config/constants.ts';
import handleCTAmixins from './mixins/handleCTAmixins';
import QuizTimer from './QuizTimer.vue';
import { createNewField, createNewRow } from './types/index.ts';

// import ThankYouCard from './ThankYouCard'
export default {
  name: 'FormsDemo',
  props: ['draggable', 'field'],
  mixins: [handleCTAmixins],
  components: {
    PlaygroundQuestion,
    Container,
    Draggable,
    LvButton,
    ProgressBar,
    WizardProgressBar,
    FormSeparator,
    QuizTimer,
    // ThankYouCard
  },

  data() {
    return {
      dragOverTarget: '',
      formData: {},

      model: {},
      // activeElement: [],
      color: 'Teal',
      showSchema: false,
      hoverRowIndex: '',
      dragging: false,
      incrementPercent: 0,
      sectionCount: 1,
      CTA_ACTION_TYPES,
    };
  },

  computed: {
    backButtonDisabled() {
      return this.selectedSection === 1 || (this.schema?.quiz_settings?.timer_enabled && this.schema?.quiz_settings?.section_timer_enabled);
    },
    activeRowId: {
      get: function() {
        return this.$store.state.builder.activeRowId;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setActiveRowId', newValue);
      },
    },

    schema: {
      get: function() {
        return this.$store.state.builder.schema;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSchema', newValue);
      },
    },

    settings: {
      get: function() {
        return this.$store.state.builder.schema.settings;
      },
    },
    selectedSection: {
      get: function() {
        return this.$store.state.builder.selectedSection;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSelectedSection', newValue);
      },
    },
    isThankyouSectionActive() {
      return this.schema.sections[this.selectedSection].type === 'ThankYou';
    },
    isLastSection() {
      return this.schema.sections[this.selectedSection + 1] && this.schema.sections[this.selectedSection + 1].type === 'ThankYou';
    },
    showTimer() {
      return this.schema?.quiz_settings?.timer_enabled && ((this.schema.quiz_settings.global_timer_enabled && this.selectedSection >= 0 && this.selectedSection < this.schema.sections.length - 1) || (this.schema?.quiz_settings?.section_timer_enabled && this.selectedSection > 0 && this.selectedSection < this.schema.sections.length - 1));
    },
  },

  methods: {
    // ------------------------------------------
    selectRow(rowIndex) {
      let activeRow = this.schema.sections[this.selectedSection].rows[rowIndex];
      this.activeRowId = activeRow.id;
      this.hoverRowIndex = ''; // to prevent hover state, if any
    },
    selectHoverRow(rowIndex) {
      if (!this.activeRowId && !this.dragging) {
        // to prevent hover, if any-row is active
        this.hoverRowIndex = rowIndex;
      }
    },
    dndDragStart(e) {
      this.dragging = true;
    },
    dndDragEnd(e) {
      this.dragging = false;
    },
    dndDrop(e) {
      // console.log(e);
      this.dragging = false;
      if (e.payload && !e.removedIndex) {
        this.addNewElement(e.payload.type, e.addedIndex);
      } else {
        this.moveRow(e.removedIndex, e.addedIndex);
      }
    },

    addNewElement(type, i) {
      console.log('Dropping new element', 'Index', i, 'field_type', type);
      this.addRow(createNewField(type), i);
    },
    onDragOver(event, index) {
      event.preventDefault();
      event.stopPropagation();
      this.dragOverTarget = index;
      // this.activeElementId = "";
    },
    onDragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.dragOverTarget = '';
      // console.log("onDragLeave", event);
    },

    deleteRow(i) {
      let activeSection = this.schema.sections[this.selectedSection];

      // console.log("Let see unique id", activeSection.rows[i].id);
      // let deletedRow = { ...activeSection.rows[i] };
      // activeSection.rows = [
      //   ...activeSection.rows.slice(0, i),

      //   ...activeSection.rows.slice(i + 1),
      // ];
      return activeSection.rows.splice(i, 1);
    },

    addRow(newField, toIndex) {
      let newRow = createNewRow({
        fields: [newField],
      });
      let activeSection = this.schema.sections[this.selectedSection];
      // let activeSection = { ...this.schema.sections[this.selectedSection] };

      // activeSection.rows = [
      //   ...activeSection.rows.slice(0, toIndex),
      //   newRow,
      //   ...activeSection.rows.slice(toIndex),
      // ];
      activeSection.rows.splice(toIndex, 0, newRow);
    },

    moveDragStart(e, rowIndex) {
      const target = e.target;
      e.stopPropagation();
      e.dataTransfer.setData('move_from', rowIndex);
      this.draggingRowIndex = rowIndex;
      console.log(this.draggingRowIndex);
    },
    moveRow(fromIndex, toIndex) {
      console.log(`Moving row from ${fromIndex} to ${toIndex}`);
      let activeSection = this.schema.sections[this.selectedSection];

      // let deletedRow = this.deleteRow(fromIndex);
      // console.log(deletedRow);
      // this.addRow(deletedRow, toIndex);

      let removedRow = activeSection.rows[fromIndex];
      // Deleting fromIndex
      activeSection.rows.splice(fromIndex, 1);
      // Adding toIndex
      activeSection.rows.splice(toIndex, 0, removedRow);
    },
    changeSelectedSection(newSectionIndex) {
      this.activeRowId = '';
      this.selectedSection = newSectionIndex;
      if (newSectionIndex == this.schema.sections.length - 1) {
        this.$router.push({ query: { ...this.$route.query, step: 1 } });
      } else {
        this.$router.push({ query: { ...this.$route.query, step: parseInt(newSectionIndex) + 1 } });
      }
    },
    resetForm() {
      // Do nothing
    },
    validateSection() {
      // Do nothing
    },
  },
};
</script>
