<template>
  <div class="thanks-page-cont">
    <div class="thankCard">
      <!--  Header  -->
      <div class="thankCard__header">
        <div class="thankCard__formTitle">{{ formData.project_name }}</div>
        <div class="thankCard-formDescription">{{ "Form Description" }}</div>
      </div>
      <!--  Body -------------- -->
      <div class="thankCard__body">
        <div class="thankyou-msg-div">
          <i class="light-icon-clipboard-x"></i>
          <span v-if="customizedMsg" class="thank-msg">{{customizedMsg}}</span>
          <span v-else class="thank-msg">This form is no longer accepting responses!</span>
        </div>
        <br />
        <!-- <span class="thank-sub-msg">Your Response was Submitted</span><br /> -->

        <!-- <div class="submit-another-div">
          <a
            ><router-link to="/dashboard" class="submit-another-link"
              >Submit another response</router-link
            >
          </a>
        </div> -->
      </div>

      <!-- Footer   -->
      <!-- <div class="thankCard__footer">
        <div><a>Create Your own Form</a></div>
        <br />
        <div>
          <p>Powered by FormStellar | Privacy & Cookies | Terms of Use</p>
        </div>
      </div> -->
      <!-- ------- -->
    </div>
    <!-- ----thank card Ends--- -->

    <!---- Introduction to responser card ----->
    
    <div class="intro-responser-card">
      <div class="itc-header">
        Want to create forms like this?
        <div class="itc-subheader">
          <h3 style="color: teal">Try FORM STUDIO</h3>
        </div>
      </div>
      <div class="itc-body">
        <div class="itc-content">
          <div>
            <img src="../assets/opinion.png" class="opinion-img" />
          </div>
          <div><img src="../assets/party.png" class="party-img" /></div>
          <div><img src="../assets/poll.png" class="poll-img" /></div>
        </div>

        <!--  -->
        <div class="itc-btn-div">
          <button class="itc-btn" @click="navigateToLanding">
            Create a survey,quiz, or poll
          </button>
        </div>
      </div>
    </div>
    <!-- ---------------------- -->
  </div>
</template>

<script>

export default {
  props:['formData','customizedMsg'],
  methods:{
    navigateToLanding(){
      window.open("https://formstudio.io/",'/blank');
    }
  }
};
</script>

<style scoped>
/*Short Forms

 itc=intro-responser-card 
 
 */
.thanks-page-cont {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Thanks card */
.thankCard {
  width: 672px;
  margin: auto;

  border-radius: 12px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
}

.thankCard__header {
  /* height: 60px; */
  background-color: teal;
  color: #ffffff;
  text-align: left;
  padding: 30px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.thankCard__formTitle {
  font-size: 24px;
}

.thankCard__formDescription {
  font-size: 16px;
}
.thankCard__body {
  background: #ffffff;
  height: 270px;
  padding: 24px;
  border-radius: 12px;

  text-align: left;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.thankyou-msg-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-msg {
  font-size: 28px;
  color: #203d4a;

  margin-top: 8px;
}
.thank-sub-msg {
  color: #203d4a;
}
.thankyou-msg-div i {
  font-size: 32px;
  /* height: 50px; */
  font-style: bold;
  background-color: teal;
  color: #ffffff;
  border-radius: 50%;
  padding: 4px;
  margin-right: 12px;
}

.submit-another div {
  margin-top: 16px;
  color: teal;
}

.submit-another-link {
  text-decoration: none;
  color: teal;
}

.thankCard__footer {
  padding: 24px;
  background-color: #f4f4f4;
  background-color: transparent;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  /* margin-bottom: 20px; */
  font-size: 16px;
}

/*  */

/* ---------------------------- */

.form-header .form-heading {
  font-size: 24px;
}
.form-header .form-description {
  font-size: 16px;
}

.form-body {
  margin: 30px;
  text-align: left;
}

/*  */

/* Intro To responser card */
.intro-responser-card {
  width: 672px;

  background-color: #ffffff;
  border-radius: 16px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  margin-bottom: 80px;
}

.itc-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.itc-subheader {
  text-align: center;
}

.itc-content {
  display: flex;
  padding: 8px;
  justify-content: space-evenly;
}
.opinion-img,
.party-img,
.poll-img {
  width: 140px;
  height: 160px;
  margin-bottom: 16px;
}
.itc-btn-div {
  display: flex;
  justify-content: center;
  padding: 28px;
}

.itc-btn {
  background-color: teal;
  color: #ffffff;
  padding: 12px 16px;
  border: none;
  outline: none;
  border-radius: 16px;
  cursor: pointer;
}

/* ------ */
</style>