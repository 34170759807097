<template>
  <div>
    <ol :style="{ margin: options_top_margin + 'px', display: 'grid', 'grid-template-columns': `repeat(auto-fill, ${layout})`, margin: '4px 2px' }">
      <li v-for="option in options" @click="refresh(option)" v-bind="$attrs" :aria-label="getOptionLabel(option)" :key="getOptionRenderKey(option)" role="option" :aria-selected="isOptionSelected(option)" :checked="isOptionSelected(option)" :disabled="isOptionDisabled(option)" :value="getOptionValue(option)">
        <LvButton
          :label="option"
          :raised="raised"
          :deepShadow="deepShadow"
          :rounded="rounded"
          :deepShadowHover="deepShadowHover"
          :push="push"
          :outlined="outlined"
          class="lv--success fs-fe__btn-option"
          :style="{
            'background-color': background_color,
            color: font_color,
            width: btn_width + 'px',
            margin: btn_margin + 'px',
          }"
          :iconRight="modelValue === option || (modelValue && modelValue.includes(option)) ? 'light-icon-circle-check' : 'light-icon-circle'"
          :class="{ '--active': modelValue == option || (modelValue && modelValue.includes(option)) }"
        />
      </li>
    </ol>
  </div>
</template>

<script>
import LvButton from 'lightvue/button/Button.vue';
import { localValueMixin, optionsMixin } from 'lightvue/mixins';
import selectPropsMixin from './selectPropsMixin';

export default {
  components: {
    LvButton,
  },
  mixins: [localValueMixin, optionsMixin, selectPropsMixin],
  props: ['options', 'enable_multiselect', 'selected_btn_background', 'label', 'raised', 'deepShadow', 'rounded', 'deepShadowHover', 'push', 'outlined', 'align', 'background_color', 'font_color', 'btn_width', 'options_top_margin', 'btn_margin', 'label_align', 'options_layout'],

  data() {
    return {
      selectedOption: null,
      multiSelectedOption: [],
      isSelected: null,
    };
  },
  computed: {
    btnBackgroundColor() {
      return this.isSelected ? this.selected_btn_background : this.background_color;
    },
    layout() {
      return this.options_layout === 'grid' ? '50%' : '100%';
    },
    optionLabel() {
      return this.optionLabelKey;
    },
    optionValue() {
      return this.optionValueKey;
    },
  },

  methods: {
    activateOption(option) {
      this.selectedOption = option;
    },
    activateMultipleOption(option) {
      if (this.multiSelectedOption.includes(option)) {
        let oldOptionIndex = this.multiSelectedOption.findIndex(mOption => mOption === option);
        this.multiSelectedOption.splice(oldOptionIndex, 1);
        this.isSelected = false;
      } else {
        this.multiSelectedOption.push(option);
        this.isSelected = true;
      }
      console.log('multiSelectedOptions', this.multiSelectedOption);
      return this.isSelected;
    },

    refresh(option) {
      if (this.enable_multiselect) {
        this.activateMultipleOption(option);
        console.log('isSelected', this.isSelected);
        this.updateValue(this.multiSelectedOption);
      } else {
        // temporary workaround
        this.multiSelectedOption = [];

        // let value = this.getOptionValue(option);
        let value = this.modelValue == option ? '' : this.getOptionValue(option);
        // console.log(event, value, option);
        this.updateValue(value);
      }
    },
  },
};
</script>

<style lang="scss">
ol {
  list-style: none;
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;
}

.fs-fe__btn-option {
  &__image {
    width: 50%;
    margin: 8px 0;
  }
  &.--active {
    color: #fff;
    background-color: #38b2ac;
    // filter: brightness(125%) hue-rotate(-4deg);
    // border-color: red;
  }
}

.lv-button__icon i {
  &::before {
    font-size: 1.5rem;
  }
}
</style>
