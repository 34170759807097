<template>
  <!-- ---------------------------------------------------CUSTOM STYLES---------------------------------------------- -->
  <div>
    <!-- <div class="section-heading">CUSTOMIZATIONS</div> -->
    <div class="fs-cs__tabs">
      <div class="fs-cs__tab-buttons">
        <div class="fs-cs__tab-button" :class="{ active: activeTab === 'properties' }" @click="activeTab = 'properties'">
          <span style="font-size:24px"> CUSTOMIZATIONS</span>
        </div>
      </div>

      <div class="fs-cs__tab-content">
        <!------------------------------ Properties TAB Content ---------------------------->
        <div v-if="activeTab === 'properties'">
          <div v-if="activeElement">
            <div v-if="activeCategory === 'element_properties'" class="fs-cs__category-items">
              <ElementProperties />
              <ElementStyles />
            </div>

            <div v-if="activeCategory === 'element_styles'" class="fs-cs__category-items"></div>
          </div>
          <!----------------------------------- FORM PROPERTIES --------------------------------->
          <div v-else-if="formSchema">
            <FormProperties @showProjectDesign="$emit('showProjectDesign')" />
          </div>

          <!-------------------------------- THANK YOU PAGE CUSTOMIZATIONS ----------------------------->

          <!-- <div v-else-if="selectedSection == formSchema.sections.length - 1"> -->
          <!-- <d-toggle-switch label="Redirect URL"  v-model="formSchema.settings.multiple_responses" /> -->
          <!-- <lv-input label="Enter URL" v-model="formSchema.settings.redirect_url" /> -->
          <!-- <br /> -->
          <!-- <d-toggle-switch   label="Allow multiple responses" v-model="activeElement.hidden" /> -->
          <!-- </div> -->
          <!-- -------------------------------------------- -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormStyles from './FormStyles.vue';
import ElementStyles from './ElementStyles.vue';
import FormProperties from './FormProperties.vue';
import ElementProperties from './ElementProperties.vue';
import AccordianCustomStyles from './AccordianCustomStyles';
import LvInput from 'lightvue/input';
import DToggleSwitch from 'lightvue/input-toggle';

export default {
  name: 'CustomStyles',
  props: ['placeholder', 'name', 'value', 'options', 'color'],
  emits: ['showProjectDesign'],
  components: {
    FormProperties,
    ElementProperties,
    ElementStyles,
    FormStyles,
    AccordianCustomStyles,
    LvInput,
    DToggleSwitch,
  },
  data: () => {
    return {
      activeTab: 'properties',
      activeCategory: 'element_properties',
    };
  },
  // -------------------------------computed---------------------------------------------------------
  computed: {
    formSchema() {
      return this.$store.state.builder.schema;
    },
    activeRowId() {
      return this.$store.state.builder.activeRowId;
    },
    selectedSection: {
      get: function() {
        return this.$store.state.builder.selectedSection;
      },
    },
    activeElement() {
      if (this.formSchema && this.formSchema.sections && this.formSchema.sections[this.selectedSection]) {
        let rowIndex = this.formSchema.sections[this.selectedSection].rows.findIndex(row => row.id === this.activeRowId);
        if (rowIndex === -1) {
          return null;
        } else {
          return this.formSchema.sections[this.selectedSection].rows[rowIndex].fields[0];
        }
      } else {
        return null;
      }
    },
  },

  methods: {
    hasErrors(e) {
      console.log(e);
    },
    // ------------PUBLISH FORM-------------------
    formSettings() {
      // if (this.schema) {
      //   let previewData = this.$store.state.builder.schema;
      //   this.$emit("on-preview", previewData);
      this.$router.push({
        name: 'Settings',
        query: { id: this.$route.query.id },
      });
      // }
    },
    setActiveCategory(newCategory) {
      this.activeCategory = this.activeCategory === newCategory ? '' : newCategory;
    },

    getErrors(e) {
      console.log(e);
    },
    dirty(name) {},
  },
};
</script>

<style scoped>
/* .section-heading {
  font-size: 24px;
  padding: 16px 16px 8px;
  text-align: center;
} */
/* **************************** */
/* -------------------- */
/**
 * Tabs
 */
.fs-cs__tabs {
  padding: 12px;
  /* padding-top: 12px ; */

  /* font-size: 24px; */
}
.fs-cs__tabs .fs-cs__tab-buttons {
  display: flex;
}
.fs-cs__tabs .fs-cs__tab-button {
  flex-grow: 1;
  display: block;
  padding: 4px;
  cursor: pointer;
  background-color: #fff;
  /* background-color: #b2bec3; */
  /* font-weight: bold; */
  transition: background-color ease 0.2s;
}

.fs-cs__tab-button {
  text-align: center;
}
.fs-cs__tabs .fs-cs__tab-button.active {
  background-color: #b2bec3;
  padding: 12px;
}
.fs-cs__tabs .fs-cs__tab-content {
  width: 100%;
  /* padding: 12px; */
  background: #fff;
  text-align: left;
}

.fs-cs__category-items {
  padding: 16px;
  transition: all 0.4s;
}
</style>
