<template>
  <div class="nav-list__category-wrap">
    <!-- <div class="nav-list__category-wrap" v-for="category in $options.categories" :key="category.category_name" > -->

    <!-- </div> -->
    <!---------------------------------------------PLAYGROUND BACKGROUND------------------>
    <accordian-custom-styles :label="`Form Background`" :activeCategory="activeCategory" @click.stop="setActiveCategory('Form Background')" />
    <div v-if="activeCategory === 'Form Background'" class="category-items">
      <!-- FORM THEME COLOR -->
      <ColorPicker v-model="settings.styles.playground_background" label="Background Color" />
      <br />
    </div>

    <!--------------------------------- FORM OVERALL STYLES ----------------------------->
    <accordian-custom-styles :label="`Form Overlall Styles`" :activeCategory="activeCategory" @click.stop="setActiveCategory('Form Overlall Styles')" />
    <div v-if="activeCategory === 'Form Overlall Styles'" class="category-items">
      <!-- FORM THEME COLOR -->
      <ColorPicker v-model="settings.styles.form_theme_color" label="Theme Color" />
      <br />
      <!-- FORM BODY COLOR -->
      <ColorPicker v-model="settings.styles.form_background" label="Body Color" />
      <br />
      <!-- Form Shadow -->
      <d-toggle-switch label="Shadow" v-model="settings.styles.form_shadow" />

      <br />

      <!-- Form Border Radius -->
      <div class="form-styles-fields">
        <lv-input v-model="settings.styles.border_radius" label="Radius" />
      </div>
      <!-- Form Border Width -->
      <div class="form-styles-fields">
        <d-toggle-switch label="Border" name="reqBorder" v-model="settings.border_width" />
      </div>
    </div>

    <!-------------------------------------- FORM HEADING STYLES ---------------------------->
    <accordian-custom-styles :label="`Form Header Styles`" :activeCategory="activeCategory" @click.stop="setActiveCategory('Form Header Styles')" />
    <div v-if="activeCategory === 'Form Header Styles'" class="category-items">
      <!-- Form Head Color -->
      <ColorPicker v-model="settings.styles.form_layout_color" label="Layout Color" />
      <br />

      <!-- Form Header Font Color -->
      <ColorPicker v-model="settings.styles.header_font_color" label="Header Font Color" />
      <br />
      <!-- Form Header Font Size -->
      <lv-input v-model="settings.styles.header_font_size" :placeholder="settings.styles.header_font_size" label="Heading Size" />
      <br />
      <!-- Form Header Description Font Size -->
      <lv-input v-model="settings.styles.header_descp_font_size" label="Heading Size" />
    </div>

    <!-------------------------------------- PROGRESS BAR STYLES ---------------------------->
    <accordian-custom-styles :label="`Progress Bar Styles`" :activeCategory="activeCategory" @click.stop="setActiveCategory('Progress Bar Styles')" />

    <div v-if="activeCategory === 'Progress Bar Styles'" class="category-items">
      <!-- Progress Bar Disable -->
      <d-toggle-switch label="Disable" v-model="settings.styles.progressbar_hide" />

      <br />
      <!-- Progress Bar Color -->
      <d-toggle-switch label="Wizard Mode" v-model="settings.styles.progressbar_wizard_mode" />

      <br />
      <ColorPicker v-model="settings.styles.progressbar_color" label="Color" />
      <br />
    </div>

    <!--------------------------------- SECTION HEADING STYLES --------------------------------->
    <accordian-custom-styles :label="`Section Heading Styles`" :activeCategory="activeCategory" v-if="settings.section_heading_visibility" @click.stop="setActiveCategory('Section Heading Styles')" />

    <div v-if="activeCategory === 'Section Heading Styles'" class="category-items">
      <!-- Section Heading Customizations -->
      <ColorPicker v-if="settings.section_heading_visibility" v-model="settings.styles.section_header_bg_color" label="Header Color" />
      <br />
      <ColorPicker v-if="settings.section_heading_visibility" v-model="settings.styles.section_header_font_color" label="Font Color" />
    </div>

    <!---------------------------------------- FIELD STYLES -------------------------------------------->
    <accordian-custom-styles :label="`Field Styles`" :activeCategory="activeCategory" @click.stop="setActiveCategory('Field Styles')" />

    <div v-if="activeCategory === 'Field Styles'" class="category-items">
      <!-- ---Rounded Input-------- -->
      <div class="form-styles-fields">
        <d-toggle-switch label="Rounded Input" name="RoundedInput" v-model="settings.styles.rounded_input" />
      </div>
      <!-- ------ -->
      <!-- ---Floating Label-------- -->
      <div class="form-styles-fields">
        <d-toggle-switch label="Floating Label" name="FloatingLabel" v-model="settings.styles.floating_label" />
      </div>
    </div>
    <!-- ------ -->
  </div>
  <!-- </div> -->
</template>

<script>
import DToggleSwitch from 'lightvue/input-toggle';
import LvInput from 'lightvue/input';
import ColorPicker from '../lightvue/components/colorpicker/ColorPicker';
import AccordianCustomStyles from './AccordianCustomStyles.vue';

export default {
  name: 'FormStyles',
  data() {
    return {
      activeCategory: 'Form Overlall Styles',
    };
  },
  components: {
    DToggleSwitch,
    LvInput,
    ColorPicker,
    AccordianCustomStyles,
  },
  computed: {
    //Form Settings------------
    settings: {
      get: function() {
        return this.$store.state.builder.schema.settings;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSettings', newValue);
      },
    },

    styles: {
      get: function() {
        return this.$store.state.builder.schema.styles;
      },
    },
    //Form Head Color------------
    head_color: {
      get: function() {
        return this.$store.state.builder.schema.settings.head_color;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setHeadColor', newValue);
      },
    },

    //Form Border Radius-----------------
    border_radius: {
      get: function() {
        return this.$store.state.builder.schema.settings.border_radius;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setBorderRadius', newValue);
      },
    },
  },
  methods: {
    setActiveCategory(newCategory) {
      this.activeCategory = this.activeCategory === newCategory ? '' : newCategory;
    },
  },
};
</script>

<style lang="scss">
.form-styles-fields {
  margin-bottom: 12px;
}
.nav-list__category-wrap {
  border-radius: 0px;
  // background: #3f5062;
  // border-left: 5px solid rgba(0, 0, 0, 0);

  // border-bottom: 1px solid #314254;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  // box-shadow: inset 7px 7px 100px #364453, inset -7px -7px 100px #485c71;
  &.--active {
    box-shadow: inset 0px 0px 1px #212a33, inset 0px 0px 2px #212a33;
    background-color: rgba(255, 255, 255, 0.04);
    border-left: 5px solid #607b89;
  }
}

.category-items {
  padding: 16px;
  transition: all 0.4s;
}
</style>
