<template>
  <div class="shimmer-card">
    <div class="shimmer-card__content">
      <div class="cardImg animate din"></div>
      <div class="projectName animate"></div>
      <div class="projectResponse animate"></div>
      <!-- <div class="projectResponse animate"></div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.shimmer-card {
  // border: 2px solid #fff;
  border-radius: 4px;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  padding: 10px 12px;
  width: 250px;
  height: 280px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-right: 20px;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); */
  /* transition: all 0.2s ease-in-out; */
  position: relative;
  &:hover {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
}
.shimmer-card__content {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  height: 180px;
  width: 0px;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cardImg {
  width: 224px;
  height: 100px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.projectName {
  height: 40px;
  // background: #777;
  margin-top: 16px;
  border-radius: 20px;
  width: 80%;
}
.projectResponse {
  height: 20px;
  // background: #777;
  margin-top: 16px;
  border-radius: 10px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>