<template>
  <!-- RURBAN -->
  <div class="res-table__wrap light-scrollbar">
    <div class="res-table">
      <div class="res-row --header">
        <div class="res-cell">#</div>
        <!-- <div class="res-cell" v-for="(question, j) in questionsList" :key="question.id">{{ question.label }}</div> -->
        <div class="res-cell">Submission Time</div>
        <div class="res-cell"></div>
      </div>
      <div class="res-row --entry" v-for="(response, k) in sortedResponses" :key="response.res_id" @click="handleClickEntry(response.response_id)">
        <div class="res-cell --bold">{{ k + 1 }}</div>
        <!-- <div class="res-cell" v-for="question in questionsList" :key="question.id">{{ response.response[question.id] }}</div> -->
        <div class="res-cell">{{ formatUnixTime(response.date_submitted) }}</div>
        <div class="res-cell">
          <!-- <i class="light-icon-trash"></i> -->
          <i class="light-icon-chevron-right"></i>
        </div>
      </div>
    </div>
    <lv-drawer v-model="showResponseDetail" right :close="true" :shadow="true" :width="850" :height="650" :zIndex="1000" :maxSpan="85" headerTitle="Response details" background="#F5F8FA" headerColor="#007474" headerBackground="#fff" headerFontSize="20px" padding="0px">
      <ResponsePrefill v-if="selectedResponse" :response="selectedResponse" :schema="schema"></ResponsePrefill>
    </lv-drawer>
  </div>
</template>

<script>
import { timeSince } from '@main/utils';
import ResponsePrefill from 'builder/ResponsePrefill.vue';
import LvDrawer from 'lightvue/drawer';
export default {
  props: ['responses', 'schema', 'limit'],
  data() {
    return {
      formattedTime: '',
      showResponseDetail: false,
      selectedResponseIndex: 0,
      selectedResponse: null,
      // unixTime:"",
    };
  },
  components: {
    LvDrawer,
    ResponsePrefill,
  },
  computed: {
    sortedResponses() {
      return [...this.responses].sort((a, b) => (a.date_submitted < b.date_submitted ? 1 : -1)).slice(0, this.limit);
    },
    questionsList() {
      let localList = [];
      if (this.schema) {
        this.schema.sections.forEach((section, sectionIndex) => {
          section.rows.forEach(row => {
            row.fields.forEach(field => {
              if (field.read_only == false) {
                localList.push({
                  ...field,
                  section_id: section.id,
                  row_id: row.id,
                });
              } //
            });
          });
        });
      }
      return localList;
    },
  },

  // const timeStampDate = record.createdAt;
  // const dateInMillis  = timeStampDate._seconds * 1000

  // var date = new Date(dateInMillis).toDateString() + ' at ' + new Date(dateInMillis).toLocaleTimeString()
  // OutPut Example: Sat 11 Jul 2020 at 21:21:10

  methods: {
    formatUnixTime(unixTime) {
      if (typeof unixTime == 'number') {
        //if unixtime is a string, its divided by 1000, if unix time is object it is untouched
        let seconds = unixTime;
        unixTime = {};
        unixTime.seconds = seconds / 1000;
      }
      const dateInMillis = unixTime.seconds * 1000;
      // var date = new Date(dateInMillis).toDateString() + ' at ' + new Date(dateInMillis).toLocaleTimeString();

      // return date;

      return timeSince(dateInMillis);
    },
    handleClickEntry(responseId) {
      console.log('RESPONSE', responseId);
      let responseIndex = this.sortedResponses.findIndex(response => response.response_id === responseId);
      if (responseIndex !== -1) {
        this.selectedResponse = this.sortedResponses[responseIndex];
        this.showResponseDetail = true;
      }
    },
  },
};
</script>

<style lang="scss">
.res-table__wrap {
  // max-width: calc(100% - 32px);
  overflow-x: auto;
}
.res-table {
  display: table;
  font-size: 14px;
  .res-row {
    display: table-row;
    &:nth-child(odd) {
      background-color: #f6f8fa;
    }
    &.--header {
      background-color: #d2e4e5;
      white-space: nowrap;
      font-weight: 600;
      cursor: pointer;
    }
    &.--entry {
      cursor: pointer;
      &:hover {
        background-color: #d3e5e5;
      }
    }
  }
  .res-cell {
    display: table-cell;
    padding: 10px 14px;
    vertical-align: middle;
    &.--bold {
      font-weight: 600;
    }
  }
}
</style>
