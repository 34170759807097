<template>
  <section class="login-page">
    <div class="left-panel">
      <div class="dots" id="dot-1"></div>
      <div class="dots" id="dot-2"></div>
      <div class="dots" id="dot-3"></div>
      <div class="dots" id="dot-4"></div>
      <div class="dots" id="dot-5"></div>
      <div class="left-panel-content">
        <img src="../assets/form-studio-only-logo.svg" class="logo" />
        <div class="main-heading">Sign up to FormStellar</div>

        <div class="social-tab">
          <div class="tab">
            <div class="tab-1" @click="signup_google">
              <div class="g-logo-img">
                <img src="../assets/google-logo.svg" class="g-logo" />
              </div>
              <div class="google">
                SignUp with Google
              </div>
            </div>
          </div>
        </div>

        <div class="line">
          <div class="line-text">or</div>
        </div>
        <div class="form">
          <div class="fsection-container">
            <div class="form-container-singup">
              <div class="form-text">
                <b>First name</b>
              </div>
              <div class="form-tab">
                <input type="text" v-model="fname" />
              </div>
            </div>
            <div class="form-container-singup">
              <div class="form-text">
                <b>Last name</b>
              </div>
              <div class="form-tab">
                <input type="text" v-model="lname" />
              </div>
            </div>
          </div>
          <!-- <div class="fsection-container">
            <div class="form-container-singup">
              <div class="form-text">
                <b>Gender</b>
              </div>
              <div class="form-tab">
                <input type="button" value="Choose an option" />
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
            </div>
            <div class="form-container-singup">
              <div class="form-text">
                <b>Date of birth</b>
              </div>
              <div class="form-tab">
                <input type="button" value="Choose a date" />
                <div class="date">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>-->
          <div class="fsection-container">
            <div class="form-container-singup">
              <div class="form-text">
                <b>Email</b>
              </div>
              <div class="form-tab">
                <input type="text" v-model="email" />
              </div>
            </div>
            <div class="form-container-singup">
              <div class="form-text">
                <b>Phone number</b>
              </div>
              <div class="form-tab">
                <input type="text" v-model="phone" />
              </div>
            </div>
          </div>
          <!-- <div class="fsection-container">
            <div class="form-container-singup">
              <div class="form-text">
                <b>State</b>
              </div>
              <div class="form-tab">
                <input type="button" value="Choose an option" />
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
            </div>
          </div>-->
          <!-- <div class="line"></div>
          <div class="username">
            <div class="form-text">
              <b>Username</b>
            </div>
            <div class="form-tab">
              <input type="text" />
            </div>
          </div>-->
          <div class="fsection-container">
            <div class="form-container-singup">
              <div class="form-text">
                <b>Password</b>
              </div>
              <div class="form-tab">
                <input type="password" v-model="password" />
                <div class="grey-icon">
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="form-container-singup">
              <div class="form-text">
                <b>Confirm Password</b>
              </div>
              <div class="form-tab">
                <input type="password" v-model="cnfpassword" />
                <div class="grey-icon">
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="lv-fluid">
            <lv-button @click="signup" deep-shadow push rounded>Signup for Account</lv-button>
          </div>
          <div class="line"></div>
          <div class="last-line">
            <div class="last-text">Already a member?</div>
            <div class="button-2" @click="$router.push({ name: 'Login' })">
              Login
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-panel">
      <login-right-panel />
    </div>
  </section>
</template>

<script>
import LoginRightPanel from '../components/LoginRightPanel.vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import { registerUser } from '../utils/auth';
export default {
  created() {
    firebase.auth().signOut();
  },
  data() {
    return {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      password: '',
      cnfpassword: '',
      formLoading: false,
    };
  },
  methods: {
    // show_error(error) {
    //   alert({
    //     message: error.message,
    //     timeout: 1500,
    //     type: "warning",
    //     position: "top-right",
    //   });
    // },
    signup_google() {
      this.formLoading = true;
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async res => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = result.credential.accessToken;
          // await this.registerUser(res);
          registerUser(res, '', '', res.user.phoneNumber);
        })
        .catch(error => {
          // this.show_error(error);
          console.log(error);
        })
        .finally(() => {
          // this.formLoading = false;
        });
    },
    signup() {
      // this.formLoading = true;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(res => {
          registerUser(res, this.fname, this.lname, this.phone);
          this.$router.push({ name: 'Login' });
        })

        .catch(error => {
          // this.show_error(error);
          console.log(error);
        })
        .finally(() => {
          // this.formLoading = false;
        });
    },
  },
  components: {
    LoginRightPanel,
  },
};
</script>

<style scoped>
.login-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  background: #f2faf5;
  min-height: 100vh;
}

.left-panel,
.right-panel {
  flex-grow: 1;
  flex-basis: 1;
  width: 50%;
}

.left-panel {
  position: relative;
  padding: 80px;
  background: #ffffff;
}

.logo {
  width: 80px;
}

.left-panel-content {
  position: relative;
}

.main-heading {
  text-align: left;
  /* margin-top: 50px; */
  padding: 15px;
  padding-left: 0px;
  font-size: 36px;
  color: #143a56;
}

.content {
  color: #a6aeb7;
  padding: 15px;
  padding-left: 0px;
}

.social-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 7px; */
  margin-right: -20px;
}

.tab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 300px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #555;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  margin-right: 20px;
  white-space: nowrap;
}

.tab .tab-1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 200px;
  background: #ffffff;
  padding: 12px;

  margin-top: 20px;
  transition: all 0.3s ease-in-out;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
}
.tab .tab-1:hover {
  background: #f5f5f5;
}

.g-logo {
  display: flex;
  width: 15px;
  margin-right: 10px;
  justify-content: center;
}

.tab .tab-2 {
  background: #269ff1;
  padding: 12px;
  border-radius: 8px;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}
.tab .tab-2:hover {
  background: #1d79b6;
}

.line {
  border-bottom: 1px solid #e9ebed;
  padding-top: 40px;
}

.line-text {
  display: block;
  margin: auto;
  text-align: center;
  transform: translateY(50%);
  background: #ffffff;
  max-width: 30px;
}

.username {
  width: 100%;
  margin-top: 30px;
}

.fsection-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -30px;
}

.form-container-singup {
  min-width: 200px;
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 30px;
}

.form-text {
  margin-top: 20px;
  color: #143a56;
  font-size: 14px;
}

.form-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  margin: 14px 0;
  border: 0.5px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 45px;
}

.form-tab:focus-within {
  border: 1px solid rgb(126, 126, 126);
}

input[type='text'] {
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
}

input[type='password'] {
  outline: none;
  border: none;
  background: transparent;
  width: 90%;
}

input[type='button'] {
  outline: none;
  border: none;
  background: transparent;
  width: 90%;
  text-align: left;
  color: #babcbe;
  cursor: pointer;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

.form-tab i {
  color: #143a56;
  cursor: pointer;
}

.form-tab .grey-icon i {
  color: #a6aeb7;
}

.button-1 {
  padding: 10px;
  border-radius: 8px;
  max-width: 100%;
  margin: auto;
  margin-top: 50px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  background-color: #5cd0b9;
  box-shadow: 0px 6px 15px -1px #5cd0b9;
  cursor: pointer;
}

.button-1:hover {
  background: #51b6a2;
}

.last-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.last-line .last-text {
  font-size: 16px;
  color: #143a56;
  padding-right: 25px;
  margin-top: 10px;
}
.last-line .button-2 {
  padding: 12px 50px;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  background-color: #143a56;
  box-shadow: 0px 6px 15px -1px #143a56;
  cursor: pointer;
  margin-top: 10px;
}

.button-2:hover {
  background: #1b4d70;
}

.dots {
  position: absolute;
  border-radius: 50%;
}

@media (max-width: 850px) {
  .left-panel {
    width: 100%;
  }

  .right-panel {
    width: 100%;
  }

  .right-photo {
    margin-top: 50px;
  }
}

@media (max-width: 750px) {
  .left-panel {
    padding: 30px;
  }
}

#dot-1 {
  width: 38px;
  height: 38px;
  top: 70px;
  left: 30px;
  background: #4b96ce;
}

#dot-2 {
  width: 16px;
  height: 16px;
  top: 150px;
  left: 320px;
  background: #5cd0b9;
}

#dot-3 {
  width: 16px;
  height: 16px;
  top: 600px;
  left: 70px;
  background: #5cd0b9;
}

#dot-4 {
  width: 32px;
  height: 32px;
  top: 800px;
  left: -8px;
  background: #4b96ce;
}

#dot-5 {
  width: 55px;
  height: 55px;
  top: 150px;
  left: 500px;
  background: #4b96ce;
}
</style>
