<template>
  <div class="fs-rd__page-wrap">
    <div class="fs-rd__flex-row">
      <div class="fs-rd__card --flex-column">
        <div class="fs-rd__stats-row">
          <div class="fs-rd__stats-card --highlighted ">
            <div class="__heading">Total Responses</div>
            <div class="__value">{{ responses.length }}</div>
          </div>
          <!-- <div class="fs-rd__stats-card --highlighted ">
            <div class="__heading">Today's Count</div>
            <div class="__value">{{ responses.length }}</div>
          </div> -->
        </div>
        <div class="fs-rd__card-body">
          <ResponseDateStats :responses="responses" :formData="formData" v-if="responses.length && formData"></ResponseDateStats>
        </div>
      </div>
    </div>
    <div class="fs-rd__flex-row">
      <div class="fs-rd__card --half --flex-column">
        <div class="fs-rd__card-header">
          <div class="fs-rd__card-name">Recent Submissions</div>
          <div class="fs-rd__card-actions">
            <export-responses :responses="responses" :formData="formData" v-if="responses.length && formData"></export-responses>
          </div>
        </div>
        <div class="fs-rd__card-body">
          <responses-table-mini :responses="responses" :schema="formData.schema" v-if="responses.length && formData" :limit="15" />
        </div>
        <div class="fs-rd__card-footer">
          <div class="__card-footer__link" @click="$router.push({ name: 'ResponsesStats', query: { activetab: 'table' } })">View all Submissions<i class="light-icon-arrow-narrow-right"></i></div>
        </div>
      </div>
      <div class="fs-rd__card --half --flex-column">
        <div class="fs-rd__card-header">
          <div class="fs-rd__card-name">Quick Results</div>
          <div class="fs-rd__card-actions">
            <LvButton icon="light-icon-settings" v-if="!editHighlightedStats" class="--link-button lv--secondary" @click="editHighlightedStats = true" />
            <LvButton icon="light-icon-lock-open" v-else class="--link-button lv--secondary" @click="editHighlightedStats = false" />
          </div>
        </div>
        <div class="fs-rd__card-body">
          <ResponseChartsDashboard :responses="responses" :formData="formData" v-if="responses.length && formData" :edit="editHighlightedStats" />
        </div>
        <div class="fs-rd__card-footer">
          <div class="__card-footer__link" @click="$router.push({ name: 'ResponsesStats', query: { activetab: 'stats' } })">View More <i class="light-icon-arrow-narrow-right"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Forms from '../models/form_schema';
import LvButton from 'lightvue/button';
import ResponsesTableMini from '@main/components/responses/ResponsesTableMini';
import ExportResponses from '@main/components/responses/ExportResponses';
import ResponseChartsDashboard from '../components/responses/ResponseChartsDashboard';
import ResponseDateStats from '../components/responses/ResponseDateStats';
import config from '../config/index';

export default {
  components: {
    LvButton,
    ResponsesTableMini,
    ExportResponses,
    ResponseDateStats,
    ResponseChartsDashboard,
  },

  data() {
    return {
      formData: null,
      responses: [],
      editHighlightedStats: false,
    };
  },

  created() {
    console.log('Route Params', this.$route.params.form_slug);
    this.getformdata();
    this.getResponses();
  },

  methods: {
    async getformdata() {
      let resForm = await Forms.getBySlug(this.$route.params.form_slug);
      this.formData = resForm.data;
    },
    async getResponses() {
      try {
        let apiResp = await fetch(`${config.api_base_url}/stats/get_by_slug`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            form_slug: this.$route.params.form_slug + '',
          }),
        });
        let allResponses = await apiResp.json();
        if (allResponses.status !== 'error') {
          this.responses = allResponses.map(res => {
            return { ...res, response: res.response_data };
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.fs-rd__page-wrap {
  margin: 32px auto;
  max-width: 1200px;
  .fs-rd__flex-row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    margin: 0px 12px;
  }
  .fs-rd__card {
    background-color: #ffffff;
    // min-height: 300px;
    width: 100%;
    margin: 12px;
    border-radius: 6px;
    box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.1);
    &.--flex-column {
      display: flex;
      flex-direction: column;
    }
    &.--half {
      flex: 1 1 0;
      max-width: calc(50% - 24px);
      min-width: 400px;
      @media (max-width: 848px) {
        max-width: unset;
      }
    }

    .fs-rd__card-body,
    .fs-rd__card-header,
    .fs-rd__card-footer {
      padding: 16px;
      width: 100%;
    }
    .fs-rd__card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      .fs-rd__card-name {
        color: #566a72;
        padding-left: 8px;
        font-size: 20px;
      }
      i {
        font-weight: 600;
      }
    }
    .fs-rd__card-body {
      flex-grow: 1;
    }
    .fs-rd__card-footer {
      .__card-footer__link {
        color: #037f7f;
        cursor: pointer;
        text-decoration: none;
        text-align: right;

        font-size: 14px;
        opacity: 0.8;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        &:hover {
          opacity: 1;
        }
        i {
          font-weight: 600;
          margin-left: 4px;
        }
      }
    }
  }
  .fs-rd__stats-row {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .fs-rd__stats-card {
    width: 240px;
    margin: 0px 16px;
    padding: 16px;
    color: #037f7f;
    cursor: pointer;
    border-bottom: 4px solid #e1e8e8;
    &:hover {
      border-bottom: 4px solid #037f7f;
    }
    .__heading {
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
    .__value {
      font-size: 48px;
      line-height: 48px;
      text-align: right;
    }
  }
}
</style>
