export default {
  computed: {
    user: {
      get: function() {
        return this.$store.state.user.user;
      },
      set: function(newValue) {
        this.$store.dispatch('user/set', newValue);
      },
    },
  },
};
