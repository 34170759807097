import { createNewSchema } from '../types';
import { defaultSection, defaultIntroSection, defaultThankyouSection, defaultQuizIntroSection } from './defaultSections';

const defaultSchema = createNewSchema({
  sections: [defaultIntroSection, defaultSection, defaultThankyouSection],
});

const defaultQuizSchema = createNewSchema({
  sections: [defaultQuizIntroSection, defaultSection, defaultThankyouSection],
});

export { defaultSchema, defaultQuizSchema };
