<template>
  <div class="dropdown">
    <div class="dropdown-label">
      <i class="light-icon-dots" />
    </div>
    <div class="dropdown-content">
      <ul>
        <li @click="$router.push({ name: 'Editor', query: { id: formSlug } })"><i class="light-icon-pencil"></i> Edit</li>
        <li @click="duplciateForm()"><i class="light-icon-copy"></i> Clone</li>
        <li @click="deleteCurrent_Form"><i class="light-icon-trash"></i> Delete</li>
        <li @click="$router.push({ name: 'form-settings', query: { id: formSlug } })"><i class="light-icon-settings"></i> Settings</li>
        <li @click="dropdownPublish"><i class="light-icon-external-link"></i> Preview</li>
        <li @click="$router.push({ name: 'form-settings', query: { id: formSlug } })"><i class="light-icon-users"></i> Add collaborator</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Forms from '@main/models/form_schema';
import { getSlug } from '@main/utils';
import { uid } from '@main/utils/uid';
import { userMixin } from '@main/mixins';

export default {
  mixins: [userMixin],

  props: ['author_id', 'currentForm_id', 'formSlug', 'project_name'],
  data() {
    return {
      deleted: false,
      currentFormData: {},
    };
  },
  mounted() {},

  methods: {
    async deleteCurrent_Form() {
      await Forms.deleteForm(this.author_id, this.currentForm_id);

      this.$emit('deleted', this.currentForm_id);

      this.$toast.add({
        content: 'Deleted Succesfully',
        type: 'Success',
        duration: 2000,
      });
    },

    async duplciateForm() {
      let currentForm_dropdown = await Forms.getById(this.currentForm_id);
      let unique_slug = getSlug(currentForm_dropdown.data.form_slug) + '-' + uid().substring(0, 4);

      let dropdown_formData = {
        ...currentForm_dropdown.data,
        // schema:currentForm_dropdown.data.schema,
        project_name: 'Copy of ' + currentForm_dropdown.data.project_name,
        form_slug: unique_slug,
      };
      const duplciateForm = new Forms({
        data: dropdown_formData,
      });

      await duplciateForm.create();
      // console.log(currentForm_dropdown)
      // console.log(duplciateForm)
    },

    dropdownPublish() {
      this.$emit('preview');
    },
  },
};
</script>

<style scoped>
/* .dropdown {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  height: 100%;
  padding: 4px 0px;
  padding-right: 10px;
  min-width: 70px;
  margin-right: 10px;
  cursor: pointer;
} */
.dropdown {
  position: relative;
  /* display: inline-block; */
  font-size: 16px;
  /* font-weight: 600; */
  color: #fff;
  height: 100%;
  /* padding: 4px 0px; */
  /* border-right: 1px solid #c9c9c9; */
  /* padding-right: 10px; */
  /* min-width: 70px; */
  /* margin-right: 10px; */
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: left;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* width: calc(100% + 70px); */
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 13px;
  color: #203d4a;
  right: 0px;
  top: 100%;
}

.dropdown:hover .dropdown-content {
  display: flex;

  /* flex-direction: column; */
}

.dropdown-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
}

.dropdown-content ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;
}

.dropdown-content li {
  padding: 10px;
  cursor: pointer;
  background-color: #f9f9f9;
  display: flex;
  justify-content: left;
  align-items: left;
  white-space: nowrap;
}
.dropdown-content li i {
  margin-right: 4px;
}

.dropdown-content li:nth-child(2n-1) {
  background-color: #f5f8fa;
}

.dropdown-content li:hover {
  background-color: #c9c9c9;
}
</style>
