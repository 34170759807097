<template>
  <pie :datasets="datasets" :options="options" :animateRotate="true" :labels="local_dataSet.label" :height="300"></pie>
</template>

<script>
import { Pie } from 'lightvue/chart';
import { Bar } from 'lightvue/chart';
export default {
  components: {
    Pie,
    Bar,
  },
  props: ['schema', 'responses'],

  data() {
    return {
      local_dataSet: [],
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],

          backgroundColor: ['#b2bec3', '#38b2ac', '#607c8a90', '#38b2ac90'],

          // borderColor: 'rgb(255, 99, 132)',
          data: [0, 10, 5, 2, 20, 30, 45],
        },
      ],
    };
  },

  mounted() {
    // this.formDataSet();
  },
  computed: {
    questionsList() {
      let questionsList = [];
      if (this.schema) {
        this.schema.sections.forEach(section => {
          section.rows.forEach(row => {
            row.fields.forEach(field => {
              questionsList.push({
                ...field,
                section_id: section.id,
                row_id: row.id,
              });
            });
          });
        });
      }
      return questionsList;
    },
  },

  methods: {
    formDataSet() {
      console.log('FORM SCHEMA AT RESPONSES STATS', this.schema);
      this.schema.sections.forEach((section, sectionIndex) => {
        section.rows.forEach((row, rowIndex) => {
          row.fields.forEach((field, fieldIndex) => {
            this.local_dataSet.push({
              id: field.id,
              label: field.label,
              value: response[field.id], // handle undefined case.
              type: field.field_type,
            });
          });
        });
      });
    },
  },
};
</script>

<style></style>
