<template>
  <div class="settings-card">
    <!-- -----------Setting Header------- -->
    <div class="setting-header">
      <div class="top-heading">
        <div class="setting-icon">
          <!-- <i class="light-icon-hash"></i> -->
        </div>
        <div class="setting-title">Tracking</div>
      </div>
      <div class="setting-description">
        Integrate analytics in your project and track a wide range of users' actions. All events are being sent out automatically. Learn more about tracking & events.
      </div>
    </div>
    <!-- -----------------------Card Start--------------------------- -->
    <div class="settings__card">
      <hidden-setings-overlay />
      <!-------- Divide Container------------ -->
      <div class="settings-divide-container">
        <!-- Google Analytics block -->
        <div class="google-analytics-block">
          <div class="setting-field-label"></div>
          <div>
            <lv-input type="text" label="Google Analytics" placeholder="Tracking ID" class="trackingIlv-input" />
          </div>
        </div>
        <!-- Anonymize IP for Google Analytics checkbox -->
        <div class="IP-google-block">
          <input type="checkbox" id="rx-email" name="receive email for submission" value="email" class="IPgoogle-analytics-checkbox" />
          <label class="IP-check-label" for="rx-email">Anonymize IP for Google Analytics</label>
        </div>
      </div>
      <!-- -----Divide Container ends---------------- -->

      <!-- Facebook Pixel -->
      <div class="fb-pixel-block">
        <!-- <div class="setting-field-label"></div> -->
        <div>
          <lv-input type="text" label="Facebook Pixel" placeholder="Tracking ID" class="trackingIlv-input" icon-right="brand-facebook" />
        </div>
      </div>

      <!-- Google Tag Manager block -->
      <div class="google-tag-block">
        <div>
          <lv-input type="text" label="Google Tag Manager" placeholder="Tracking ID" class="trackingIlv-input" />
        </div>
      </div>

      <!--  -->
      <!--Checkbox:- Prevent duplicate email entries  -->
      <div class="duplicate-email-block">
        <input type="checkbox" id="dup-email" name="send email" value="s-email" class="duplicate-email-check" />
        <label class="email-field-label" for="dup-email">Prevent duplicate email entries</label>
      </div>

      <!----------------------------- Update Settings Button ---------------------------->
      <div class="update-setting">
        <lv-button class="lv--success" push size="md">Update Settings</lv-button>
      </div>
      <!-- ---------------- -->
    </div>
  </div>
</template>

<script>
import LvInput from 'lightvue/input';
import LvButton from 'lightvue/button';
import HiddenSetingsOverlay from './HiddenSetingsOverlay';

export default {
  components: {
    LvInput,
    LvButton,
    HiddenSetingsOverlay,
  },
};
</script>

<style scoped>
.top-heading {
  display: flex;
}

.settings-divide-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 16px;
  justify-content: space-between;
}

.google-analytics-block,
.fb-pixel-block,
.google-tag-block {
  width: 45%;
}

.fb-pixel-block,
.google-tag-block {
  margin-bottom: 16px;
}

.trackingIlv-input {
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0.7rem;
  margin-top: 4px;
}

.IP-google-block {
  width: 45%;
  /* display: inline-block;
  vertical-align: middle; */
}

/* -----------CHECKBOX ---*/

.duplicate-email-block {
  margin-bottom: 20px;
}
.dup-email-block {
  margin-bottom: 20px;
}

.duplicate-email-check,
.IPgoogle-analytics-checkbox {
  margin-right: 8px;
  cursor: pointer;
  /* color-adjust: exact; */
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  height: 1.6rem;
  width: 1.6rem;
  color: teal;
  background-color: #fff;
  border-color: #a0aec0;
  border-width: 1px;
  border-radius: 0.25rem;
}
</style>
