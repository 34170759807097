<template>
  <div id="app">
    <router-view />
    <lv-toast />
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '~@lightvue/icons/dist/light-icon.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f8fa;
}
</style>
