<template>
  <div class="percent-chart-wrapper" :style="`height: ${height || '220'}px`">
    <div class="percent-chart__card" v-for="(percent, label) in percentMap" :key="label">
      <div class="percent-chart__value-wrap">
        <div class="percent-chart__value">{{ percent }}%</div>
        <div class="percent-chart__pin"></div>
      </div>
      <div class="percent-chart__label">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentMap: {},
    };
  },
  props: ['datasets', 'labels', 'options', 'height'],
  created() {
    let totalResponses = 0;
    for (let count of this.datasets[0].data) {
      totalResponses += count;
    }
    if (totalResponses == 0) return;
    for (let i = 0; i < this.labels.length; i++) {
      this.percentMap[this.labels[i]] = Math.floor((this.datasets[0].data[i] * 100) / totalResponses);
    }
  },
};
</script>

<style>
.percent-chart-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 60px;
}

.percent-chart__card {
  /* height: 150px; */
  width: max-content;
  min-width: 100px;
  /* margin: 20px 20px; */
  position: relative;
}
.percent-chart__value-wrap {
  /* height: 130px; */
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
}

.percent-chart__value {
  display: flex;
  position: relative;
  bottom: 18px;
  right: 4px;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  background-color: #037f7f;
  border: solid 8px #97bdbd;
  color: #ffffff;
  height: 50px;
  width: 50px;
  font-size: 12px;
  border-radius: 50%;
  z-index: 1;
}

.percent-chart__label {
  text-align: center;
  font-size: 12px;
  opacity: 0.6;
}

.percent-chart__pin {
  width: 88px;
  height: 88px;
  border-radius: 50% 50% 50% 0;
  background: #d3e5e5;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.1);
  position: absolute;
  transform: rotate(-45deg);

  margin: -34px 0 0 -8px;
}
</style>
