<template>
  <div class="add-section-bar">
    <div class="add-more-section-card" v-if="isQuizModeEnabled">
      <div class="section-pagination-card add-section-bar__button" @click="$emit('showQuizOverview', true)">
        <div class="spc-content">
          <lv-button label="Quiz Overview" />
        </div>
      </div>
    </div>

    <div class="vertical-separator" v-if="isQuizModeEnabled"></div>

    <div class="add-more-section-card">
      <div class="section-pagination-card" :class="{ '--active': selectedSection === 0 }" @click="changeSelectedSection(0)">
        <div class="spc-content">
          <span style="font-size: 12px">Intro page</span>
        </div>
      </div>
    </div>
    <div class="vertical-separator"></div>
    <container @mouseleave="hoveredSectionIndex = -1" @drop="dndDrop" @dragStart="dndDragStart" @dragEnd="dndDragEnd" orientation="horizontal" behaviour="contain" v-if="schema" class="sections-grid-row">
      <draggable v-for="(section, sectionIndex) in schema.sections.length - 2" :key="sectionIndex" @click="changeSelectedSection(section)" @mouseover="hoveredSectionIndex = section" draggable="true" class="section-pagination-card" :class="{ '--active': selectedSection === section }" :title="section.heading">
        <!-- <div class="spc-actions" v-if="hoveredSectionIndex === sectionIndex">
          <div class="spc-actions-btn" @click.stop title="Duplicate">
            <i class="light-icon-copy" />
          </div>

        </div> -->
        <div class="delete-section-btn" @click.stop="deleteSection(section)" title="Delete" v-if="!dragging && (hoveredSectionIndex === section || selectedSection === section)">
          <i class="light-icon-trash" />
        </div>
        <div class="spc-content">
          <span>
            <h2>{{ section }}</h2>
          </span>
        </div>
      </draggable>
    </container>
    <div class="sections-grid-row">
      <!--Vertical separator -->
      <!--  -->
      <div class="vertical-separator"></div>

      <!-- ---Add More section Card-- -->
      <div class="add-more-section-card">
        <div class="add-pagination-card" @click="addSection">
          <div class="spc-content">
            <i class="light-icon-plus"></i>
            <!-- <span style="font-size: 12px">Add Section</span> -->
          </div>
        </div>
      </div>
      <!-- ------- -->
      <div class="vertical-separator"></div>

      <!-- ---Thank You section Card-- -->
      <div class="add-more-section-card">
        <div class="section-pagination-card" :class="{ '--active': selectedSection === schema.sections.length - 1 }" @click="changeSelectedSection(schema.sections.length - 1)">
          <div class="spc-content">
            <span style="font-size: 12px">Thank you page</span>
          </div>
        </div>
      </div>
      <!-- ------- -->
    </div>
  </div>
</template>

<script>
// import { v4 as uuid } from "uuid";
import { uid } from './utils/uid';
import { Container, Draggable } from './smooth-dnd/main';
import isQuizModeEnabled from './mixins/computedMixins/isQuizModeEnabled.js';

// import Notification from "lightvue/notification/Notification"
export default {
  data() {
    return {
      hoveredSectionIndex: -1,
      dragging: false,
    };
  },
  emits: ['showQuizOverview'],
  mixins: [isQuizModeEnabled],

  components: {
    Draggable,
    Container,
    // Notification
  },
  computed: {
    schema: {
      get: function() {
        return this.$store.state.builder.schema;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSchema', newValue);
      },
    },
    selectedSection: {
      get: function() {
        return this.$store.state.builder.selectedSection;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSelectedSection', newValue);
      },
    },
  },

  created() {
    if (this.$route.query.step && this.$store.state.builder.schema.sections.length >= parseInt(this.$route.query.step)) {
      this.changeSelectedSection(this.$route.query.step);
    } else {
      this.changeSelectedSection(0);
    }
  },
  methods: {
    addSection() {
      let newSection = {
        id: 'sec_' + uid(),
        name: '',
        type: '',
        heading: `Section Heading`,
        heading_visibility: true,
        visiblity: true,
        hidden: false,
        config: {
          default_btn: true,
        },
        rows: [], // array of rows}
      };
      if (this.isQuizModeEnabled) {
        newSection.config['section_timer_duration'] = 30;
      }
      // this.schema.sections.push(newSection);
      this.schema.sections.splice(this.schema.sections.length - 1, 0, newSection);

      this.changeSelectedSection(this.schema.sections.length - 2);
    },

    deleteSection(sectionIndex) {
      // let activeSection = this.schema.sections[this.selectedSection];
      if (this.selectedSection === this.schema.sections.length - 1) {
        // i.e. last index
        if (this.schema.sections.length === 1 && sectionIndex === 0) {
          // This condition should never occur.
          // alert('You need atleast 1 section !!');
          this.$toast.add({
            content: 'You need atleast 1 section !!',
            type: 'info',
            duration: 2000,
          });
          return;
          // } else {
          // this.changeSelectedSection(this.selectedSection - 1);
        }
      }
      if (this.selectedSection > 0) {
        this.changeSelectedSection(this.selectedSection - 1);
      }
      return this.schema.sections.splice(sectionIndex, 1);
    },

    dndDragStart(e) {
      this.dragging = true;
    },
    dndDragEnd(e) {
      this.dragging = false;
    },
    dndDrop(e) {
      // console.log(e);
      this.dragging = false;
      this.moveRow(e.removedIndex + 1, e.addedIndex + 1);
    },
    moveRow(fromIndex, toIndex) {
      // console.log(`Moving section from ${fromIndex} to ${toIndex}`);
      let removedSection = this.schema.sections[fromIndex];
      // Deleting fromIndex
      this.schema.sections.splice(fromIndex, 1);
      // Adding toIndex
      this.schema.sections.splice(toIndex, 0, removedSection);
      this.changeSelectedSection(toIndex);
    },
    changeSelectedSection(newSectionIndex) {
      this.activeRowId = '';
      this.selectedSection = newSectionIndex;
      this.$router.push({ query: { ...this.$route.query, step: parseInt(newSectionIndex) } });
    },
  },
};
</script>

<style scoped lang="scss">
.add-section-bar {
  display: flex;
  align-items: stretch;
  background-color: #203d4a;
  color: #ffffff;
  padding: 0px 12px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  user-select: none;
  &__button {
    margin: 0 8px;
  }
}
.sections-grid-row {
  display: flex !important; // to prevent conflicts from smooth-dnd.horizontal
}
.section-pagination-card {
  width: 90px;
  height: 48px !important;
  background-color: #ffffff;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #203d4a;
  margin-right: 12px;

  cursor: pointer;
  display: flex !important; // to prevent conflicts from smooth-dnd.horizontal
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  &.--active {
    background-color: #037f7f;
    color: #ffffff;
  }
  &:hover {
    background-color: #337ab7;
    color: #ffffff;
    cursor: move;
  }
}

.add-pagination-card {
  border-radius: 50%;
  width: 40px;
  height: 40px !important;
  background-color: #ffffff;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #203d4a;
  margin-right: 12px;

  cursor: pointer;
  display: flex !important; // to prevent conflicts from smooth-dnd.horizontal
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  &.--active {
    background-color: #037f7f;
    color: #ffffff;
  }
  &:hover {
    cursor: pointer;
    background-color: #337ab7;
    color: #ffffff;
  }
}
.spc-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.light-icon-plus {
  font-size: 28px;
}

.spc-actions {
  position: absolute;
  top: -14px;
  right: 0px;
  background-color: #007f7f;
  display: flex;
  padding: 0px 13px;
  border-radius: 8px 8px 0px 0px;
  /* box-shadow: 3px 3px 12px 3px #ebebeb; */
}
/* .spc-actions.white {
  background-color: #ffffff;
  width: 100%;
} */

.vertical-separator {
  border-right: 1px solid rgba(255, 255, 255, 0.17);
  height: 48px;
  margin-top: 8px;
  margin-right: 11px;
}
/* .add-more-section-card {
  border-left: 3px solid #fff;
  
} */
.delete-section-btn {
  position: absolute;
  right: -12px;
  top: -12px;
  height: 24px;
  width: 24px;
  border-radius: 50%;

  background-color: #b53d3d;
  color: #ffffff;
  font-weight: 600;
  display: flex;

  align-items: center;
  justify-content: center;

  cursor: pointer;
  i {
    font-size: 12px;
  }
  &:hover {
    background-color: rgb(165, 45, 45);
  }
}
</style>
