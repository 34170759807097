<template>
  <!-- RURBAN -->
  <section class="login-page">
    <div class="left-panel">
      <div class="dots" id="dot-1"></div>
      <div class="dots" id="dot-2"></div>
      <div class="dots" id="dot-3"></div>
      <div class="dots" id="dot-4"></div>
      <div class="dots" id="dot-5"></div>
      <div class="left-panel-content">
        <img src="../assets/form-studio-only-logo.svg" class="logo" />
        <div class="main-heading">Form Studio<span style="font-size: 24px"> | RURBAN</span></div>
        <!-- <div class="login-menu">
          <div class="menu-text">Admin Login</div>
        </div> -->
        <div class="line"></div>
        <!-- <div class="social-tab">
          <div class="tab">
            <div class="tab-1" @click="loginGoogle">
              <div class="g-logo-img">
                <img src="../assets/google-logo.svg" class="g-logo" />
              </div>
              <div class="google">Login with Google</div>
            </div>
          </div>
        </div> -->
        <!-- <div class="line">
          <div class="line-text">or</div>
        </div> -->
        <div class="form-box" style="margin-top: 56px;">
          <div class="email">
            <div class="email-text">Email</div>
            <input type="email" v-model="email" />
          </div>
          <div class="password">
            <div class="pass-content">
              <div class="email-text">Password</div>
              <!-- <div class="Forgot-text" @click="show_forgot_password_dialog = true">
                Forgot Password?
              </div> -->
            </div>
            <div class="pass-box">
              <input type="password" v-model="password" />
              <div class="hide-icon">
                <i class="fa fa-eye-slash" aria-hidden="true"></i>
              </div>
            </div>
          </div>

          <input type="checkbox" name="persist" id="persist" />
          <label class="checkbox" for="persist">Remember me</label>
        </div>
        <!-- <div class="button-1" @click="login_email">Login to Account</div> -->
        <div class="lv-fluid">
          <lv-button @click="login_email" deep-shadow push rounded>Login to Account</lv-button>
        </div>
        <div class="line"></div>
        <!-- <div class="last-line">Do you have an account yet?</div> -->
        <!-- <div class="button-2" @click="$router.push({ name: 'Signup' })">
          Sign Up
        </div> -->
        <!-- <div class="lv-fluid">
          <lv-button @click="$router.push({ name: 'Signup' })" class="lv--secondary" deep-shadow push rounded>Sign Up</lv-button>
        </div> -->
      </div>
    </div>
    <div class="right-panel">
      <login-right-panel />
    </div>
  </section>
</template>

<script>
import LoginRightPanel from '../components/LoginRightPanel.vue';
import firebase from 'firebase/app';
import 'firebase/auth';

import { registerUser, setGaUser } from '../utils/auth';
import Users from '@main/models/users.ts';
export default {
  data() {
    return {
      user_name: '',
    };
  },
  components: {
    LoginRightPanel,
  },

  methods: {
    async do_login(res) {
      try {
        setGaUser(res.user.uid, res.user.email);
      } catch (err) {
        //
      }
      try {
        let user = await Users.getById('usr_' + res.user.uid);
        console.log('USER UID', res.user.uid);
        console.log(user);
        if (user) {
          this.$store.dispatch('user/set', user.data);
          this.$router.push({ name: 'Dashboard' });
        }
      } catch (err) {
        console.info(err, 'Doc does not exist');
        let user = await registerUser(res, '', '', res.user.phoneNumber);
        this.$store.dispatch('user/set', user.data);
        console.log('User Data just after Login', user.data);
        this.$router.push({ name: 'Dashboard' });
      }
    },
    login_email() {
      this.formLoading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(res => {
          this.do_login(res);
          // console.log("Res on Email SignIn", res.user);
        })
        .catch(error => {
          console.log(error);
          this.$toast.add({
            content: error.message,
            type: 'error',
            duration: 3000,
          });
        });
    },
    loginGoogle() {
      this.formLoading = true;
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(res => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = result.credential.accessToken;
          this.do_login(res);
        })
        .catch(error => {
          console.log(error);
          this.$toast.add({
            content: error.message,
            type: 'error',
            duration: 3000,
          });
          // this.formLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.login-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  background: #f2faf5;
  min-height: 100vh;
}

.left-panel,
.right-panel {
  flex-grow: 1;
  flex-basis: 1;
  width: 50%;
}

.left-panel {
  position: relative;
  padding: 80px;
  background: #ffffff;
}

.logo {
  width: 80px;
  cursor: pointer;
}

.left-panel-content {
  position: relative;
}

.main-heading {
  text-align: left;
  /* margin-top: 50px; */
  display: inline-block;
  text-transform: uppercase;
  padding: 15px 0px;
  padding-bottom: 50px;
  font-size: 36px;
  color: #143a56;
  margin-left: 30px;
  /* margin-top: -30px; */
  vertical-align: top;
  cursor: pointer;
}

.login-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.menu-text {
  color: #a6aeb7;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.menu-text.active {
  border-bottom: 3px solid #5cd0b9;
  color: #143a56;
  padding-bottom: 14px;
}

.line {
  border-bottom: 1px solid #e9ebed;
  margin-bottom: 20px;
}

.form-box {
  padding: 20px 0px;
}

.email-text {
  color: #143a56;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 5px;
}

.pass-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 5px;
}

.Forgot-text {
  font-size: 12px;
  cursor: pointer;
}

.pass-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px 16px;
}

.pass-box:focus-within {
  border: 1px solid rgb(126, 126, 126);
}

.pass-box i {
  font-size: 18px;
  /* padding: 16px 20px; */
  cursor: pointer;
}

input[type='password'] {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: transparent;
  max-width: calc(100% - 5px);
}

input[type='email'] {
  width: 100%;
  padding: 16px 16px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

input[type='email']:focus-within {
  border: 1px solid rgb(126, 126, 126);
}

.checkbox {
  padding: 5px;
  font-size: 13px;
  cursor: pointer;
}

.button-1 {
  padding: 10px;
  border-radius: 8px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 40px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  background-color: #5cd0b9;
  box-shadow: 0px 6px 15px -1px #5cd0b9;
  cursor: pointer;
}

.button-1:hover {
  background-color: #55bda8;
}

.last-line {
  color: #143a56;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  margin-bottom: 20px;
}

.button-2 {
  padding: 10px;
  border-radius: 8px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  background-color: #123b56;
  box-shadow: 0px 6px 15px -1px #123b56;
  cursor: pointer;
}

.button-2:hover {
  background-color: #144365;
}
.hide-icon {
  color: #c7c7c7;
}

.dots {
  position: absolute;
  border-radius: 50%;
}

@media (max-width: 980px) {
  .left-panel {
    width: 100%;
  }

  /* .right-panel {
    width: 100%;
  }

  .right-photo {
    margin-top: 50px;
  } */
}

@media (max-width: 750px) {
  .left-panel {
    padding: 30px;
  }
}

#dot-1 {
  width: 38px;
  height: 38px;
  top: 30px;
  left: 30px;
  background: #4b96ce;
}

#dot-2 {
  width: 16px;
  height: 16px;
  top: 150px;
  left: 320px;
  background: #4b96ce;
}

#dot-3 {
  width: 16px;
  height: 16px;
  top: 300px;
  left: 60px;
  background: #4b96ce;
}

#dot-4 {
  width: 32px;
  height: 32px;
  top: 800px;
  left: -8px;
  background: #5cd0b9;
}

#dot-5 {
  width: 55px;
  height: 55px;
  top: 200px;
  left: 500px;
  background: #5cd0b9;
}

.modal-heading {
  font-size: 20px;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.72);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.social-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 7px; */
  margin-right: -20px;
}
.g-logo {
  display: flex;
  width: 15px;
  margin-right: 10px;
  justify-content: center;
}
.tab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 300px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #555;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  margin-right: 20px;
  white-space: nowrap;
}
.tab .tab-1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 200px;
  background: #ffffff;
  padding: 12px;

  margin-top: 20px;
  transition: all 0.3s ease-in-out;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
}
.tab .tab-1:hover {
  background: #f5f5f5;
}
.line-text {
  display: block;
  margin: auto;
  text-align: center;
  transform: translateY(50%);
  background: #ffffff;
  max-width: 30px;
}
</style>
