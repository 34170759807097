import firebase from 'firebase/app';
import 'firebase/firestore';

import Forms from './form_schema';
// import { USER_ID_PREFIX as DOC_ID_PREFIX } from "../config/constants.js";

const DOC_ID_PREFIX = 'usr';
export interface UserData {
  _id?: string;
  _date_added?: firebase.firestore.Timestamp;
  _date_updated?: firebase.firestore.Timestamp;
  deleted?: boolean;
  name?: string;
  email_id?: string;
  api_key?: string;
  // admin_orgs?: AdminOrgDetails[],
  // admin_depts?: AdminDeptDetails[],
  forms_number?: Number;
  // max_forms?: Number;

  [key: string]: any;
}

interface classArguments {
  snapshot?: firebase.firestore.DocumentSnapshot;
  id?: string; // Unique-ID (Alphanumeric only) to create new document
  docId?: string; // Document-ID with prefix, to create a new object of this class.
  data?: UserData; // Data to initialize the new object's data
}

// interface AdminOrgDetails {
//     id?: string,
//     name?: string,
//     slug?: string,
//     [key: string]: any
// }
// interface AdminDeptDetails {
//     id?: string,
//     name?: string,
//     category?: string
// }

export default class Users {
  // Reference to the collection
  public static collectionRef(): firebase.firestore.CollectionReference {
    return firebase.firestore().collection('users');
  }
  // Private variables
  private _id: string;
  private _data: UserData;
  private _unsaved_data: UserData = {};
  private _ref: firebase.firestore.DocumentReference;
  // private _snapshot: firebase.firestore.DocumentSnapshot;

  constructor(args: classArguments) {
    if (args.snapshot) {
      // Initializing new object, with query (Snapshot)
      this._id = args.snapshot.id;
      this._data = { ...args.snapshot.data() };
      this._ref = args.snapshot.ref;
      // this._snapshot = snapshot;
    } else if (args.docId) {
      // Initializing new object, without new query
      this._ref = Users.collectionRef().doc(args.docId);
      this._id = this._ref.id;
      this._data = args.data || {};
    } else {
      // Creating a new document
      if (!args.id) {
        args.id = Users.collectionRef().doc().id;
      }
      this._ref = Users.collectionRef().doc(`${DOC_ID_PREFIX}_${args.id}`);
      this._id = this._ref.id;
      this._data = args.data || {};
      // Initialize new document
      this.push({
        _date_added: firebase.firestore.Timestamp.fromMillis(+new Date()),
        _id: this._id,
        deleted: false,
        // max_forms: 100,
      });
    }
  }

  // Public methods Methods
  get data(): UserData {
    // Return complete data object
    return { ...this._data, ...this._unsaved_data };
  }

  public push(newData: UserData): UserData {
    this._unsaved_data = { ...this._unsaved_data, ...newData };
    return this.data; // Return complete data object
  }
  public async create(): Promise<Users> {
    await this._ref.set(this.data);
    return this;
  }
  public async save(): Promise<Users> {
    this.push({
      _date_updated: firebase.firestore.Timestamp.fromMillis(+new Date()),
    });
    await this._ref.update(this._unsaved_data);
    return this;
  }

  public static getByData(docData: UserData): Users {
    // return new object, without any network request
    if (!docData._id) {
      console.error('Document ID is missing, creating a new document !!');
    }
    return new Users({
      docId: docData._id,
      data: docData,
    });
  }

  public static getById(docId: string): Promise<Users> {
    return new Promise((resolve, reject) => {
      Users.collectionRef()
        .doc(docId)
        .get()
        .then((snapshot: firebase.firestore.DocumentSnapshot) => {
          if (snapshot.exists) return resolve(new Users({ snapshot }));
          else {
            throw new Error(`No document found with id: ${docId}`);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public static getByEmail(email: string): Promise<Users> {
    return new Promise((resolve, reject) => {
      Users.collectionRef()
        .where('email_id', '==', email)
        .get()
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            throw new Error('No user found');
          } else if (querySnapshot.docs.length > 1) {
            throw new Error('Multiple users found');
          } else {
            return resolve(new Users({ snapshot: querySnapshot.docs[0] }));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // public async updateAdminOrganization(orgData: AdminOrgDetails) {
  //     // Check if organization already exist
  //     let new_admin_orgs = this.data.admin_orgs || [];
  //     let orgIndex = new_admin_orgs ? new_admin_orgs.findIndex(x => x.id === orgData.id) : -1;
  //     if (orgIndex !== -1) {
  //         // Update existing organization
  //         new_admin_orgs[orgIndex] = orgData;
  //     } else {
  //         // Add new organization
  //         new_admin_orgs.push(orgData);
  //     }
  //     this.push({ admin_orgs: new_admin_orgs });
  // }

  // public async updateAdminDepartment(deptData: AdminDeptDetails) {
  //     // Check if organization already exist
  //     let new_admin_depts = this.data.admin_depts || [];
  //     let deptIndex = new_admin_depts ? new_admin_depts.findIndex(x => x.id === deptData.id) : -1;
  //     if (deptIndex !== -1) {
  //         // Update existing organization
  //         new_admin_depts[deptIndex] = deptData;
  //     } else {
  //         // Add new organization
  //         new_admin_depts.push(deptData);
  //     }
  //     this.push({ admin_depts: new_admin_depts });
  // }
}
