<template>
  <!---------------- Timer ------------->
  <div class="fs-pg__form-timer" :class="{ '--rounded': animated }">
    <svg class="fs-pg__form-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" v-if="animated">
      <g class="fs-pg__form-timer__circle">
        <circle class="fs-pg__form-timer__path-elapsed" cx="50" cy="50" r="45" />
        <path
          id="fs-pg__form-timer-path-remaining"
          :stroke-dasharray="`${size} 283`"
          :class="['fs-pg__form-timer__path-remaining', { green: size < 290, orange: size < 188, tomato: size < 94 }]"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
        "
        ></path>
      </g>
    </svg>
    <span class="fs-pg__form-timer__label" :class="{ '--border': !animated }">
      <div class="fs-pg__form-timer__label__time">
        <span>{{ mins }} :</span> <span class="fs-pg__form-timer__label__time__unit">m</span>
      </div>

      <div class="fs-pg__form-timer__label__time">
        <span>{{ secs }}</span> <span class="fs-pg__form-timer__label__time__unit">s</span>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    animated: {
      type: Boolean,
      default: true,
    },
    totalTime: {
      type: Number,
    },
    duration: {
      type: Number,
    },
  },
  computed: {
    size() {
      return Math.floor((this.duration / this.totalTime) * 283) || 283;
    },
    secs() {
      return ('0' + ((this.duration || this.totalTime) % 60)).substr(-2);
    },
    mins() {
      return ('0' + Math.floor((this.duration || this.totalTime) / 60)).substr(-2);
    },
  },
};
</script>

<style lang="scss">
.fs-pg__form-timer {
  position: absolute;
  right: 0px;
  top: -100px;
  height: 140px;
  width: 140px;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  transform: scale(0.5);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  &.--rounded {
    border-radius: 50%;
  }

  &__circle {
    fill: none;
    stroke: none;
  }
  &__path-elapsed {
    stroke-width: 5px;
    stroke: rgb(223, 230, 233);
  }
  &__label {
    position: absolute;
    width: 140px;
    height: 140px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // font-family: serif;
    font-size: 32px;
    &.--border {
      //   height: 100px;
      padding: 0 20px;
      width: max-content;
      border-radius: 8px;
    }
    &__time {
      margin: 0 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__unit {
        font-size: smaller;
        opacity: 0.3;
      }
    }
  }
  &__path-remaining {
    stroke-width: 5px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    stroke: currentColor;
    &.green {
      color: rgb(3, 127, 127);
    }
    &.orange {
      color: orange;
    }
    &.tomato {
      color: tomato;
    }
  }
}
</style>
