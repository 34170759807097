<template>
  <div class="intro-card-wrapper">
    <div class="intro-responser-card">
      <div class="itc-header">
        Want to create forms like this?
        <div class="itc-subheader">
          <h3 style="color: teal">Try FORM STUDIO</h3>
        </div>
      </div>
      <div class="itc-body">
        <div class="itc-content">
          <div>
            <img src="@main/assets/opinion.png" class="opinion-img" />
          </div>
          <div><img src="@main/assets/party.png" class="party-img" /></div>
          <div><img src="@main/assets/poll.png" class="poll-img" /></div>
        </div>

        <!--  -->
        <div class="itc-btn-div">
          <a href="https://formstudio.io" target="_blank" style="text-decoration: none;">
            <lv-button rounded push class="lv--success">Create a Survey, Quiz or Poll</lv-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LvButton from 'lightvue/button';
export default {
  components: {
    LvButton,
  },
};
</script>

<style lang="scss" scoped>
.intro-card-wrapper {
  max-width: 800px;
  // min-width: 300px;
}
.intro-responser-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  // margin-bottom: 80px;
}

.itc-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.itc-subheader {
  text-align: center;
}

.itc-content {
  display: flex;
  padding: 8px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.opinion-img,
.party-img,
.poll-img {
  width: 140px;
  height: 160px;
  margin-bottom: 16px;
}
.itc-btn-div {
  display: flex;
  justify-content: center;
  padding: 28px;
}

.itc-btn {
  background-color: teal;
  color: #ffffff;
  padding: 12px 16px;
  border: none;
  outline: none;
  border-radius: 16px;
}
</style>
