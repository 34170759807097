<template>
  <table class="quiz-summary__card">
    <h3 class="heading">Quiz Summary</h3>
    <br />
    <tr class="quiz-summary__card__row" style="padding:20px;">
      <td class="quiz-summary__card__row__data">Total Marks</td>
      <td class="quiz-summary__card__row__data">{{ totalMarks1 }}</td>
    </tr>
    <tr class="quiz-summary__card__row">
      <td class="quiz-summary__card__row__data">Total Questions</td>
      <td class="quiz-summary__card__row__data">{{ totalQuestions1 }}</td>
    </tr>
    <tr class="quiz-summary__card__row" v-if="schema.quiz_settings?.timer_enabled">
      <td class="quiz-summary__card__row__data">Total Duration</td>
      <td class="quiz-summary__card__row__data">{{ totalDuration }}</td>
      <!-- <td class="quiz-summary__card__row__data">
         <table>
          <tr>
            <th>
              Section
            </th>
            <th>
              Duration
            </th>
          </tr>

           <tr v-for="(section, index) in schema.sections.filter(section => section.type !== 'Intro' && section.type !== 'ThankYou')">
            <td>{{ index + 1 }}</td>
            <td>{{ `${Math.floor(section.config?.section_timer_duration / 60)} mins ${Math.floor(section.config?.section_timer_duration % 60)} secs` }}</td>
          </tr> 
          <tr>
            <td>Total</td>
            <td>{{ schema.sections.filter(section => section.type !== 'Intro' && section.type !== 'ThankYou').reduce(section => section.config) }}</td>
          </tr>
        </table> 
      </td> -->
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      totalQuestions: 0,
      totalMins: 0,
      totalSecs: 0,
    };
  },
  computed: {
    questions() {
      return this.schema.sections
        .filter(section => section.type !== 'Intro' && section.type !== 'ThankYou')
        .map(row => row.rows)
        .reduce((prev, next) => [...prev, ...next], [])
        .map(row => row.fields)
        .reduce((prev, next) => [...prev, ...next], [])
        .filter(q => !q.read_only);
    },
    totalMarks1() {
      return this.questions.reduce((prevQ, nextQ) => prevQ + +nextQ.individual_score ?? 1, 0);
    },
    totalQuestions1() {
      return this.questions.length;
    },
    totalDuration() {
      // * REFACTOR
      return this.schema.quiz_settings?.global_timer_enabled ? `${Math.floor(this.schema.quiz_settings?.global_timer_duration / 60)} mins ${Math.floor(this.schema.quiz_settings?.global_timer_duration % 60)} secs` : this.schema.quiz_settings?.section_timer_enabled ? `${Math.floor(this.schema.sections.filter(section => section.type !== 'Intro' && section.type !== 'ThankYou').reduce((acc, nextSection) => acc + nextSection.config.section_timer_duration, 0) / 60)} mins ${Math.floor(this.schema.sections.filter(section => section.type !== 'Intro' && section.type !== 'ThankYou').reduce((acc, nextSection) => acc + nextSection.config.section_timer_duration, 0) % 60)} secs` : 0;
    },

    schema: {
      get: function() {
        return this.$store.state.builder.schema;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSchema', newValue);
      },
    },
    totalMarks() {
      // let quizSummaryObj={
      //   totalMarks:0,
      //   totalQuestions:0,
      //   totalDuration:0,
      // };
      let totalMarks = 0;
      this.totalQuestion = 0;
      this.schema.sections.forEach(section => {
        if (section.type !== 'Intro' && section.type !== 'ThankYou') {
          //  total duration calc
          if (!this.schema.quiz_settings?.global_timer_enabled) {
            this.totalSecs += +section.config.section_timer_duration;
          }
          section.rows.forEach(row => {
            row.fields.forEach(field => {
              // field should not be read_only
              if (!field.read_only) {
                this.totalQuestions++;

                //if individual score enabled
                if (this.schema.quiz_settings?.individual_score_enabled) {
                  totalMarks += +field.individual_score;
                }
                // 1 mark for each question
                else {
                  totalMarks += 1;
                }
              }
            });
          });
        }
      });
      //total duration if global timer enabled
      if (this.schema.quiz_settings?.global_timer_enabled) {
        this.totalSecs = this.schema.quiz_settings?.global_timer_duration;
      }
      return totalMarks;
    },
  },
};
</script>

<style lang="scss">
.quiz-summary__card {
  background-color: #fff;
  padding: 12px 16px;
  //   cursor: pointer;
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  //   position: relative;

  border-radius: 4px;
  margin: 8px 0px;
  .heading {
    color: #007474;
  }

  &__row {
    border-radius: 8px;
    &__data {
      padding: 2px;
    }
  }
}
.section-accordian:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}
</style>
