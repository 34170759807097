<template>
  <div>
    <div class="setting-header">
      <div class="top-heading">
        <div class="setting-title">Social Media Sharing</div>
      </div>
      <div class="setting-description">
        Control the way your project looks when shared as a link on social media sites like Facebook. When users share it from inside an embed on your website, the sharing title, description and image form the website are used instead.
      </div>
    </div>

    <div class="settings-card">
      <!-- -----------Setting Header------- -->
      <!-- --------------Card Start------------------ -->

      <div class="settings__card">
        <!-- Project Name Block -->
        <div class="project-name-block">
          <lv-input type="text" label="Page Title" bottom-bar placeholder="Enter title..." v-model="page_title" />
        </div>
        <!-- ---------------- -->
        <div class="project-name-block">
          <lv-textarea rows="4" label="Sharing Description" bottom-bar placeholder="This interactive content has been created with FormStudio" v-model="page_description" />
        </div>
        <!-- ----------Share Image Block-------------- -->

        <div class="project-name-block">
          <lv-input label="Thumbnail (Image) URL" bottom-bar placeholder="Enter url..." v-model="thumbnail_url" />
          <!-- <div class="upload-img-box"> -->
          <!-- <div class="upload-icon"> -->
          <!-- <i class="light-icon-upload"></i> -->
          <!-- <br /> -->
          <!-- <span>Choose image</span> -->
          <!-- </div> -->
          <!-- </div> -->
        </div>

        <!----------------------------- Update Settings Button ---------------------------->
        <div class="update-setting">
          <lv-button class="lv--success" push size="md" @click="handleUpdate">Update Settings</lv-button>
        </div>
        <!-- ---------------- -->
      </div>
    </div>
  </div>
</template>

<script>
import LvInput from 'lightvue/input';
import LvButton from 'lightvue/button';
import LvTextarea from 'lightvue/textarea';
import { currentFormMixin } from '@main/mixins';
import Forms from '../../models/form_schema';
export default {
  mixins: [currentFormMixin],

  components: {
    LvInput,
    LvButton,
    LvTextarea,
  },
  created() {
    // console.log('CURRENT FORM in STORE', this.currentForm);
  },

  data() {
    return {
      page_title: '',
      page_description: '',
      thumbnail_url: '',
    };
  },

  watch: {
    currentForm: {
      handler(newVal, oldVal) {
        // here having access to the new and old value
        if (this.currentForm && this.currentForm.schema && this.currentForm.schema.settings) {
          this.page_title = this.currentForm.schema.settings.meta_info && this.currentForm.schema.settings.meta_info.page_title ? this.currentForm.schema.settings.meta_info.page_title : this.currentForm.schema.settings.form_heading;
          this.page_description = this.currentForm.schema.settings.meta_info && this.currentForm.schema.settings.meta_info.page_description ? this.currentForm.schema.settings.meta_info.page_description : this.currentForm.schema.settings.form_description;
          this.thumbnail_url = this.currentForm.schema.settings.meta_info && this.currentForm.schema.settings.meta_info.thumbnail_url ? this.currentForm.schema.settings.meta_info.thumbnail_url : '';
        }
      },
      deep: true,
      immediate: true, //  Also very important the immediate in case you need it, the callback will be called immediately after the start of the observation
    },
  },

  methods: {
    async handleUpdate() {
      if (this.$route.query.id) {
        var seoObj = {
          page_title: this.page_title || '',
          page_description: this.page_description || '',
          thumbnail_url: this.thumbnail_url || '',
        };

        await Forms.updateMetaSttings(this.currentForm._id, seoObj);

        this.$toast.add({
          content: 'Settings Updated !!',
          type: 'Success',
          duration: 2000,
          stricky: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.top-heading {
  display: flex;
}

.light-icon-hash {
  font-size: 36px;
}

.project-name-block {
  margin-top: 10px;
  margin-bottom: 16px;
}
.project-name-field,
.project-url-field .label {
  margin-bottom: 8px;
}

.setting-field-label {
  margin-bottom: 4px;
}

.projectName-input {
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0.7rem;
}

.upload-img-box {
  width: 304px;
  height: 192px;
  background: #edf2f7;
  color: #2d3748;
  padding: 12px 16px;
}

.upload-icon {
  margin-top: 28px;
  text-align: center;
  cursor: pointer;
}

.light-icon-upload {
  font-size: 48px;
  /* margin: 0px 0px 12px; */
  /* text-align: center; */
}
</style>
