<template>
  <div class="loader">
    <div class="dotA"></div>
    <div class="dotB"></div>
    <div class="dotA"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.loader div {
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  margin: 6px;
  opacity: 0.5;
}
.dotA {
  animation: load 0.4s linear 0s infinite alternate;
}
.dotB {
  animation: load 0.4s linear 0.4s infinite alternate;
}
@keyframes load {
  from {
    opacity: 0.5;
    transform: scale(1);
  }
  to {
    opacity: 1;
    transform: scale(1.5);
  }
}
</style>
