<template>
  <div>
    <dashboard-layout />
    <div class="error_wrapper">
      <div class="error__container">
        <div class="error__div"></div>
        <!-- <h1>Error 404</h1> -->
        <div class="error-img__container">
          <img src="../assets/error.svg" alt />
        </div>
        <div class="error__button">
          <p>OHH ! No records found !!</p>
          <br />
          <div @click="$router.push({ name: 'ProjectType' })">
            <LvButton label="Create new form" deep-shadow push size="lg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardLayout from '../DashboardLayout.vue';
export default {
  name: 'ErrorPage',
  components: {
    DashboardLayout,
  },
};
</script>

<style>
.error_wrapper {
  width: 100%;
  text-align: center;
  padding: 0 20px;
}
/* .button {
  margin: -20px 0 20px 0;
  z-index: 1000;
} */

.error-img__container {
  /* margin: 50px 0px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-img__container img {
  width: 100%;
  max-width: 500px;
  display: block;
  margin: 0px auto;
}
.error__container h1 {
  font-size: 50px;
  text-align: center;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
  color: #008080;
}

.error__button p {
  text-align: center;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
  color: #405364;
  margin-bottom: 20px;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
</style>
