<template>
  <div>
    <ol class="fs-fe__photoSelect">
      <li :class="layout" v-for="(imgOption, imgIndex) in options" @click="refresh(imgOption)" v-bind="$attrs" :aria-label="getOptionLabel(imgOption)" :key="getOptionRenderKey(imgIndex)" role="option" :aria-selected="isOptionSelected(imgOption)" :checked="isOptionSelected(imgOption)" :disabled="isOptionDisabled(imgOption)" :value="getOptionValue(imgOption)">
        <button class="fs-fe__photoSelect__option" :style="{ margin: img_margin + 'px' }" :class="{ '--selected': modelValue === imgOption || (modelValue && modelValue.includes(imgOption)) }" style="position:relative">
          <img :class="`--${img_shape}`" :src="imgOption" :alt="`option ${imgIndex + 1}`" :style="{ width: img_width + 'px', height: img_height + 'px' }" />
          <i v-if="modelValue === imgOption || (modelValue && modelValue.includes(imgOption))" :class="[selected_icon || 'light-icon-check', { selected: modelValue === imgOption || (modelValue && modelValue.includes(imgOption)) }]" :style="{ fontSize: selected_icon_size + 'px' }"></i>
        </button>
      </li>
    </ol>
  </div>
</template>

<script>
import { localValueMixin, optionsMixin } from 'lightvue/mixins';
import selectPropsMixin from './selectPropsMixin';

export default {
  mixins: [localValueMixin, optionsMixin, selectPropsMixin],

  props: ['options', 'enable_multiselect', 'img_width', 'img_height', 'img_margin', 'selected_icon', 'selected_icon_size', 'layout', 'img_shape'],
  data() {
    return {
      multiSelectedOption: [],
      isSelected: null,
      selectedOption: null,
    };
  },
  computed: {
    optionLabel() {
      return this.optionLabelKey;
    },
    optionValue() {
      return this.optionValueKey;
    },
  },
  methods: {
    activateOption(option) {
      this.selectedOption = option;
    },
    activateMultipleOption(option) {
      if (this.multiSelectedOption.includes(option)) {
        let oldOptionIndex = this.multiSelectedOption.findIndex(mOption => mOption === option);
        this.multiSelectedOption.splice(oldOptionIndex, 1);
        this.isSelected = false;
      } else {
        this.multiSelectedOption.push(option);
        this.isSelected = true;
      }
      console.log('multiSelectedOptions', this.multiSelectedOption);
      return this.isSelected;
    },

    refresh(option) {
      if (this.enable_multiselect) {
        this.activateMultipleOption(option);
        console.log('isSelected', this.isSelected);
        this.updateValue(this.multiSelectedOption);
      } else {
        // temporary workaround
        this.multiSelectedOption = [];

        // let value = this.getOptionValue(option);
        let value = this.modelValue == option ? '' : this.getOptionValue(option);
        // console.log(event, value, option);
        this.updateValue(value);
      }
    },
  },
};
</script>

<style lang="scss">
.fs-fe__photoSelect {
  justify-content: flex-start;
  width: fit-content;
  .grid {
    flex-basis: 50%;
  }
  .list {
    flex-basis: 100%;
  }
  &__option {
    cursor: pointer;
    width: min-content;
    img {
      border: 1px inset silver;
      padding: 3px;
      // object-fit: contain;
      // object-fit: cover;
    }
    .--circle {
      border-radius: 50%;
      // overflow: hidden;
    }
    .--square {
      border-radius: 5px;
      // overflow: hidden;
    }
    border: none;
    transition: 0.3s ease;
    padding: 2px;

    &:hover {
      img {
        filter: brightness(85%);
      }
    }

    &.--selected {
      img {
        filter: brightness(60%) opacity(90%) contrast(120%) hue-rotate(10deg);
      }
      &:hover {
        filter: brightness(90%);
      }
    }
  }
  .selected {
    position: absolute;
    cursor: pointer;
    transition: 0.3s ease;
    top: 50%;
    left: 50%;
    z-index: 99;
    filter: drop-shadow(0px 0px 4px #000);
    transform: translate(-50%, -50%);
    color: rgb(236, 236, 236);
  }
}
</style>
