// import state from './state'
import { Module } from 'vuex';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';
import { FormData } from '@main/models/form_schema';

export const state: { currentForm: FormData } = {
  currentForm: {},
};

const application: Module<any, any> = {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
};

export default application;
