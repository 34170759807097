<template>
  <LvTextarea :auto-resize="auto_resize" :label="label" :placeholder="placeholder" :show-limit="show_limit" :rows="+rows" :cols="column" :max-length="max_length" :value="modelValue" @input="updateInputValue"> </LvTextarea>
</template>

<script>
import LvTextarea from 'lightvue/textarea';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  components: {
    LvTextarea,
  },
  props: ['auto_resize', 'label', 'placeholder', 'show_limit', 'max_length', 'column', 'rows'],
  methods: {
    inp(e) {
      // debugger;
    },
    updateInputValue(e) {
      if (!e.target) {
        this.updateValue(e);
      }
    },
  },
};
</script>

<style></style>
