<template>
  <section v-if="activeElement">
    <div>
      <!---------------------------------------------- Toggle Button-------------------------------------- -->
      <div v-if="activeElement.field_type == 'FormToggle'">
        <!-- Dense Toggle Switch -->
        <div class="el-styles-field">
          <d-toggle-switch label="Dense" name="densetoggle" v-model="activeElement.dense" />
        </div>
      </div>
      <!-- -----------------------------------------------Star Rating--------------------------------------------- -->
      <div v-if="activeElement.field_type == 'FormRating'">
        <!-- <lv-input label="Active Color" v-model="activeElement.active_color" name="Active-color" /> -->
        <ColorPicker v-model="activeElement.active_color" label="Active-color" />

        <br />
        <!-- <lv-input label="In-Active Color" v-model="activeElement.inactive_color" name="Active-color" /> -->
        <ColorPicker v-model="activeElement.inactive_color" label="Inactive-color" />

        <br />
        <lv-input type="number" label="Size" v-model="activeElement.star_size" name="Star_size" />
        <br />
      </div>
      <!--  -->

      <!-- ----------------------------------------------DROPDOWN-------------------------------------------------- -->

      <div v-if="activeElement.field_type === 'FormDropdown'">
        <div v-if="activeElement.img_show">
          <h4 class="category_title">Question image</h4>

          <div class="el-prop-field">
            <lv-input type="number" label="Image margin(px)" :bottomBar="true" v-model="activeElement.img_margin_question" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image width (px)" :bottomBar="true" v-model="activeElement.img_width_question" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image height (px)" :bottomBar="true" v-model="activeElement.img_height_question" />
          </div>

          <div class="el-prop-field">
            <lv-dropdown v-model="activeElement.img_object_fit_q" :options="['cover', 'contain', 'fill']" placeholder="Select fit" label="Image Fit" />
          </div>
        </div>

        <br />
      </div>

      <!-- ----------------------------------------------RANGE SLIDER-------------------------------------------------- -->
      <div v-if="activeElement.field_type == 'FormRangeSlider'">
        <lv-input type="number" label="Min value" v-model="activeElement.min" />

        <br />
        <lv-input type="number" label="Max value" v-model="activeElement.max" />

        <br />
        <ColorPicker v-model="activeElement.sliderColor" label="Slider Color" />

        <br />

        <lv-input type="number" label="Step size" v-model="activeElement.step" />

        <br />
        <ColorPicker v-model="activeElement.trackColor" label="Track Color" />
      </div>
      <!-- *** -->
      <!-- ----------------------------------------------FORM CHECKBOX GROUP-------------------------------------------------- -->
      <div v-if="activeElement.field_type == 'FormCheckboxGroup'">
        <h4 class="category_title">All options</h4>

        <div class="el__align__btn">
          <d-toggle-switch label="Pulse Effect" v-model="activeElement.pulse"> </d-toggle-switch>
        </div>
        <div>Arrange options</div>
        <div class="el__align__btn">
          <LvButton :outlined="activeElement.options_layout != 'auto'" icon="light-icon-dots" @click="activeElement.options_layout = 'auto'" />
          <LvButton :outlined="activeElement.options_layout != 'grid'" icon="light-icon-columns" @click="activeElement.options_layout = 'grid'" />
          <LvButton :outlined="activeElement.options_layout != 'list'" icon="light-icon-menu-2" @click="activeElement.options_layout = 'list'" />
        </div>

        <!-- <lv-input type="number" label="Image margin(px)" :bottomBar="true" v-model="activeElement.logo_margin" /> -->

        <div v-if="activeElement.img_show">
          <h4 class="category_title">Question image</h4>
          <div class="el-prop-field">
            <lv-input type="number" label="Image margin(px)" :bottomBar="true" v-model="activeElement.img_margin_question" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image width (px)" :bottomBar="true" v-model="activeElement.img_width_question" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image height (px)" :bottomBar="true" v-model="activeElement.img_height_question" />
          </div>

          <div class="el-prop-field">
            <lv-dropdown v-model="activeElement.img_object_fit_q" :options="['cover', 'contain', 'fill']" placeholder="Select fit" label="Image Fit" />
          </div>
        </div>
      </div>
      <!-- *** -->
      <!-- ----------------------------------------------FORM RADIO GROUP-------------------------------------------------- -->
      <div v-if="activeElement.field_type == 'FormRadioGroup'">
        <h4 class="category_title">All options</h4>

        <div class="el__align__btn">
          <d-toggle-switch label="Pulse Effect" v-model="activeElement.pulse"> </d-toggle-switch>
        </div>
        <div>Arrange options</div>
        <div class="el__align__btn">
          <LvButton :outlined="activeElement.options_layout != 'auto'" icon="light-icon-dots" @click="activeElement.options_layout = 'auto'" />
          <LvButton :outlined="activeElement.options_layout != 'grid'" icon="light-icon-columns" @click="activeElement.options_layout = 'grid'" />
          <LvButton :outlined="activeElement.options_layout != 'list'" icon="light-icon-menu-2" @click="activeElement.options_layout = 'list'" />
        </div>

        <!-- <lv-input type="number" label="Image margin(px)" :bottomBar="true" v-model="activeElement.logo_margin" /> -->

        <div v-if="activeElement.img_show">
          <h4 class="category_title">Question image</h4>
          <div class="el-prop-field">
            <lv-input type="number" label="Image margin(px)" :bottomBar="true" v-model="activeElement.img_margin_question" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image width (px)" :bottomBar="true" v-model="activeElement.img_width_question" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image height (px)" :bottomBar="true" v-model="activeElement.img_height_question" />
          </div>

          <div class="el-prop-field">
            <lv-dropdown v-model="activeElement.img_object_fit_q" :options="['cover', 'contain', 'fill']" placeholder="Select fit" label="Image Fit" />
          </div>
        </div>
        <br />
      </div>

      <!-------------------- FORM HTML EDITOR -------------------------->
      <div v-if="activeElement.field_type == 'FormCustomHTML'"></div>
      <!-- *** -->
      <!-- ----------------------------------------------FORM BUTTON SELECT-------------------------------------------------- -->
      <div v-if="activeElement.field_type == 'FormButtonSelect'">
        <h4 class="category_title">Label</h4>
        <lv-input type="number" label="Label size(px)" :bottomBar="true" v-model="activeElement.label_size" />

        <!-- Required Toggle Switch -->

        <h4 class="category_title">All options</h4>

        <lv-input type="number" label="Options Margin Top(px)" :bottomBar="true" v-model="activeElement.options_top_margin" />
        <br />
        <lv-input type="number" label="Margin(px)" :bottomBar="true" v-model="activeElement.btn_margin" />
        <br />

        <span>Options layout</span>
        <div class="el__align__btn">
          <LvButton :outlined="activeElement.options_layout != 'grid'" label="Grid" @click="activeElement.options_layout = 'grid'" />
          <LvButton :outlined="activeElement.options_layout != 'list'" label="List" @click="activeElement.options_layout = 'list'" />
        </div>

        <div v-if="activeElement.img_show">
          <h4 class="category_title">Question Image</h4>
          <div class="el-prop-field">
            <lv-input type="number" label="Image Margin(px)" :bottomBar="true" v-model="activeElement.img_margin" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image width (px)" :bottomBar="true" v-model="activeElement.img_width" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image height (px)" :bottomBar="true" v-model="activeElement.img_height" />
          </div>

          <div class="el-prop-field">
            <lv-dropdown v-model="activeElement.img_object_fit_q" :options="['cover', 'contain', 'fill']" placeholder="Select fit" label="Image Fit" />
          </div>
        </div>

        <h4 class="category_title">Button</h4>

        <lv-input type="number" label="Width(px)" :bottomBar="true" v-model="activeElement.btn_width" />
        <br />
        <ColorPicker v-model="activeElement.selected_btn_background" label="Selected Button Color" />
        <br />

        <div class="el-prop-field">
          <d-toggle-switch label="Push Type" name="reqToggle" v-model="activeElement.push" />
        </div>
        <!-- Outlined Type Btn Toggle Switch -->
        <div class="el-prop-field">
          <d-toggle-switch label="Outlined Type" name="reqToggle" v-model="activeElement.outlined" />
        </div>
        <!-- Raised style Toggle Switch -->
        <div class="el-prop-field">
          <d-toggle-switch label="Raised" name="reqToggle" v-model="activeElement.raised" />
        </div>
        <!-- DeepShadow style Toggle Switch -->
        <div class="el-prop-field">
          <d-toggle-switch label="Deep-Shadow" name="reqToggle" v-model="activeElement.deepShadow" />
        </div>
        <!-- Rounded Style Toggle Switch -->
        <div class="el-prop-field">
          <d-toggle-switch label="Rounded" name="reqToggle" v-model="activeElement.rounded" />
        </div>
        <!-- Deep Shadow Hover Style Toggle Switch -->
        <div class="el-prop-field">
          <d-toggle-switch label="Deep-Shadow Hover" name="reqToggle" v-model="activeElement.deepShadowHover" />
        </div>
        <br />
        <ColorPicker v-model="activeElement.background_color" label="Button Color" />
        <br />
        <ColorPicker v-model="activeElement.font_color" label="Font Color" />

        <!-- Push Type Btn Toggle Switch -->
      </div>
      <!-- *** -->
      <!-- ----------------------------------------------FORM Photo SELECT-------------------------------------------------- -->
      <div v-if="activeElement.field_type == 'FormImage'">
        <!-- Required Toggle Switch -->

        <!-- <lv-input type="number" label="Width(px)" :bottomBar="true" v-model="activeElement.btn_width" /> -->
        <!-- <br /> -->
        <lv-input type="number" label="Image margin(px)" :bottomBar="true" v-model="activeElement.logo_margin" />
        <br />

        <span>Align Image </span>
        <div class="el__align__btn">
          <LvButton :outlined="activeElement.logo_align != 'left'" icon="light-icon-align-left" @click="activeElement.logo_align = 'left'" />
          <LvButton :outlined="activeElement.logo_align != 'center'" icon="light-icon-align-center" @click="activeElement.logo_align = 'center'" />
          <LvButton :outlined="activeElement.logo_align != 'right'" icon="light-icon-align-right" @click="activeElement.logo_align = 'right'" />
        </div>

        <!-- *** -->
      </div>

      <div v-if="activeElement.field_type == 'FormPhotoSelect'">
        <!-- Required Toggle Switch -->

        <!-- <lv-input type="number" label="Width(px)" :bottomBar="true" v-model="activeElement.btn_width" /> -->
        <!-- <br /> -->

        <!-- QUESTION IMAGE -->
        <h4 class="category_title">Label</h4>
        <lv-input type="number" label="Label size(px)" :bottomBar="true" v-model="activeElement.label_size" />

        <h4 class="category_title">All options</h4>

        <span>Image group layout </span>
        <div class="el__layout__btn">
          <LvButton :outlined="activeElement.layout != 'grid'" label="Grid" icon-right="light-icon-grid" class="" @click="activeElement.layout = 'grid'" />
          <LvButton :outlined="activeElement.layout != 'list'" label="List" icon-right="light-icon-layout-list" class="" @click="activeElement.layout = 'list'" />
          <LvButton :outlined="activeElement.layout != ''" label="Auto" icon-right="light-icon-wind" class="" @click="activeElement.layout = ''" />
        </div>

        <lv-dropdown v-model="activeElement.img_shape" :options="['square', 'circle']" placeholder="Select shape" label="Image Shape" />
        <div class="el-prop-field">
          <lv-input type="number" label="Image Width (px)" :bottomBar="true" v-model="activeElement.img_width" />
        </div>
        <!-- height -->
        <div class="el-prop-field">
          <lv-input type="number" label="Image Height (px)" :bottomBar="true" v-model="activeElement.img_height" />
        </div>
        <!-- Margin -->
        <div class="el-prop-field">
          <lv-input type="number" label="Margin (px)" :bottomBar="true" v-model="activeElement.img_margin" />
        </div>
        <br />
        <!-- Selected Icon Settings -->
        <lv-dropdown v-model="activeElement.selected_icon" :options="['light-icon-check', 'light-icon-circle-check', 'light-icon-square-check']" placeholder="Select icon" label="Checked Icon" />
        <div class="el-prop-field">
          <lv-input type="number" label="Selected Icon Size (px)" :bottomBar="true" v-model="activeElement.selected_icon_size" />
        </div>
        <div v-if="activeElement.img_show">
          <h4 class="category_title">Question Image</h4>

          <div class="el-prop-field">
            <lv-input type="number" label="Image margin(px)" :bottomBar="true" v-model="activeElement.img_margin_question" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image width (px)" :bottomBar="true" v-model="activeElement.img_width_question" />
          </div>

          <div class="el-prop-field">
            <lv-input type="number" label="Image height (px)" :bottomBar="true" v-model="activeElement.img_height_question" />
          </div>

          <div class="el-prop-field">
            <lv-dropdown v-model="activeElement.img_object_fit_q" :options="['cover', 'contain', 'fill']" placeholder="Select fit" label="Image Fit" />
          </div>
        </div>

        <br />

        <br />
      </div>
      <!-- *** -->
      <!----------------------------- Form Heading ------------------->
      <div v-if="activeElement.field_type === 'FormHeading'">
        <div class="el-prop-field">
          <lv-input type="number" label="Heading size (px)" :bottomBar="true" v-model="activeElement.heading_size" />
        </div>

        <div class="el-prop-field">
          <lv-input type="number" label="Subheading size (px)" :bottomBar="true" v-model="activeElement.sub_heading_size" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LvInput from 'lightvue/input';
import DToggleSwitch from 'lightvue/input-toggle';
import ColorPicker from '../lightvue/components/colorpicker/ColorPicker';
import LvDropdown from 'lightvue/dropdown/Dropdown';

export default {
  name: 'ElementStyles',
  props: ['placeholder', 'name', 'value', 'options', 'color'],

  data() {
    return {
      label: 'Enter Your label',
      showOptions: false,
    };
  },
  components: {
    LvInput,
    DToggleSwitch,
    ColorPicker,
    LvDropdown,
  },
  data: () => {
    return {
      activeTab: 'properties',
    };
  },
  // -------------------------------computed---------------------------------------------------------
  computed: {
    formSchema() {
      return this.$store.state.builder.schema;
    },
    activeRowId() {
      return this.$store.state.builder.activeRowId;
    },
    selectedSection: {
      get: function() {
        return this.$store.state.builder.selectedSection;
      },
    },
    activeElement() {
      let rowIndex = this.formSchema.sections[this.selectedSection].rows.findIndex(row => row.id === this.activeRowId);
      if (rowIndex === -1) {
        return null;
      } else {
        return this.formSchema.sections[this.selectedSection].rows[rowIndex].fields[0];
      }
    },
  },

  methods: {
    hasErrors(e) {
      console.log(e);
    },

    getErrors(e) {
      console.log(e);
    },
    dirty(name) {},
  },
};
</script>

<style scoped>
/* .el-styles-field { */
/* margin-top: 8px; */
/* } */
.el-prop-field {
  margin-top: 8px;
}

.el__layout__btn {
  display: flex;
  justify-content: flex-start;
  margin: 8px 0px 12px 0px;
}
.el__layout__btn button {
  margin-left: 8px;
}
</style>
