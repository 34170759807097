<template>
  <div class="fs-sp__hidden-overlay__wrap">
    <!-- <coming-soon /> -->

    <i class="light-icon-tool" style="font-size: 44px; margin-bottom: 24px"></i>
    <!-- <br /> -->
    <span> We are working on these !!</span>
  </div>
</template>

<script>
import ComingSoon from '@main/components/ComingSoon.vue';

export default {
  components: {
    ComingSoon,
  },
};
</script>

<style lang="scss">
.fs-sp__hidden-overlay__wrap {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
</style>
