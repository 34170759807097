<template>
  <Navbar />
  <div class="page-container">
    <router-view />
  </div>
</template>

<script>
import Navbar from '@main/components/dashboard/Navbar.vue';
export default {
  components: {
    Navbar,
  },
};
</script>
<style>
.page-container {
  padding-top: 56px;
}
</style>
