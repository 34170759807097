<template>
  <div class="form-elements-col">
    <div class="form-elements-column-heading">FORM ELEMENTS</div>
    <section>
      <Container behaviour="copy" group-name="builder" :get-child-payload="getChildPayload" class="form-elements">
        <Draggable class="form-elements-item" :id="element.type" v-for="element in elementsList" :key="element.type">
          <div class="chip-icon">
            <i :class="element.icon" />
          </div>
          <div class="form-element-name">{{ element.name }}</div>
        </Draggable>
      </Container>
      <!-- <div class="form-elements">
        <div
          class="form-elements-item"
          :id="element.type"
          :draggable="true"
          @dragstart="dragStart($event, element.type)"
          v-for="element in $options.elements"
          :key="element.type"
        >
          <div class="chip-icon">
            <i :class="element.icon" />
          </div>
          <div class="form-element-name">
            {{ element.name }}
          </div>
        </div>
      </div>-->
      <!-- ***************** -->
    </section>
  </div>
</template>

<script>
import { Container, Draggable } from './smooth-dnd/main';
import { elementsList } from './elements/Elements.js';
export default {
  name: 'FormElements',
  components: {
    Container,
    Draggable,
  },
  data() {
    return {
      // dragging: false
      elementsList: elementsList,
    };
  },

  methods: {
    // dragStart: (e, Type) => {
    //   const target = e.target;
    //   this.dragging = true;
    //   e.dataTransfer.setData("card_id", target.id);
    // },
    // getShouldAcceptDrop (index, sourceContainerOptions, payload) {
    //   console.log('should-accept-drop', sourceContainerOptions, payload)
    //   return false;
    // },
    getChildPayload(index) {
      return this.elementsList[index];
    },
  },
};
</script>

<style>
/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
/* @media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
} */

.output {
  font: bold;
  color: green;
}

.form-elements-col {
  max-width: 300px;
  text-align: center;
  margin: auto;
}
/* .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;

  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
} */
/*  */
/* FORM ELEMENTS STYLING */
/*  */
.form-elements-column-heading {
  font-size: 24px;
  padding: 16px 16px 8px;
}
.form-elements {
  /* padding: 16px; */
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  justify-content: center;
  align-items: stretch;
  padding: 6px;
}
.form-elements-item {
  background: #ffffff;
  padding: 12px;
  color: black;
  border-radius: 4px;
  font-size: 12px;
  width: 100%;
  height: 84px;
  width: 84px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6px;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  /* will-change: transform, box-shadow; */
}

.chip-icon i {
  font-size: 28px;
  /* color: #2d3436; */
}

.form-elements:not(.--dragging) .form-elements-item:hover {
  background-color: #607c8a;
  color: #ffffff;
  /* color: teal;
  background: #f2f5f6; */
  /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}

.form-element-name {
  text-align: center;
  /* margin-top: 8px; */
}

/* .chip-icon i:hover {
  color: teal;
} */
</style>
