<template>
  <div>
    <lv-checkbox-group :style="groupStyle" :options="options" :optionLabel="optionLabelKey" :optionValue="optionValueKey" color="success" :pulse="pulse" outline :value="modelValue" @input="updateValue"></lv-checkbox-group>
  </div>
</template>

<script>
import LvCheckboxGroup from 'lightvue/checkbox-group';
import { trueValueMixin } from 'lightvue/mixins';
import selectPropsMixin from './selectPropsMixin';

export default {
  mixins: [trueValueMixin, selectPropsMixin],

  components: {
    LvCheckboxGroup,
  },

  props: ['options', 'color', 'pulse', 'options_layout'],

  computed: {
    groupStyle() {
      if (this.options_layout === 'auto') {
        return {};
      } else {
        return {
          display: 'grid',
          'grid-template-columns': `repeat(auto-fill, ${this.options_layout === 'grid' ? '50%' : '100%'})`,
          margin: '4px 2px',
        };
      }
    },
  },
};
</script>

<style lang="scss">
.fs-field_label {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.8;
  color: #495057;
}
</style>
