<template>
  <!---------- Custom Domain Page container ------------->
  <div class="pt-page_cont">
    <!-- ----------Page Header--------------- -->
    <div class="pt-top-row">
      <div class="pt_header">
        <div class="pt-main_heading">Choose project type</div>
        <div class="pt-heading_description">
          What kind of content would you like to create?
        </div>
      </div>
    </div>

    <!-- Start From Scratch Heading -->
    <div class="start-scratch-div" @click="$router.push({ name: 'Create' })">
      <div class="pt-plus-btn"><i class="light-icon-plus"></i></div>
      <div class="pt-center-head">
        Start From Scratch
        <div class="pt-center-subhead">Easily build on a blank canvas</div>
      </div>
    </div>
    <!-- -------------------- -->
    <!----------- Different Project Types ------------------->
    <div class="project-types-div">
      <div class="project-type-grid">
        <!-- Project Type QUIZ circle card -->
        <div class="pt-circle-card">
          <div class="pt-circle">
            <i class="light-icon-bulb"></i>
          </div>
          <div class="pt-card-content">
            <div class="pt-card-head">Quiz</div>
            <div class="pt-card-description">
              Engage your readers & get them sharing. Maximize user interactions
              on your site.
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Project Type SURVEY circle card -->
        <div class="pt-circle-card">
          <div class="pt-circle">
            <i class="light-icon-thumb-up"></i>
          </div>
          <div class="pt-card-content">
            <div class="pt-card-head">Survey</div>
            <div class="pt-card-description">
              Engage your readers & get them sharing. Maximize user interactions
              on your site.
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Project Type CALCULATOR circle card -->
        <div class="pt-circle-card">
          <div class="pt-circle">
            <i class="light-icon-keyboard"></i>
          </div>
          <div class="pt-card-content">
            <div class="pt-card-head">Calculator</div>
            <div class="pt-card-description">
              Engage your readers & get them sharing. Maximize user interactions
              on your site.
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Project Type FORM circle card -->
        <div class="pt-circle-card">
          <div class="pt-circle">
            <i class="light-icon-float-left"></i>
          </div>
          <div class="pt-card-content">
            <div class="pt-card-head">Form</div>
            <div class="pt-card-description">
              Engage your readers & get them sharing. Maximize user interactions
              on your site.
            </div>
          </div>
        </div>
        <!--  -->
        <!-- Project Type FORM circle card -->
        <div class="pt-circle-card">
          <div class="pt-circle">
            <i class="light-icon-currency-dollar"></i>
          </div>
          <div class="pt-card-content">
            <div class="pt-card-head">Payment Form</div>
            <div class="pt-card-description">
              Engage your readers & get them sharing. Maximize user interactions
              on your site.
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Project Type LEAD PAGE circle card -->
        <div class="pt-circle-card">
          <div class="pt-circle">
            <i class="light-icon-credit-card"></i>
          </div>
          <div class="pt-card-content">
            <div class="pt-card-head">Lead Page</div>
            <div class="pt-card-description">
              Engage your readers & get them sharing. Maximize user interactions
              on your site.
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Project Type PROMOTION circle card -->
        <div class="pt-circle-card">
          <div class="pt-circle">
            <i class="light-icon-trophy"></i>
          </div>
          <div class="pt-card-content">
            <div class="pt-card-head">Promotion</div>
            <div class="pt-card-description">
              Engage your readers & get them sharing. Maximize user interactions
              on your site.
            </div>
          </div>
        </div>
        <!--  -->
        <!-- Project Type PERSONALITY TEST circle card -->
        <div class="pt-circle-card">
          <div class="pt-circle">
            <i class="light-icon-user-check"></i>
          </div>
          <div class="pt-card-content">
            <div class="pt-card-head">Personality Test</div>
            <div class="pt-card-description">
              Engage your readers & get them sharing. Maximize user interactions
              on your site.
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
    <!-- ------ -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectTypes: [
        {
          title: "Quiz",
          ProjectDescription: "",
          ProjectIcon: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.pt-page_cont {
  margin: 40px;
  padding: 20px;
}

.pt-top-row {
  display: flex;
  justify-content: space-between;
}

.pt-main_heading {
  font-size: 28px;
  font-weight: 600;
  border-bottom: 3px solid teal;
}

.pt-heading_description {
  margin-top: 8px;
  opacity: 0.6;
}

.start-scratch-div {
  margin-top: 72px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.start-scratch-div:hover {
  color: #258ebb;
}

.pt-plus-btn {
  background-color: #fff;
  border-radius: 62px;

  margin-right: 12px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.light-icon-plus {
  padding: 10px;
  font-size: 28px;
  font-weight: 800;
  text-align: center;
}
.pt-center-head {
  font-size: 20px;
  font-weight: 800;
}
.pt-center-subhead {
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
}

.project-types-div {
  display: flex;
  justify-content: center;
}

.project-type-grid {
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
}
.pt-circle-card {
  display: flex;
  flex-direction: column;
  width: 250px;
  cursor: pointer;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 28px;
}
.pt-circle {
  background-color: #38b2ab;
  padding: 35px;
  width: 120px;

  border-radius: 50%;
}

.light-icon-bulb,
.light-icon-thumb-up,
.light-icon-keyboard,
.light-icon-float-left,
.light-icon-currency-dollar,
.light-icon-credit-card,
.light-icon-trophy,
.light-icon-user-check {
  font-size: 48px;
  color: #fff;
}

.pt-card-head {
  margin-top: 8px;
  text-align: center;
  font-size: 22px;
  font-weight: 800;
}

.pt-card-description {
  text-align: center;
  margin-top: 8px;
  font-size: 13px;
}
.pt-card-content:hover {
  color: #258ebb;
}

.pt-circle {
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); */
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}
.pt-circle:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}
</style>