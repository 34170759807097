const elementsList=[
    {
       "name":"Single Line Text",
       "type":"FormInput",
       "icon":"light-icon-typography",
       "img":"../assets/icons/TextInput.svg"
    },
    {
       "name":"Number Input",
       "type":"FormNumber",
       "icon":"light-icon-dice",
       "img":"../assets/icons/number-icon2.svg"
    },
    {
       "name":"Radio Button",
       "type":"FormRadioGroup",
       "icon":"light-icon-focus",
       "img":"../assets/icons/radio-button.svg"
    },
    {
       "name":"Toggle Button",
       "type":"FormToggle",
       "icon":"light-icon-toggle-left",
       "img":"../assets/icons/checkbox.svg"
    },
    {
       "name":"Checkbox",
       "type":"FormCheckboxGroup",
       "icon":"light-icon-checkbox",
       "img":"../assets/icons/checkbox.svg"
    },
    {
       "name":"Dropdown",
       "type":"FormDropdown",
       "icon":"light-icon-caret-down",
       "img":"../assets/icons/drop-down.svg"
    },
    {
       "name":"Contact Number",
       "type":"FormContactNumber",
       "icon":"light-icon-phone-calling",
       "img":"../assets/icons/number-icon2.svg"
    },
    {
       "name":"Date",
       "type":"Date",
       "icon":"light-icon-calendar",
       "img":"../assets/icons/calendar.svg"
    },
    {
       "name":"Button",
       "type":"FormButton",
       "icon":"light-icon-crosshair",
       "img":"../assets/icons/submit.svg"
    },
    {
       "name":"Free Text Input",
       "type":"FormTextarea",
       "icon":"light-icon-message",
       "img":"../assets/icons/submit.svg"
    },
    {
       "name":"Rating",
       "type":"FormRating",
       "icon":"light-icon-star",
       "img":"../assets/icons/submit.svg"
    },
    {
       "name":"Button Select",
       "type":"FormButtonSelect",
       "icon":"light-icon-layout-align-left"
    },
    {
       "name":"Photo Select",
       "type":"FormPhotoSelect",
       "icon":"light-icon-chart-area-line"
    },
    {
       "name":"Separator",
       "type":"FormSeparator",
       "icon":"light-icon-separator",
       "img":"../assets/icons/submit.svg"
    },
    {
       "name":"Image",
       "type":"FormImage",
       "icon":"light-icon-photo",
       "img":"../assets/icons/submit.svg"
    },
    {
       "name":"Slider",
       "type":"FormRangeSlider",
       "icon":"light-icon-adjustments-horizontal",
       "img":"../assets/icons/submit.svg"
    },
    {
       "name":"Custom HTML",
       "type":"FormCustomHTML",
       "icon":"light-icon-code"
    }
 ];

 export {elementsList};

