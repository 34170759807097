<template>
  <div class="builder-wrap">
    <div id="main">
      <!------------------------------- FORM ELEMENTS ------------------------------->
      <div class="col1 light-scrollbar">
        <FormElements />
      </div>

      <!-- ************************************************************************-->

      <!------------------------------- PLAYGROUND ------------------------------->
      <div class="col2 light-scrollbar">
        <Playground @on-save="handleSave" />
        <!-- recieving on-save functn from playground & then calling handleSave method with formdata as default arg ie schema -->
      </div>

      <!-- ************************************************************************-->

      <!------------------------------- CUSTOM STYLES ------------------------------->
      <div class="col3 light-scrollbar">
        <CustomStyles @showProjectDesign="showProjectDesign" />
      </div>
      <lv-drawer v-model="showQuizOverviewDrawer" right :close="true" :shadow="true" :absolute="true" :width="520" :height="250" :zIndex="1000" :maxSpan="85" headerTitle="Quiz Overview" background="#F5F8FA" headerColor="#007474" headerBackground="#fff" headerFontSize="20px" padding="0px">
        <!-- <div class="quiz-drawer__header">
          <div>Quiz Overview</div>
        </div> -->
        <!-- Sections wrapper -->
        <div class="quiz-sections__wrapper">
          <section-row />
        </div>
        <hr />
        <div class="quiz-summary__wrapper">
          <QuizSummaryCard />
        </div>
      </lv-drawer>

      <lv-drawer v-model="showProjectDesignDrawer" right :close="true" :shadow="true" :absolute="true" :width="350" :height="250" :zIndex="1000" :maxSpan="85" headerTitle="Project Design" background="#F5F8FA" headerColor="#007474" headerBackground="#fff" headerFontSize="20px" padding="0px">
        <form-design />
      </lv-drawer>
    </div>
    <add-sections @showQuizOverview="showQuizOverview" />
  </div>
</template>

<script>
import FormElements from './FormElements.vue';
import Playground from './Playground.vue';
import CustomStyles from './CustomStyles.vue';
import FormDesign from './FormDesign.vue';
import AddSections from './AddSections.vue';
import builder from './store/index.js';
import { defaultSchema, defaultQuizSchema } from './store/defaultSchemas.ts';
import quizMixin from './mixins/computedMixins/isQuizModeEnabled.js';
import LvDrawer from 'lightvue/drawer';
import { SectionRow, QuizSummaryCard } from './sections-overview';
import deepClone from './utils/deepClone.ts';
export default {
  name: 'DigiForm',
  props: ['schema', 'isQuizSchema'],
  mixins: [quizMixin],
  //receving schema as prop from parent ie. Builder.vue
  components: {
    FormElements,
    Playground,
    CustomStyles,
    AddSections,
    LvDrawer,
    SectionRow,
    QuizSummaryCard,
    FormDesign,
  },
  data() {
    return {
      showQuizOverviewDrawer: false,
      showProjectDesignDrawer: false,
    };
  },

  methods: {
    handleSave(e) {
      this.$emit('on-save', e); //emitting on-save functn to parent ie. here editor.vue with e as arg which is basically schema as formdata
    },
    unregister() {
      window.removeEventListener('beforeunload', this.preventNav);

      this.$store.dispatch('builder/setSchema', {});
      this.$store.unregisterModule('builder');
    },
    preventNav(event) {
      if (JSON.stringify(this.schema) !== JSON.stringify(this.$store.state.builder.schema)) {
        event.preventDefault();
        event.returnValue = 'Your changes are unsaved';
      }
      return;
    },
    beforeRouteLeave(to, from, next) {
      if (JSON.stringify(this.schema) !== JSON.stringify(this.$store.state.builder.schema)) {
        if (!window.confirm('You have unsaved changes. Leave without saving?')) {
          return;
        }
      }
      next();
    },
    showQuizOverview() {
      this.showQuizOverviewDrawer = !this.showQuizOverviewDrawer;
    },
    showProjectDesign() {
      this.showProjectDesignDrawer = !this.showProjectDesignDrawer;
    },
  },
  watch: {
    schema() {
      if (this.schema) {
        this.$store.dispatch('builder/setSchema', deepClone(this.schema));
      }
    },
  },

  created() {
    this.$store.registerModule('builder', builder);
    if (this.isQuizSchema) {
      this.$store.dispatch('builder/setSchema', deepClone(defaultQuizSchema));
    } else {
      this.$store.dispatch('builder/setSchema', deepClone(defaultSchema));
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  // beforeDestroy() {
  //   /** Vue2 compatible only */
  //   this.unregister();
  // },
  beforeUnmount() {
    /** Vue3 compatible only */
    this.unregister();
  },
};
</script>

<style>
@import '~@lightvue/icons/dist/light-icon.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.builder-wrap {
  font-family: 'Roboto', sans-serif;
  height: calc(100vh - 56px);
}
#main {
  display: flex;
  max-height: calc(100% - 64px);
  min-height: calc(100% - 64px);
  /* height: calc(100% - 200px); */
  /* min-height: calc(100vh - 99px); */
  /* min-height: 100vh; */
  align-items: stretch;
}
#main > .col2 {
  /* flex: 1; */
  width: 60%;
  overflow-y: auto;
}
/* ---------- */
/* #main > .col1 {
  max-width: 25%;
  background-color: #dfe6e9;
} */
/* -------- */
#main > .col1,
#main > .col3 {
  width: 22%;
  flex: 0 0 22vw;
  overflow-y: auto;
  background-color: #dfe6e9;
}
.quiz-drawer__header {
  padding: 4px 8px;
  background-color: #dedede;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.quiz-sections__wrapper,
.quiz-summary__wrapper {
  padding: 12px 24px;
}

.headerBg {
  z-index: -1;
  opacity: 0.7;
  position: absolute;
  left: 0px;
}

footer {
  /* background: Linear-gradient(to Right, #35e79f, #103344); */
  /* height: 20vh; */
  z-index: 20;
  /* position: fixed; */
}
/* .footerBg {
  z-index: -1;
  opacity: 0.7;
  position: absolute;
  left: 100px;
} */

.fvl-input,
.fvl-color-picker {
  border-width: 0px;
}
</style>
