import firebase from 'firebase/app';
import 'firebase/firestore';
// import { FORM_ID_PREFIX as DOC_ID_PREFIX } from "../config/constants";
const DOC_ID_PREFIX = 'form';
import Users from './users';

interface field {
  field_type?: string;
  help_block?: boolean;
  help_block_text?: string;
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  placeholder_required?: boolean;
  required?: boolean;
}

interface row {
  config?: string;
  fields?: field[];
  row_id?: string;
}

interface section {
  id: string;
  name: string;
  heading: string;
  visiblity: boolean;
  hidden: boolean;
  config?: {};
  rows?: row[]; // array of rows
}

export interface schema {
  sections: section[];
  settings: {
    is_quiz: Boolean;
    form_heading: string;
    form_description: string;
    head_color: string;
    border_color: string;
    border_radius: string;
    border_width: boolean;
    form_header: boolean;
    section_heading_visibility: boolean;
    meta_info: {
      page_title: string;
      page_description: string;
      thumbnail_url: string;
    };
  }; // form properties
}
type formStatus = 'active' | 'draft' | 'published' | 'completed';
type security = 'public' | 'private';
type form_typeNew = 'form' | 'template';

export interface FormData {
  _id?: string;
  form_type?: form_typeNew;
  _date_added?: firebase.firestore.Timestamp;
  project_name?: string;
  form_slug?: string;
  _date_updated?: firebase.firestore.Timestamp;
  deleted?: boolean;
  [key: string]: any;
  author_id?: string;
  collaborators?: Array<string>;
  schema?: schema;
  status?: formStatus;
  req_end_date?: Boolean;
  end_date?: firebase.firestore.Timestamp;
  security?: security;
  total_responses?: Number;
  // max_responses?: Number;
  // multiple_responses?:Boolean,
  accepting_responses?: Boolean;
  not_accepting_res_msg?: String;
  highlighted_field_id?: {
    first: String;
    second: String;
  };
}

interface classArguments {
  snapshot?: firebase.firestore.DocumentSnapshot;
  id?: string; // Unique-ID (Alphanumeric only) to create new document [without prefix]
  docId?: string; // Document-ID with prefix, to create a new object of this class. [with prefix]
  data?: FormData; // Data to initialize the new object's data
}

export default class Forms {
  // Reference to the collection
  public static collectionRef(): firebase.firestore.CollectionReference {
    return firebase.firestore().collection('forms');
  }
  // Private variables
  private _id: string;
  private _data: FormData;
  private _unsaved_data: FormData;
  private _ref: firebase.firestore.DocumentReference;
  // private _snapshot: firebase.firestore.DocumentSnapshot;

  constructor(args: classArguments) {
    this._unsaved_data = {};
    if (args.snapshot) {
      // Initializing new object, with query (Snapshot)
      this._id = args.snapshot.id;
      this._data = { ...args.snapshot.data() };
      this._ref = args.snapshot.ref;
      // this._snapshot = snapshot;
    } else if (args.docId) {
      // Initializing new object, without new query
      this._ref = Forms.collectionRef().doc(args.docId);
      this._id = this._ref.id;
      this._data = args.data || {};
    } else {
      // Creating a new document
      if (!args.id) {
        args.id = Forms.collectionRef().doc().id;
      }
      this._ref = Forms.collectionRef().doc(`${DOC_ID_PREFIX}_${args.id}`);
      this._id = this._ref.id;
      this._data = args.data || {};
      // Initialize new document
      this.push({
        _date_added: firebase.firestore.Timestamp.fromMillis(+new Date()),
        _id: this._id,
        deleted: false,
        status: 'draft',
        security: 'private',
        // max_responses: 1000,
        total_responses: 0,
        // multiple_responses:false,
        form_type: args.data?.form_type || 'form',
        collaborators: [],
        accepting_responses: true,
        req_end_date: false,
        // form_slug: this._data.form_slug
        // end_date:this._data._date_added.setMonth(this._data._date_added.getMonth() + 1);
      });
    }
  }

  // Public methods Methods
  get data(): FormData {
    // Return complete data object
    return { ...this._data, ...this._unsaved_data };
    //._data already saved data on firestore which is private
  }

  public push(newData: FormData): FormData {
    this._unsaved_data = { ...this._unsaved_data, ...newData };
    return this.data; //calling above data method which Returns complete data object
  }
  public async create(): Promise<Forms> {
    await this._ref.set(this.data);
    return this;
  }
  public async save(): Promise<Forms> {
    this.push({
      _date_updated: firebase.firestore.Timestamp.fromMillis(+new Date()),
      //pushed date_updated in unsaved data
    });
    await this._ref.update(this._unsaved_data);
    return this;
  }

  public async endDate(): Promise<Forms> {
    // this._data.end_date=firebase.firestore.Timestamp.fromMillis(+new Date + 1)
    //         date=this._data._date_added
    // this._data.end_date: this._data._date_added.setMonth(this._data._date_added.getMonth() + 1);
    // console.log();
    this.push({
      end_date: firebase.firestore.Timestamp.fromMillis(+new Date() + 1),
      //pushed date_updated in unsaved data
    });
    await this._ref.update(this._unsaved_data);
    return this;
  }

  // public static getByData(docData: FormData): Form {
  //     // return new object, without any network request
  //     return new Form({
  //         docId: docData._id,
  //         data: docData
  //     })
  // }

  public static getById(docId: string): Promise<Forms> {
    return new Promise((resolve, reject) => {
      Forms.collectionRef()
        .doc(docId)
        .get()
        .then((snapshot: firebase.firestore.DocumentSnapshot) => {
          if (snapshot.exists) return resolve(new Forms({ snapshot }));
          else {
            throw new Error(`No document found with id: ${docId}`);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public static getByAuthor(authorId: string): Promise<Forms[]> {
    return new Promise((resolve, reject) => {
      Forms.collectionRef()
        .where('author_id', '==', authorId)
        .where('deleted', '==', false)
        .orderBy('_date_added', 'desc')
        .onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            // throw new Error("No form found")
            reject('No Form Found');
            // .get() //fetching all forms of particular user based on userId
            // .then.((querySnapshot: firebase.firestore.QuerySnapshot) => {
            //     if (querySnapshot.docs.length === 0) {
            //         throw new Error("No form found")
          }
          //if user has created some forms then then we are looping through them all & retuning each form as new Form object
          else {
            return resolve(querySnapshot.docs.map(doc => new Forms({ snapshot: doc })));
          }
        });

      // .catch((err) => {
      //     reject(err)
      // })
    });
  }

  // public static getCollabFormsByAuthor(authorId: string): Promise<Forms[]> {
  //   return new Promise((resolve, reject) => {
  //     Forms.collectionRef()
  //       .where('collaborators', 'array-contains', authorId)
  //       .where('deleted', '==', false)
  //       .orderBy('_date_added', 'desc')
  //       .onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
  //         if (querySnapshot.docs.length === 0) {
  //           reject('No Form Found');
  //         }
  //         //if user has created some forms then then we are looping through them all & retuning each form as new Form object
  //         else {
  //           return resolve(querySnapshot.docs.map(doc => new Forms({ snapshot: doc })));
  //         }
  //       });
  //   });
  // }

  public static getCollabFormsByEmail(email_id: string): Promise<Forms[]> {
    return new Promise((resolve, reject) => {
      Forms.collectionRef()
        .where('collaborators', 'array-contains', email_id)
        .where('deleted', '==', false)
        .orderBy('_date_added', 'desc')
        .onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            reject('No Form Found');
          }
          //if user has created some forms then then we are looping through them all & retuning each form as new Form object
          else {
            return resolve(querySnapshot.docs.map(doc => new Forms({ snapshot: doc })));
          }
        });
    });
  }

  public static getPublicTemplates(): Promise<Forms[]> {
    return new Promise((resolve, reject) => {
      Forms.collectionRef()
        .where('verified', '==', true)
        .where('deleted', '==', false)
        .where('security', '==', 'public')
        .orderBy('_date_added', 'desc')
        .onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
          if (querySnapshot.docs.length === 0) {
            reject('No Template Found');
          } else {
            return resolve(querySnapshot.docs.map(doc => new Forms({ snapshot: doc })));
          }
        });

      // .catch((err) => {
      //     reject(err)
      // })
    });
  }

  public static getBySlug(slug: string): Promise<Forms> {
    return new Promise((resolve, reject) => {
      Forms.collectionRef()
        .where('form_slug', '==', slug || '')
        .get()
        .then((snapshot: firebase.firestore.QuerySnapshot) => {
          if (snapshot.docs.length === 0) {
            throw new Error('No Form found with this slug');
          }
          if (snapshot.docs.length > 1) {
            throw new Error('Multiple Forms found with this slug');
          } else {
            resolve(new Forms({ snapshot: snapshot.docs[0] }));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public static async updateTotalResponse(form_id: string, count: number) {
    try {
      await Forms.collectionRef()
        .doc(form_id)
        .update({
          total_responses: firebase.firestore.FieldValue.increment(count || 1),
        });
    } catch (err) {
      console.log(err);
    }
  }

  public static async updateMetaSttings(form_id: string, seoObj: Object) {
    try {
      await Forms.collectionRef()
        .doc(form_id)
        .set({ schema: { settings: { meta_info: seoObj } } }, { merge: true });
    } catch (err) {
      console.log(err);
    }
  }

  public static async deleteForm(author_id: string, _id: string) {
    try {
      var currentForm_ref = Forms.collectionRef()
        .where('author_id', '==', author_id)
        .where('_id', '==', _id);
      currentForm_ref.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          doc.ref.update({
            deleted: true,
          }),
            { merge: true };
          console.log('FORM DELETED SUCCESSFULLY');
        });
      });
    } catch (error) {
      console.error('Error removing document: ', error);
    }
  }

  public static async isSlugAvailable(form_slug: string) {
    const snapshot = await Forms.collectionRef()
      .where('form_slug', '==', form_slug)
      .get();
    if (snapshot.docs.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  public static async directlyUpdate(form_id: string, field_key: string, field_value: any) {
    try {
      await Forms.collectionRef()
        .doc(form_id)
        .set({ [field_key]: field_value }, { merge: true });
    } catch (err) {
      console.log(err);
    }
  }

  public static async addCollaborator(formId: string, email: string) {
    try {
      const collabUser = await Users.getByEmail(email);
      await Forms.collectionRef()
        .doc(formId)
        .update({
          collaborators: firebase.firestore.FieldValue.arrayUnion(collabUser.data.email_id),
        });

      return { status: 'Collaborator added.' };
    } catch (err) {
      if (err == 'Error: No user found') {
        // REQUESTED COLLAB. DOES NOT EXIST, SO WE SEND HIM SIGNUP EMAIL, AND NOTE DOWN THIS REQUEST IN A SEPARATE COLLECTION
        await Forms.collectionRef()
          .doc(formId)
          .update({
            collaborators: firebase.firestore.FieldValue.arrayUnion(email),
          });

        let userDoc = await firebase
          .firestore()
          .collection('pendingCollaborators')
          .doc(email)
          .get();
        if (!userDoc.exists)
          await userDoc.ref.set({
            email,
            forms: [formId],
          });
        else
          await userDoc.ref.update({
            forms: firebase.firestore.FieldValue.arrayUnion(formId),
          });

        return { status: 'Invite sent.' };
      } else {
        console.log('Error: ', err);
        return { status: err };
      }
    }
  }

  public static async removeCollaborator(formId: string, email: string) {
    try {
      await Forms.collectionRef()
        .doc(formId)
        .update({
          collaborators: firebase.firestore.FieldValue.arrayRemove(email),
        });

      let pendingDoc: any = await firebase
        .firestore()
        .collection('pendingCollaborators')
        .doc(email)
        .get();
      if (pendingDoc.data().forms.length <= 1) await pendingDoc.ref.delete();
      else
        await firebase
          .firestore()
          .collection('pendingCollaborators')
          .doc(email)
          .update({
            forms: firebase.firestore.FieldValue.arrayRemove(formId),
          });
      return { status: 'Collaborator removed.' };
    } catch (err) {
      console.log('Error: ', err);
      return { status: err };
    }
  }
}
