<template>
  <div>
    <div v-if="loading">
      <lv-list-loader :width="300" :height="100" style="margin: 128px auto; display: block;" />
    </div>
    <preview-module v-else-if="formData.accepting_responses" :schema="formData.schema" mode="preview">
      <!-- currentDate < form_end_date && -->
      <!-- Receiving @submit from previewModule from builder -->
    </preview-module>
    <FormClosed v-else :formData="formData" :customizedMsg="formData.not_accepting_res_msg"></FormClosed>
  </div>
</template>
<script>
import PreviewModule from 'builder/PreviewModule.vue';
import Forms from '@main/models/form_schema';
import Response from '@main/models/responses';
import Responses from '@main/models/responses';
import { uid } from '@main/utils';
import config from '@main/config/index.ts';
import FormClosed from './FormClosed';

import LvListLoader from 'lightvue/skeleton/preset/ListLoader.vue';
export default {
  components: {
    PreviewModule,
    FormClosed,
    LvListLoader,
  },
  created() {
    this.getFormSchema();
    this.currentDate = +new Date() / 1000;
  },
  data() {
    return {
      loading: false,
      formData: {},
      form_id: '',
      form_end_date: '',
      currentDate: '',

      // responseValue: "",
    };
  },

  methods: {
    async getFormSchema() {
      this.loading = true;
      if (this.$route.query.id || this.$route.params.form_slug) {
        this.form_slug = this.$route.query.id || this.$route.params.form_slug;
        console.log('checking', this.form_slug);

        //getting forms based on slug
        var form = await Forms.getBySlug(this.form_slug);
        if (form && form.data && form.data.schema) {
          this.formData = form.data;
          this.form_id = form.data._id;
          console.log('formData Based on Slug', this.formData);
          this.form_end_date = this.formData.end_date.seconds;
          // console.log(this.formData.end_date.seconds,((+new Date)/1000))
        }
      }
      this.loading = false;
    },
    // After Pressing Submit Button
    onSubmit(submitFormValue) {
      console.log(submitFormValue);
      // let formSchema = this.$store.state.builder.schema;
      // if (this.formData.status === 'published') {
      // this.handleSubmit(submitFormValue);
      // }
    },
    async zapResponseFormat(responseId, response) {
      let zapResponse = [];
      let responseObject = [];
      responseObject = this.submitFormValue;
      let formSchema = this.formData.schema;

      formSchema.sections.forEach((section, sectionIndex) => {
        section.rows.forEach((row, rowIndex) => {
          row.fields.forEach((field, fieldIndex) => {
            zapResponse.push({
              id: field.id,
              label: field.label,
              value: response[field.id], // handle undefined case.
              type: field.field_type,
            });
          });
        });
      });

      console.log('ZAP RESPONSE', zapResponse);
      let zapResponseObj = {
        res_id: responseId,
        time_submission: +new Date(),
        form_id: this.form_id,
        project_name: this.formData.project_name,
        author_id: this.formData.author_id,
        response: zapResponse,
      };

      fetch(`${config.api_base_url}/response`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(zapResponseObj),
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(response) {
          console.log(response);
        })
        .catch(function(e) {
          console.error(e);
        });

      console.log('Zap Response Object', zapResponseObj);
    },
  },
};
</script>

<style></style>
