<template>
  <div>
    <form-parent @on-save="handleSave" :schema="formData.schema" ref="editor" />
    <!-- receiving on-save functn from child  ie. FormParent.vue with schema as arg & then calling handleSave method -->
    <!-- we also passing already saved schema(from firestore) as props to child ie. FormParent.vue
    so that user can see his previous work-->
    <div class="preview-wrap" v-if="$route.name === 'Preview'">
      <router-view></router-view>
    </div>
    <div class="publish-wrap" v-if="$route.name === 'Publish'">
      <router-view></router-view>
    </div>
    <div class="settings-wrap" v-if="$route.name === 'form-settings'">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import FormParent from 'builder/FormParent.vue';
import Forms from '@main/models/form_schema';
import { userMixin, currentFormMixin } from '@main/mixins';

export default {
  mixins: [userMixin, currentFormMixin],
  data() {
    return {
      formData: {},
      form_slug: '',
    };
  },
  components: { FormParent },
  created() {
    this.getSchema();
  },

  methods: {
    async getSchema() {
      if (this.$route.query.id) {
        this.form_slug = this.$route.query.id;
        // getting forms based on slug
        try {
          var form = await Forms.getBySlug(this.form_slug);
          if (form && form.data && form.data.schema && form.data.author_id) {
            // validating form_slug
            if (form.data.author_id === this.user._id || form.data.collaborators?.includes(this.user.email_id)) {
              // validating permissions
              this.formData = form.data;
              this.currentForm = {
                ...form.data,
              };
              return;
            } else {
              // this.$router.replace({name: 'Dashboard'});
            }
          } else {
            // this.$router.replace({name: 'Dashboard'});
          }
        } catch (err) {
          console.error(err);
        }
      }
      this.$router.replace({ name: 'Dashboard' });
    },
    handleSave(e) {
      // handling this event from navbar save button. No more needed here.
    },
  },
  beforeRouteLeave(to, from, next) {
    return this.$refs.editor.beforeRouteLeave(to, from, next);
  },
};
</script>

<style scoped>
.preview-wrap,
.publish-wrap,
.settings-wrap {
  position: fixed;
  top: 56px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 56px);
  overflow: auto;
  z-index: 9999;
  background-color: #f5f8fa;
  /* transform: translateY(-4); */
  /* bg white */
  transition: transform 2s ease;
}
</style>
