<template>
  <div class="QuizPage-cont">
    <div class="qp-leftPanel">
      <div class="qp-main-content">
        <div class="coming-soon-block">
          <div class="coming-soon-letter-card"><h1>C</h1></div>
          <div class="coming-soon-letter-card"><h1>O</h1></div>
          <div class="coming-soon-letter-card"><h1>M</h1></div>
          <div class="coming-soon-letter-card"><h1>I</h1></div>
          <div class="coming-soon-letter-card"><h1>N</h1></div>
          <div class="coming-soon-letter-card"><h1>G</h1></div>
          <div class="coming-soon-letter-card"><h1>:</h1></div>

          <div class="coming-soon-letter-card"><h1>S</h1></div>
          <div class="coming-soon-letter-card"><h1>O</h1></div>
          <div class="coming-soon-letter-card"><h1>O</h1></div>
          <div class="coming-soon-letter-card"><h1>N</h1></div>
        </div>

        <!-- <div class="qp-main-head">Under Construction</div> -->
        <div class="qp-subhead">
          We our currently working on it.Hopefully we will be live with it soon. Thank You for your patience.
        </div>
        <div class="fs-coming-soon_btn">
          <lv-button label="Notify Me!!" />
        </div>
        <!-- <div class="qp-input-block"> -->
        <!-- <lv-input type="text" placeholder="Enter Your Email here..."> -->
        <!-- <template #append>
              <div class="qp-notify-btn">Notify me!</div>
            </template>
          </lv-input>
        </div>
        <div class="qp-bottom-description">
          Sign up now to get early notification of our launch date!
        </div> -->
      </div>

      <!-- Form Section Ends -->
    </div>
    <div class="qp-rightPanel"></div>
  </div>
</template>

<script>
import LvInput from 'lightvue/input';
import LvButton from 'lightvue/button';
export default {
  components: {
    LvInput,
    LvButton,
  },

  data() {
    return {
      email: '',
    };
  },
};
</script>

<style scoped>
.QuizPage-cont {
  display: flex;
}

.qp-leftPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #626568; */
}

.coming-soon-block {
  display: flex;

  /* justify-content: center; */
}

.coming-soon-letter-card {
  background: #fff;
  /* height: 88px; */
  width: 60px;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid hsl(198deg 5% 92%);
  margin-right: 6px;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); */
}

.soon-circle {
  /* margin: 180px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 6px solid #5cd0b9;
  width: 220px;
  height: 220px;
  border-radius: 12%;
  text-align: center;
  cursor: pointer;
  background-image: url('../assets/pattern.png');

  background-color: #38b2ac;
  background-repeat: repeat;
  box-shadow: 0px 6px 15px -1px #5cd0b9;
}
.button-1 {
  padding: 10px;
  border-radius: 8px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 40px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  background-color: #5cd0b9;
  box-shadow: 0px 6px 15px -1px #5cd0b9;
  cursor: pointer;
}

.button-1:hover {
  background-color: #55bda8;
}

.circle-content {
  padding: 40px;
  font-size: 24px;
  font-weight: 800;
  /* color: #464c52; */
  color: #fff;
}

.soon-circle:hover {
  /* background-color: #55bda8; */
  border: 6px solid #55bda8;
  color: #fff;
}

.qp-main-content {
  margin-top: 52px;
  padding: 40px;
  color: #203d4a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fs-coming-soon_btn {
  margin-top: 20px;
}

.qp-main-head {
  font-size: 40px;
  font-weight: 800;
}
.qp-subhead {
  margin-top: 28px;
}
.qp-input-block {
  margin: 36px 0px 16px 0px;
}
.qp-notify-btn {
  padding: 6px;
  background-color: #337ab7;
  color: #fff;
  cursor: pointer;
}
</style>
