<template>
  <div>
    <h3 v-if="showHeader == true" class="response__single-chart__heading">
      Responses for <span>{{ fieldLabel }}</span>
    </h3>
    <component :is="chartType" :datasets="datasets" :labels="responseAnswers" :height="220"></component>
  </div>
</template>

<script>
import { Bar, Doughnut, Pie, Horizontalbar, Line, PercentChart } from 'lightvue/chart';

export default {
  components: {
    Bar,
    Doughnut,
    Pie,
    Horizontalbar,
    Line,
    PercentChart,
  },

  props: ['responses', 'chartType', 'fieldLabel', 'fieldId', 'showHeader'],

  data() {
    return {
      // x-axis labels is dateArray here
      responseAnswers: [],
      responseMap: {},
      datasets: [
        {
          label: 'Reponses',
          // responseAnswerCount value array lies in 'data[]' below
          data: [],
          backgroundColor: ['#203d4abb', '#037f7fbb', '#203d4aaa', '#037f7faa', '#203d4a80', '#037f7f80', '#203d4abb', '#037f7fbb', '#203d4aaa', '#037f7faa', '#203d4a80', '#037f7f80', '#203d4abb', '#037f7fbb', '#203d4aaa', '#037f7faa', '#203d4a80', '#037f7f80', '#203d4abb', '#037f7fbb', '#203d4aaa', '#037f7faa', '#203d4a80', '#037f7f80'],
        },
      ],
    };
  },
  created() {
    this.resetData();
    this.prepareResponseMap();
    this.populateChart();
  },
  watch: {
    fieldId() {
      this.resetData();
      this.prepareResponseMap();
      this.populateChart();
    },
  },

  methods: {
    resetData() {
      this.responseMap = {};
      this.datasets[0].data = [];
      this.responseAnswers = [];
    },
    prepareResponseMap() {
      this.responses.forEach(resp => {
        let currentResponse = resp.response[this.fieldId] || 'Unanswered';
        if (this.chartType == 'Doughnut' && currentResponse == 'Unanswered') currentResponse = 'false';
        if (this.responseMap[currentResponse]) this.responseMap[currentResponse]++;
        else this.responseMap[currentResponse] = 1;
      });
    },
    populateChart() {
      for (let answerKey in this.responseMap) {
        this.responseAnswers.push(answerKey);
        this.datasets[0].data.push(this.responseMap[answerKey]);
      }
    },
  },
};
</script>

<style>
.response__single-chart__heading {
  color: #203d4aed;
  background-color: white;
  padding: 0px 0px;
  border-radius: 8px;
  font-weight: normal;
  margin: 20px 0px;
}

.response__single-chart__heading span {
  color: #06c2c2;
  font-weight: bold;
  /* text-decoration: underline; */
}
</style>
