<template>
  <li class="nav-item" v-if="$route.name === 'Editor' || $route.name === 'Create'" style="width: 140px; text-align: center" @click="handleSave">
    <loader v-if="saving" />
    <span v-else> Save &amp; Preview </span>
  </li>
</template>

<script>
import Forms from '@main/models/form_schema';
import { getSlug } from '@main/utils';
import { userMixin, currentFormMixin } from '@main/mixins';
import Loader from '@main/components/Loader.vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { uid } from '@main/utils/uid';

export default {
  mixins: [userMixin, currentFormMixin],
  components: {
    Loader,
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    async handleSave() {
      let newFormSchema = this.$store.state.builder.schema;

      this.saving = true;
      // let form_slug = this.$route.query.id;
      try {
        /*---------updating already exiting form--------------------------- */
        if (this.currentForm && this.$route.query.id) {
          var newForm = new Forms({
            docId: this.currentForm._id,
          });

          newForm.push({
            schema: newFormSchema,
            project_name: this.currentForm.project_name,
            // currentSection: 0,
          }); //push will save the data locally in unsaved_data
          await newForm.save(); //save will save the data on firestore by pushing unsaved_data & updating date
          console.log('form saved', newForm);
          // ----------------------
          this.saving = false;
          this.$router.push({
            name: 'Preview',
            // query: { id: this.form_slug },
            query: { id: this.$route.query.id },
          });
        } else {
          /* -------------------------creating new form-------------------------- */

          let form_slug = getSlug(this.currentForm.project_name + '-' + uid().substring(0, 4));
          console.log('FORM_SLUG', form_slug);

          var date = new Date();
          date.setMonth(date.getMonth() + 1);
          console.log(date);
          var form = new Forms({
            //initializing new Form object
            data: {
              schema: newFormSchema,
              author_id: this.user._id,
              form_slug: form_slug,
              end_date: '', //firebase.firestore.Timestamp.fromMillis(+date)
              project_name: this.currentForm.project_name,
            },
          });
          await form.create();
          console.log('form saved', form);
          //  -------------------
          this.saving = false;
          this.$router.push({
            name: 'Preview',
            query: { id: form_slug },
            // query: { id: this.$route.query.id },
          });
        }
      } catch (err) {
        console.warn(err);
        setTimeout(() => {
          this.saving = false;
        }, 1000);
        //
      }
    },
  },
};
</script>
