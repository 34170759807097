<template>
  <div>
    <div class="setting-header">
      <div class="top-heading">
        <!-- <div class="setting-icon"><i class="light-icon-settings"></i></div> -->
        <div v-if="currentForm.form_type === 'form'" class="setting-title">Template Details</div>
      </div>
      <!-- <div class="setting-description">
        Setup your project details, change your project's unique URL, set a
        project end date and more
      </div>-->
    </div>
    <div class="settings__card" style="margin-top:0px">
      <lv-input v-model="templateLocalName" label="Template Name"></lv-input>

      <br />

      <!-- <lv-input v-model="templateLocalUrl" label="Template Url"></lv-input> -->
      <!-- Template URL Block -->
      <div class="slug-validation-msg">{{ feedback }}</div>
      <div class="project-url-block">
        <div>
          <lv-input v-model="computedSlug" @change="checkSlug(computedSlug)" label="Template URL">
            <template #prepend>
              <div class="preview-base-url">{{ templateURL }}</div>
            </template>
            <template #append v-if="slug_status">
              <div class="slug-status --success" v-if="slug_status === 'available'" title="available">
                <i class="light-icon-circle-check" />
              </div>
              <div class="slug-status --danger" v-else-if="slug_status === 'not-available'" title="not available">
                <i class="light-icon-circle-x" />
              </div>
              <div class="slug-status" v-else>
                <i class="light-icon-target" />
              </div>
            </template>
          </lv-input>
        </div>
      </div>

      <!--Template Url Block ends  -->
      <br />
      <div class="template-modal-footer">
        <lv-button v-if="currentForm.form_type === 'form'" class="lv-success" @click="saveAsTemplate()" label="Create new Template"></lv-button>
        <lv-button v-if="currentForm.form_type === 'template'" class="lv-success" @click="updateTemplateSetting()" label="Save"></lv-button>
        <div class="template-save-msg">
          {{ saveMessage }}
          <span>
            <i v-if="templateSaveLoading == false" class="light-icon-circle-check"></i>
            <i v-if="templateSaveLoading == true" class="light-icon-target"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LvButton from 'lightvue/button';

import LvInput from 'lightvue/input';
import Forms from '../../models/form_schema';
import { TEMPLATE_BASE_URL } from '../../config/constants';
import { getSlug } from '@main/utils';

import config from '@/config';

export default {
  components: {
    LvInput,
    // LvButton,
  },
  data() {
    return {
      templateLocalName: this.currentForm.form_type === 'template' ? this.currentForm.project_name : 'Template ' + this.currentForm.project_name,
      templateLocalUrl: this.currentForm.form_type === 'template' ? this.currentForm.form_slug : 'template-' + this.currentForm.form_slug,
      project_name: '',
      form_slug: '',
      slug_status: '', // 'checking', 'available', 'not-available'
      // editedSlugValue: "",
      templateURL: config.link_base_url + '/template/',
      name: '',
      accept: false,
      endDate: '',
      feedback: null,
      saveMessage: '',
      templateSaveLoading: null,
    };
  },
  props: ['currentForm'],
  computed: {
    computedSlug: {
      get: function() {
        return this.templateLocalUrl || getSlug(this.templateLocalName || '');
      },
      set: function(newValue) {
        this.templateLocalUrl = getSlug(newValue);
      },
    },
  },
  methods: {
    async saveAsTemplate() {
      let newData = {
        schema: this.currentForm.schema,
        author_id: this.currentForm.author_id,
        project_name: this.templateLocalName,
        form_slug: this.templateLocalUrl,
        form_type: 'template',
        deleted: false,
      };
      // console.log(this.currentForm.schema);
      // debugger
      delete newData['_id'];
      const temp_form = new Forms({
        data: newData,
      });
      await temp_form.create();
      console.log('Saved as Template ');
      this.$router.push({
        name: 'Dashboard',
      });
      // console.log("temp_form",temp_form);
    },

    //  Check unique Slug Method
    async checkSlug(form_slug) {
      this.slug_status = 'checking';
      let availability = await Forms.isSlugAvailable(form_slug);
      if (availability || form_slug === this.currentForm.form_slug) {
        this.slug_status = 'available';
        // this.feedback = "Slug Available";
      } else {
        this.slug_status = 'not-available';
        // this.feedback = "Not Available";
      }
      console.log(form_slug, availability);
    },

    async updateTemplateSetting() {
      this.templateSaveLoading = true;
      // console.log('Before updating Template data', this.currentForm);
      if (this.currentForm && this.$route.query.id) {
        var form3 = new Forms({
          // data: this.currentForm,
          docId: this.currentForm._id,
        });

        form3.push({
          form_slug: this.templateLocalUrl,
          project_name: this.templateLocalName,
        }); //push will save the data locally in unsaved_data
        await form3.save(); //save will save the data on firestore by pushing unsaved_data & updating date
        this.saveMessage = 'Saved';
        this.templateSaveLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.preview-base-url {
  display: flex;
  align-items: center;
  padding-left: 12px;
  margin-right: -8px !important;
  font-size: 14px;
  margin-bottom: 3px;
}

.slug-validation-msg {
  color: red;
  font-size: 12px;
}
.slug-status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  color: rgba(0, 0, 0, 0.2);
  i {
    font-size: 20px;
    font-weight: 600;
  }
  &.--success {
    color: teal;
  }
  &.--danger {
    color: rgb(216, 36, 36);
  }
}

.template-modal-footer {
  display: flex;
}

.template-save-msg {
  display: flex;
  align-items: center;
  color: teal;
  padding: 12px;
}
.template-save-msg span {
  margin-left: 4px;
}
.template-save-msg i {
  // padding: 12px;
  color: teal;
  font-size: 20px;
}
</style>
