<template>
  <!-- RURBAN -->
  <!----------- Recent Forms ---- ----------- -->
  <div class="bottom-section">
    <div class="recent-forms">
      <div class="recent-forms__header">
        <!-- Title row -->
        <div class="title-row">
          <div class="light-bg-btn" :class="{ active: activeStateTab === 'recent' }" @click="activeStateTab = 'recent'">
            <p>Recent Forms</p>
          </div>
          <!-- <div class="light-bg-btn" :class="{ active: activeStateTab === 'shared' }" @click="activeStateTab = 'shared'">
            <p>Shared Forms</p>
          </div>
          <div class="light-bg-btn" :class="{ active: activeStateTab === 'template' }" @click="activeStateTab = 'template'">
            <p>My Templates</p>
          </div> -->
        </div>
        <div class="form-utils">
          <!-- <div class="select-box">
            <select name id>
              <option value>All Forms</option>
              <option value>Uncategorized Forms</option>
              <option value>Deleted Forms</option>
            </select>
          </div>-->
          <!-- --Search Bar----- -->

          <div class="searchContainer">
            <div class="search-bar">
              <lv-input rounded icon-right="light-icon-search" label="Search" floating-label placeholder="Search" />
            </div>
          </div>
        </div>
      </div>
      <!-- -----Search bar Ends-------- -->
      <!-------------------- MY FORMS CARDS ------------------>
      <div class="recent-form-wrap light-scrollbar--none" v-if="activeStateTab === 'recent'">
        <div class="card-shimmer__wrapper" v-if="loading === true && recent_list.length === 0">
          <card-shimmer v-for="n in 3" :key="n" />
        </div>
        <!-- --------NO FORMS------ -->
        <div class="no-forms-div" v-else-if="loading === false && recent_list.length == 0">
          <div class="no-forms">
            <div>No records</div>
            <i class="light-icon-clipboard-x"></i>
          </div>
        </div>
        <!-- Form Cards -->
        <div v-for="form in recent_list" :key="form.data._id" class="form-card" @click="openResponses(form.data.form_slug)">
          <!-- <FormCards :form="form" :activeStateTab="activeStateTab" ></FormCards> -->

          <div class="form-img-cont" @click.stop :class="{ '--template': form.data.form_type === 'template' }">
            <div class="form-card__actions">
              <!-- <RfDropdown :author_id="form.data.author_id" :currentForm_id="form.data._id" :formSlug="form.data.form_slug" @preview="openFormLink(form.data.form_slug)" @deleted="handleDelete(form.data._id)" /> -->
              <!-- <i class="light-icon-dots"></i> -->
            </div>
            <div class="status__tag__wrap">
              <div v-if="form.data.form_type === 'form'" class="status-tag">{{ form.data.status }}</div>
              <div class="status-tag" v-if="form.data.form_type === 'template'">{{ form.data.form_type }}</div>
            </div>
          </div>
          <div class="form-card-content">
            <div class="form-card-content-div">
              <div class="form-card__title">
                <span>{{ form.data.project_name }}</span>
                <!-- <span v-if="form.data.status === 'published'" class="link-btn" @click.stop="openFormLink(form.data.form_slug)">
                  <i class="light-icon-external-link"></i>
                </span> -->
              </div>
            </div>
            <div class="form-card__footer">
              <div class="form-response" v-if="form.data.form_type === 'form'" @click.stop="openResponses(form.data.form_slug)">
                <span>{{ form.data.total_responses || 0 }} Responses</span>
                <!-- {{ this.responseCount }} -->
              </div>
              <!-- <div v-if="form.data.collaborators?.length > 0" class="collab-btn" @click.stop="$router.push({ name: 'form-settings', query: { id: form.data.form_slug } })">
                <i class="light-icon-users"></i>
                <span>{{ form.data.collaborators?.length }}</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Card Ends -->

      <!-------------------- COLLAB CARDS ------------------>
      <div class="recent-form-wrap light-scrollbar--none" v-if="activeStateTab === 'shared'">
        <div class="card-shimmer__wrapper" v-if="loading === true && collab_list.length === 0">
          <card-shimmer v-for="n in 3" :key="n" />
        </div>
        <!-- --------NO COLLAB FORMS------ -->
        <div class="no-forms-div" v-else-if="loading === false && collab_list.length == 0">
          <div class="no-forms">
            <div>No records</div>
            <i class="light-icon-clipboard-x"></i>
          </div>
        </div>
        <!-- COLLAB Form Cards -->
        <div v-for="form in collab_list" :key="form.data._id" class="form-card" @click="$router.push({ name: 'Editor', query: { id: form.data.form_slug } })">
          <!-- <FormCards :form="form" :activeStateTab="activeStateTab" ></FormCards> -->

          <div class="form-img-cont" @click.stop :class="{ '--template': form.data.form_type === 'template' }">
            <div class="form-card__actions">
              <RfDropdown :author_id="form.data.author_id" :currentForm_id="form.data._id" :formSlug="form.data.form_slug" @preview="openFormLink(form.data.form_slug)" @deleted="handleDelete(form.data._id)" />
              <!-- <i class="light-icon-dots"></i> -->
            </div>
            <div class="status__tag__wrap">
              <div v-if="form.data.form_type === 'form'" class="status-tag">{{ form.data.status }}</div>
              <div class="status-tag" v-if="form.data.form_type === 'template'">{{ form.data.form_type }}</div>
            </div>
          </div>
          <div class="form-card-content">
            <div class="form-card-content-div">
              <div class="form-card__title">
                <span>{{ form.data.project_name }}</span>
                <span v-if="form.data.status === 'published'" class="link-btn" @click.stop="openFormLink(form.data.form_slug)">
                  <i class="light-icon-external-link"></i>
                </span>
              </div>
            </div>
            <div class="form-card__footer">
              <div class="form-response" v-if="form.data.form_type === 'form'" @click.stop="openResponses(form.data.form_slug)">
                <span>{{ form.data.total_responses || 0 }} Responses</span>
                <!-- {{ this.responseCount }} -->
              </div>
              <div v-if="form.data.collaborators?.length > 0" class="collab-btn" @click="$router.push({ name: 'form-settings', query: { id: form.data.form_slug } })">
                <i class="light-icon-users"></i>
                <span>{{ form.data.collaborators?.length }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- COLLAB Card Ends -->
    </div>

    <!-- MY TEMPLATES ---------- -->
    <div class="recent-form-wrap light-scrollbar--none" v-if="activeStateTab === 'template'">
      <div class="card-shimmer__wrapper" v-if="loading === true && form_list.length === 0">
        <card-shimmer v-for="n in 3" :key="n" />
      </div>
      <!-- --------NO FORMS------ -->
      <div class="no-forms-div" v-else-if="loading === false && form_list.length == 0">
        <div class="no-forms">
          <div>No Templates</div>
          <i class="light-icon-clipboard-x"></i>
        </div>
      </div>
      <!----------------------------------------- Template Cards ---------------------------->
      <div v-for="form in template_list" :key="form.data._id" class="form-card" @click="$router.push({ name: 'Editor', query: { id: form.data.form_slug } })">
        <!-- <FormCards :form="form" :activeStateTab="activeStateTab" ></FormCards> -->

        <div class="form-img-cont" @click.stop :class="{ '--template': form.data.form_type === 'template' }">
          <div class="form-card__actions">
            <RfDropdown :author_id="form.data.author_id" :currentForm_id="form.data._id" :formSlug="form.data.form_slug" :project_name="form.data.project_name" @preview="openFormLink(form.data.form_slug)" @deleted="handleDelete(form.data._id)" />
            <!-- <i class="light-icon-dots"></i> -->
          </div>
        </div>
        <div class="form-card-content">
          <div class="form-card__title">
            <span>{{ form.data.project_name }}</span>
            <div v-if="form.data.form_type === 'form'" class="status-tag">{{ form.data.status }}</div>
            <div class="status-tag">{{ form.data.form_type }}</div>
          </div>
          <div class="form-card__footer">
            <!-- <div class="form-response" @click.stop="openResponses(form.data.form_slug)"> -->
            <!-- <span>{{ form.data.total_responses || 0 }} Responses</span> -->
            <!-- {{ this.responseCount }} -->
            <!-- </div> -->
            <div v-if="form.data.status === 'published'" class="link-btn" @click.stop="openFormLink(form.data.form_slug)">
              <i class="light-icon-external-link"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Template card ends -->
  </div>

  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import RfDropdown from './RfDropdown.vue';
import LvInput from 'lightvue/input';
import Forms from '@main/models/form_schema';
import { userMixin, currentFormMixin } from '@main/mixins';
import config from '@/config/index.ts';
// import FormCards from './FormCards';

import CardShimmer from './CardShimmer.vue';
export default {
  mixins: [userMixin, currentFormMixin],
  components: {
    LvInput,
    RfDropdown,
    CardShimmer,
    // FormCards,
  },

  data() {
    return {
      recent_list: [],
      form_list: [],
      collab_list: [],
      template_list: [],
      loading: false,
      activeStateTab: 'recent',
    };
  },
  created() {
    //mount hone se phele hi we shud have user forms
    this.getForms();
    this.getCollabForms();
    // console.log("User details in recent forms", this.user);
  },
  methods: {
    async getForms() {
      this.loading = true;
      try {
        this.form_list = await Forms.getByAuthor(this.user._id);
        let form_number = this.form_list.length;
        this.user.forms_count = form_number;
        this.form_list.forEach(form => {
          if (form.data.form_type === 'template') {
            this.template_list.push(form);
          }
        });
      } finally {
        this.loading = false;
      }
      this.recent_list = [...this.form_list];
    },
    async getCollabForms() {
      this.loading = true;
      try {
        this.collab_list = await Forms.getCollabFormsByEmail(this.user.email_id);
      } finally {
        this.loading = false;
      }
      if (this.collab_list) {
        this.recent_list = [...this.recent_list, ...this.collab_list];
      }
    },
    openFormLink(form_slug) {
      // let user_slug = getSlug(this.user.name);

      let url = config.link_base_url + '/p/' + form_slug;
      window.open(url, '_blank');
      // this.$router.push({name:'Submit', params:{form_slug}})
    },
    openResponses(form_slug) {
      this.$router.push(`/response/${form_slug}`);
    },

    handleDelete(currentForm_id) {
      let removedIndex = this.recent_list.findIndex(form => form.data._id === currentForm_id);
      if (removedIndex !== -1) {
        this.recent_list.splice(removedIndex, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* --------Bottom Section------------- */
.recent-forms__header {
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom-section {
  /* margin-top: 20px; */

  /* display: grid; */
  /* grid-template-columns: 3fr 1fr;
  grid-gap: 1em;
  grid-row-gap: 1em;
  grid-auto-rows: minmax(300px, auto); */
}

/* --------Recent Forms----------- */

.light-bg-btn {
  background-color: rgba(0, 0, 0, 0.002);
  border: 1px solid transparent;
  box-sizing: border-box;

  padding: 5px 0px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 20px;
  width: fit-content;

  &.active {
    border-bottom: 3px solid teal;
    color: teal;
  }
}

.recent-forms {
}

.title-row {
  /* background-color: #f2f2f2; */
  display: flex;
  text-align: left;
}

.title-row > h3 {
  padding: 10px 10px;
}
.form-utils {
  margin-bottom: 20px;
  display: flex;
}

.searchContainer:last-of-type {
  margin-left: auto;
}

.select-box select {
  height: 28px;
  padding-left: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  line-height: 22px;
}

/* Search Row */

.searchContainer {
  /* width: 300px;
  height: 36px;
  border: 2px solid teal;
  padding: 0px 10px;
  border-radius: 12px; */
}

.search-bar {
  width: 100%;
  height: 100%;
  vertical-align: -webkit-baseline-middle;
}

/* .searchInput {
  border: none;
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  border-radius: 50px;
  color: #424242;
}

.searchInput:focus {
  outline: none;
} */
/* ----- */

/* --------------------------------------------------Form Row----------------------------------------- */
.recent-form-wrap {
  display: flex;

  /* margin: auto; */
  overflow-x: auto;
  padding: 10px 50px;
}

.card-shimmer__wrapper {
  display: flex;
}

/* ----------FORM CARD----------------- */
.form-card {
  width: 250px;
  height: 280px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  position: relative;
  border-radius: 4px;
  /* justify-content: center;
  align-items: center; */
}

.form-card:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.form-card-content {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  height: 180px;
}
.form-img-cont {
  width: 250px;
  height: 100px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #337ab7;
  background-image: url('/pattern.png');
  position: relative;
  cursor: initial;
  &.--template {
    background-color: #38b2ac;
  }
}
// .form-card:nth-child(even) .form-img-cont {
//   background-color: #38b2ac;
// }
.status__tag__wrap {
  position: absolute;
  display: flex;
  right: 2px;
  bottom: 2px;
}

.form-card__actions {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-top-right-radius: 4px;
  /* padding: 0px 12px; */
  height: 24px;
  color: #ffffff;
  cursor: pointer;
}
.form-card__actions:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.form-card-content-div {
  display: flex;
  // flex-direction: column;
}

.form-card__title {
  color: #203d4a;
  font-size: 24px;
  text-align: left;
  padding: 16px;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // /* margin-bottom: 68px; */
}
.form-card__title span {
  word-break: break-all;
}

.form-card__footer {
  padding: 16px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
}

.form-response {
  font-size: 14px;
  z-index: 10;
}

.link-btn {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-weight: 600;
  opacity: 0.5;
  transition: all 0.2s;
}
.link-btn:hover {
  opacity: 0.8;
  transform: scale(1.2);
}

.collab-btn {
  position: absolute;
  right: 16px;
  bottom: 14px;
  z-index: 5;
  text-align: right;
  // width: 20px;
  width: 100%;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  font-weight: 600;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.collab-btn > span {
  margin-left: 4px;
  position: relative;
  top: -2px;
}

.collab-btn:hover {
  opacity: 0.8;
}

.status-tag {
  background-color: #abdfdc;
  padding: 4px 8px;
  margin: 4px;
  font-size: 12px;
  border-radius: 20px;
  display: inline-block;
}

/*  */
.no-forms-div {
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.no-forms {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #336573;
  background-color: #e0f3f8;
  border-color: #d3eef6;
  text-align: center;
  padding: 20px;
  max-width: 250px;
  border: 2px dashed #336573;
}
</style>
