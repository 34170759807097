<template>
  <div class="category-items">
    <!-- Timer settings -->
    <lv-toggle v-if="schema?.quiz_settings" label="Enable Timer" v-model="schema.quiz_settings.timer_enabled" />

    <div>
      <div class="sub-settings">
        <lv-radio-group
          v-if="schema.quiz_settings.timer_enabled"
          v-model="chosenTimerSetting"
          name="Timer Setting"
          optionLabel="label"
          optionValue="value"
          :options="[
            { label: 'Overall Timer', value: 'Overall Timer' },
            { label: 'Section Wise Timer', value: 'Section Wise Timer' },
          ]"
          pulse
          color="primary"
        ></lv-radio-group>
        <template v-if="schema?.quiz_settings?.timer_enabled">
          <br />
          <div v-if="schema?.settings?.is_quiz && schema.quiz_settings.global_timer_enabled" class="prop-row ">
            <!-- v-model with timer component -->
            <lv-input type="number" min="0" max="59" label="Min" style="width:44px" v-model="mins" />
            <div style="width:12px">:</div>
            <lv-input type="number" min="0" max="60" label="Sec" style="width:44px" v-model="secs" step="5" />
          </div>
          <div v-else-if="schema.quiz_settings.section_timer_enabled && selectedSection !== 0">
            <p>Timer for section {{ selectedSection }}</p>

            <div class="prop-row">
              <!-- v-model with timer component -->
              <lv-input type="number" min="0" max="59" label="Min" style="width:44px" v-model="mins1" />
              <div style="width:12px">:</div>
              <lv-input type="number" min="0" max="60" label="Sec" style="width:44px" v-model="secs1" step="5" />
            </div>
          </div>
          <br />
          <lv-toggle v-if="schema?.settings?.is_quiz && (schema.quiz_settings.global_timer_enabled || schema.quiz_settings.section_timer_enabled)" label="Animated Timer" v-model="schema.quiz_settings.animated_timer" />
        </template>
      </div>
    </div>
    <!-- </div> -->
    <!--  -->

    <!-- Score Settings  -->
    <div>
      <br />
      Show score based on
      <br />
      <br />
      <div class="prop-row">
        <lv-toggle label="Enable Individual Score" v-model="schema.quiz_settings.individual_score_enabled" />
        <!-- <lv-radio-group v-model="chosenScoreSetting" name="Score Setting" :options="scoreOptions" optionLabel="name" optionValue="code" pulse color="primary"></lv-radio-group> -->
        <!-- {{chosenScoreSetting}} -->
      </div>
      <br />
      <lv-toggle label="Enable Negative Marking" v-model="schema.quiz_settings.negative_marking" />
    </div>
    <!-- QUiz Overall view -->
    <br />
    <div>
      <!-- <lv-button @click="$emit('showQuizOverview', true)">Overall Quiz View</lv-button> -->
    </div>
  </div>
</template>

<script>
import quizMixin from '../mixins/computedMixins/isQuizModeEnabled.js';
import formSchemaSettingsMixin from '../mixins/computedMixins/formSchemaSettingsMixin.js';
import AccordianCustomStyles from '../AccordianCustomStyles.vue';
import LvToggle from 'lightvue/input-toggle';
import LvInput from 'lightvue/input';
import LvRadioGroup from 'lightvue/radio-group';
import LvButton from 'lightvue/button';
import LvCollapsible from 'lightvue/collapsible';

export default {
  mixins: [quizMixin, formSchemaSettingsMixin],
  props: ['isActive'],
  // emits: ['showQuizOverview'],
  components: {
    AccordianCustomStyles,
    LvToggle,
    LvInput,
    LvRadioGroup,
    LvButton,
    LvCollapsible,
  },
  created() {
    this.chosenTimerSetting = this.schema.quiz_settings.global_timer_enabled ? 'Overall Timer' : this.schema.quiz_settings.section_timer_enabled ? 'Section Wise Timer' : '';
    this.schema.quiz_settings.global_timer_enabled = this.chosenTimerSetting == 'Overall Timer';
    this.schema.quiz_settings.section_timer_enabled = this.chosenTimerSetting == 'Section Wise Timer';
  },
  data() {
    return {
      chosenScoreSetting: 'Correct answered',
      chosenTimerSetting: 'Overall Timer',
      scoreOptions: [
        { name: 'Individual score', code: 'IS' },
        { name: 'Correct answered', code: 'CA' },
      ],
      settingCategory: '',
    };
  },
  computed: {
    mins: {
      get: function() {
        return ('0' + Math.floor(this.schema.quiz_settings.global_timer_duration / 60)).substr(-2);
      },
      set: function(val) {
        this.schema.quiz_settings.global_timer_duration = +val * 60 + +this.secs;
      },
    },
    secs: {
      get: function() {
        return ('0' + Math.floor(this.schema.quiz_settings.global_timer_duration % 60)).substr(-2);
      },
      set: function(val) {
        this.schema.quiz_settings.global_timer_duration = +this.mins * 60 + +val;
      },
    },
    mins1: {
      get: function() {
        return ('0' + Math.floor(this.schema.sections[this.selectedSection].config.section_timer_duration / 60)).substr(-2);
      },
      set: function(val) {
        this.schema.sections[this.selectedSection].config.section_timer_duration = +val * 60 + +this.secs1;
      },
    },
    secs1: {
      get: function() {
        return ('0' + Math.floor(this.schema.sections[this.selectedSection].config.section_timer_duration % 60)).substr(-2);
      },
      set: function(val) {
        this.schema.sections[this.selectedSection].config.section_timer_duration = +this.mins1 * 60 + +val;
      },
    },
    selectedSection() {
      return this.$store.state.builder.selectedSection;
    },
  },
  methods: {
    setSettingCategory(newCategory) {
      this.settingCategory = this.settingCategory === newCategory ? '' : newCategory;
    },
  },
  watch: {
    chosenTimerSetting(val) {
      this.schema.quiz_settings.global_timer_enabled = val == 'Overall Timer';
      this.schema.quiz_settings.section_timer_enabled = val == 'Section Wise Timer';
    },
  },
};
</script>

<style lang="scss">
.prop-row {
  display: flex;
  align-items: center;
}
.sub-settings {
  padding: 8px 16px;
}
</style>
