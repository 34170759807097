<template>
  <!-- RURBAN -->
  <LvButton icon="light-icon-download" class="--link-button lv--secondary" @click="startCsvDownload" />
</template>

<script>
const converter = require('json-2-csv');

export default {
  props: ['responses', 'formData'],
  data() {
    return {
      processedResponses: [],
      fieldLabels: {},
    };
  },
  created() {
    // this.processResponses()
  },
  methods: {
    processResponses() {
      this.formData.schema.sections?.forEach(section => {
        section?.rows?.forEach(row => {
          row?.fields?.forEach(field => {
            this.fieldLabels[field.id] = field.label;
          });
        });
      });

      //deleted fields.
      //multiselect responses.

      this.responses.forEach(response => {
        let tempRes = {
          response_id: response.response_id || '',
          date_submitted: response.date_submitted ? new Date(response.date_submitted) : '',
          submission_status: response.submission_status || '',
          steps_completed: response.other_data ? response.other_data.steps_completed : '',
          user_id: response.other_data && response.other_data.user ? response.other_data.user.uid : '',
          user_name: response.other_data && response.other_data.user ? response.other_data.user.name : '',
          team_number: response.other_data && response.other_data.user ? response.other_data.user.team_number : '',
          photos: response.other_data && response.other_data.photos ? JSON.stringify(response.other_data.photos.map(photo => photo.url).join(',')) : '',
        };

        for (let field in this.fieldLabels) tempRes[this.fieldLabels[field]] = response.response_data[field] || ' ';
        delete tempRes['undefined'];
        delete tempRes['Submit Another Response'];

        tempRes['Created'] = new Date(response.createdAt);
        tempRes['Last updated'] = new Date(response.updatedAt);
        this.processedResponses.push(tempRes);
      });
    },

    startCsvDownload() {
      this.processResponses();

      // for(var i=0;i<1000;i++) this.processedResponses.push( {srno: i, ...this.processedResponses[0]} )

      converter.json2csv(this.processedResponses, (err, csv) => {
        if (err) {
          throw err;
        }
        let fileName = this.formData.form_slug + '_responses.csv';
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        element.setAttribute('download', fileName);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      });
    },
  },
};
</script>
