<template>
  <!---------- Custom Domain Page container ------------->
  <div class="cd-page_cont">
    <!-- ----------Page Header--------------- -->
    <div class="cd-top-row">
      <div class="cd_header">
        <div class="cd-main_heading">Manage custom domains</div>
        <div class="cd-heading_description">
          Use your own domains on involve.me with CNAME entries. Read up on
          custom domains in the documentation.
        </div>
      </div>
      <!-- --------Upgrade------ -->
      <div class="cd-plan_upgrade">
        <div>Not included in your plan</div>
        <button class="cd-upgrade_btn">Upgrade</button>
      </div>
      <!-- ----------------- -->
    </div>
    <!-- ---------Header Ends--------- -->
    <div class="cd-main">
      <!-- ---------Domains Card Row------- -->
      <div class="cd-domains_cards">
        <!-- Organization Domain card -->
        <div class="od-card">
          <div class="od-card_body">
            <div class="od-card_head">Organization Domain</div>
            <div class="od-card_description">
              Each project URL consists of your organization domain and the URL
              snippet you specified for the project.
            </div>
            <div class="od-card_example">quizzes.mydomain.com/my-quiz</div>
          </div>
        </div>
        <!-- ---------------- -->

        <!-- Project Domain card -->
        <div class="od-card">
          <div class="od-card_body">
            <div class="od-card_head">Project Domain</div>
            <div class="od-card_description">
              A project domain overrides the organization domain for the
              specified project and directly opens it. You can change the
              assigned project later on.
            </div>
            <div class="od-card_example">quizzes.mydomain.com/my-quiz</div>
          </div>
        </div>
        <!-- ---------------- -->
      </div>
      <!-- -------Domains Cards Row Ends------- -->

      <!-- Custom Domain PLan Price Card -->
      <div class="cd-plan-row">
        <div class="cd-plan_card">
          <div class="plan-card_body">
            <div class="plan-card_header">Not Included in your plan</div>
            <div class="plan-card_description">
              You can add custom domains on top of your Business or Enterprise
              plan and cancel them on a monthly basis.
            </div>
            <div class="cd-plan_price">
              <h2>$35</h2>
              Per Month
            </div>
            <div class="cd-contact-support">
              <button class="cd-support_btn">Contact support to buy</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* Short Form Prefixes */
/* cd = custom domain */
/* od = organization domain */
.cd-page_cont {
  margin: 40px;
  padding: 20px;
}

.cd-top-row {
  display: flex;
  justify-content: space-between;
}

.cd-main_heading {
  font-size: 28px;
  font-weight: 600;
  border-bottom: 3px solid teal;
  width: 45%;
}

.cd-plan-upgrade {
  width: 45%;
}

.cd-plan_upgrade {
  text-align: center;
}

.cd-upgrade_btn {
  border: none;
  outline: none;
  padding: 4px 8px;
  color: white;
  background-color: rgba(77, 77, 214, 0.336);
  border-radius: 16px;
  cursor: pointer;
}

.cd-heading_description {
  margin-top: 8px;
  opacity: 0.6;
}

.cd-domains_cards {
  display: flex;
  margin: auto;
}
.cd-main {
  margin-top: 40px;
}
.od-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 460px;
  margin-right: 28px;
  background-color: white;
  padding: 16px 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
}

.od-card_head {
  font-size: 24px;
  font-weight: 550;

  /* font-style: bold; */
  border-bottom: 1px solid black;
  margin-top: 16px;
  margin-bottom: 16px;
}
.od-card_description {
  margin-bottom: 24px;
}

.od-card_example {
  background-color: #f2f2f2;
  padding: 2px 4px;
  margin-bottom: 8px;
}

.cd-plan-row {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cd-plan_card {
  width: 400px;

  background-color: #fff;
  padding: 20px 24px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
}

.plan-card_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan-card_header {
  font-size: 24px;
  font-weight: 550;
  margin-bottom: 20px;
}

.plan-card_description {
  text-align: center;
  margin-bottom: 28px;
}
.cd-plan_price {
  text-align: center;
  margin-bottom: 28px;
}

.cd-support_btn {
  border: none;
  outline: none;
  background-color: teal;
  color: white;
  font-size: 16px;
  padding: 12px 16px;
  border-radius: 16px;
  cursor: pointer;
}
</style>