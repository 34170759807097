<template>
  <div>
    <div class="setting-header">
      <div class="top-heading">
        <div class="setting-title">Manage collaborators</div>
      </div>
      <div class="setting-description">
        Allow others users to manage this form, access response data and modify its settings.
      </div>
    </div>

    <div class="settings-card">
      <div class="settings__card">
        <div class="project-name-block">
          <form @submit.prevent="addCollaborator">
            <lv-input v-model="collaboratorEmail" label="Add collaborator using email">
              <template #append>
                <LvButton @click="addCollaborator" icon="light-icon-plus" class="lv--success" />
              </template>
            </lv-input>
          </form>
        </div>
        <div class="project-name-block" v-if="currentForm && currentForm.collaborators && currentForm.collaborators.length">
          <br />
          <b>Current collaborators:</b>
          <div v-for="collabEmail in currentForm.collaborators" :key="collabEmail" class="collab-email-card">
            <i class="light-icon-user"></i>
            {{ collabEmail }}

            <LvButton icon="light-icon-x" class="lv--danger collab-email-card__removeButton" outlined @click="removeCollaborator(collabEmail)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LvInput from 'lightvue/input';
import LvButton from 'lightvue/button';
import { currentFormMixin } from '@main/mixins';
import Forms from '../../models/form_schema';
export default {
  mixins: [currentFormMixin],

  components: {
    LvInput,
    LvButton,
  },
  created() {
    // this.collabEmailList = this.currentForm.collaborators || [];
  },

  data() {
    return {
      collaboratorEmail: '',
      // collabEmailList: [],
    };
  },

  methods: {
    async addCollaborator(e) {
      e.preventDefault();
      if (this.collaboratorEmail === '') return;
      if (this.isValidEmail(this.collaboratorEmail) == false) {
      this.$toast.add({
        content: 'The email address you have entered is invalid.',
        type: 'success',
        duration: 2000,
      });       
      return;
      }
      try {
        let res = await Forms.addCollaborator(this.currentForm._id, this.collaboratorEmail.toLowerCase());
        if (res.status === 'Collaborator added.' || res.status === 'Invite sent.') {
          if (this.currentForm.collaborators) {
            this.currentForm.collaborators.push(this.collaboratorEmail);
          } else {
            this.currentForm.collaborators = [this.collaboratorEmail];
          }
          this.collaboratorEmail = '';
        }
        this.$toast.add({
          content: res.status,
          type: 'error',
          duration: 2000,
        });
      } catch (err) {
        this.$toast.add({
          content: err.message,
          type: 'error',
          duration: 2000,
        });
      }

      // alert(res.status);
    },
    async removeCollaborator(emailToRemove) {
      let isConfirmed = confirm(`This will remove ${emailToRemove} from collaborators for this form.`);

      if (isConfirmed) {
        let res = await Forms.removeCollaborator(this.currentForm._id, emailToRemove);
        let collabIndex = this.currentForm.collaborators.findIndex(collab => collab === emailToRemove);
        if (res.status === 'Collaborator removed.' && collabIndex !== -1) {
          // this.collabEmailList = this.collabEmailList.filter(email => email !== emailToRemove);
          this.currentForm.collaborators.splice(collabIndex, 1);
        }
        // alert(res.status);
        this.$toast.add({
          content: res.status,
          type: 'success',
          duration: 2000,
        });
      }
    },
    // async showCollaborators() {
    //   this.collabEmailList = this.currentForm.collaborators;
    // },
    isValidEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style scoped>
.top-heading {
  display: flex;
}

.light-icon-hash {
  font-size: 36px;
}

.project-name-block {
  margin-top: 10px;
  margin-bottom: 16px;
}
.project-name-field,
.project-url-field .label {
  margin-bottom: 8px;
}

.setting-field-label {
  margin-bottom: 4px;
}

.projectName-input {
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0.7rem;
}

.upload-img-box {
  width: 304px;
  height: 192px;
  background: #edf2f7;
  color: #2d3748;
  padding: 12px 16px;
}

.upload-icon {
  margin-top: 28px;
  text-align: center;
  cursor: pointer;
}

.light-icon-upload {
  font-size: 48px;
  /* margin: 0px 0px 12px; */
  /* text-align: center; */
}

.collab-email-card {
  margin: 24px 0px;
}

.collab-email-card > i {
  position: relative;
  top: 4px;
  font-size: 1.2em;
  margin-right: 8px;
}

.collab-email-card > .collab-email-card__removeButton {
  float: right;
  transform: scale(0.8);
}
</style>
