<template>
  <div>
    <container @mouseleave="hoveredSectionIndex = -1" @drop="dndDrop" @dragStart="dndDragStart" @dragEnd="dndDragEnd" v-if="schema">
      <draggable v-for="(section, i) in questionSections" :key="i" class="section-accordian" @click="activeSection(i)">
        <div class="section-mover">
          <i class="light-icon-grip-vertical"></i>
        </div>

        <div class="accordian-heading__wrap ">
          <div class="accordian-label">
            <!-- <i class="light-icon-credit-card"></i> -->
            <i class="light-icon-device-laptop"></i>
            Section {{ i + 1 }}
          </div>
          <div v-if="schema.settings.is_quiz && schema.quiz_settings?.timer_enabled && schema.quiz_settings?.section_timer_enabled" class="section-timer-badge">
            <i class="light-icon-clock"></i>
            <div v-if="currentSection !== i">
              <span v-if="Math.floor(section.config.section_timer_duration / 60)"> {{ Math.floor(section.config.section_timer_duration / 60) }} mins </span>

              {{ Math.floor(section.config.section_timer_duration % 60) }} secs &nbsp; &nbsp;
            </div>

            <!-- activeSectionLabel.slice('Section '.length) -->
            <div class="prop-row " v-if="editDurationMode && currentSection == i">
              <!-- v-model with timer component -->
              <lv-input type="number" min="0" max="59" style="width:44px" v-model="mins" />
              <div style="width:12px">:</div>
              <lv-input type="number" min="0" max="60" style="width:44px" v-model="secs" step="5" />
            </div>

            <i
              class="light-icon-pencil"
              v-if="currentSection != i"
              @click="
                editDurationMode = true;
                currentSection = i;
              "
            ></i>
            <i
              v-if="editDurationMode && currentSection == i"
              class="light-icon-check"
              @click="
                editDurationMode = false;
                currentSection = -1;
              "
            ></i>
          </div>
          <i :class="open1 ? 'light-icon-chevron-up' : 'light-icon-chevron-down'"></i>
        </div>
        <LvCollapsible :show="activeSectionLabel === `Section ${i + 1}`">
          <div class="section-content__wrapper">
            <SectionQuestions :section="section"></SectionQuestions>
          </div>
        </LvCollapsible>
      </draggable>
    </container>
  </div>
</template>

<script>
import LvCollapsible from 'lightvue/collapsible';
import { Container, Draggable } from '../smooth-dnd/main';
import SectionQuestions from './SectionQuestions.vue';
import LvInput from 'lightvue/input';

export default {
  components: {
    LvCollapsible,
    Draggable,
    Container,
    LvInput,
    SectionQuestions,
  },
  data() {
    return {
      open1: false,
      activeSectionLabel: '',
      dragging: false,
      hoveredSectionIndex: -1,
      editDurationMode: false,
      // * Use activeSectionLabel's slice
      currentSection: -1,
    };
  },
  computed: {
    schema: {
      get: function() {
        return this.$store.state.builder.schema;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSchema', newValue);
      },
    },
    questionSections() {
      return this.schema.sections.filter((section, k) => k !== 0 && k !== this.schema.sections.length - 1);
    },
    settings: {
      get: function() {
        return this.$store.state.builder.schema.settings;
      },
    },
    selectedSection: {
      get: function() {
        return this.$store.state.builder.selectedSection;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSelectedSection', newValue);
      },
    },
    mins: {
      get: function() {
        return Math.floor(this.questionSections[this.currentSection]?.config?.section_timer_duration / 60);
      },
      set: function(val) {
        this.questionSections[this.currentSection].config.section_timer_duration = +val * 60 + this.secs;
      },
    },
    secs: {
      get: function() {
        return Math.floor(this.questionSections[this.currentSection]?.config?.section_timer_duration % 60);
      },
      set: function(val) {
        this.questionSections[this.currentSection].config.section_timer_duration = this.mins * 60 + +val;
      },
    },
  },
  methods: {
    activeSection(i) {
      if (this.activeSectionLabel === `Section ${i + 1}`) {
        this.activeSectionLabel = '';
      }
      this.activeSectionLabel = `Section ${i + 1}`;

      // this.activeSectionLabel ? `Section ${i + 1}` : '';
    },
    dndDragStart(e) {
      this.dragging = true;
    },
    dndDragEnd(e) {
      this.dragging = false;
    },
    dndDrop(e) {
      // console.log(e);
      this.dragging = false;
      this.moveSection(e.removedIndex + 1, e.addedIndex + 1);
    },
    moveSection(fromIndex, toIndex) {
      // console.log(`Moving section from ${fromIndex} to ${toIndex}`);
      let removedSection = this.schema.sections[fromIndex];
      // Deleting fromIndex
      this.schema.sections.splice(fromIndex, 1);
      // Adding toIndex
      this.schema.sections.splice(toIndex, 0, removedSection);
      // this.changeSelectedSection(toIndex);
    },
  },
};
</script>

<style lang="scss">
.section-accordian {
  // background-color: #fff;
  //   padding: 12px 16px;
  cursor: pointer;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  // transition: all 0.2s ease-in-out;
  position: relative;

  border-radius: 4px;
  margin: 8px 0px;
}

.section-mover {
  opacity: 0.7;
  position: absolute;
  top: 0px;
  right: 100%;
  height: 100%;
  width: 16px;
  background-color: #607c8a;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  // cursor: pointer;
  transition: all 0.2s;
  cursor: move;
  border-radius: 4px 0px 0px 4px;
}
.section-accordian:hover {
  // box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.section-timer-badge {
  position: absolute;
  background-color: #9f9d9d;
  color: #fff;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 10px;
  right: 60px;
  display: flex;
  align-items: center;
}
.accordian-heading__wrap {
  background-color: #1d3843;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  .accordian-label {
    display: flex;
    align-items: center;
  }
  i {
    font-size: 20px;
    margin-right: 8px;
    min-width: 20px;
    height: 20px;
  }
  cursor: pointer;
  font-size: 18px;
}
.section-content__wrapper {
  padding: 8px 24px 8px 24px;
  background-color: #607c8a33;
}
</style>
