<template>
  <div class="bar__wrapper">
    <LvProgressBar class="progress__bar-inner" :value="increment" :showValue="false" :color="color" style="height:4px; border-radius: 0px" />
    <div class="wiz-progress-bar-wrap">
      <div class="wizard-title" v-for="(section, sectionIndex) in sectionCount" :key="sectionIndex">
        <div @click="changeSectionWiz(sectionIndex)" class="wizard__icon">
          <span>{{ sectionIndex + 1 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LvProgressBar from 'lightvue/progressbar/ProgressBar';
export default {
  components: {
    LvProgressBar,
  },

  props: ['sectionCount', 'selectedSection', 'color'],

  computed: {
    increment() {
      this.incrementPercent = 100 / this.sectionCount;
      return this.incrementPercent * this.selectedSection;
    },
  },

  methods: {
    changeSectionWiz(newSectionIndex) {
      this.$emit('wiz-section', newSectionIndex);
    },
  },
};
</script>

<style lang="scss">
.bar__wrapper {
  position: relative;
  margin-top: 14px;
  .progress__bar-inner {
    position: absolute;
    width: 100%;
    height: 5px;
    top: 42%;
    z-index: 1;
  }
}
.wiz-progress-bar-wrap {
  display: flex;
  //   overflow: auto;
  position: relative;
  justify-content: space-around;
  margin-bottom: 20px;
  cursor: pointer;
  //   .progress-bar-inner {
  //     // width: 100%;
  //     // height: 5px;
  //     // margin-left: auto;
  //     // margin-right: auto;
  //     top: 30%;
  //     z-index: 50;
  //     position: absolute;
  //     // background: #008080;
}
//     // display: flex;

// }
// // .progress-bar-inner{
// //     z-index: 1;
// // }
.wizard-title {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .wizard__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #cccccc;
    width: 50px;
    z-index: 100;
    background: #ffffff;
    height: 50px;
    &.--completed {
      background: #008080;
      span {
        color: #ffffff;
      }
    }
  }
  .wizard__name {
    text-align: center;
  }
}
</style>
