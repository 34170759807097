export default {
  props: {
    optionLabelKey: {
      type: String,
    },
    optionValueKey: {
      type: String,
    },
  },
};
