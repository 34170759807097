[<template>
  <form-parent :schema="formData.schema" :isQuizSchema="isQuizSchema"/>
</template>

<script>
import FormParent from 'builder/FormParent.vue';
import Forms from '@main/models/form_schema';
import { currentFormMixin } from '@main/mixins';
export default {
  components: {
    FormParent,
  },
  mixins: [currentFormMixin],
  data() {
    return {
      formData: {},
      isQuizSchema:false
    };
  },

  created() {
    this.getSchema();
  },

  methods: {
    async getSchema() {
      // let short_uid = uid().substring(0, 6);
      // let project_name = 'PROJECT-' + short_uid;
      this.currentForm = {
        project_name: 'Untitled Form',
      };

      // Coming on /create from template click
      if (this.$route.query.template) {
        this.form_slug = this.$route.query.template;
        var form = await Forms.getBySlug(this.form_slug);
        if (form.data.form_type !== 'template') {
          return this.$router.push({
            name: 'Dashboard',
          });
        } 
        else if (form && form.data && form.data.schema) {
          this.formData = { ...form.data };
          this.currentForm = {
            ...form.data,
            form_slug: '',
          };
        }
      }
       else if (this.$route.query.quiz) {
          this.currentForm = {
            project_name: 'Untitled Quiz',
          };
          this.isQuizSchema=true;
        }
    },
  },
};
</script>
