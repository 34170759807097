export default {
  methods: {
    validateSection() {
      console.log('Validating Section...');
      //---------***** Validations ***
      this.formErrors = {};
      // this.schema.sections.forEach(section => {
      this.schema.sections[this.selectedSection].rows.forEach(row => {
        let fieldErrors = [];
        // row.fields.forEach((field, fieldIndex) => {
        if (row.fields[0].required === true) {
          if (this.formData[row.fields[0].id] === undefined || this.formData[row.fields[0].id] === '') {
            // i.e. not filled
            fieldErrors.push('This field is required');
            this.formErrors[row.fields[0].id] = fieldErrors;
          }
        }
        // });
        console.log(' row field value', this.formData[row.fields[0].id]);
        // });
      });
      // Object.keys(this.formErrors).forEach(function(key) {
      //   if (this.formErrors[key]) {
      //     console.log(key);
      //   }
      // });

      if (Object.values(this.formErrors).length > 0) {
        console.log('value of object', Object.values(this.formErrors).length);

        return false;
      }
      return true;
    },
  },
};
