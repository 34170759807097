export default {
  data() {
    return {
      is_running: false,
      seconds: 283,
      timer: null,
      scoreStats: {},
      timerDuration: 0,
      finalScore: 0,
      maxScore: 0,
    };
  },
  created() {
    this.seconds = (this.schema?.quiz_settings?.global_timer_enabled && this.schema?.quiz_settings?.global_timer_duration) || (this.schema?.quiz_settings?.section_timer_enabled && this.schema.sections[this.selectedSection + 1].config.section_timer_duration);
    this.timerDuration = this.seconds;
  },
  watch: {
    selectedSection(val) {
      if (this.schema?.quiz_settings?.section_timer_enabled) {
        this.seconds = this.schema.sections[val + 1]?.config.section_timer_duration;
        // this.$store.state.builder.timerDuration = this.seconds;
      }
    },
  },
  methods: {
    calculateScore() {
      const rows = this.schema.sections[this.selectedSection].rows.filter(row => !row.fields[0].read_only);

      const questions = rows.map(question => question.fields).reduce((prevQuestion, currQuestion) => [...prevQuestion, ...currQuestion], []);

      const attempted = questions.filter(question => Object.keys(this.formData).includes(question.id));

      let sectionScore = 0;
      attempted.filter(question => {
        if (question.enable_multiselect || question.field_type === 'FormCheckboxGroup') {
          //Multiselect- check if answer correct
          if (
            Array.from(this.formData[question.id])
              .sort((a, b) => a - b)
              .join(' ') ===
            question.options
              .filter(option => option.correct)
              .map(item => item.src)
              .sort((a, b) => a - b)
              .join(' ')
          ) {
            if (this.schema.quiz_settings.individual_score_enabled) {
              sectionScore += +question.individual_score;
            } else {
              sectionScore += 1;
            }
          }
          //if answer not correct
          else {
            //  negative marking enabled
            if (this.schema.quiz_settings.negative_marking) {
              sectionScore -= +question.negative_score;
            }
          }
        }
        //If Single select question
        else {
          // check correct answer
          if (this.formData[question.id] === (question.options && question.options.find(e => e.correct)?.src)) {
            //If individual score enabled
            if (this.schema.quiz_settings?.individual_score_enabled) {
              sectionScore += +question.individual_score;
            }
            //score based on number of correct
            else {
              sectionScore += 1;
            }
          }
          //If not correct answer
          else {
            //  Negative marking enabled
            if (this.schema.quiz_settings?.negative_marking) {
              sectionScore -= +question.negative_score;
            }
          }
        }
      });

      this.scoreStats[this.selectedSection] = {
        questions,
        attempted,
        score: sectionScore,
      };
    },

    handleGlobalTimerOnSectionChange() {
      if (this.selectedSection === 0 && !!this.validateSection()) {
        const global_timer_enabled = this.schema.quiz_settings?.global_timer_enabled;
        if (global_timer_enabled) {
          this.is_running = true;
          let seconds = this.seconds;
          let timer = setInterval(() => {
            if (this.is_running) {
              seconds--;
              this.timerDuration = seconds;
              if (seconds <= 0) {
                // reset
                clearInterval(timer);
                this.calculateScore();
                this.handleSubmit();
                return;
              }
            } else {
              // reset
              clearInterval(timer);
              return;
            }
          }, 1000);
        }
      }
    },
    handleSectionTimerOnSectionChange() {
      const section_timer_enabled = this.schema.quiz_settings?.section_timer_enabled;

      if (section_timer_enabled) {
        if (this.is_running) {
          clearInterval(this.timer);
        }
        let seconds = this.seconds;
        this.is_running = true;

        this.timer = setInterval(() => {
          if (this.is_running) {
            seconds--;
            console.log('timer running');
            this.timerDuration = seconds;
            if (seconds <= 0) {
              // reset
              this.calculateScore();
              clearInterval(this.timer);
              this.is_running = false;
              this.selectedSection + 1 < this.schema.sections.length - 1 ? this.handleNext() : this.handleSubmit();
              return;
            }
          } else {
            // reset
            clearInterval(this.timer);
            return;
          }
        }, 1000);
      }
    },

    calculateFinalScore() {
      const sectionStats = Object.values(this.scoreStats);

      this.finalScore = sectionStats.reduce((prevQt, currQt) => prevQt + currQt.score, 0);

      const allQuestions = sectionStats.map(section => section.questions).reduce((q1, q2) => [...q1, ...q2], []);

      this.maxScore = this.schema.quiz_settings?.individual_score_enabled ? allQuestions.reduce((prev, curr) => prev + +curr.individual_score, 0) : allQuestions.length;

      console.log(this.finalScore + '/' + this.maxScore);
    },
  },
};
