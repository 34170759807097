<template>
  <div>
    <Line :datasets="datasets" :labels="dateArray" :height="220"></Line>
  </div>
</template>

<script>
import { Line } from 'lightvue/chart';

export default {
  components: {
    Line,
  },

  props: ['formData', 'responses'],

  data() {
    return {
      dateArray: [''],
      dateToResponsesMap: {},
      datasets: [
        {
          label: 'Total Response',
          // y-axis value array lies in 'data[]' below
          data: [0],
          pointBackgroundColor: '#037f7f',
          borderColor: '#037f7f',
          tension: 0,
        },
      ],
    };
  },
  created() {
    this.createDateToResponsesMap();
    this.fillGapsInMap();
    this.populateLineChart();
    this.sortByDate();
    this.prefixSum();
  },
  methods: {
    createDateToResponsesMap() {
      let createdDate = new Date(this.formData._date_added.seconds * 1000).toDateString();
      // console.log(1,{createdDate})

      this.dateToResponsesMap[createdDate] = 0;
      this.dateToResponsesMap[createdDate + ' (created)'] = 0;

      this.responses.forEach(response => {
        let responseDate = new Date(response.date_submitted).toDateString();
        if (this.dateToResponsesMap[responseDate]) this.dateToResponsesMap[responseDate]++;
        else this.dateToResponsesMap[responseDate] = 1;
      });

      this.dateToResponsesMap[createdDate + ' (created)'] = this.dateToResponsesMap[createdDate];
      delete this.dateToResponsesMap[createdDate];
    },
    fillGapsInMap() {
      let createdDate = this.formData._date_added.seconds * 1000;
      // console.log(2,{createdDate})

      let currentDate = new Date().toDateString();
      if (new Date(createdDate).toDateString() == currentDate) return;
      let dateReached = false;
      while (!dateReached) {
        createdDate = parseInt(createdDate) + 24 * 3600 * 1000;
        let createdDateString = new Date(createdDate).toDateString();
        if (new Date(createdDateString).toDateString() === new Date().toDateString()) dateReached = true;

        if (this.dateToResponsesMap[createdDateString] == undefined) {
          this.dateToResponsesMap[createdDateString] = 0;
        }
      }
    },
    populateLineChart() {
      for (let entry in this.dateToResponsesMap) {
        this.dateArray.push(entry);
        this.datasets[0].data.push(this.dateToResponsesMap[entry]);
      }
    },
    sortByDate() {
      let currentData = this.datasets[0].data;
      for (let i = 0; i < currentData.length - 1; i++) {
        for (let j = i + 1; j < currentData.length; j++) {
          if (new Date(this.dateArray[i]) > new Date(this.dateArray[j])) {
            let temp = this.dateArray[i];
            this.dateArray[i] = this.dateArray[j];
            this.dateArray[j] = temp;

            temp = currentData[i];
            currentData[i] = currentData[j];
            currentData[j] = temp;
          }
        }
      }
      this.datasets[0].data = currentData;
    },
    prefixSum() {
      let currentData = this.datasets[0].data;
      for (let i = 1; i < currentData.length; i++) currentData[i] += currentData[i - 1];
      this.datasets[0].data = currentData;
    },
  },
};
</script>
