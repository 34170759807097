import GenerateChart from './GenerateChart';

export default {
  extends: GenerateChart,
  props: {
    chartId: {
      default: 'line-chart',
      type: String,
    },
    chartType: {
      type: String,
      default: 'line',
    },
    datasets: {
      type: Array,
      default: [],
    },
    labels: {
      type: Array,
      default: [],
    },
    options: {
      type: Object,
      default: { responsive: true, maintainAspectRatio: false },
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: this.datasets,
      },
      this.options
    );
  },
};
