<template>
  <d-toggle type="FormToggle" role="switch" class="d-toggle__input" :name="name" :label="label" :checked="value" :value="value" @input="updateValue"> </d-toggle>
</template>
<script>
import DToggle from 'lightvue/input-toggle';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  components: {
    DToggle,
  },
  props: ['placeholder', 'label', 'name', 'value', 'required', 'placeholder_required', 'help_block', 'help_block_text'],
};
</script>

<style></style>
