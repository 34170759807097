export default {
  created() {
    if (this.mode === 'live') {
      // This must be triggered on mounted for better dimensions.
      this.emitDimensionsToIframe();
    }
  },
  methods: {
    postMessageToParentFrame(messageObj) {
      console.log(messageObj);
      window.parent.postMessage(messageObj, '*');
    },
    emitSubmitToIframe(submitFormValue) {
      let fieldLabels = {};
      let labelledResponse = {};
      this.schema.sections?.forEach(section => {
        section?.rows?.forEach(row => {
          row?.fields?.forEach(field => {
            if (field.field_type !== 'FormButton') {
              fieldLabels[field.id] = field.label;
            }
          });
        });
      });

      for (let field in fieldLabels) {
        labelledResponse[fieldLabels[field]] = submitFormValue[field] || '';
      }
      delete labelledResponse['undefined'];
      delete labelledResponse['Submit Another Response'];

      try {
        let messageObj = {
          messageType: 'submitEvent',
          response: labelledResponse,
        };
        this.postMessageToParentFrame(messageObj);
      } catch (err) {
        console.log(err);
      }

      this.emitDimensionsToIframe();
    },
    // call emitDimensionsToIframe() on submit or on tab change, or whenever height/width should be updated.
    emitDimensionsToIframe() {
      setTimeout(() => {
        try {
          let messageObj = {
            messageType: 'dimensions',
            height: this.$el.offsetHeight + 'px',
          };
          this.postMessageToParentFrame(messageObj);
        } catch (err) {
          return;
        }
      }, 50);
    },
  },
};
