<template>
  <!---------- Custom Domain Page container ------------->
  <div class="Integeration-page_cont">
    <!-- ----------Page Header--------------- -->
    <div class="IP-top-row">
      <div class="IP_header">
        <div class="IP-main_heading">Integerations</div>
        <div class="IP-heading_description">
          Configure integrations to other webservices and apps.
        </div>
      </div>
      <!-- --------Upgrade------ -->
      <div class="IP-plan_upgrade">
        <div>Your current plan does not include integrations.</div>
        <button class="IP-upgrade_btn">Upgrade</button>
      </div>
      <!-- ----------------- -->
    </div>
    <!-- --------USER API KEY------ -->
    <div>
      <div class="api__key__head">API KEY</div>
      <div class="encoded__data__wrap">
        <lv-input type="text">
          <template #prepend>
            <div class="encoded__data">{{ user.api_key }}</div>
          </template>
          <!-- <LvButton icon="light-icon-search" class="lv--secondary" /> -->
          <template #append>
            <!-- <lv-button icon="light-icon-copy" class="lv--info" /> -->
            <CopyButton :text="user.api_key" />
          </template>
        </lv-input>
      </div>

      <!-- <lv-input type="text"  >
        <template #prepend>
                <div class="encoded__data">{{user._id}}</div>
              </template>
    </lv-input> -->
    </div>
    <div class="IP-main-content">
      <!-- ----------------- -->
      <!-- Zapier Integerations -->
      <div class="IP-zapier-wrap">
        <div class="zapier-head-div">
          <img src="../assets/zapier_logo.png" alt="" class="zapier-head-img" />

          <div class="zapier-head-description">
            Use our Zapier integration to send data to all popular platforms, including all major email marketing services, CRMs & mailing systems and send your new leads straight to the tools you already use.
          </div>
        </div>
        <!-- -----------Zapier APPS GRID------ -->
        <div class="zapier-app-grid">
          <!-- Google Sheets -->
          <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img src="../assets/google_sheets.png" alt="" class="zapier-app-img" />
            </div>
            <div class="zapier-app-head">
              Google Sheets
              <div class="zapier-app-subhead">Google +1</div>
            </div>
          </div>
          <!-- -App Block Ends--------- -->
          <!-- Gmail  -->
          <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img src="../assets/gmail.png" alt="" class="zapier-app-img" />
            </div>
            <div class="zapier-app-head">
              Gmail
              <div class="zapier-app-subhead">Email +1</div>
            </div>
          </div>
          <!-- -App Block Ends--------- -->
          <!-- PipeDrive -->
          <!-- <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img
                src="../assets/pipedrive_logo.png"
                alt=""
                class="zapier-app-img"
              />
            </div>
            <div class="zapier-app-head">
              PipeDrive
              <div class="zapier-app-subhead">
                CRM(Customer Relationship Management)
              </div>
            </div>
          </div> -->
          <!-- -App Block Ends--------- -->
          <!-- Slack -->
          <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img src="../assets/slack.png" alt="" class="zapier-app-img" />
            </div>
            <div class="zapier-app-head">
              Slack
              <div class="zapier-app-subhead">Team Chat</div>
            </div>
          </div>
          <!-- -App Block Ends--------- -->
          <!-- MailChimp -->
          <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img src="../assets/mailchimp_logo.png" alt="" class="zapier-app-img" />
            </div>
            <div class="zapier-app-head">
              MailChimp
              <div class="zapier-app-subhead">Email Newsletters</div>
            </div>
          </div>
          <!-- -App Block Ends--------- -->
          <!-- Google Drive -->
          <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img src="../assets/gdrive_logo.png" alt="" class="zapier-app-img" />
            </div>
            <div class="zapier-app-head">
              Google Drive
              <div class="zapier-app-subhead">File Management & Storage</div>
            </div>
          </div>
          <!-- -App Block Ends--------- -->
          <!-- Hubspot -->
          <!-- <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img
                src="../assets/hubspot_logo.png"
                alt=""
                class="zapier-app-img"
              />
            </div>
            <div class="zapier-app-head">
              Hubspot
              <div class="zapier-app-subhead">Marketing Automation</div>
            </div>
          </div> -->
          <!-- -App Block Ends--------- -->

          <!-- Google Calendar -->
          <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img src="../assets/google_calendar.png" alt="" class="zapier-app-img" />
            </div>
            <div class="zapier-app-head">
              Google Calendar
              <div class="zapier-app-subhead">Calendar +1</div>
            </div>
          </div>
          <!-- -App Block Ends--------- -->
          <!-- Zoho Mail -->
          <!-- <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img
                src="../assets/zohomail_logo.png"
                alt=""
                class="zapier-app-img"
              />
            </div>
            <div class="zapier-app-head">
              ZohoMail
              <div class="zapier-app-subhead">Email +1</div>
            </div>
          </div> -->
          <!-- -App Block Ends--------- -->

          <!-- Trello -->
          <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <img src="../assets/trello_logo.png" alt="" class="zapier-app-img" />
            </div>
            <div class="zapier-app-head">
              Trello
              <div class="zapier-app-subhead">Project Management</div>
            </div>
          </div>
          <!-- -App Block Ends--------- -->

          <!-- Many More Apps -->
          <div class="zapier-app-block">
            <div class="zapier-app-img-div">
              <i class="light-icon-plus"></i>
            </div>
            <div class="zapier-app-head">Many More</div>
          </div>
          <!-- -App Block Ends--------- -->
        </div>

        <!-- ---------- -->
      </div>
      <!-- ------ -->
    </div>
  </div>
</template>

<script>
import LvInput from 'lightvue/input';
import LvButton from 'lightvue/button';
import CopyButton from '../components/CopyButton';
import { userMixin } from '@main/mixins';

export default {
  mixins: [userMixin],
  components: {
    LvInput,
    LvButton,
    CopyButton,
  },
  data() {
    return {
      // encodedData:"",
    };
  },

  methods: {},
};
</script>

<style scoped>
.Integeration-page_cont {
  margin: 40px 48px 48px 48px;
  padding: 46px;
}

.IP-top-row {
  display: flex;
  justify-content: space-between;
}

.IP-main_heading {
  font-size: 28px;
  font-weight: 600;
  border-bottom: 3px solid teal;
  width: 45%;
}

.IP-plan-upgrade {
  width: 45%;
}

.IP-plan_upgrade {
  color: #336573;
  background-color: #e0f3f8;
  border-color: #d3eef6;
  text-align: center;
  padding: 20px;
  max-width: 250px;
}

.IP-upgrade_btn {
  margin-top: 12px;
  border: none;
  outline: none;
  padding: 4px 8px;
  color: white;
  background-color: #4364a9;
  border-radius: 16px;
  cursor: pointer;
}
.api__key__head {
  padding: 8px;
  border-radius: 8px;
  color: #fff;
  background-color: #008080;
  display: flex;
  justify-content: center;
  width: 90px;
}

.encoded__data__wrap {
  margin: 8px 0px 8px 0px;
}
.encoded__data {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-left: 12px;
  /* margin-right: -8px !important; */
  font-size: 14px;
  color: #4364a9;
  /* margin-bottom: 3px; */
}

.IP-heading_description {
  margin-top: 8px;
  opacity: 0.6;
}
.IP-main-content {
  display: flex;
  flex-direction: column;
}

.zapier-head-img {
  width: 200px;
}

.zapier-app-grid {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
}

.zapier-app-block {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 260px;
  height: 100px;
  padding: 10px 20px 10px 10px;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  border-radius: 12px;
  cursor: pointer;
  /* text-align: center; */
  margin: 18px;
}

.zapier-app-img-div {
  margin-right: 12px;
  background-color: #fff;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-width: 1px;
  border-radius: 12px;
  border-color: rgb(230, 230, 230);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  transition-duration: 300ms;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zapier-app-img {
  height: 56px;
  width: 56px;
}

.zapier-app-head {
  margin: 0px 0px 0px 20px;
  font-size: 16px;
  font-weight: 600;
}
.zapier-app-subhead {
  font-size: 14px;
  color: #666666;
  margin-top: 8px;
}
.zapier-app-block:hover {
  /* background-color: #fafafa; */
  background-color: #fff;
}

.zapier-plus-img-div {
  background-color: #336573;
  color: #fff;
  border-radius: 16px;
  padding: 10px;
}
.light-icon-plus {
  font-size: 24px;
  font-weight: 600;
}
</style>
