<template>
  <div class="publish-page-container">
    <!-- ------PUblish Card------- -->
    <div class="publish-card-container">
      <h1 class="publish-card-head">Integrate Your Form</h1>
      <br />
      <h4 class="publish-card-subhead">
        Use the following option to Integrate your custom Form
      </h4>

      <!-------------------------Card Panels Wrapper ---------------------->
      <div class="pc-panel-wrapper">
        <!-- Left Panel -->
        <div class="pc-left-panel">
          <!-------------- Customizable Link---- -->

          <div class="input-link-div">
            <div class="publish-link-head">Direct Sharable Link</div>
            <lv-input class="preview-base-url" type="text" style="width:100%"
              ><template #prepend>
                <div class="preview-base-url">{{ constURL }}{{ currentForm.form_slug }}</div>
              </template>
              <template #append>
                <CopyButton :text="constURL + currentForm.form_slug" />
              </template>
            </lv-input>
          </div>

          <!-- IFRAME EMBED --------->
          <div class="Iframe-div-wrap">
            <div class="Iframe-head">Embeded in your web-pages</div>
            <div class="Iframe-subhead">
              Copy & paste this customizable code to embed this project in your website. It will automatically generate the right iframe. <br />
              The Formstudio content is fully responsive and works on all content management platforms, from WordPress & Drupal to Wix, Squarespace & Weebly.
            </div>
            <!-- Creating IFRAME -->
            <!----- Iframe Customizations ---->
            <div class="Iframe__customizations">
              <!-- Iframe height -->
              <div class="Iframe_height">
                <lv-input v-model="iframe_height" type="number" label="Height" style="width:100%">
                  <!-- <template #append>
                    <div class="preview-base-url">
                      <pre>%</pre>
                    </div>
                  </template> -->
                </lv-input>
              </div>
              <!-- Iframe Width -->
              <div class="Iframe_width">
                <lv-input v-model="iframe_width" type="number" label="Width" style="width:100%">
                  <!-- <template #append>
                    <div class="preview-base-url">
                      <pre>%</pre>
                    </div>
                  </template> -->
                </lv-input>
              </div>
            </div>
            <!-- *** -->
            <lv-input class="preview-base-url" type="text" style="width:100%" icon-right="light-icon-copy"
              ><template #prepend>
                <div class="preview-base-url">
                  <pre>
              <div >{{`<iframe width="${iframe_width}px" height="${iframe_height}px" src="${ constURL}${currentForm.form_slug }" frameborder="0"></iframe>`}}</div>
                  </pre>
                </div>
              </template>

              <!-- <template #append>
                <CopyButton :text="constURL + currentForm.form_slug" />
              </template> -->
            </lv-input>

            <!-- *** -->
          </div>
        </div>
        <!-- Left Panel End -->
        <!-- -------------------Right Panel ------------------------>

        <div class="pc-right-panel">
          <!-- social medial div -->
          <div class="pc-social-media-div">
            <div class="pc-social-media-head">Share</div>
            <div class="pc-social-media-grid">
              <div class="fb-logo">
                <img src="../assets/facebook_logo.svg" alt="" class="pc-social-logo" />
              </div>
              <div class="mail-logo">
                <img src="../assets/linkedin_logo.svg" class="pc-social-logo" alt="" />
              </div>
              <div class="mail-logo">
                <img src="../assets/mail_logo.svg" class="pc-social-logo" alt="" />
              </div>
              <div class="twitter-logo">
                <img src="../assets/twitter_logo.svg" alt="" class="pc-social-logo" />
              </div>
              <div class="whatsap-logo">
                <img src="../assets/whatsap_logo.svg" alt="" class="pc-social-logo" />
              </div>
            </div>
          </div>
          <!-- QR-code Div -->
          <div class="qr-code-div">
            <div class="pc-qr-code-head">QR Code</div>
            <div class="pc-qr-code-subhead">
              Download this QR code to share it digitally or print it physically. Let your users access the project anywhere just by scanning the code with their mobile camera.
            </div>
            <div class="pc-qr-code-img-div" id="myQR">
              <!-- <img src="../assets/qr-code.svg" alt="" class="pc-qr-code-img" /> -->
              <qrcode-vue :value="qrCodeValue" :size="250" :foreground="currentForm.schema.head_color" level="H" />
            </div>
          </div>
        </div>
        <!-- Right-panel-end -->
      </div>
      <!-- left Right Panel Wrapper Ends -->
      <br />
      <div class="embed__preview">
        <div class="embed__preview__heading">Embed Preview</div>
        <iframe id="myIframe" :width="iframe_width" :height="iframe_height" :src="constURL + currentForm.form_slug" frameborder="0"></iframe>
      </div>
    </div>
    <!-- -----------Publish-card-container Ends------------------- -->
    <div class="copyright-footer-div">
      <copyright-footer />
    </div>
    <!-- -------------- -->
  </div>
</template>
<script type="text/javascript"></script>

<script>
import QrcodeVue from 'qrcode.vue';
import CopyrightFooter from '@main/components/CopyrightFooter.vue';
import { currentFormMixin } from '@main/mixins';
import config from '../config/index.ts';

import LvInput from 'lightvue/input';
import CopyButton from './CopyButton';
export default {
  mixins: [currentFormMixin],
  components: {
    LvInput,
    CopyrightFooter,
    QrcodeVue,
    CopyButton,
  },
  data() {
    return {
      constURL: config.link_base_url + '/p/',
      // constURL: `http://link.formstudio.io/p/`,
      iframe_height: 315,
      iframe_width: 560,
      qrCodeValue: '',
    };
  },

  created() {
    this.generateQR_value();
  },
  methods: {
    createIframe() {
      var ifrm = document.createElement('iframe');
      ifrm.setAttribute('id', 'ifrm'); // assign an id
      // assign url
      ifrm.setAttribute('src', 'demo.html');
      //document.body.appendChild(ifrm); // to place at end of document

      // to place before another page element
      var el = document.getElementById('marker');
      el.parentNode.insertBefore(ifrm, el);
    },

    generateQR_value() {
      this.qrCodeValue = this.constURL + 'embed/' + this.currentForm.form_slug;
    },
    generateQR() {
      const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        data: this.constURL + 'embed/' + this.currentForm.form_slug,
        dotsOptions: {
          color: '#1fec71',
          type: 'square',
        },
        backgroundOptions: {
          color: '#FFFFFF',
        },
      });
      qrCode.append(document.getElementById('myQR'));
    },
  },
};
</script>

<style>
.publish-page-container {
  background-color: #f4f4f4;
}
/*------------ TABS------------- */
.publish-card-container {
  background: #fff;
  color: black;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  /* letter-spacing: 0.01em; */
  /* line-height: 1.6em; */
  margin: 56px 100px 16px 100px;
  padding: 35px 44px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
}
.publish-card-head {
  font-size: 40px;
  /* line-height: 0.8em; */
  color: teal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.publish-card-subhead {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.pc-panel-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.pc-left-panel {
  width: 60%;
}

.pc-right-panel {
  width: 40%;
}
.publish-link-head {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}
.input-link-div {
  margin: 28px 0px 28px 0px;

  width: 450px;
}

.preview-base-url {
  display: flex;
  align-items: center;
  padding: 12px;
  margin-right: -8px !important;
  font-size: 14px;
  margin-bottom: 3px;
}
.preview-base-url pre {
  white-space: normal;
  opacity: 0.8;
}
.Iframe-div-wrap {
  margin: 12px 0px 0px 0px;
}

.Iframe-head {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 600;
}

.Iframe-subhead {
  margin: 12px 0px 12px 0px;
  font-size: 13px;
  font-weight: 400;
  max-width: 600px;
  line-height: 1.3;
}

.Iframe__customizations {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 8px;
}

/* .Iframe__customizations {
  width: 5%;
} */

.Iframe_height,
.Iframe_width {
  width: 80px;
}

.Iframe-textarea {
  background-color: #edf2f7;
  border: none;
  padding: 16px;
  border-radius: 10px;
  max-width: 600px;
  height: 120px;
  width: 100%;
  line-height: 1.6;
}

.light-icon-copy {
  /* background-color: teal;
  color: #fff; */
  cursor: pointer;
}
.embed__preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.embed__preview__heading {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #203d4a;
}

.copyright-footer-div {
  text-align: center;
  margin: 12px 0px 16px 0px;
}
.pc-social-media-div {
  margin-top: 28px;
  display: flex;
  flex-direction: column;
}

.pc-social-media-head {
  /* text-align: center; */
  margin-left: 72px;
  font-size: 24px;
  font-weight: 600;
  /* border-bottom: 4px solid teal; */
  margin-bottom: 20px;
}

.pc-social-media-grid {
  display: flex;
  justify-content: center;
}

.pc-social-logo {
  width: 52px;
  margin-right: 12px;
  cursor: pointer;
}
.pc-qr-code-head {
  font-size: 24px;
  font-weight: 600;
  margin-top: 28px;
  margin-left: 72px;
}
.pc-qr-code-subhead {
  font-size: 13px;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 72px;
}

.pc-qr-code-img-div {
  /* display: flex;
  justify-content: flex-start; */
  margin-left: 72px;

  margin-top: 20px;
}
.pc-qr-code-img {
  width: 192px;
  cursor: pointer;
}
</style>
