<template>
  <div style="overflow-x: auto; max-width: 100%;">
    <!-- <div class="res-table">
    <div class="res-row --header">
      <div class="res-cell --bold">Field Label</div>
      <div class="res-cell" v-for="(response, i) in responses" :key="response.res_id">R{{ i + 1 }}</div>
    </div>
    <div class="res-row" v-for="question in questionsList" :key="question.id">
      <div class="res-cell --bold">{{ question.label }}</div>
      <div class="res-cell" v-for="response in responses" :key="response.res_id">{{ response.response[question.id] }}</div>
    </div>
  </div> -->
    <!--  -->
    <div class="res-table">
      <div class="res-row --header">
        <div class="res-cell --bold">Field Label</div>
        <div class="res-cell" v-for="(question, j) in questionsList" :key="question.id">Q{{ j + 1 }}.) {{ question.label }}</div>
        <div class="res-cell">Date Submitted</div>
      </div>
      <div class="res-row" v-for="(response, k) in responses" :key="response.res_id">
        <div class="res-cell --bold">R{{ k + 1 }}</div>
        <div class="res-cell" v-for="question in questionsList" :key="question.id">{{ response.response[question.id] }}</div>
        <div class="res-cell">{{ formatUnixTime(response.date_submitted) }}</div>
      </div>
    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  props: ['responses', 'schema'], // 'maxResLimit'
  data() {
    return {
      formattedTime: '',
      // unixTime:"",
    };
  },
  computed: {
    questionsList() {
      let localList = [];
      if (this.schema) {
        this.schema.sections.forEach((section, sectionIndex) => {
          // if (sectionIndex + 1 < this.schema.sections.length) {
          section.rows.forEach(row => {
            row.fields.forEach(field => {
              if (field.read_only == false) {
                localList.push({
                  ...field,
                  section_id: section.id,
                  row_id: row.id,
                });
              } //
            });
          });
          // } //if condtion bracket
        });
      }
      return localList;
    },
  },

  // const timeStampDate = record.createdAt;
  // const dateInMillis  = timeStampDate._seconds * 1000

  // var date = new Date(dateInMillis).toDateString() + ' at ' + new Date(dateInMillis).toLocaleTimeString()
  // OutPut Example: Sat 11 Jul 2020 at 21:21:10

  methods: {
    formatUnixTime(unixTime) {
      if (typeof unixTime == 'number') {
        //if unixtime is a string, its divided by 1000, if unix time is object it is untouched
        let seconds = unixTime;
        unixTime = {};
        unixTime.seconds = seconds / 1000;
      }
      const dateInMillis = unixTime.seconds * 1000;
      var date = new Date(dateInMillis).toDateString() + ' at ' + new Date(dateInMillis).toLocaleTimeString();

      return date;
    },
  },
};
</script>

<style lang="scss">
.res-table {
  display: table;
  .res-row {
    display: table-row;
    &.--header {
      background-color: #b2bec3;
    }
  }
  .res-cell {
    display: table-cell;
    padding: 12px;
    &.--bold {
      font-weight: 600;
    }
  }
}
</style>
