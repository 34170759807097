const override = process.env.DB_OVERRIDE === 'true' || false;
const productionConfig = {
  firebase: {
    apiKey: 'AIzaSyBmojqHQPK86e0MLFBmxhwCb3sHmqoFx3g',
    authDomain: 'auth.formstudio.io',
    databaseURL: 'https://formstudio-io.firebaseio.com',
    projectId: 'formstudio-io',
    storageBucket: 'formstudio-io.appspot.com',
    messagingSenderId: '373420745925',
    appId: '1:373420745925:web:c3737b0b12ba847c2a7cac',
    measurementId: 'G-DHZWZ7R67P',
  },
  ga_id: 'G-8JM8GZLBWP',
  // api_base_url: 'https://api.formstudio.io',
  api_base_url: 'https://link.formstudio.io/api',
  link_base_url: 'https://link.formstudio.io',
  environment: 'production',
};
const developmentConfig = {
  firebase: {
    apiKey: 'AIzaSyC4xrP8S7kCpDu2NdgPVwKDfoD_pfPjSI8',
    authDomain: 'formstellar.firebaseapp.com',
    databaseURL: 'https://formstellar.firebaseio.com',
    projectId: 'formstellar',
    storageBucket: 'formstellar.appspot.com',
    messagingSenderId: '96087742493',
    appId: '1:96087742493:web:ad05764ff27bb060bc4ef4',
    measurementId: 'G-V1DVGC5PJP',
  },
  ga_id: 'G-383JSG3QT7',
  api_base_url: 'http://localhost:3000/api',
  link_base_url: 'http://localhost:3000',
  environment: 'devlopment',
};

const config = {
  development: {
    ...developmentConfig,
    firebase: override ? productionConfig.firebase : developmentConfig.firebase,
  },
  production: productionConfig,
  beta: {
    ...productionConfig,
    api_base_url: 'https://beta.link.formstudio.io/api',
    link_base_url: 'https://beta.link.formstudio.io',
    environment: 'beta',
  },
};

const isBeta = process.env.VUE_APP_MODE === 'beta' || process.env.MODE === 'beta';
const isProduction = process.env.NODE_ENV === 'production';

export default isBeta ? config['beta'] : isProduction ? config['production'] : config['development'];
