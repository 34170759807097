<template>
  <div>
    <!----------------------------------------------PLAYGROUND------------------------------------------------------------>

    <section class="fs-pg" v-if="schema && schema.settings" :style="{ background: schema.settings.styles.playground_background }">
      <div
        class="fs-pg__form-wrap"
        :style="{
          'border-color': schema.settings.head_color,
          'border-radius': schema.settings.border_radius,
          background: schema.settings.styles.form_background,
        }"
        :class="{ '--no-border': !schema.settings.border_width, '--form-wrap-shadow': !schema.settings.styles.form_shadow }"
      >
        <div class="fs-pg__form-header" :style="{ 'background-color': schema.settings.styles.form_layout_color || schema.settings.styles.form_theme_color, color: schema.settings.styles.header_font_color }" :class="{ '--d-none': !schema.settings.form_header, '--ai-center': schema.settings.form_header_align == 'center', '--ai-end': schema.settings.form_header_align == 'right' }">
          <div class="fs-pg__form-heading" :style="{ 'font-size': schema.settings.styles.header_font_size }">{{ schema.settings.form_heading }}</div>
          <div class="fs-pg__form-desc" :style="{ 'font-size': schema.settings.styles.header_descp_font_size }">
            {{ schema.settings.form_description }}
          </div>
        </div>
        <form-separator class="fs-pg__separator" :class="{ '--d-none': !schema.settings.styles.header_separator_visibility }" />

        <div class="fs-pg__form-body" v-if="!submitted">
          <div v-for="(section, sectionIndex) in schema.sections" :key="'sec_' + section.id">
            <div>
              <div class="fs-pg__form-section-heading" v-if="schema.settings && schema.settings.section_heading_visibility && schema.sections[sectionIndex].heading !== undefined && schema.sections[sectionIndex].rows">
                {{ schema.sections[sectionIndex].heading }}
              </div>
              <div v-for="(row, rowIndex) in schema.sections[sectionIndex].rows" :key="'row_' + row.id">
                <div class="fs-pg__form-row" v-for="(field, fieldIndex) in row.fields" :key="field.id">
                  <!-- <component
                    :is="field.field_type"
                    v-model="formData[field.id]"
                    :name="field.name"
                    v-bind="field"
                    :rounded="schema.settings.rounded_input"
                  ></component> -->
                  <PlaygroundQuestion :field="field" :settings="schema.settings" :value="response.response[field.id]"></PlaygroundQuestion>
                </div>

                <!--  -->
              </div>
              <br />
              <!--- Next & Back Section Button-- -->
              <!-- <div class="next-back-row"> -->
              <!-- <lv-button
                  push
                  class="lv--success"
                  v-if="sectionIndex > 0"
                  @click="backSection"
                  icon="light-icon-arrow-left"
                  label="Back"
                  :rounded="schema.settings.rounded_input"
                /> -->
              <!-- <div v-if="sectionIndex === 0"></div>
                <lv-button
                  push
                  class="lv--success"
                  :rounded="schema.settings.rounded_input"
                  v-if="sectionIndex + 1 < schema.sections.length"
                  @click="nextSection"
                  icon-right="light-icon-arrow-right"
                  >Next</lv-button
                > -->
              <!-- <lv-button
                  push
                  class="lv--success"
                  :rounded="schema.settings.rounded_input"
                  icon-right="light-icon-plane-departure"
                  @click="onSubmit"
                  v-if="sectionIndex + 1 === schema.sections.length"
                  >Submit</lv-button
                > -->
              <!-- </div> -->
            </div>
          </div>
          <!-- Show only if not availabe in schema -->
        </div>
      </div>
      <!-- ************ -->
    </section>
    <!-- ********************************************************************************************************* -->
  </div>
</template>

<script>
import './Playground.scss';

import PlaygroundQuestion from './PlaygroundQuestion.vue';
import FormInput from './elements/FormInput.vue';
import FormToggle from './elements/FormToggle.vue';
import Watermark from './Watermark.vue';
import LvButton from 'lightvue/button';
import FormSeparator from './elements/FormSeparator';
export default {
  name: 'FormsDemo',
  props: ['schema', 'response'],

  components: {
    FormInput,
    FormToggle,
    Watermark,
    LvButton,
    PlaygroundQuestion,
    FormSeparator,
  },

  data() {
    return {
      currentSection: 0,
      formData: {},
      submitted: false,
    };
  },

  methods: {
    // nextSection() {
    //   this.currentSection++;
    // },
    // backSection() {
    //   this.currentSection--;
    // },
    // resetForm() {
    //   this.currentSection = 0;
    //   this.submitted = false;
    // },
    // onSubmit() {
    //   this.$emit('submit', this.formData);
    //   setTimeout(() => {
    //     this.submitted = true;
    //   }, 400);
    // },
  },
};
</script>
