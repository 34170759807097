<template>
  <div>
    <p class="cp-text">© Copyright 2020 FORM-STUDIO. All rights reserved.</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.cp-text {
  /* color: rgba(0, 0, 0, 0.7); */
  font-size: 12px;
  opacity: 0.6;
  text-shadow: 0 1px rgba(255, 255, 255, 0.1);
}
</style>