<template>
  <div>
    <div v-if="!currentForm && !currentForm.schema && !currentForm.form_type">
      <lv-list-loader :width="300" :height="100" style="margin: 128px auto; display: block;" />
    </div>
    <!-- <div v-if="!loading"> -->
    <div class="setting-header">
      <div class="top-heading">
        <!-- <div class="setting-icon"><i class="light-icon-settings"></i></div> -->
        <div v-if="currentForm.form_type === 'form'" class="setting-title">General Settings</div>
        <div v-if="currentForm.form_type === 'template'" class="setting-title">Template Settings</div>

        <lv-button class="lv--success button__styling" v-if="currentForm.status === 'draft' && currentForm.form_type === 'form'" push @click="publishForm" size="lg">Publish Form</lv-button>
        <lv-button class="lv--success button__styling" v-if="currentForm.status === 'published' && currentForm.form_type === 'form'" push @click="shareForm()" size="lg">Share & Embed</lv-button>
        <!-- template marketplace button -->
        <lv-button class="lv--success button__styling" v-if="currentForm.form_type === 'template'" push @click="publishTemplate" size="lg">Publish to Marketplace</lv-button>
      </div>
      <div class="setting-description" v-if="currentForm.form_type === 'form'">
        Setup your project details, change your project's unique URL, set a project end date and more
      </div>
    </div>
    <!------------------ Form Setting card --------------------------->
    <div class="settings__card" v-if="currentForm.form_type === 'form'">
      <!-- -----------Setting Header------- -->
      <!-- --------------Card Start------------------ -->
      <div class="settings-card-body">
        <!-- Project Name Block -->
        <div class="project-name-block">
          <!-- <div class="setting-field-label">Project Name</div> -->
          <div>
            <lv-input v-model="currentForm.project_name" bottom-bar label="Project Name" />
          </div>
        </div>
        <!-- Project URL Block -->
        <div class="slug-validation-msg">{{ feedback }}</div>
        <div class="project-url-block">
          <div>
            <lv-input v-model="computedSlug" bottom-bar @change="checkSlug(computedSlug)" label="Project URL">
              <template #prepend>
                <div class="preview-base-url">{{ publishURL }}</div>
              </template>
              <template #append v-if="slug_status">
                <div class="slug-status --success" v-if="slug_status === 'available'" title="available">
                  <i class="light-icon-circle-check" />
                </div>
                <div class="slug-status --danger" v-else-if="slug_status === 'not-available'" title="not available">
                  <i class="light-icon-circle-x" />
                </div>
                <div class="slug-status" v-else>
                  <i class="light-icon-target" />
                </div>
              </template>
            </lv-input>
          </div>
        </div>

        <!-- Form Language & End Date -->
        <!-- <div>
          <lv-toggle-switch label="Stop response collection after end-date" v-model="currentForm.req_end_date"></lv-toggle-switch>
          <lv-input v-if="currentForm.req_end_date" v-model="currentForm.end_date" label="Project End Date" />
        </div> -->

        <!------------------- WaterMark & Security -------------------------->
        <div class="form-divide-container">
          <!-- -------check box for removing watermark -->
          <div class="watermark-block">
            <lv-toggle-switch label="Remove FormStudio watermark" name="remove-watermark" v-model="removeWatermark" />
          </div>
          <!-- -------------Public or Private -->
          <!-- <div class="security-block">
            <div>
              <span class="security-label">Security</span>
            </div>
            <div class="security-input-row">
              <input
                type="radio"
                id="public"
                name="public"
                value="public"
                class="security-input"
              />
              <label for="public" class="public-label">Public</label>
              <input
                type="radio"
                id="private"
                name="private"
                value="private"
                class="security-input"
              />
              <label for="private" class="private-label">Private</label>
              <br />
            </div>
          </div>-->
          <!-- ------------------------ -->
        </div>
        <!-- --------------------Chat Widget--------------------- -->
        <!-- <div class="chat-integration-block">
          <label class="chat-label" for="chats">Integrate Chat Widget:</label>
          <select id="cars" name="chats" class="chat-dropdown">
            <option value="volvo">MailChimp</option>
            <option value="saab">Saab</option>
            <option value="fiat">Fiat</option>
            <option value="audi">Audi</option>
          </select>
        </div>-->
        <!-- ------------------- -->

        <!----------------------------- Update Settings Button ---------------------------->
        <!-- <div class="update-setting">
          <button class="update-btn" @click="handleUpdate">
            Update Settings
          </button>
        </div>-->
        <!-- ---------------- -->
      </div>

      <!-- --Collaboration section: Begins--- -->
      <!-- <lv-input v-model="collaboratorEmail" label="Add collaborator using email" >
        <template #append>
          <LvButton @click="addCollaborator" icon="light-icon-plus" class="lv--success" />
        </template>
      </lv-input> -->
      <!-- --Collaboration section: Ends--- -->

      <!-- --Ready To Publish--- -->
      <div class="publish-row">
        <lv-button @click="handleUpdate" class="lv--success button__styling" push size="lg">Update Settings</lv-button>
        <lv-button class="lv--success button__styling" v-if="currentForm.form_type == 'form'" push @click="showTemplateModal = !showTemplateModal" size="lg">Save as Template</lv-button>
        <lv-button v-if="currentForm.form_type == 'template'" class="lv--success button__styling" push size="lg">Save to Marketplace</lv-button>
      </div>
      <!-- -----------General Settings Card Ends----------- -->
    </div>
    <!-- </div> -->
    <!-- loading -->

    <!--*** -->
    <!-- Template Settings Card -->
    <!-- <template-modal
    :templateHeading="false"
    :templateCreated="true"
      :currentForm="currentForm"
      :currentForm="currentForm"
      :user="user"
      v-if="currentForm.form_type==='template'"
    /> -->
    <!-- *** -->
    <!-- Template Modal -->
    <!-- :templateCreated="false"
    :templateHeading="true" -->
    <template-modal :currentForm="currentForm" v-if="currentForm && (showTemplateModal || currentForm.form_type === 'template')" />
  </div>
</template>
<script>
import Forms from '../../models/form_schema';
import Templates from '../../models/templates';
import { getSlug } from '@main/utils';
import LvInput from 'lightvue/input';
import LvButton from 'lightvue/button';
import LvToggleSwitch from 'lightvue/input-toggle';
import { currentFormMixin } from '@main/mixins';
import TemplateModal from './TemplateModal.vue';
import LvListLoader from 'lightvue/skeleton/preset/ListLoader.vue';
import config from '@/config';

export default {
  mixins: [currentFormMixin],
  components: {
    LvInput,
    LvToggleSwitch,
    LvButton,
    LvListLoader,
    TemplateModal,
  },
  props: ['slug'],

  data() {
    return {
      loading: false,
      project_name: '',
      form_slug: '',
      slug_status: '', // 'checking', 'available', 'not-available'
      // editedSlugValue: "",
      publishURL: config.link_base_url + '/p/',
      name: '',
      accept: false,
      // endDate: '',
      feedback: null,
      removeWatermark: false,
      showTemplateModal: false,
      // collaboratorEmail: ''
    };
  },
  created() {
    // this.formDetails(); // taking from store, since with-in editor page.
    this.form_slug = this.$route.query.id;
  },

  watch: {
    currentForm: {
      handler(newVal, oldVal) {
        // console.log(newVal);

        // here having access to the new and old value
        if (this.currentForm && this.currentForm.schema && this.currentForm.schema.settings) {
          this.project_name = this.currentForm.project_name;
          console.log(this.currentForm);
        }
      },
      deep: true,
      immediate: true, //  Also very important the immediate in case you need it, the callback will be called immediately after the start of the observation
    },
  },

  computed: {
    computedSlug: {
      get: function() {
        return this.form_slug || getSlug(this.project_name || '');
      },
      set: function(newValue) {
        this.form_slug = getSlug(newValue);
      },
    },
    user: {
      get: function() {
        return this.$store.state.user.userDetails;
      },
    },
  },

  methods: {
    // async formDetails() {
    //   this.loading = true;
    //   this.project_name = this.currentForm.project_name;
    //   let settingForm = await Forms.getBySlug(this.currentForm.form_slug);
    //   this.currentForm = settingForm.data;
    //   this.loading = false;
    //   // this.project_name = this.settingForm.data.project_name;
    //   console.log('setting form: Project Name', this.settingForm.data.project_name);
    //   const TimestampDate = this.currentForm.end_date;
    //   // this.currentForm.end_date = TimestampDate.toDate(); //error toDate is not function
    //   // console.log(TimestampDate.toDate());
    //   // console.log(this.currentForm.end_date.seconds);
    // },

    async checkSlug(form_slug) {
      this.slug_status = 'checking';
      let is_available = await Forms.isSlugAvailable(form_slug);
      if (is_available || form_slug === this.currentForm.form_slug) {
        this.slug_status = 'available';
        // this.feedback = "Slug Available";
      } else {
        this.slug_status = 'not-available';
        // this.feedback = "Not Available";
      }
      console.log(form_slug, is_available);
    },
    async publishForm() {
      // console.log(this.currentForm);
      this.currentForm.status = 'published';
      // console.log(this.currentForm.status);
      if (this.currentForm && this.$route.query.id) {
        var form3 = new Forms({
          // data: this.currentForm,
          docId: this.currentForm._id,
        });

        form3.push({
          status: this.currentForm.status,
        }); //push will save the data locally in unsaved_data
        await form3.save(); //save will save the data on firestore by pushing unsaved_data & updating date
        console.log('form saved', form3);

        this.$router.push({
          name: 'Publish',
          query: { id: this.$route.query.id },
        });
      }
    },
    // Publish template to MarketPlace
    async publishTemplate() {
      //  this.currentForm.security = 'public';
      if (this.currentForm && this.$route.query.id) {
        var templateForm = new Forms({
          // data: this.currentForm,
          docId: this.currentForm._id,
        });

        templateForm.push({
          verified: false,
          security: 'public',
        }); //push will save the data locally in unsaved_data
        await templateForm.save(); //save will save the data on firestore by pushing unsaved_data & updating date
        this.$toast.add({
          content: 'Sent for Verification !!',
          type: 'info',
          duration: 2000,
        });
        console.log('form saved', templateForm);
        // debugger;
        this.$router.push({
          name: 'Dashboard',
          // query: { id: this.$route.query.id },
        });
      }
    },

    async handleUpdate() {
      console.log('on updating data', this.currentForm);
      if (this.currentForm && this.$route.query.id) {
        var form3 = new Forms({
          // data: this.currentForm,
          docId: this.currentForm._id,
        });

        form3.push({
          form_slug: this.form_slug,
          project_name: this.currentForm.project_name,
          req_end_date: this.currentForm.req_end_date,
          end_date: this.currentForm.end_date,
        }); //push will save the data locally in unsaved_data
        await form3.save(); //save will save the data on firestore by pushing unsaved_data & updating date
        console.log('form saved', form3);
        this.$toast.add({
          content: 'Settings Updated !!',
          type: 'Success',
          duration: 2000,
        });
        this.$router.push({
          name: 'Editor',
          query: { id: this.form_slug },
        });
      }
    },
    // ***
    shareForm() {
      let form_slug = this.$route.query.id;
      this.$router.push({
        name: 'Publish',
        query: { id: this.$route.query.id },
      });
    },

    // async addCollaborator() {
    // console.log({id: this.currentForm._id, collaboratorEmail: this.collaboratorEmail})
    // if(this.collaboratorEmail === '') return;
    // let res = await Forms.addCollaborator(this.currentForm._id, this.collaboratorEmail)
    // alert(res.status)
    // }
  },
};
</script>
<style scoped lang="scss">
.settings-page {
  padding: 50px;
  overflow-x: hidden;
}

.settings-card-body {
  background-color: #ffffff;
  // padding: 30px;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); */
}

.button__styling {
  font-size: 14px;
  margin-right: 8px;
}

.top-heading {
  display: flex;
  justify-content: space-between;
}
.project-name-block,
.project-url-block {
  margin-top: 10px;
  margin-bottom: 16px;
}
.project-name-field,
.project-url-field .label {
  margin-bottom: 8px;
}

.setting-field-label {
  margin-bottom: 4px;
}

.input-field {
  padding: 0.5rem 0.75rem 0.5rem 1rem;
}

.input-field input[type='text'] {
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
}

.form-divide-container {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}
.lang-block {
  width: 50%;
  margin-right: 16px;
}

.projectName-input,
.projectUrl-input,
.lang-input-field,
.date-input-field {
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0.7rem;
}

.security-block {
  width: 50%;
}

.watermark-check {
  margin-right: 4px;

  /* color-adjust: exact; */
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  height: 1.6rem;
  width: 1.6rem;
  color: teal;
  background-color: #fff;
  border-color: #a0aec0;
  border-width: 1px;
  border-radius: 0.25rem;
}

.security-block {
  margin-left: 28px;
}

.security-input-row {
  margin-top: 8px;
}

.security-input {
  margin-left: 4px;
  margin-right: 4px;
}

.chat-label {
  margin-right: 4px;
}

.chat-dropdown {
  padding: 4px 8px;
}

.update-btn {
  padding: 8px 12px;
  background: teal;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 12px;
  margin-top: 16px;
}
.publish-row {
  display: flex;
  // justify-content: space-around;
  margin-top: 30px;
  align-items: center;
  background: #fff;
  padding-bottom: 30px;
}
.publish-btn {
  padding: 8px 12px;
  background: teal;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  outline: none;
}
.preview-base-url {
  display: flex;
  align-items: center;
  padding-left: 12px;
  margin-right: -8px !important;
  font-size: 14px;
  margin-bottom: 3px;
}

.slug-validation-msg {
  color: red;
  font-size: 12px;
}
.slug-status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  color: rgba(0, 0, 0, 0.2);
  i {
    font-size: 20px;
    font-weight: 600;
  }
  &.--success {
    color: teal;
  }
  &.--danger {
    color: rgb(216, 36, 36);
  }
}
</style>
