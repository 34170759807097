<template>
  <div>
    <!-------------- Page Conatiner---------------------- -->
    <main>
      <div class="page-container">
        <!-- <img src="../assets/background.svg" class="page-background" /> -->
        <div class="upper-section">
          <!-- row above templates -->
          <!-- <div class="row-above-templates">
            <div class="new">New</div>
            <div class="hide-templates">
              <p>Hide Templates</p>
            </div>
          </div> -->

          <!-- Templates Row -->
          <div class="template-row light-scrollbar--none">
            <!--CREATE NEW FORM Card -->

            <div class="new-form-col">
              <div class="button">
                <div class="one" @click="newForm()">
                  <div class="content">New Form</div>
                </div>
                <div class="one" @click="$router.push({ name: 'QuizComingSoon' })">
                  <div class="content">New Quiz</div>
                </div>
              </div>
            </div>

            <!----------- Templates Cards -------->
            <div v-for="template in templates_list" :key="template.id" class="template-cards-wrap" @click="createFromTemplate(template.data.form_slug)">
              <div class="template-card">
                <div class="img">
                  <img src="@main/assets/pattern.png" class="template-image" />
                </div>

                <div class="template-content">
                  {{ template._data.project_name }}
                </div>
              </div>
            </div>
          </div>
          <!-- -------------Cards End------ -->

          <hr class="templates-hr" />
          <!-- More templates row -->
          <!-- <div class="more-templates">
            <button class="more-templates-button">
              <p>More Templates </p>
            </button>
          </div> -->
          <!-- ------------- -->
          <!-- -------Template Row End------- -->
        </div>
        <!----------------------------- ***UPPER SECTION ENDS*** --------------->

        <!-- Bottom Section End -->
      </div>
    </main>
  </div>
</template>

<script>
import Templates from '../../models/templates';
import Forms from '../../models/form_schema';
import { getSlug } from '../../utils/slug';
import { uid } from '../../utils/uid';
import { userMixin } from '@main/mixins';

export default {
  mixins: [userMixin],

  data() {
    return {
      templates_list: [],
      loading: false,
      // templateID:"",
    };
  },

  created() {
    this.getTemplates();
  },
  methods: {
    newForm() {
      // if (this.user.forms_count < this.user.max_forms)
      this.$router.push({ name: 'ProjectType' });
      // else {
      //   this.$toast.add({
      //     content: 'Please Upgrade Your Plan !!',
      //     type: 'info',
      //     duration: 3000,
      //   });
      // }
    },

    async getTemplates() {
      this.loading = true;
      this.templates_list = await Forms.getPublicTemplates();
      // console.log('templates_list', this.templates_list);

      this.loading = false;
      let templates_number = this.templates_list.length;
      // console.log('NUMBER OF Templates', templates_number);
    },

    async createFromTemplate(templateSlug) {
      this.$router.push({
        name: 'Create',
        query: { template: templateSlug },
      });

      // console.log(currentForm_dropdown)
      // console.log(duplciateForm)
    },
    // openFormLink(form_slug) {
    //   let url = PUBLISH_BASE_URL + form_slug;
    //   window.open(url, "_blank");
    // },
    // handleDelete(currentForm_id) {
    //   let removedIndex = this.form_list.findIndex(
    //     (form) => form.data._id === currentForm_id
    //   );
    //   if (removedIndex !== -1) {
    //     this.form_list.splice(removedIndex, 1);
    //   }
    // },
  },
};
</script>

<style scoped>
/* -------------------- */
.row-above-templates {
  display: flex;
  padding-top: 20px;
  padding-left: 24px;
}

.hide-templates:last-of-type {
  margin-left: auto;
  padding-right: 28px;
}

/* ----------------- */

/* UPPER SECTION */
.upper-section {
  /* background-color: #f2f2f2; */
  /* margin-top: 2%;
  margin-left: 1%;
  margin-right: 1%; */
  /* max-height: 250px; */
}

/*---------- Create Form Card ----------*/
.new-form-col {
  margin-right: 16px;
}

.button {
  display: flex;
  flex-direction: column;
}
.one {
  width: 250px;
  background-color: #ffffff;
  padding: 35px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
}
.one:hover {
  border-color: #969696;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  color: inherit;
}

/* --------- */

/* --------TEMPLATE ROW --------*/
.template-row {
  display: flex;
  margin: auto;

  overflow: auto;
  padding: 0px 50px;
  /* height: 200px; */
}

/* -----------TEMPLATE CARDS------------------ */

.template-card {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  width: 240px;
  /* height: 260px; */
  background-color: #ffffff;
  border-radius: 4px;
  margin-right: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.template-card:hover {
  /* background: #eaeaea; */
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

/* .img {
  background-color: #ffffff;
  width: 220px;
  height: 120px;
} */
.template-image {
  width: 240px;
  height: 92px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  /* padding: 10px; */
  background-color: #38b2ab;
}
.template-cards-wrap:nth-child(even) .template-image {
  background-color: #258ebb;
}
.template-content {
  color: #203d4a;
  height: 90px;
  padding: 16px;
}
/* .img-content {
  font-size: 15px;
  margin: 15px 15px 0px;
  color: #555555;
} */
/* background-image: url('/pattern.png'); */

/* ------------------------------------------------------------------------------- */

.templates-hr {
  background: #eaeaea;
  height: 1px;
  margin: 5px 50px 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 0;
  border-top: 2.5px solid rgb(199, 194, 194);
  /* ---- */
  display: block;
  opacity: 0.3;
  /* unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  box-sizing: content-box;  */
}

.more-templates {
  display: flex;
  padding-bottom: 12px;
  padding-left: 24px;
}
.more-templates-button:last-of-type {
  margin-left: auto;
}

.more-templates-button {
  margin-right: 15px;
  background-color: rgba(0, 0, 0, 0.002);
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: transparent solid 1px;
  cursor: pointer;
}
/* -------------------------------------------------------- */

/* ------------------------- */

/* ---------------------------------- */
</style>
