import { createApp } from 'vue';
import App from './App.vue';
// import './registerServiceWorker'
import Router from './router';
import store from './store';
import LvButton from 'lightvue/button';
import VueGtag, { trackRouter } from 'vue-gtag-next';
import Hotjar from 'vue-hotjar';

import firebase from 'firebase/app';
import 'firebase/auth';
import './utils/smooth-scroll.scss';
import config from './config';
import LvToast from 'lightvue/toast';
// import {config} from './config/index'
trackRouter(Router);

const app = createApp(App)
  .use(store)
  .use(Router);

app.config.globalProperties.$listeners = '';
app.component('LvButton', LvButton);
app.use(LvToast);
app.use(Hotjar, {
  id: '2318281',
  snippetVersion: 6,
  isProduction: config.environment === 'production' ? true : false,
});
app.use(VueGtag, {
  property: {
    id: config.ga_id,
    params: {
      //   user_id: '12345',
    },
  },
});

app.mount('#app');
// Initialize Firebase
firebase.initializeApp(config.firebase);

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    //logged in
  } else {
    //logged out
    window.localStorage.clear();
    window.sessionStorage.clear();
    Router.replace({ name: 'Home' });
  }
});

// if (window.ga) {
// gtag('set', 'page', Router.currentRoute.value);
// gtag('send', 'pageview');
// gtag('event', 'page_view', {
//   page_title: 'INIT',
//   page_location: Router.currentRoute.value,
//   page_path: Router.currentRoute.value,
//   // send_to: '<GA_MEASUREMENT_ID>'
// });
// }

// Router.afterEach((to, from) => {
// if (config.googleAnalyticId) {
//   if (to.name !== from.name) {
//     ga('set', 'page', to.name);
//     ga('send', 'pageview');
//   }
// }
//   if (window.ga) {
//   gtag('set', 'page', to.path);
//   gtag('send', 'pageview');
//   gtag('event', 'page_view', {
//     page_title: to.name,
//     page_location: to.fullPath,
//     page_path: to.path,
//     // send_to: '<GA_MEASUREMENT_ID>'
//   });
//   }
// });
