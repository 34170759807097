import { defaultSchema } from './defaultSchemas';

const state = {
  schema: defaultSchema,
  selectedSection: 0,
  activeRowId: '',
};
// --------------------------------------------------

const mutations = {
  setSchema: (state, schema) => {
    state.schema = schema;
  },
  setSelectedSection: (state, selectedSection) => {
    state.selectedSection = selectedSection;
  },

  setActiveRowId: (state, activeRowId) => {
    state.activeRowId = activeRowId;
  },

  setFormHeading: (state, formHeading) => {
    state.schema.settings.form_heading = formHeading;
  },

  setFormDescription: (state, formDescription) => {
    state.schema.settings.form_description = formDescription;
  },

  setHeadColor: (state, headColor) => {
    state.schema.settings.head_color = headColor;
  },
  setBorderColor: (state, borderColor) => {
    state.schema.settings.border_color = borderColor;
  },
  setBorderRadius: (state, borderRadius) => {
    state.schema.settings.border_radius = borderRadius;
  },

  setProgressBarColor: (state, progressbar_color) => {
    state.schema.settings.progressbar_color = progressbar_color;
  },
  setSettings: (state, settings) => {
    state.schema.settings = settings;
  },
};

// -----------------------------------------------------
const actions = {
  setSchema: ({ commit }, schema) => {
    commit('setSchema', schema);
  },
  setSelectedSection: ({ commit }, selectedSection) => {
    commit('setSelectedSection', selectedSection);
  },

  setActiveRowId: ({ commit }, activeRowId) => {
    commit('setActiveRowId', activeRowId);
  },

  setFormHeading: ({ commit }, formHeading) => {
    commit('setFormHeading', formHeading);
  },

  setFormDescription: ({ commit }, formDescription) => {
    commit('setFormDescription', formDescription);
  },

  setHeadColor: ({ commit }, headColor) => {
    commit('setHeadColor', headColor);
  },

  setBorderColor: ({ commit }, borderColor) => {
    commit('setBorderColor', borderColor);
  },

  setBorderRadius: ({ commit }, borderRadius) => {
    commit('setBorderRadius', borderRadius);
  },

  setProgressBarColor: ({ commit }, progressbar_color) => {
    commit('setProgressBarColor', progressbar_color);
  },

  setSettings: ({ commit }, settings) => {
    commit('setSettings', settings);
  },
};

// -------------------------------------------------------

const getters = {
  getSchema: state => {
    state.schema;
  },
};

const builder = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
export default builder;
