import { uid } from '../utils/uid';

const CTA_ACTION_TYPES = {
  NEXT_SECTION: 'NEXT_SECTION',
  PREV_SECTION: 'PREV_SECTION',
  SUBMIT: 'SUBMIT',
  SUBMIT_ANOTHER: 'SUBMIT_ANOTHER',
  REDIRECT: 'REDIRECT',
  OPEN_LINK: 'OPEN_LINK',
};

export interface IField {
  field_type?: string;
  read_only?: boolean;
  help_block?: boolean;
  help_block_text?: string;
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  placeholder_required?: boolean;
  required?: boolean;
  rounded?: boolean;
  optionLabelKey: string;
  optionValueKey: string;
  options?: Object[];
  enable_multiselect?: boolean;
  options_layout?: 'auto' | 'grid';
  button_type?: string;
  cta_action_type?: string;
  background_color?: string;
  deepShadow?: boolean;
  font_color?: string;
}

interface IFieldConfigs {
  cta_type?: 'normal' | 'submit' | 'resubmit';
}

export function createNewField(type: string, defaults?: IField, configs?: IFieldConfigs): IField {
  let newFieldDefaults: IField = {
    read_only: false,
    placeholder_required: false,
  };
  let newField: IField;
  let newFieldSelectDefaults: IField = {
    optionLabelKey: 'label',
    optionValueKey: 'label',
    options: [
      {
        label: 'Option 1',
        correct: false,
      },
      {
        label: 'Option 2',
        correct: false,
      },
      {
        label: 'Option 3',
        correct: false,
      },
      {
        label: 'Option 4',
        correct: false,
      },
    ],
  };
  switch (type) {
    // field_type= case = element Wrapper Name=element type
    // here these properties are saved in our database
    // for database underscore case(snake_case)
    // for vue props either camelCase or kebab-case
    case 'TextInput': {
      newField = {
        ...newFieldDefaults,
        field_type: 'TextInput',
        read_only: false,
        placeholder: 'Input Text',
        label: 'Enter Your Field Label',
        required: false,
        help_block: false,
        help_block_text: '',
      };
      break;
    }
    case 'FormInput': {
      newField = {
        ...newFieldDefaults,
        field_type: 'FormInput',
        read_only: false,
        placeholder_required: false,
        label: 'Enter Your Field Label',
        name: uid(),
        required: false,
        help_block: false,
        help_block_text: '',
      };
      break;
    }

    case 'FormToggle': {
      newField = {
        ...newFieldDefaults,
        field_type: 'FormToggle',
        read_only: false,
        label: 'Enable Label',
        name: uid(),
        dense: true,
        required: false,
        help_block: false,
        help_block_text: '',
      };
      break;
    }
    case 'FormNumber': {
      newField = {
        ...newFieldDefaults,
        field_type: 'FormNumber',
        read_only: false,
        placeholder: 'Number Input',
        label: 'Enter Your Field Label',
        minValue: 0,
        required: false,
        placeholder_required: false,
        help_block: false,
        help_block_text: '',
      };
      break;
    }
    case 'FormContactNumber': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormContactNumber',
        read_only: false,
        placeholder_required: false,
        label: 'Contact number',
        country_code: '+91',
        name: uid(),
        required: false,
        help_block: false,
        help_block_text: '',
      };
      break;
    }
    case 'FormButton': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormButton',
        read_only: true,
        name: 'Btn',
        button_type: 'normal',
        label: 'Next',
        deepShadow: true,
        deepShadowHover: false,
        raised: false,
        rounded: true,
        push: false,
        outlined: false,
        required: false,
        align: 'center',
        background_color: '',
        font_color: '',
        btn_width: '300',
        btn_margin: '12',
        hidden: false,
        cta_action_type: CTA_ACTION_TYPES.NEXT_SECTION,
        cta_action_value: '',
      };
      if (configs?.cta_type === 'submit') {
        newField.label = 'Submit';
        newField.button_type = 'submit'; // TODO
        newField.cta_action_type = CTA_ACTION_TYPES.SUBMIT;
      }
      if (configs?.cta_type === 'resubmit') {
        newField.label = 'Submit Another Response';
        newField.button_type = 'resubmit'; // TODO
        newField.cta_action_type = CTA_ACTION_TYPES.SUBMIT_ANOTHER;
      }
      break;
    }

    case 'FormRating': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormRating',
        read_only: false,
        name: 'Rating',
        required: false,
        increment: 1,
        showRating: true,
        maxRating: 5,
        active_color: '	#ffe234',
        inactive_color: '#d8d8d8',
        star_size: 20,
        // label: "",
      };
      break;
    }

    case 'FormRadioGroup': {
      newField = {
        ...newFieldDefaults,
        ...newFieldSelectDefaults,
        field_type: 'FormRadioGroup',
        read_only: false,
        // label_req: true,
        required: false,
        label: 'Enter your label',
        color: '#037f7f',
        pulse: true,
        individual_score: 1,
        negative_score: 1,
        // img_show: false,
        // img_src: 'https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg',
        // img_margin_question: 10,
        // img_width_question: 300,
        // img_height_question: 300,
        // img_object_fit_q: 'cover',
        options_layout: 'auto',
      };
      break;
    }

    case 'FormDropdown': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormDropdown',
        read_only: false,
        name: 'Dropdown',
        required: false,
        label: 'Enter Your Label',
        placeholder: 'Select Options',
        rounded: false,
        individual_score: 1,
        negative_score: 1,

        // img_show: false,
        // img_src: 'https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg',
        // img_margin_question: 10,
        // img_width_question: 300,
        // img_height_question: 300,
        // img_object_fit_q: 'cover',

        floating_label: false,
        bottom_bar: false,
        ...newFieldSelectDefaults,
      };
      break;
    }

    case 'FormCheckboxGroup': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormCheckboxGroup',
        read_only: false,
        // label_req: true,
        label: 'Enter Your label',
        required: false,
        individual_score: 1,
        negative_score: 1,
        ...newFieldSelectDefaults,
        name: '',
        // img_show: false,
        // img_src: 'https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg',
        // img_margin_question: 10,
        // img_width_question: 300,
        // img_height_question: 300,
        // img_object_fit_q: 'cover',
        options_layout: 'auto',
        pulse: false,
        color: '#03FC53',
        // unique Id
      };
      break;
    }
    case 'FormButtonSelect': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormButtonSelect',
        read_only: false,
        // label_req: true,
        label: 'Enter Your label',
        label_size: 12,
        label_align: 'left',
        required: false,
        individual_score: 1,
        negative_score: 1,
        ...newFieldSelectDefaults,
        enable_multiselect: false,
        // img_show: false,
        // img_src: 'https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg',
        // img_margin: 12,
        // img_width: 300,
        // img_height: 200,
        // img_object_fit_q: 'cover',
        options_layout: 'grid',
        name: '',
        pulse: false,
        color: '#03FC53',
        deepShadow: false,
        deepShadowHover: false,
        raised: false,
        rounded: true,
        push: false,
        outlined: false,
        align: 'center',
        background_color: '',
        selected_btn_background: '',
        font_color: '',
        btn_width: '250',
        btn_margin: 4,
        options_top_margin: 12,
        // unique Id
      };
      break;
    }
    case 'FormPhotoSelect': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormPhotoSelect',
        read_only: false,
        // label_req: true,
        label: 'Enter your label',
        label_align: 'left',
        required: false,
        individual_score: 1,
        negative_score: 1,
        optionLabelKey: 'img_src',
        optionValueKey: 'img_src',
        options: [
          {
            label: '',
            img_src: 'https://i.picsum.photos/id/717/200/200.jpg?hmac=bKg3Tgutvsprm3Nh_kFIbGVlWJaT334I_qZCDn_INZ8',
            correct: false,
          },
          {
            label: '',
            img_src: 'https://i.picsum.photos/id/412/200/200.jpg?hmac=uEooNSqsBiLGg6Fwx10zkeVpge35EFeQ7bDQgmPfemI',
            correct: false,
          },
          {
            label: '',
            img_src: 'https://i.picsum.photos/id/558/200/200.jpg?hmac=tFHyh9KzOASFBog3Hpj6oSkBkBr90f67Yuejl0XnFDM',
            correct: false,
          },
          {
            label: '',
            img_src: 'https://i.picsum.photos/id/853/200/200.jpg?hmac=f4LF-tVBBnJb9PQAVEO8GCTGWgLUnxQLw44rUofE6mQ',
            correct: false,
          },
        ],
        enable_multiselect: false,
        name: '',
        // img_show: false,
        // img_src: 'https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg',
        // img_margin_question: 10,
        // img_width_question: 300,
        // img_height_question: 300,
        // img_object_fit_q: 'cover',
        pulse: false,
        color: '#03FC53',
        img_width: 120,
        img_height: 120,
        img_margin: 4,
        img_shape: 'square',
        selected_icon: 'light-icon-check',
        selected_icon_size: '64',
        layout: 'grid',
        // unique Id
      };
      break;
    }

    case 'FormTextarea': {
      newField = {
        ...newFieldDefaults,
        field_type: 'FormTextarea',
        read_only: false,
        placeholder: 'Input Text',
        placeholder_required: false,
        label: 'Enter Your Field Label',
        auto_resize: true,
        show_limit: true,
        max_length_required: false,
        max_length: null,
        required: false,
        help_block: false,
        help_block_text: '',
        rows: 3,
        column: null,
        rows_required: false,
        column_required: false,
      };
      break;
    }
    case 'FormRangeSlider': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormRangeSlider',
        read_only: false,
        required: false,
        // range: false,
        label: 'How would you rate us ?',
        min: 0,
        max: 100,
        step: 1,
        sliderColor: '#38b2ac',
        trackColor: '#E5E5E5',
      };
      break;
    }

    case 'FormSeparator': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormSeparator',
        read_only: true,
        required: false,

        // orientation: 'horizontal',
      };
      break;
    }
    // case 'FormHeading': {
    //   newField = {
    //     field_type: 'FormHeading',
    //     read_only: true,
    //     heading: 'This is a headline',
    //     sub_heading: 'Your subheadline goes here',
    //     heading_size: '48px',
    //     sub_heading_size: '32px',
    //     heading_align: 'left',
    //
    //     required: false,

    //     // orientation: 'horizontal',
    //   };
    //   this.addRow(newField, i);
    //   break;
    // }
    case 'FormTextBox': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormTextBox',
        read_only: true,
        msg: 'Enter Your Text Here...',
        sub_msg: 'Your Response was Submitted',
        required: false,

        // orientation: 'horizontal',
      };
      break;
    }

    case 'FormImage': {
      newField = {
        ...newFieldDefaults,

        field_type: 'FormImage',
        read_only: true,
        required: false,
        logo_url: 'https://beta.formstudio.io/img/form-studio-only-logo.6fa7ef58.svg',
        logo_alt: '',
        logo_width: 300,
        logo_height: 300,
        logo_margin: 0,
        logo_align: 'center',
        // orientation: 'horizontal',
      };
      break;
    }

    case 'FormCustomHTML': {
      newField = {
        ...newFieldDefaults,
        field_type: 'FormCustomHTML',
        read_only: false,
        markup: 'Custom <b>HTML</b> block',
      };
      break;
    }

    case 'Submit': {
      newField = {
        ...newFieldDefaults,
        field_type: 'Submit',
        read_only: false,
      };
      break;
    }
    case 'QuizScore': {
      newField = {
        ...newFieldDefaults,
        field_type: 'QuizScore',
        read_only: true,
      };
      break;
    }
  }
  return {
    ...newFieldDefaults,
    ...newField,
    ...defaults,
    id: uid(),
  };
}
