<template>
  <lv-input :value="value" :placeholder="placeholder" :label="label" :name="name" :required="required" :help-text="help_block_text" @update:modelValue="$emit('input', $event)" type="tel">
    <template #prepend v-if="country_code">
      <div class="country-code">
        {{ country_code }}
      </div>
    </template>
  </lv-input>
</template>

<script>
import LvInput from 'lightvue/input';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  components: {
    LvInput,
  },
  mixins: [trueValueMixin],
  props: ['placeholder', 'label', 'name', 'value', 'required', 'placeholder_required', 'help_block', 'help_block_text', 'country_code'],
};
</script>

<style>
.country-code {
  display: flex;
  align-items: center;
  padding-left: 12px;
  margin-right: -8px !important;
  font-size: 14px;
  margin-bottom: 3px;
}
</style>
