import { UserData } from '@main/models';
import firebase from 'firebase/app';
import 'firebase/auth';

import { setGaUser } from '@main/utils/auth.js';

export const user = {
  namespaced: true,
  state: {
    user: undefined,
  },
  mutations: {
    set: (state, payload: UserData) => {
      state.user = payload;
    },
    delete: state => {
      state.user = undefined;
    },
  },
  actions: {
    // refreshFirebaseToken: async (
    //   context: ActionContext<AuthState, RootState>,
    //   params: { [key: string]: any }
    // ) => {
    //   try {
    //     const response = await axios.get(
    //       `/signin/refresh?version=2&location_id=${activeLocations}`
    //     )
    //     if (response && response.status === 200) {
    //       await firebase.auth().signInWithCustomToken(response.data.token)
    //       localStorage.set('refreshedToken', response.data.token)
    //     }
    //   } catch (e) {}
    // },

    get: context => {
      if (context.state.user) {
        setGaUser(context.state.user._id, context.state.user.email);

        return context.state.user;
      }
      const b64String = localStorage.getItem('a');
      if (!b64String) return false;

      const user = JSON.parse(atob(b64String));
      context.commit('set', user);
      setGaUser(user._id, user.email);
      return user;
    },
    set: (context, payload: UserData) => {
      context.commit('set', payload);
      localStorage.setItem('a', btoa(JSON.stringify(payload)));
      // localStorage.set('loginDate', moment().toDate());
      const hostName = window.location.hostname.split('.');
      const hostLength = hostName.length;
      let domainName = 'localhost';
      if (hostName[0] !== 'localhost') {
        domainName = `.${hostName[hostLength - 2]}.${hostName[hostLength - 1]}`;
      }

      document.cookie = `a=${btoa(JSON.stringify(payload))};domain=${domainName};path=/;max-Age=31536000;`;
    },
    logout: {
      root: true,
      handler: async context => {
        context.commit('delete');
        localStorage.removeItem('a');
        firebase.auth().signOut();
        // localStorage.remove('loginDate');
        document.cookie = 'a=; Max-Age=-99999999;domain=.formstudio.io;path=/;';
      },
    },
  },
};
