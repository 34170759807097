<template>
  <div>
    <Dropdown :options="options" :label="label" :rounded="rounded" :optionLabel="optionLabelKey" :optionValue="optionValueKey" :placeholder="placeholder" :value="modelValue" @input="updateValue" clearable> </Dropdown>
  </div>
</template>

<script>
import { trueValueMixin } from 'lightvue/mixins';
import selectPropsMixin from './selectPropsMixin';

import Dropdown from 'lightvue/dropdown/Dropdown.vue';
export default {
  mixins: [trueValueMixin, selectPropsMixin],

  components: {
    Dropdown,
  },
  props: ['placeholder', 'options', 'rounded', 'label'],
};
</script>

<style></style>
