<template>
  <div class="form-design">
    <!-- <div v-if="selectedSection == 0" class="customizations-inline-label">
      <span>Button Alignment</span>
      <LvButton outlined icon="light-icon-align-left" @click="settings.styles.submit_btn_align = 'left'" />
      <LvButton outlined icon="light-icon-align-center" @click="settings.styles.submit_btn_align = 'center'" />
      <LvButton outlined icon="light-icon-align-right" @click="settings.styles.submit_btn_align = 'right'" />
    </div> -->

    <!-- // ?needs to be ordered properly -->

    <div class="form-styles-fields">
      <ColorPicker v-model="settings.styles.form_theme_color" label="Theme Color" />
    </div>

    <div class="form-styles-fields">
      <ColorPicker v-model="settings.styles.form_layout_color" label="Layout Color" />
    </div>
    <div class="form-styles-fields">
      <ColorPicker v-model="settings.styles.form_background" label="Body Color" />
    </div>

    <h4 class="category_title">Fields</h4>
    <div class="form-styles-fields">
      <d-toggle-switch label="Bottom bar" v-model="settings.styles.bottom_bar" />
    </div>

    <div class="form-styles-fields">
      <d-toggle-switch label="Floating Label" name="FloatingLabel" v-model="settings.styles.floating_label" />
    </div>

    <div class="form-styles-fields">
      <d-toggle-switch label="Rounded Input" name="RoundedInput" v-model="settings.styles.rounded_input" />
    </div>
    <div class="form-styles-fields">
      <d-toggle-switch label="Border" name="reqBorder" v-model="settings.border_width" />
    </div>
    <div class="form-styles-fields">
      <RangeSlider :min="0" label="Border Radius" v-model="settings.styles.border_radius" />
    </div>
    <div class="form-styles-fields">
      <lv-input :bottomBar="true" v-model="settings.styles.border_radius" label="Radius" />
    </div>
    <div class="form-styles-fields">
      <ColorPicker v-model="settings.styles.button_color" label="Button Color" />
    </div>

    <div class="form-styles-fields">
      <RangeSlider :min="100" :max="500" label="Button width" v-model="settings.styles.button_width" />
    </div>
  </div>
</template>

<script>
import RangeSlider from 'lightvue/rangeslider/RangeSlider.vue';
import DToggleSwitch from 'lightvue/input-toggle';
import LvInput from 'lightvue/input';
import ColorPicker from 'lightvue/colorpicker/ColorPicker';
import LvButton from 'lightvue/button/Button.vue';
import formSchemaSettingsMixin from './mixins/computedMixins/formSchemaSettingsMixin.js';

export default {
  mixins: [formSchemaSettingsMixin],
  components: {
    ColorPicker,
    LvInput,
    LvButton,
    RangeSlider,
    DToggleSwitch,
  },
};
</script>

<style>
.form-design {
  padding: 1rem;
}
</style>
