<template>
  <number placeholder="0" :label="label" :min="0" controls :value="modelValue" @input="updateValue" />
</template>
<script>
import Number from 'lightvue/number/Number';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  name: 'FormNumber',
  mixins: [trueValueMixin],

  props: ['placeholder', 'label', 'value', 'required', 'placeholder_required', 'help_block', 'help_block_text'],
  components: { Number },
};
</script>
