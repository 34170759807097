<template>
  <div class="accordian-nav-list__category" :class="{ '--active': activeCategory === this.label }">
    <div class="accordian-nav-list__category-label">
      {{ this.label }}
    </div>
    <i :class="this.activeCategory === this.label ? 'light-icon-chevron-up' : 'light-icon-chevron-down'"></i>
  </div>
</template>

<script>
export default {
  props: ['label', 'activeCategory'],
};
</script>

<style lang="scss">
.accordian-nav-list__category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-top: 1px solid #edf2f6;

  .accordian-nav-list__category-label {
    display: flex;
    align-items: center;
  }
  i {
    font-size: 20px;
    margin-right: 8px;
    min-width: 20px;
    height: 20px;
  }

  color: #203d4a;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #edf2f6;
  }
  &.--active {
    background-color: #edf2f6;
    font-weight: 600;
  }
}
</style>
