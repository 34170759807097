<template>
  <div>
    <div class="lv-html-editor light-scrollbar">
      <div class="lv-html-editor__playground" v-html="markup"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['markup'],
};
</script>

<style lang="scss">
.lv-html-editor {
  // a {
  //   text-decoration: none;
  // }
  // &__playground {
  //   min-height: 100px;
  //   padding: 8px 12px;
  // }
}
</style>
