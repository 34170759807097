<template>
  <!-- <component
    :is="field.field_type"
    v-model="formData[field.name]"
    v-bind="field"
    :rounded="schema.settings.rounded_input"
    :floatingLabel="schema.settings.floating_label"
  ></component>-->
  <div>
    <div class="fs-field_label" :class="`--${field.label_align}`" v-if="!checkFieldLabel && field.label">
      <span :style="{ 'font-size': field.label_size + 'px' }"> {{ field.label }}</span>
    </div>
    <img v-if="field.img_show" :src="field.img_src" alt="" class="fs-fe__radio_image" :style="{ margin: field.img_margin_question + 'px', width: field.img_width_question + 'px', height: field.img_height_question + 'px', 'object-fit': field.img_object_fit_q }" />
    <component :is="field.field_type" v-bind="field" :rounded="settings.styles.rounded_input" :floatingLabel="settings.styles.floating_label" :bottom-bar="settings.styles.bottom_bar" :value="modelValue" @input="updateValue" :settings="settings" @ctaClick="handleCtaClick"></component>
    <div v-if="errors" class="fs-pg__field-errors">
      <div v-for="(error, errorIndex) in errors" :key="errorIndex">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import LvButton from 'lightvue/button';
import FormButton from './elements/FormButton';

import FormToggle from 'lightvue/input-toggle';
import FormInput from './elements/FormInput.vue';
import FormRating from './elements/FormRating.vue';
import FormDropdown from './elements/FormDropdown.vue';
import FormCheckboxGroup from './elements/FormCheckboxGroup.vue';
import FormRadioGroup from './elements/FormRadioGroup.vue';
// import FormSlider from './elements/FormSlider.vue';
import FormTextarea from './elements/FormTextarea.vue';
import FormNumber from './elements/FormNumber.vue';
import FormSeparator from './elements/FormSeparator';
import InputValue from './mixins/InputValue';
import FormRangeSlider from './elements/FormRangeSlider';
import FormTextBox from './elements/FormTextBox';
import FormImage from './elements/FormImage';
import FormButtonSelect from './elements/FormButtonSelect';
import FormPhotoSelect from './elements/FormPhotoSelect';
// import FormHeading from './elements/FormHeading.vue';
// import QuizScore from './quiz-elements/Score.vue';
// import FormLogo from './elements/FormLogo';
import FormContactNumber from './elements/FormContactNumber';
import FormCustomHTML from './elements/FormCustomHTML.vue';

export default {
  name: 'PlaygroundQuestion',
  props: ['field', 'settings', 'errors'],
  mixins: [InputValue],
  emits: ['optionSelect'],
  data() {
    return {
      hasLabel: ['FormButton', 'FormInput', 'FormDropdown', 'FormTextarea', 'FormNumber', 'FormTextBox', 'FormToggle', 'FormContactNumber'],
      // fieldLabel: 'Enter your Label',
    };
  },
  components: {
    FormInput,
    FormToggle,
    LvButton,
    FormButton,
    FormRating,
    FormDropdown,
    FormCheckboxGroup,
    FormRadioGroup,
    FormTextarea,
    FormNumber,
    FormSeparator,
    FormRangeSlider,
    FormTextBox,
    FormImage,
    FormButtonSelect,
    FormPhotoSelect,
    // FormHeading,
    // QuizScore,
    // FormLogo,
    FormContactNumber,
    FormCustomHTML,
  },
  computed: {
    checkFieldLabel() {
      if (this.hasLabel.includes(this.field.field_type)) {
        return true;
      }
    },
  },

  methods: {
    handleCtaClick(cta) {
      this.$emit('ctaClick', cta);
    },
  },
};
</script>

<style lang="scss">
.fs-pg__field-errors {
  color: #ff0b04;
  font-size: 12px;
  margin-top: 4px;
  line-height: 16px;
}
.lv-checkbox .state label:before,
.lv-checkbox .state label:after {
  top: 0px !important;
}

.fs-field_label {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.8;
  color: #495057;
  transition: 0.4s ease-in-out;
  display: flex;
  &.--center {
    justify-content: center;
  }
  &.--right {
    justify-content: flex-end;
  }
  &.--left {
    justify-content: flex-start;
  }
}
</style>
