<template>
  <!-- RURBAN -->
  <div class="dropdown">
    <div class="dropdown-label">
      {{ userName }}
    </div>
    <div class="dropdown-content">
      <ul>
        <!-- <li @click="$router.push({ name: 'Account' })"><i class="light-icon-user"></i>Account</li>
        <li @click="$router.push({ name: 'Integerations' })"><i class="light-icon-credit-card"></i>Integerations</li> -->
        <!-- <li @click="$router.push({ name: 'CustomDomain' })">
          <i class="light-icon-hash"></i>Custom Domains
        </li> -->
        <!-- <li @click="$router.push({ name: 'Contact' })">
          <i class="light-icon-phone-check"></i>Contact Us
        </li> -->
        <li @click="signout"><i class="light-icon-power"></i>Sign Out</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userName'],
  methods: {
    signout: function() {
      this.$store.dispatch('user/logout');
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  font-size: 16px;
  color: #fff;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: calc(100% + 72px);
  min-width: 150px;
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 13px;
  color: #203d4a;
  right: -16px;
  top: 100%;
}

.dropdown:hover .dropdown-content {
  display: block;
  /* flex-direction: column; */
}

.dropdown-content ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.dropdown-content li {
  padding: 10px 16px;
  cursor: pointer;
  background-color: #f9f9f9;
  display: flex;
}

.dropdown-content li:nth-child(2n-1) {
  background-color: #f5f8fa;
}

.dropdown-content li:hover {
  background-color: #c9c9c9;
}

.dropdown-content li i {
  font-size: 16px;
  margin-right: 12px;
  width: 20px;
  margin-left: 8px;
}
</style>
