<template>
  <div :style="styles" :class="cssClasses">
    <canvas :id="chartId" :width="width" :height="height" ref="canvas" />
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  props: {
    chartId: {
      default: 'chartId',
      type: String,
    },
    width: {
      default: 400,
      type: Number,
    },
    height: {
      default: 400,
      type: Number,
    },
    cssClasses: {
      type: String,
      default: '',
    },
    styles: {
      type: Object,
    },
    plugins: {
      type: Array,
      default() {
        return [];
      },
    },
    chartType: {
      type: String,
      default: 'bar',
    },
  },

  data() {
    return {
      _chart: null,
      _plugins: this.plugins,
    };
  },

  methods: {
    addPlugin(plugin) {
      this.$data._plugins.push(plugin);
    },
    generateLegend() {
      if (this.$data._chart) {
        return this.$data._chart.generateLegend();
      }
    },
    renderChart(data, options) {
      if (this.$data._chart) this.$data._chart.destroy();
      if (!this.$refs.canvas) throw new Error('Please remove the <template></template> tags from your chart component.');
      this.$data._chart = new Chart(this.$refs.canvas.getContext('2d'), {
        type: this.chartType,
        data: data,
        options: options,
        plugins: this.$data._plugins,
      });
    },
  },
  beforeDestroy() {
    if (this.$data._chart) {
      this.$data._chart.destroy();
    }
  },
};
</script>
