<template>
  <div>
    <div class="profile-header-bg-container">
      <div class="header-icons-row">
        <div><i class="light-icon-bell" alt="Notifications"></i></div>
        <div><i class="light-icon-command"></i></div>
        <div><i class="light-icon-compass"></i></div>
      </div>
      <div class="user-profile-hero-card">
        <div class="user-profile-img-div">
          <img :src="user.photo_url" alt="" class="user-profile-pic" v-if="user.photo_url" />
          <i v-if="!user.photo_url" class="light-icon-user"></i>
        </div>
        <div class="user-quick-details">
          <h1 class="user-name">{{ user.name }}</h1>
          <div>
            <p>{{ user.email_id }}</p>
          </div>
          <div>
            <p>Joined FormStudio at {{ join_date }}</p>
          </div>
          <div>
            <p>Created {{ user.forms_count }} Forms. Collaborates on 1 team</p>
          </div>
        </div>
      </div>
      <!-- Hero Card Ends -->
    </div>
    <!-- USer Profile Header Ends -->
    <!-- User Profle Card Layout -->
    <div class="user-profile-container">
      <div class="user-profile-layout">
        <div class="user-profile-sidebar">
          <ul class="user-sidebar-section">
            <li><a href=""> General</a></li>
            <li><a href=""> Profile</a></li>
            <li><a href=""> Account</a></li>
            <li><a href=""> Affilate Program</a></li>
            <li><a href=""> Security</a></li>
            <li><a href=""> Applications</a></li>
          </ul>
        </div>
        <div class="user-main-sections">
          <div class="user-profile-section">
            <div class="profile-section-header">
              Profile
              <div class="profile-section-subhead">
                Your Personal Information
              </div>
            </div>
            <!------------- Personal Info Card ------------->
            <div class="personal-info-card">
              <div class="personal-info-header">Personal Information</div>
              <div class="personal-info-form">
                <!-- Non Editable user details -->
                <div v-if="personalInfoEdit == false">
                  <div class="details-display-form">
                    <!-- Name Block -->
                    <div class="details-display-row">
                      <div style="margin-right: 24px">Name :</div>
                      <div>{{ user.name }}</div>
                    </div>
                    <!-- Email Block -->
                    <div class="details-display-row">
                      <div style="margin-right: 24px">Email :</div>
                      <div>{{ user.email_id }}</div>
                    </div>
                    <!-- Timezone Block -->
                    <div class="details-display-row">
                      <div style="margin-right: 24px">Timezone :</div>
                      <div>Not set</div>
                    </div>
                    <!-- Save & Cancel Row -->
                    <div class="save-cancel-row">
                      <lv-button class="lv--success" push size="md" @click="personalInfoEdit = true">
                        Edit
                      </lv-button>
                    </div>
                    <!-- ----- -->
                  </div>
                </div>
                <div v-if="personalInfoEdit == true">
                  <div class="name-block">
                    <lv-input placeholder="placeholder" label="Name" v-model="user.name" floating-label @input="$emit('input', $event)" />
                  </div>
                  <!-- Email Block -->
                  <div class="name-block">
                    <lv-input placeholder="placeholder" label="Email" floating-label v-model="user.email_id" @input="$emit('input', $event)" />
                  </div>
                  <!-- Password Block -->
                  <!-- <div class="name-block">
                  <lv-input
                    placeholder="placeholder"
                    label="Password"
                    v-model="user.photo_url"
                    floating-label
                    @input="$emit('input', $event)"
                  />
                </div> -->
                  <!-- Timezone Block -->
                  <div class="name-block">
                    <lv-input placeholder="placeholder" label="Your Timezone" floating-label @input="$emit('input', $event)" />
                  </div>

                  <!-- EDit Button -->
                  <div class="edit-row">
                    <lv-button class="lv--success" push size="md" style="margin-right: 16px" @click="personalInfoEdit = false">
                      Save
                    </lv-button>
                    <lv-button class="lv--success" push size="md" @click="personalInfoEdit = false">
                      Cancel
                    </lv-button>
                  </div>
                  <!--  -->
                </div>
              </div>
            </div>
            <!--Personal Info Card Ends -->

            <!------------- Account Details Card ------------->
            <div class="account-details-card fs-up__card-wrap">
              <div class="account-details-header">Account Details</div>
              <div class="account-details-form">
                <hidden-setings-overlay />
                <!-- Plan Block -->
                <div class="plan-block">
                  <lv-input placeholder="placeholder" label="Plan" floating-label @input="$emit('input', $event)" />
                </div>
                <!-- Send Monthly Reports Block -->
                <div class="plan-block">
                  <lv-input placeholder="placeholder" label="Send Monthly Reports" floating-label @input="$emit('input', $event)" />
                </div>
                <!-- Member since Block -->
                <div class="name-block">
                  <lv-input placeholder="placeholder" label="Member since" floating-label @input="$emit('input', $event)" />
                </div>
                <!-- Last Updated -->
                <div class="name-block">
                  <lv-input placeholder="placeholder" label="Last Updated" floating-label @input="$emit('input', $event)" />
                </div>

                <!-- EDit & Save Button -->
                <div class="edit-row">
                  <lv-button class="lv--success" push size="md">
                    Edit
                  </lv-button>
                </div>
                <!--  -->
              </div>
            </div>
            <!--Account Details Card Ends -->
            <!------------- Affilate Program Card ------------->
            <div class="affilate-program-card fs-up__card-wrap">
              <div class="affilate-program-header">Affilate Program</div>
              <div class="affilate-program-form">
                <hidden-setings-overlay />

                <!-- Plan Block -->
                <div class="plan-block">
                  <lv-input placeholder="placeholder" label="Not yet enrolled" floating-label @input="$emit('input', $event)" />
                </div>

                <!-- EDit & Save Button -->
                <div class="edit-row">
                  <lv-button class="lv--success" push size="md">
                    Edit
                  </lv-button>
                </div>
                <!--  -->
              </div>
            </div>
            <!--Affilate Program Card Ends -->
          </div>
          <div class="copyright-footer">
            <copyright-footer />
          </div>
        </div>
        <!-- Main Cards Section end -->
      </div>
    </div>
  </div>
</template>

<script>
// import Navbar from "../components/dashboard/Navbar.vue";
import CopyrightFooter from '../components/CopyrightFooter.vue';
import LvInput from 'lightvue/input';
import LvButton from 'lightvue/button';
import { userMixin } from '@main/mixins';
import HiddenSetingsOverlay from './settings/HiddenSetingsOverlay.vue';
export default {
  mixins: [userMixin],
  components: {
    // Navbar,
    LvInput,
    LvButton,
    CopyrightFooter,
    HiddenSetingsOverlay,
  },
  data() {
    return {
      join_date: '',
      personalInfoEdit: false,
    };
  },
  created() {
    console.log('user', this.user);
    const TimestampDate = this.user._date_added;
    // console.log(TimestampDate.toDate());
    this.join_date = new Date(TimestampDate.seconds * 1000);
  },
  methods: {},
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
}

.profile-header-bg-container {
  background-color: #203d4a;
  position: relative;
  /* display: block; */
  height: 160px;
  margin-bottom: 100px;
  /* left: 0; */
  /* position: absolute; */
  /* top: 0; */
  width: 100%;
  padding: 0px 44px;
}
/* z-index: -1; */

.header-icons-row {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0px;
}
.header-icons-row i {
  color: #7e878b;
  font-size: 26px;
  font-weight: 500;
  margin-left: 10px;

  cursor: pointer;
}

.header-icons-row i:hover {
  color: #ffffff;
}

.user-profile-hero-card {
  /* top: 84px;
  position: fixed; */
  /* max-width: 680px; */
  width: 100%;
  height: 176px;
  margin: 20px 0px 0px 0px;
  padding: 24px;
  background-color: #ffffff;
  color: #2e3c42;
  border-radius: 6px;
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.user-quick-details {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: 400;
}
.user-quick-details p {
  color: #646e73;
  line-height: 24px;
  margin: 8px 0px 0px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.user-profile-img-div {
  margin-right: 30px;
}

.user-profile-pic,
.light-icon-user {
  width: 128px;
  height: 128px;
  font-size: 128px;
  border: 1px solid hsl(198deg 5% 92%);

  border-radius: 50%;
}

.user-profile-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  position: relative;
  width: auto;
}

.user-profile-layout {
  padding: 44px;
  display: flex;
}

.user-profile-sidebar {
  background-color: #ffffff;
  width: 264px;

  height: 226px;
  margin: 0 24px 0px 0px;
  position: sticky;
  border: 1px solid hsl(198deg 5% 92%);
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.user-sidebar-section a {
  text-decoration: none;
  color: #0e1e25;
}
.user-sidebar-section li {
  list-style-type: none;
  border-top: 1px solid hsl(198deg 5% 92%);
  padding: 8px 16px;
  border-left: 2px solid teal;
  /* cursor: pointer; */
}

.user-main-sections {
  /* margin: 24px 0px 0px; */
}

.user-profile-section {
  display: flex;
  flex-direction: column;
}
.profile-section-header {
  padding: 8px 0px;
  font-size: 24px;
  font-weight: 500;
  color: #2e3c42;
  margin: -8px 0px;
}

.profile-section-subhead {
  color: #646e73;
  font-size: 14px;
  margin: 8px 0px;
}

.personal-info-card,
.account-details-card,
.affilate-program-card {
  width: 862px;
  background-color: #ffffff;
  border-radius: 6px;
  margin: 16px 0px 0px;
  padding: 24px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.personal-info-header,
.account-details-header,
.affilate-program-header {
  font-size: 20px;
  font-weight: 400;
  /* line-height: 1.5; */
  padding: 0px 0px 8px 0px;
  border-bottom: 1px solid hsl(198deg 5% 92%);
}

.details-display-row {
  margin-top: 24px;
  /* padding: 10px 0px; */
  /* padding-left: 12px; */
  /* border-radius: 5px; */
}

/* .details-display-row:nth-child(odd) {
  background-color: #edf2f7;
} */

.details-display-row {
  display: flex;
}

.edit-btn {
  padding: 8px 14px;
  background: teal;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 16px;
  border-radius: 12px;
  border: none;
  outline: none;
  margin-bottom: 20px;
}

.edit-row,
.save-cancel-row {
  margin-top: 28px;
}

.copyright-footer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 8px;
}

.fs-up__card-wrap {
  position: relative;
}
</style>
