<template>
  <!-- RURBAN -->
  <div class="dashboard-page">
    <!-- <templates /> -->
    <recent-forms />
  </div>
</template>

<script>
import RecentForms from '../components/dashboard/RecentForms.vue';
import Templates from '../components/dashboard/Templates.vue';

export default {
  components: {
    RecentForms,
    Templates,
  },
};
</script>

<style>
.dashboard-page {
  /* padding: 50px; */
  overflow-x: hidden;
}
</style>
