import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import user from '../store/user/index';
import store from '../store';

const requireAuth = (to, from, next) => {
  store
    .dispatch('user/get')
    .then(user => {
      // console.log(user);
      if (user) {
        //user signed in,proceed to route
        next();
      } else {
        store.dispatch('user/logout');
        //no user signed in,redirect to login
        next({ name: 'Login' });
      }
    })
    .catch(err => {
      next({ name: 'Login' });
    });
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Login' },
    // component: () => import(/* webpackChunkName: "home" */ '../pages/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/Login.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../pages/Signup.vue'),
  },
  {
    path: '/dashboard',
    name: 'DashboardLayout',
    component: () => import('../DashboardLayout.vue'),
    beforeEnter: requireAuth,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../pages/Dashboard.vue'),
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('../pages/Profile.vue'),
      },
      {
        path: '/project',
        name: 'ProjectType',
        component: () => import('../pages/ProjectTypes.vue'),
      },
      {
        path: '/quiz',
        name: 'QuizComingSoon',
        component: () => import('../pages/QuizPage.vue'),
      },
      {
        path: '/quiz-beta',
        name: 'QuizTemplates',
        component: () => import('../pages/quiz/QuizTemplates.vue'),
      },
      {
        path: '/integerations',
        name: 'Integerations',
        component: () => import('../pages/Integerations.vue'),
      },
      {
        path: '/custom-domains',
        name: 'CustomDomain',
        component: () => import('../pages/settings/CustomDomains.vue'),
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('../pages/ContactUs.vue'),
      },
      {
        path: '/response/:form_slug',
        name: 'ResponsesDashboard',
        component: () => import('../pages/ResponsesDash.vue'),
      },
      {
        path: '/response-stats/:form_slug',
        name: 'ResponsesStats',
        component: () => import('../pages/ResponsesStats.vue'),
      },
      {
        path: '/response-old/:form_slug',
        name: 'Responses',
        component: () => import('../pages/Responses.vue'),
      },
      {
        path: '/create',
        name: 'Create',
        component: () => import('@main/pages/Create.vue'),
      },

      {
        path: '/editor',
        name: 'Editor',
        component: () => import('@main/pages/Editor.vue'),

        children: [
          {
            path: 'settings',
            name: 'form-settings',
            component: () => import('../pages/settings/FormSettings.vue'),
          },
          {
            path: 'preview',
            name: 'Preview',
            component: () => import('../pages/Preview.vue'),
          },

          {
            path: 'publish',
            name: 'Publish',
            component: () => import('../components/Publish.vue'),
          },
        ],
      },
      // {
      //   path: '/template/editor',
      //   name: 'TemplateEditor',
      //   component: () => import('@main/pages/Editor.vue'),

      //   children: [
      //     // {
      //     //   path: 'settings',
      //     //   name: 'Settings',
      //     //   component: () => import('../pages/settings/GeneralSettings.vue')
      //     // },
      //     {
      //       path: 'preview',
      //       name: 'Preview',
      //       // component: () => import('builder/Preview.vue')
      //       component: () => import('../pages/Preview.vue'),
      //     },

      //     {
      //       path: 'publish',
      //       name: 'Publish',
      //       component: () => import('../components/Publish.vue'),
      //     },
      //   ],
      // },
    ],
  },

  {
    path: '/p',
    name: 'Form',
    component: () => import('@main/pages/PreviewParent.vue'),

    children: [
      {
        path: ':form_slug',
        name: 'Submit',
        component: () => import('../pages/Preview.vue'),
      },

      // {
      //   path: 'embed/:form_slug',
      //   name: 'Submit',
      //   component: () => import('../pages/Preview.vue'),
      // },
      {
        path: 'thanks',
        name: 'ThankYou',
        component: () => import('../pages/ThankYou.vue'),
      },

      // {
      //   path: 'thanks',
      //   name: 'ThankYou',
      //   component: () => import('../pages/ThankYou.vue')
      // },
    ],
  },

  // Templates-----------------

  {
    path: '/:catchAll(.*)',
    name: 'ErrorPage',
    component: () => import('../pages/ErrorPage.vue'),
  },
  // {
  //   path: '/response/:form_slug',
  //   name: 'Responses',
  //   component: () => import('@main/pages/Responses.vue'),

  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const el = document.getElementById(to.hash.substring(1));
      if (el) {
        // a valid id on page.
        setTimeout(() => {
          return window.scrollTo({ top: el.offsetTop - 20, behavior: 'smooth' });
        }, 500); // wait before scrolling, for initial loading delay
        return;
      }
    }
    // if (to.hash) {
    //   const el = document.getElementById(to.hash.substring(1));
    //   return {
    //     el: to.hash,
    //     // top: el.offsetTop - 20,
    //     behavior: 'smooth',
    //   }
    // }
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
});

export default router;
