import { createStore } from 'vuex';
// import user from './user/index';
import application from './application/index';
import { user } from './auth_user';
// import VuexPersistence from 'vuex-persist';

// const vuexLocal = new VuexPersistence({
//   key: 'info',
//   storage: window.sessionStorage,
// });

export default createStore({
  modules: {
    // auth,
    user,
    application,
  },
  // plugins: [vuexLocal.plugin]
});
