import { createNewField, createNewRow, createNewSection } from '../types';

const defaultSection = createNewSection(); // Default Section

const defaultIntroSection = createNewSection({
  // Intro Section
  type: 'Intro',
  heading_visibility: false,
  heading: 'Intro',
  rows: [
    createNewRow({
      fields: [createNewField('FormImage')],
    }),
  ],
});

const defaultThankyouSection = createNewSection({
  // Default Thankyou Section
  type: 'ThankYou',
  heading_visibility: false,
  hidden: false,
  config: {
    default_btn: true,
  },
  rows: [
    createNewRow({
      fields: [createNewField('FormTextBox')],
    }),
    createNewRow({
      fields: [
        createNewField(
          'FormButton',
          {
            name: 'multiple_res_btn',
            background_color: '#638597',
          },
          {
            cta_type: 'resubmit',
          }
        ),
      ],
    }),
  ],
});

const defaultQuizIntroSection = createNewSection({
  // Intro Section
  type: 'Intro',
  heading_visibility: false,
  heading: 'Intro',
  rows: [
    createNewRow({
      fields: [
        createNewField(
          'FormButton',
          {
            name: 'multiple_res_btn',
            label: 'Start Quiz',
            deepShadow: false,
            // background_color: '#638597',
            font_color: '',
          },
          {
            cta_type: 'normal',
          }
        ),
      ],
    }),
  ],
});

export { defaultSection, defaultIntroSection, defaultThankyouSection, defaultQuizIntroSection };
