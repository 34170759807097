module.exports = {
  USER_ID_PREFIX: 'usr',
  FORM_ID_PREFIX: 'form',
  CTA_ACTION_TYPES: {
    NEXT_SECTION: 'NEXT_SECTION',
    PREV_SECTION: 'PREV_SECTION',
    SUBMIT: 'SUBMIT',
    SUBMIT_ANOTHER: 'SUBMIT_ANOTHER',
    REDIRECT: 'REDIRECT',
    OPEN_LINK: 'OPEN_LINK',
  },
};
