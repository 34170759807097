<template>
  <div class="right-panel-container">
    <div class="dots" id="dot-right-1"></div>
    <div class="dots" id="dot-right-2"></div>
    <div class="right-panel-content">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#f2faf5"
          fill-opacity="1"
          d="M0,64L60,85.3C120,107,240,149,360,138.7C480,128,600,64,720,80C840,96,960,192,1080,224C1200,256,1320,224,1380,208L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        />
      </svg>
      <img src="../assets/Vector-photo.png" class="right-photo" />
      <div class="bottom-wave">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#f2faf5"
            fill-opacity="1"
            d="M0,288L60,266.7C120,245,240,203,360,181.3C480,160,600,160,720,176C840,192,960,224,1080,229.3C1200,235,1320,213,1380,202.7L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          />
        </svg>
        <div class="bottom-bg"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.right-panel-container {
  background: #e0efe6;
  position: relative;
}

.right-panel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.right-photo {
  width: 80%;
  display: block;
  margin: auto;
  z-index: 2;
}
.bottom-wave {
  position: absolute;
  bottom: 0px;
  height: 50%;
  width: 100%;
  z-index: 0;
}
.bottom-wave .bottom-bg {
  width: 100%;
  height: 100%;
  background-color: #f2faf5;
  margin-top: -10px;
}

.dots {
  position: absolute;
  border-radius: 50%;
}
#dot-right-1 {
  width: 23px;
  height: 23px;
  top: 100px;
  left: 100px;
  background: #72c791;
}

#dot-right-2 {
  width: 51px;
  height: 51px;
  top: 180px;
  left: 300px;
  background: #72c791;
}
</style>