<template>
  <div>
    <container @drop="dndDrop" @dragStart="dndDragStart" @dragEnd="dndDragEnd" :orientation="vertical">
      <draggable v-for="(question, j) in section.rows" :key="'row_' + question.id" class="question-wrap" @click.stop="selectRow(j)" @mouseover.stop="selectHoverRow(j)" @mouseenter="hoveredOptionIndex = j" @mouseleave="hoveredOptionIndex = -1">
        <div :class="{ '--active': selectedOptionId === index }">
          <div class="question-mover" :class="{ '--active': hoveredOptionIndex == j }">
            <i class="light-icon-grip-vertical" />
          </div>
          <div class="question-content">
            <!-- label -->
            <div class="question-icon">
              <i :class="questionIcon(question.fields[0].field_type)"></i>
            </div>
            <div class="question-label">{{ question.fields[0].label }}</div>
            <!-- question icon -->
            <!-- marking -->
            <div class="question-marking">
              <!-- <div class="positive-marking__badge">+ {{ question.fields[0].individual_score || 1 }}</div>
              <div class="negative-marking__badge">
                - <span v-if="schema.quiz_settings?.negative_marking">{{ question.fields[0].negative_score || 1 }}</span
                ><span v-else>0</span>
              </div> -->
            </div>
            <div class="question-marking">
              <lv-input class="question-marking__input --positive" type="number" min="0" max="100" style="width:44px;" v-model="question.fields[0].individual_score" />
              <lv-input class="question-marking__input --negative" type="number" min="0" max="100" style="width:44px; margin-top: 2px" v-model="question.fields[0].negative_score" />
            </div>
          </div>
        </div>
      </draggable>
    </container>
  </div>
</template>

<script>
import { Container, Draggable } from '../smooth-dnd/main';
import LvInput from 'lightvue/input';
import {elementsList} from '../elements/Elements.js'
export default {
  components: {
    Draggable,
    Container,
    LvInput,
  },
  data() {
    return {
      dragging: false,
      hoveredOptionIndex: -1,

    };
  },
  props: ['section'],
  computed: {
    schema: {
      get: function() {
        return this.$store.state.builder.schema;
      },
      set: function(newValue) {
        this.$store.dispatch('builder/setSchema', newValue);
      },
    },
  },

  methods: {
    questionIcon(type) {   
      var quesIcon = '';
      elementsList.forEach(element => {
        if (element.type == type) {
          quesIcon = element.icon;
          return element.icon;
        }
      });
      return quesIcon;
    },
    dndDragStart(e) {
      this.dragging = true;
    },

    dndDragEnd(e) {
      this.dragging = false;
    },
    dndDrop(e) {
      // console.log(e);
      this.dragging = false;
      this.moveRow(e.removedIndex, e.addedIndex);
    },
    selectRow(rowIndex) {
      if (!this.dragging) this.hoveredOptionIndex = rowIndex;
    },
    selectHoverRow(rowIndex) {
      if (!this.dragging) this.hoveredOptionIndex = rowIndex;
    },
    moveRow(fromIndex, toIndex) {
      console.log(`Moving question from ${fromIndex} to ${toIndex}`);
      console.log('section.rows', this.section.rows);

      let removedField = this.section.rows[fromIndex];
      // Deleting fromIndex
      this.section.rows.splice(fromIndex, 1);
      // Adding toIndex
      this.section.rows.splice(toIndex, 0, removedField);
      // this.changeSelectedSection(toIndex);
    },
  },
};
</script>

<style lang="scss">
.question-wrap {
  background-color: white;
  box-shadow: 2px 2px 3px silver;
  padding: 4px 8px;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  margin: 8px 0px;
  border-radius: 0 8px 8px 0;
  position: relative;
  // display: flex;
  // justify-content: space-between;
  min-height: 44px;
  .--active {
    background-color: silver;
  }

  .question-mover {
    opacity: 0;
    position: absolute;
    top: 0px;
    right: 100%;
    height: 100%;
    width: 16px;
    background-color: #607c8a;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
    cursor: move;
    border-radius: 4px 0px 0px 4px;
    &.--active {
      opacity: 1;
    }
    /* box-shadow: 3px 3px 12px 3px #ebebeb; */
  }
}
.question-wrap:nth-child(even) {
  // background-color: #eee;
}
.question-wrap:hover {
  // box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}
.question-content {
  display: flex;
  padding: 4px;
  background-color: #ffffff;

  .question-label {
    font-size: 14px;
    display: block;
    width: 380px;
  }
  .question-icon {
    width: 38px;
    height: 38px;
    border: 1px solid #dedede;
    border-radius: 50%;
    padding: 4px;
    text-align: center;
    margin-right: 8px;
    background-color: #b6e1f1;
    i {
      color: rgb(48, 46, 46);
      font-size: 24px;
    }
  }
  .question-marking {
    // width: 44px;
    // // position: absolute;
    // // right: 8px;
    // // height: 8px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // text-align: center;
    // border-radius: 2px;
    // overflow: hidden;

    display: flex;
    flex-direction: column;
    // height: 40px;

    margin-top: 4px;

    &__input {
      color: white;
      height: 16px;
      border-radius: 4px;
      text-align: center;
      &.--positive {
        background-color: #7ec01f;
      }
      &.--negative {
        background-color: #f24f14;
      }
    }

    // .positive-marking__badge {
    //   background-color: #7ec01f;
    //   color: #fff;
    //   padding: 2px 4px;
    //   font-size: 12px;
    //   width: 40px;
    //   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    // }
    // .negative-marking__badge {
    //   font-size: 12px;
    //   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    //   background-color: #f24f14;
    //   // border-radius: 4px;
    //   color: #fff;
    //   padding: 2px 4px;
    //   width: 40px;
    // }
  }
}
</style>
