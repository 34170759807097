<template>
  <Rating :increment="+increment" :maxRating="+maxRating" :borderColor="active_color" :active-color="active_color" :inactive-color="inactive_color" :star-size="+star_size" :value="modelValue" @input="updateValue"></Rating>
</template>

<script>
import Rating from 'lightvue/rating/Rating.vue';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  data() {
    return {};
  },
  components: {
    Rating,
  },
  props: ['increment', 'maxRating', 'active_color', 'inactive_color', 'star_size'],
};

// for database underscore case(snake_case)
// for vue props either camelCase or kebab-case
</script>

<style></style>
