export default {
  computed: {
    schema: {
      get: function() {
        return this.$store.state.builder.schema;
      },
      // set: function (newValue) {
      //   this.$store.dispatch("builder/setHeadCo", newValue);
      // },
    },
  },
};
