<template>
  <response-single-chart v-for="chart of charts" style="margin-top: 40px;" :fieldId="chart.fieldId" :fieldLabel="chart.fieldLabel" :formData="formData" :responses="responses" :chartType="chart.chartType" :showHeader="true"> </response-single-chart>
</template>

<script>
import ResponseSingleChart from './ResponseSingleChart';

export default {
  components: {
    ResponseSingleChart,
  },

  props: ['formData', 'responses'],

  data() {
    return {
      charts: [],
      typeToCharts: {
        FormDropdown: 'Bar',
        FormToggle: 'Doughnut',
        FormRangeSlider: 'Horizontalbar',
      },
    };
  },
  created() {
    this.formData.schema.sections.forEach(section => {
      section.rows.forEach(row => {
        row.fields.forEach(field => {
          if (this.typeToCharts[field.field_type]) {
            this.charts.push({
              fieldId: field.id,
              fieldLabel: field.label,
              chartType: this.typeToCharts[field.field_type],
            });
          }
        });
      });
    });
  },
};
</script>

<style></style>
